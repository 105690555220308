export class Modulo {
    idModulo: number;
    nombre: string;
    idIdioma:number;
    hasHtml: boolean;
    html: string;
    hasUrl: boolean;
    urlModulo: string;
    fotoUrl: string;
    direccion: string;
    listaTraducciones: ModuloTraduccion[];
}

export class ModuloTraduccion {
    id: number;
    nombre: string;
    idIdioma: number;
    nombreIdioma: string;
    idModulo: number;
    filled: boolean;
    html: string
}
