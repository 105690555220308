import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/menu`;

@Injectable()
export class MenuService {
  public titulo: string;

  constructor(private http: HttpClient) {
    this.titulo = "HOME";
  }

  updateUserSettings(idusuario: number, idDb: number, idioma: string, tema: number, menuExpandido: boolean) {
    return this.http.post<number>(`${baseUrl}/updateUserSettings`, { idusuario, idDb, idioma, tema, menuExpandido }, { withCredentials: true }).subscribe();
  }

}
