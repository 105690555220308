import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { not } from '@angular/compiler/src/output/output_ast';
import {
  Encuesta,
  EncuestaModel,
  EncuestaTraduccion,
} from '@app/_models/encuesta';
import { Categoria } from '@app/_models/categoria';

const baseUrl = `${environment.apiUrl}/encuesta`;

@Injectable({
  providedIn: 'root',
})
export class EncuestasService {
  constructor(private http: HttpClient) {}

  public GetById(id: Number): Observable<any> {
    return this.http.get(baseUrl + '/' + id);
  }
  public getEntesAdministrados(): Observable<any> {
    return this.http.get(
      environment.apiUrl + '/organizacion/getentesuser/' + '1'
    ); //idUser no necesario
  }

  public getEncuestasByEnte(idOrg: string, lang: number): Observable<any> {
    return this.http.get(
      environment.apiUrl + '/encuesta/getall/' + idOrg + '/' + lang
    ); //idUser no necesario
  }

  public getEmcuestaByIdLang(
    idEncuesta: number,
    lang: number
  ): Observable<any> {
    return this.http.get(baseUrl + '/get/' + idEncuesta + '/' + lang); //idUser no necesario
  }

  public getOpcionesFromEncuesta(
    idEncuesta: number,
    lang: number
  ): Observable<any> {
    return this.http.get(baseUrl + '/opciones/' + idEncuesta + '/' + lang);
  }

  public loadCategorias(ididioma: string, idOrg: string): Observable<any> {
    return this.http.get(baseUrl + '/categorias/' + idOrg + '/' + ididioma);
  }
  public getCategoriaById(idCategoria: number, lang: number): Observable<any> {
    return this.http.get(
      baseUrl + '/categorias/get/' + idCategoria + '/' + lang
    );
  }

  public crearCategoria(
    listaCategoriaTraducciones,
    idOrg: string
  ): Observable<any> {
    console.log('Enviando Traducciones...');

    return this.http.post(
      baseUrl + '/categorias/crear/' + idOrg.toString(),
      JSON.stringify(listaCategoriaTraducciones),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public actualizarCategoria(myCategoria: Categoria): Observable<any> {
    console.log('Enviando Traducciones...');

    return this.http.post(
      baseUrl + '/categorias/update',
      JSON.stringify(myCategoria),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public crearEncuesta(myModel: EncuestaModel): Observable<any> {

      myModel.encuesta.fecha_inicio = new Date(this.dateToYYYYMMDDtHHmmSSz(myModel.encuesta.fecha_inicio));
      myModel.encuesta.fecha_fin = new Date(this.dateToYYYYMMDDtHHmmSSz(myModel.encuesta.fecha_fin));

    console.log('Creando Encuesta...');
    console.log(myModel.encuesta);

    return this.http.post(
      baseUrl + '/crearw/',
      JSON.stringify(myModel),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }
  public addTraduccionesEvento(listaTraducciones, idEvento): Observable<any> {
    console.log('Enviando Traducciones...');

    return this.http.post(
      baseUrl + '/addtradsw/' + idEvento.toString(),
      JSON.stringify(listaTraducciones),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public actualizarEncuesta(myEncuesta: Encuesta): Observable<any> {

    myEncuesta.fecha_inicio = new Date(this.dateToYYYYMMDDtHHmmSSz(myEncuesta.fecha_inicio));
    myEncuesta.fecha_fin = new Date(this.dateToYYYYMMDDtHHmmSSz(myEncuesta.fecha_fin));


    console.log('Actualizando Encuesta...');
    console.log(myEncuesta);
    return this.http.post(
      baseUrl + '/update/',
      JSON.stringify(myEncuesta),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  // public actualizarEncuesta(myEncuesta: Encuesta): Observable<any> {
  //   //Función para que mantanga las fechas en Local en vez de transformar automaticamente en UTC
  //   var replacer = function (key, value) {
  //     if (this[key] instanceof Date) {
  //       return this[key].toLocaleString();
  //     }

  //     return value;
  //   };

  //   console.log('Actualizando Encuesta...');
  //   console.log(myEncuesta);
  //   return this.http.post(
  //     baseUrl + '/update/',
  //     JSON.stringify(myEncuesta,replacer),
  //     {
  //       headers: new HttpHeaders({
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json',
  //         charset: 'utf-8',
  //       }),
  //       withCredentials: true,
  //     }
  //   );
  // }


  delete(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminar', formData, {
      withCredentials: true,
    });
  }

  deleteCategorias(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminarcategorias', formData, {
      withCredentials: true,
    });
  }

  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return año + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
    }

    addZero(num){
      if(num<10) return '0'+num;
      else return num;
    }

}
