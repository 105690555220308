export class Evento {
    id: string;
    titulo: string;
    descripcion: string;
    categoria:string;
    idCategoria:number;
    idIdioma:number;
    fotoUrl:string;
    fecha:Date;
    fechaInciio:Date;
    fechaFin:Date;
    idOrg:string;
    recurrenciaAnual:boolean;
    recurrenciaMensual:boolean;
    recurrenciaSemanal:boolean;
    recurrenciaDiario:boolean;
    mes:number;
    diasemana:number;
    dia:number;
    isInscripcion:boolean;
    maxInscritosPersona:number;
    aforo:number;
    inscritos:number;
    isAllDay:boolean;
    updateFoto:boolean;

}

export class EventoTraduccion {
    id: string;
    titulo: string;
    cuerpo: string;
    idIdioma:number;
    nombreIdioma:string;
    idEvento:number;
    filled:boolean;
}
export class Recurrencia {
    id: number;
    nombre: string;
}

export class EventoModel {
    evento: Evento;
    listaTraducciones: EventoTraduccion[];
}
