<div class="row  pl-4" [hidden]="numeroPerfiles > 6">
  <button kendoButton (click)="addPerfil()">Crear nuevo perfil para el comedor</button>
</div>
<div class="row p-4">
  <kendo-tabstrip (tabClose)="tabCloseHandler($event)" (tabSelect)="onTabSelect($event)" #perfilTabs style="width: 90vw; ">
    <kendo-tabstrip-tab *ngFor="let perfil of getValues(mapaPerfiles); let i=index" [title]="perfil.nombre"  [selected]="i == selectedIndex" [closable]="i!==0" >
      <ng-template kendoTabContent>
        <div class="clearfix row">
          <div class="col-comedor">
            <div class="card">
              <div class="card-header">
                <h3>
                  <label CssClass="">{{ 'restaurante.comedor' | translate }}</label>
                </h3>
                <div class="plegarpanel"></div>
              </div>
              <div class="card-body">
                <div class="example-boundary" [ngStyle]="{'background-image': 'url(' + fondo + ')'}" [style.width.px]="tamano_panel.ancho"
                [style.height.px]="tamano_panel.alto"
                  style="background-size: cover; background-repeat: no-repeat;  background-position: center;">
                  <div *ngFor="let mesa of perfil.listaMesasPanel" [id]="mesa.id" class="example-box2"
                    (mousedown)="DragStartedMesa($event, mesa)" (mouseup)="DragEndedMesa(mesa)" [ngStyle]="{'z-index': (mesa.isDragging ? 2 : 1),
                             'height': mesa.height + 'px',
                             'width': mesa.width + 'px'}">
                    <img [src]="mesa.urlFoto" style="position: absolute; width: 100%; height: 100%;" />
                    <div class="capaColor"
                      style="position: absolute; width: 100%; height: 100%; background: white; opacity: 0.5;"></div>
                    <div class="clipArriba" (click)="clickQuitarClip($event, mesa, 'arriba')"
                      [ngStyle]="{'background': (mesa.relacionadoArriba.estaRelacionado ? 'yellow' : '#aaa'),
                               'cursor': (mesa.relacionadoArriba.estaRelacionado ? 'pointer' : 'default'),
                               'visibility': ((someOneDragging() || mesa.relacionadoArriba.estaRelacionado) ? 'visible' : 'hidden')}">
                    </div>
                    <div class="clipAbajo" (click)="clickQuitarClip($event, mesa, 'abajo')"
                      [ngStyle]="{'background': (mesa.relacionadoAbajo.estaRelacionado ? 'yellow' : '#aaa'),
                               'cursor': (mesa.relacionadoAbajo.estaRelacionado ? 'pointer' : 'default'),
                               'visibility': ((someOneDragging() || mesa.relacionadoAbajo.estaRelacionado) ? 'visible' : 'hidden')}">
                    </div>
                    <div class="clipIzquierda" (click)="clickQuitarClip($event, mesa, 'izquierda')"
                      [ngStyle]="{'background': (mesa.relacionadoIzquierda.estaRelacionado ? 'yellow' : '#aaa'),
                               'cursor': (mesa.relacionadoIzquierda.estaRelacionado ? 'pointer' : 'default'),
                               'visibility': ((someOneDragging() || mesa.relacionadoIzquierda.estaRelacionado) ? 'visible' : 'hidden')}">
                    </div>
                    <div class="clipDerecha" (click)="clickQuitarClip($event, mesa, 'derecha')"
                      [ngStyle]="{'background': (mesa.relacionadoDerecha.estaRelacionado ? 'yellow' : '#aaa'),
                               'cursor': (mesa.relacionadoDerecha.estaRelacionado ? 'pointer' : 'default'),
                               'visibility': ((someOneDragging() || mesa.relacionadoDerecha.estaRelacionado) ? 'visible' : 'hidden')}">
                    </div>
                    <span class="identificador-mesa" style="position:absolute">{{mesa.numMesa}}</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-datos-comedor">
            <div class="row">
              <!--GRID MESAS-->
              <div class="col-md-7">
                <div class="card">
                  <div class="card-header">
                    <h3>
                      <label CssClass="">{{ 'restaurante.mesas' | translate }}</label>
                    </h3>
                    <div class="plegarpanel"></div>
                  </div>
                  <div class="card-body">
                    <kendo-grid [data]="perfil.listaMesas" [height]="300">
                      <!-- Necesitamos que sean sortable, y si no se duplican así no lo son -->
                      <kendo-grid-column [width]="20" field="numMesa" title="{{ 'restaurante.numero' | translate}}">
                      </kendo-grid-column>
                      <kendo-grid-column [width]="35" field="nombre" title="{{ 'restaurante.nombre' | translate}}">
                      </kendo-grid-column>
                      <kendo-grid-column [width]="20" field="comensales" title="{{ 'restaurante.nComensales' | translate}}"
                        [style]="{'text-align': 'right'}">
                      </kendo-grid-column>
                      <kendo-grid-column [width]="15" field="comensales" title=" " [headerStyle]="{'text-align': 'right'}"
                        [style]="{'text-align': 'right'}">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <button *ngIf="!mesaEstaEnPanel(dataItem)" kendoButton class="btn btn-primary btn-sm"
                            (click)="addMesaPanel(dataItem)"><i class="fa fa-plus"></i></button>
                          <button *ngIf="mesaEstaEnPanel(dataItem)" kendoButton class="btn btn-danger btn-sm"
                            (click)="deleteMesaPanel(dataItem)"><i class="fa fa-minus"></i></button>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-messages filter="{{'filter' | translate}}"
                        filterAfterOperator="{{'filterAfterOperator' | translate}}"
                        filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                        filterAndLogic="{{'filterAndLogic' | translate}}"
                        filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                        filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                        filterBooleanAll="{{'filterBooleanAll' | translate}}"
                        filterClearButton="{{'filterClearButton' | translate}}"
                        filterContainsOperator="{{'filterContainsOperator' | translate}}"
                        filterDateToday="{{'filterDateToday' | translate}}"
                        filterDateToggle="{{'filterDateToggle' | translate}}"
                        filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                        filterEqOperator="{{'filterEqOperator' | translate}}"
                        filterFilterButton="{{'filterFilterButton' | translate}}"
                        filterGtOperator="{{'filterGtOperator' | translate}}"
                        filterGteOperator="{{'filterGteOperator' | translate}}"
                        filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                        filterIsFalse="{{'filterIsFalse' | translate}}"
                        filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                        filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                        filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                        filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                        filterLteOperator="{{'filterLteOperator' | translate}}"
                        filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                        filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                        filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                        filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                        filterOrLogic="{{'filterOrLogic' | translate}}"
                        filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                        loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                        lock="{{'lock' | translate}}" noRecords="{{'noRecords' | translate}}" unlock="{{'unlock' | translate}}">
                      </kendo-grid-messages>
                    </kendo-grid>
                  </div>
                </div>
              </div>
              <!--ORDEN MESAS-->
              <div class="col-md-5">
                <div class="card">
                  <div class="card-header">
                    <h3>
                      <label CssClass="">{{ 'restaurante.orden' | translate }}</label>
                    </h3>
                    <div class="plegarpanel"></div>
                  </div>
                  <div class="card-body" *ngIf="perfil.listaMesasOrden.length>0">
                    <sort-listamesas [listaMesas]="perfil.listaMesasOrden" (listaMesasSorted)="getMesasSorted($event)"></sort-listamesas>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <h3>
                      <label CssClass="">{{ 'configuracion.datos' | translate }}</label>
                    </h3>
                    <div class="plegarpanel"></div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <!--Lista idiomas-->
                          <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
                          </kendo-label>
                          <div class="caja">
                            <kendo-dropdownlist #dropdownI [data]="listaIdiomas" textField="nombre" valueField="codigo"
                              (valueChange)="cambioIdioma()" [(ngModel)]="selectedIdioma"></kendo-dropdownlist>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="row">
                              <div class="col">
                                <div class="form-group mr-2">
                                  <kendo-label text="{{ 'restaurante.nombre' | translate}}">
                                    <kendo-textbox [(value)]="nombre"></kendo-textbox>
                                  </kendo-label>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <!--Traducciones-->
                                <button type="button" *ngIf="isAddMode" class="btn btn-primary btn-sm mr-1"
                                  (click)="btnAddTraduccion()">
                                  {{ "publicaciones.anadir" | translate }}
                                </button>
                                <div class="listado-traducciones">
                                  <div class="traduccion" *ngFor="let idioma of listaTraducciones">
                                    {{ idioma.nombre }}
            
                                    <button type="button" class="btn btn-info btn-sm mr-1" (click)="btnDeleteTraduccion(idioma)">
                                      X
                                    </button>
                                  </div>
                                </div>
                                <!---->
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <div class="form-group mr-2">
                                  <kendo-label text="{{ 'restaurante.mesas' | translate}}">
                                    <br>
                                    <kendo-numerictextbox [disabled]="true" [value]="perfil.listaMesasPanel.length"
                                      [min]="0" [autoCorrect]="true" [decimals]="0" [format]="'n'">
                                    </kendo-numerictextbox>
                                  </kendo-label>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group mr-2">
                                  <kendo-label text="{{ 'restaurante.nComensales' | translate}}">
                                    <kendo-numerictextbox [disabled]="true" [value]="perfil.comensales" [min]="0"
                                      [autoCorrect]="true" [decimals]="0" [format]="'n'">
                                    </kendo-numerictextbox>
                                  </kendo-label>
                                  <p>
                                  </p>
                                </div>
                              </div>
            
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="caja-imagen">
                          <div class="form-group">
                            <div class="caja">
                              <kendo-label text="{{ 'restaurante.fondo' | translate}}">
                                <kendo-fileselect [(ngModel)]="archivo" [restrictions]="restrictionsImage" [multiple]="false"
                                  (select)="archivoSeleccionado($event)" (remove)="archivoEliminado($event)">
                                  <kendo-upload-messages cancel="{{ 'botones.cancelar' | translate }}"
                                    dropFilesHere="{{ 'restaurante.dropFoto' | translate }}" invalidFileExtension="{{
                                                      'organizaciones.extFotoIn' | translate
                                                    }}" invalidMaxFileSize="{{
                                                      'organizaciones.invalidMax' | translate
                                                    }}" remove="{{ 'organizaciones.remFoto' | translate }}"
                                    select="{{ 'organizaciones.slFoto' | translate }}">
                                  </kendo-upload-messages>
                                </kendo-fileselect>
                              </kendo-label>
                            </div>
                          </div>
                        </div>
                        <div class="tamano-panel">
                          <div class="row">
                            <kendo-label class="col-6" text="{{ 'restaurante.alto' | translate}}"> </kendo-label>
                            <div class="col">
                              <kendo-numerictextbox [disabled]="perfil.listaMesasPanel.length>0" [format]="'n'" [(value)]="tamano_panel_cm.alto" [decimals]="2" [format]="'n2'"
                                [step]="1.0" [min]="100.00" [max]="1000.00" [autoCorrect]="true">
                              </kendo-numerictextbox>
                            </div>
                          </div>
                          <div class="row">
                            <kendo-label class="col-6" text="{{ 'restaurante.ancho' | translate}}"></kendo-label>
                            <div class="col">
                              <kendo-numerictextbox [disabled]="perfil.listaMesasPanel.length>0" [(value)]="tamano_panel_cm.ancho" [decimals]="2" [format]="'n2'" [step]="1.0"
                                [min]="100.00" [max]="1000.00" [autoCorrect]="true">
                              </kendo-numerictextbox>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <button kendoButton [disabled]="perfil.listaMesasPanel.length>0" (click)="cambiarTamanoPanel($event)"
                                class="btn mr-1  btn-primary">
                                {{ 'botones.aplicarSize' | translate}}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>



<div class="col-md-4 form-group">
  <button (click)="onSubmit()" class="btn btn-primary btn-sm mr-1">
    {{ 'botones.guardar' | translate}}
  </button>
  <button class="btnBerria btn btn-info btn-sm mr-1" (click)="onClickCancelar($event)">
    {{ 'botones.atras' | translate}}
  </button>
</div>
