import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reservas-mesas',
  templateUrl: './reservas-mesas.component.html',
})
export class ReservasMesasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    
  }

}
