import { Component } from '@angular/core';
import { MenuService, UsuariosService, UsuariosTiposService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';



@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent {
    user = this.usuariosService.userValue;
    titulo = this.menuService.titulo;
    isExpanded = false;
    tema = 1;
    currentYear = new Date().getFullYear();

    //permisos usuario
    isGlobalAdmin:boolean=true;
    isPublicacionesAdmin:boolean;
    isEventosAdmin:boolean;
    isEncuestasAdmin:boolean;
    isRecintosAdmin:boolean;
    isRestauranteAdmin:boolean;
    isEcommerceAdmin:boolean;
    isMembresiaAdmin:boolean;
    isForoAdmin:boolean;
    
    constructor(private usuariosService: UsuariosService, private translateService: TranslateService, private menuService: MenuService,private usuariosTiposService:UsuariosTiposService) {
        this.usuariosService.user.subscribe(x => this.user = x);
        if (this.user != null) {
            translateService.setDefaultLang(this.user.idioma);
            this.isExpanded = this.user.menuExpandido;
            this.tema = this.user.tema;
            if (this.tema == 1) {
                const body = document.getElementsByTagName('body')[0];
                body.classList.remove('iluna');
            } else {
                const body = document.getElementsByTagName('body')[0];
                body.classList.add('iluna');
            }
        } else {
            translateService.setDefaultLang('es');
            this.isExpanded = false;
        }

        //Obtenemos permisos usuario administrador
        if(this.user != null){
            // this.usuariosTiposService.getPermisosUsuario(Number(this.user.id)).subscribe((result)=>{
            //     console.log('Permisos que le llegan en estructura main: ');
            //     console.log(result);
            //     if(result.data){
            //         this.isGlobalAdmin= result.data[0].isGlobalAdmin;
            //         this.isPublicacionesAdmin= result.data[0].isPublicacionesAdmin;
            //         this.isEventosAdmin= result.data[0].isEventosAdmin;
            //         this.isEncuestasAdmin= result.data[0].isEncuestasAdmin;
            //         this.isRecintosAdmin= result.data[0].isRecintosAdmin;
            //         this.isRestauranteAdmin= result.data[0].isRestauranteAdmin;
            //         this.isEcommerceAdmin= result.data[0].isEcommerceAdmin;
            //         this.isMembresiaAdmin= result.data[0].isMembresiaAdmin;
            //         this.isForoAdmin= result.data[0].isForoAdmin;
            //     }
            // });
        }
        else{
            console.log('DEBUG Dejando todo si por defecto ');
            this.isGlobalAdmin=true;
        }
    }



    ngOnInit() {
    }

    temaIluna() {
        this.tema = 2;
        this.menuService.updateUserSettings(this.user.id, this.user.idDb, this.user.idioma, this.tema, this.user.menuExpandido)
        this.user.tema = this.tema;
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('iluna');
        body.classList.add('iluna');
    }
    temaArgia() {
        this.tema = 1;
        this.menuService.updateUserSettings(this.user.id, this.user.idDb, this.user.idioma, this.tema, this.user.menuExpandido)
        this.user.tema = this.tema;
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('iluna');
    }
    expandir() {
        if (this.isExpanded == true) {
            this.isExpanded = false;
        } else {
            this.isExpanded = true;
        }
        this.menuService.updateUserSettings(this.user.id, this.user.idDb, this.user.idioma, this.user.tema, this.isExpanded)
        this.user.menuExpandido = this.isExpanded;
    }

    useLanguage(language: string) {
        this.menuService.updateUserSettings(this.user.id, this.user.idDb, language, this.user.tema, this.user.menuExpandido)
        this.user.idioma = language;
        this.translateService.use(language);
    }

    logout() {
        this.usuariosService.logout();
    }
}
