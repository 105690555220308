import { ModuloUrlComponent } from './menu-main/modulo-url/modulo-url.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_helpers';

import { HomeComponent } from './home';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { ContactosComponent } from './contactos/contactos.component';
import { ContactosDetalleComponent } from './contactos/contactos-detalle/contactos-detalle.component';
import { TransportesComponent } from './transportes/transportes.component';
import { TransportesDetalleComponent } from './transportes/transportes-detalle/transportes-detalle.component';
import { EditarCrearUsuarioComponent } from './usuarios/editar-crear-usuario/editar-crear-usuario.component';
import { UsuariosTiposComponent } from './usuarios-tipos/usuarios-tipos.component';
import { EditarCrearUsuarioTipoComponent } from './usuarios-tipos/editar-crear-usuario-tipo/editar-crear-usuario-tipo.component';
import { DetailsComponent } from './profile/details.component';
import { UpdateComponent } from './profile/update/update.component';
import { EnlacesComponent } from './enlaces/enlaces.component';
import { EnlacesDetalleComponent } from './enlaces/enlaces-detalle/enlaces-detalle.component';
import { RecorridosComponent } from './recorridos/recorridos.component';
import { RecorridosDetalleComponent } from './recorridos/recorridos-detalle/recorridos-detalle.component';
import { DepartamentosComponent } from './departamentos/departamentos.component';
import { DepartamentosDetalleComponent } from './departamentos/departamentos-detalle/departamentos-detalle.component';
import { ConfiguracionComponent } from './configuracion/configuracion.component';
import { PruebaComponent } from './prueba/prueba.component';
import { PatrocinadoresComponent } from './patrocinadores/patrocinadores.component';
import { OrganizacionesComponent } from './organizaciones/organizaciones.component';
import { OrganizacionesDetalleComponent } from './organizaciones/organizaciones-detalle/organizaciones-detalle.component';
import { OrganizacionesCrearComponent } from './organizaciones/organizaciones-crear/organizaciones-crear.component';
import { OrganizacionesEditarComponent } from './organizaciones/organizaciones-editar/organizaciones-editar.component';


import { ConfiguracionInicialComponent } from './configuracion-inicial/configuracion-inicial.component';
import { AgentesComponent } from './agentes/agentes.component';
import { AgentesdetalleComponent } from './agentes/agentesdetalle/agentesdetalle.component';
import { AgentesCrearComponent } from './agentes/agentes-crear/agentes-crear.component';
import { AgentesEditarComponent } from './agentes/agentes-editar/agentes-editar.component';

import { ColaboradoresComponent } from './colaboradores/colaboradores.component';
import { ColaboradoresdetalleComponent } from './colaboradores/colaboradoresdetalle/colaboradoresdetalle.component';
import { ColaboradoresCrearComponent } from './colaboradores/colaboradores-crear/colaboradores-crear.component';
import { ColaboradoresEditarComponent } from './colaboradores/colaboradores-editar/colaboradores-editar.component';

import { AgentesTiposComponent } from './agentes-tipos/agentes-tipos.component';
import { AgentesTiposDetallesComponent } from './agentes-tipos/agentes-tipos-detalles/agentes-tipos-detalles.component';
import { DepartamentosEnteComponent } from './departamentos-ente/departamentos-ente.component';
import { DepartamentosEnteDetallesComponent } from './departamentos-ente/departamentos-ente-detalles/departamentos-ente-detalles.component';
import { ContactosEnteComponent } from './contactos-ente/contactos-ente.component';
import { ContactosEnteDetallesComponent } from './contactos-ente/contactos-ente-detalles/contactos-ente-detalles.component';
import { DepartamentosEnteCrearComponent } from './departamentos-ente/departamentos-ente-crear/departamentos-ente-crear.component';
import { ContactosEnteCrearComponent } from './contactos-ente/contactos-ente-crear/contactos-ente-crear.component';
import { PublicacionesComponent } from './publicaciones/publicaciones.component';
import { EncuestasCrearComponent } from './encuestas/encuestas-crear/encuestas-crear.component';
import { PublicacionesListaComponent } from './publicaciones/publicaciones-lista/publicaciones-lista.component';
import { PublicacionesEditarComponent } from './publicaciones/publicaciones-editar/publicaciones-editar.component';
import { EventosEditarComponent } from './eventos/eventos-editar/eventos-editar.component';
import { EventosCrearComponent } from './eventos/eventos-crear/eventos-crear.component';
import { EventosComponent } from './eventos/eventos.component';
import { EncuestasComponent } from './encuestas/encuestas.component';
import { EncuestasEditarComponent } from './encuestas/encuestas-editar/encuestas-editar.component';
import { CategoriasPublicacionesComponent } from './publicaciones/categorias/categorias-publicaciones/categorias-publicaciones.component';
import { CategoriasPublicacionesCrearComponent } from './publicaciones/categorias/categorias-publicaciones-crear/categorias-publicaciones-crear.component';
import { CategoriasPublicacionesEditarComponent } from './publicaciones/categorias/categorias-publicaciones-editar/categorias-publicaciones-editar.component';
import { CategoriasEventosComponent } from './eventos/categorias/categorias-eventos/categorias-eventos.component';
import { CategoriasEventosCrearComponent } from './eventos/categorias/categorias-eventos-crear/categorias-eventos-crear.component';
import { CategoriasEventosEditarComponent } from './eventos/categorias/categorias-eventos-editar/categorias-eventos-editar.component';
import { CategoriasEncuestasComponent } from './encuestas/categorias/categorias-encuestas/categorias-encuestas.component';
import { CategoriasEncuestasCrearComponent } from './encuestas/categorias/categorias-encuestas-crear/categorias-encuestas-crear.component';
import { CategoriasEncuestasEditarComponent } from './encuestas/categorias/categorias-encuestas-editar/categorias-encuestas-editar.component';
import { PatrocinadoresMultinivelComponent } from './patrocinadores-multinivel/patrocinadores-multinivel.component';
import { AppCrearComponent } from './patrocinadores-multinivel/app-crear/app-crear.component';
import { RecintosComponent } from './recintos/recintos/recintos.component';
import { RecintosCrearComponent } from './recintos/recintos-crear/recintos-crear.component';
import { RecintosEditarComponent } from './recintos/recintos-editar/recintos-editar.component';
import { SalasComponent } from './salas/salas/salas.component';
import { SalasCrearComponent } from './salas/salas-crear/salas-crear.component';
import { SalasEditarComponent } from './salas/salas-editar/salas-editar.component';
import { CalendarioReservasComponent } from './reservas/calendario-reservas/calendario-reservas.component';
import { CalendarioGestionReservasComponent } from './reservas/calendario-gestion-reservas/calendario-gestion-reservas.component';
import { ReservasCrearComponent } from './reservas/reservas-crear/reservas-crear.component';
import { ReservasEditarComponent } from './reservas/reservas-editar/reservas-editar.component';
import { SelectorUsuariosComponent } from './reusables/selector-usuarios/selector-usuarios.component';
import { RecorridosEnteComponent } from './recorridos-ente/recorridos-ente/recorridos-ente.component';
import { RecorridosCrearComponent } from './recorridos-ente/recorridos-crear/recorridos-crear.component';
import { RecorridosEditarComponent } from './recorridos-ente/recorridos-editar/recorridos-editar.component';
import { FarmaciasComponent } from './farmacias/farmacias/farmacias.component';
import { CategoriasArticulosComponent } from './ecommerce/categorias-articulos/categorias-articulos.component';
import { CategoriasArticulosCrearComponent } from './ecommerce/categorias-articulos-crear/categorias-articulos-crear.component';
import { CategoriasArticulosEditarComponent } from './ecommerce/categorias-articulos-editar/categorias-articulos-editar.component';
import { ArticulosComponent } from './ecommerce/articulos/articulos.component';
import { ArticulosCrearComponent } from './ecommerce/articulos-crear/articulos-crear.component';
import { ArticulosEditarComponent } from './ecommerce/articulos-editar/articulos-editar.component';
import { PeticionesComponent } from './membresia/peticiones/peticiones.component';
import { PeticionMensajeComponent } from './membresia/peticion-mensaje/peticion-mensaje.component';
import { SorteoComponent } from './publicaciones/sorteo/sorteo.component';
import { ReservasMesasComponent } from './restaurante/reservas-mesas/reservas-mesas.component';
import { ComidastipoComponent } from './restaurante/comidastipo/comidastipo.component';
import { ComidastipoCrearComponent } from './restaurante/comidastipo/comidastipo-crear/comidastipo-crear.component';
import { ComidastipoEditarComponent } from './restaurante/comidastipo/comidastipo-editar/comidastipo-editar.component';
import { RestaurantesComponent } from './restaurante/restaurantes/restaurantes.component';
import { ResturanteCrearComponent } from './restaurante/restaurantes/resturante-crear/resturante-crear.component';
import { ResturanteEditarComponent } from './restaurante/restaurantes/resturante-editar/resturante-editar.component';
import { MesasComponent } from './restaurante/mesas/mesas.component';
import { MesasDetalleComponent } from './restaurante/mesas/mesas-detalle/mesas-detalle.component';
import { ComedoresComponent } from './restaurante/comedores/comedores.component';
import { ComedoresDetalleComponent } from './restaurante/comedores/comedores-detalle/comedores-detalle.component';
import { ReservaAdminComponent } from './restaurante/reserva-admin/reserva-admin.component';
import { ReservaComponent } from './restaurante/reserva-admin/reserva/reserva.component';
import { ReservasComponent } from './restaurante/reservas/reservas.component';
import { ConfiguracionRestauranteComponent } from './restaurante/configuracion-restaurante/configuracion-restaurante.component';
import { MesasCrearComponent } from './restaurante/mesas/mesas-crear/mesas-crear.component';
import { MesasEditarComponent } from './restaurante/mesas/mesas-editar/mesas-editar.component';
import { CalendariosRestauranteComponent } from './restaurante/calendario/calendarios-restaurante/calendarios-restaurante.component';
import { SortListamesasComponent } from './reusables/sort-listamesas/sort-listamesas.component';
/*import { TestComponent } from './test/test.component';*/
import { EcommerceConfiguracionComponent } from './ecommerce/ecommerce-configuracion/ecommerce-configuracion.component';
//import { Prueba2Component } from './prueba/prueba2/prueba2.component';
//import { Prueba3Component } from './pruebas/prueba3/prueba3.component';
import { VideosComponent } from './videos/videos.component';
import { AccesosDirectosComponent } from './disenos/accesos-directos/accesos-directos.component';
import { MenuModulosComponent } from './disenos/menu-modulos/menu-modulos.component';
import { ModulosEditarComponent } from './disenos/modulos-editar/modulos-editar.component';
import { MenuMainComponent } from './menu-main/menu-main.component';
import { ModuloHtmlComponent } from './menu-main/modulo-html/modulo-html.component';
import { ModuloDirectoComponent } from './menu-main/modulo-directo/modulo-directo.component';
import { CreateModuloComponent } from './menu-main/create-modulo/create-modulo.component';
import { ModuloCategoriaComponent } from './menu-main/modulo-categoria/modulo-categoria.component';
import { EquiposComponent } from './equipos/equipos/equipos.component';
import { JugadoresComponent } from './equipos/jugadores/jugadores.component';
import { EquiposCrearComponent } from './equipos/equipos-crear/equipos-crear.component';
import { EquiposEditarComponent } from './equipos/equipos-editar/equipos-editar.component';
import { JugadoresEditarComponent } from './equipos/jugadores-editar/jugadores-editar.component';

const loginModule = () =>
  import('./login/login.module').then((x) => x.LoginModule);

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    loadChildren: loginModule,
    canActivate: [AuthGuard],
  },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', loadChildren: loginModule },
  {
    path: 'profile',
    component: DetailsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'update', component: UpdateComponent, canActivate: [AuthGuard] },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'contactos',
    component: ContactosComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'editar/:id',
        component: ContactosDetalleComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: ContactosDetalleComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'transportes',
    component: TransportesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'editar/:id',
        component: TransportesDetalleComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: TransportesDetalleComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'enlaces',
    component: EnlacesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'editar/:id',
        component: EnlacesDetalleComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EnlacesDetalleComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'recorridos',
    component: RecorridosComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'editar/:id',
        component: RecorridosDetalleComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: RecorridosDetalleComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'departamentos',
    component: DepartamentosComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'editar/:id',
        component: DepartamentosDetalleComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: DepartamentosDetalleComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'configuracion',
    component: ConfiguracionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'usuarios',
    component: UsuariosComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: EditarCrearUsuarioComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EditarCrearUsuarioComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'usuarios-tipos',
    component: UsuariosTiposComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: EditarCrearUsuarioTipoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EditarCrearUsuarioTipoComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'patrocinadores',
    component: PatrocinadoresComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: PatrocinadoresComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: PatrocinadoresComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'organizaciones',
    component: OrganizacionesComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'organizaciones-crear',
    component: OrganizacionesCrearComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'organizaciones-editar',
    component: OrganizacionesEditarComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  /*{ 
    path: 'organizaciones/crearorg',
    component: OrganizacionesCrearComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },*/
  {
    path: 'organizaciones/crear',
    component: OrganizacionesDetalleComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'organizaciones/editar/:id',
    component: OrganizacionesDetalleComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'agentes',
    component: AgentesComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'agentes-crear',
    component: AgentesCrearComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'agentes-editar',
    component: AgentesEditarComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'agentes/crear',
    component: AgentesdetalleComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'agentes/editar/:id',
    component: AgentesdetalleComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'agentes-tipos',
    component: AgentesTiposComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'agentes-tipos/editar/:id',
    component: AgentesTiposDetallesComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'agentes-tipos/crear',
    component: AgentesTiposDetallesComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'colaboradores',
    component: ColaboradoresComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'colaboradores-crear',
    component: ColaboradoresCrearComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'colaboradores-editar',
    component: ColaboradoresEditarComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'colaboradores/crear',
    component: ColaboradoresdetalleComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'colaboradores/editar/:id',
    component: ColaboradoresdetalleComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'contactos-ente',
    component: ContactosEnteComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'editar/:id',
        component: ContactosEnteDetallesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: ContactosEnteDetallesComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'contactos-ente-crear',
    component: ContactosEnteCrearComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'contactos-ente-editar',
    component: ContactosEnteDetallesComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'departamentos-ente',
    component: DepartamentosEnteComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar',
        component: DepartamentosEnteDetallesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: DepartamentosEnteCrearComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'departamentos-ente-crear',
    component: DepartamentosEnteCrearComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'departamentos-ente-editar',
    component: DepartamentosEnteDetallesComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'configuracion-inicial',
    component: ConfiguracionInicialComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'publicaciones',
    component: PublicacionesComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'publicaciones-lista',
    component: PublicacionesListaComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'publicaciones-editar',
    component: PublicacionesEditarComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'publicaciones-sorteo',
    component: SorteoComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'eventos',
    component: EventosComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'eventos-crear',
    component: EventosCrearComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'eventos-editar',
    component: EventosEditarComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'encuestas',
    component: EncuestasComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'encuestas-crear',
    component: EncuestasCrearComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'encuestas-editar',
    component: EncuestasEditarComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'publicaciones-categorias',
    component: CategoriasPublicacionesComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'publicaciones-categorias-crear',
    component: CategoriasPublicacionesCrearComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'publicaciones-categorias-editar',
    component: CategoriasPublicacionesEditarComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },

  {
    path: 'eventos-categorias',
    component: CategoriasEventosComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'eventos-categorias-crear',
    component: CategoriasEventosCrearComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'eventos-categorias-editar',
    component: CategoriasEventosEditarComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },

  {
    path: 'encuestas-categorias',
    component: CategoriasEncuestasComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'encuestas-categorias-crear',
    component: CategoriasEncuestasCrearComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'encuestas-categorias-editar',
    component: CategoriasEncuestasEditarComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'recintos',
    component: RecintosComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'recintos-crear',
    component: RecintosCrearComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'recintos-editar',
    component: RecintosEditarComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'salas',
    component: SalasComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'salas-crear',
    component: SalasCrearComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'salas-editar',
    component: SalasEditarComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'reservas',
    component: CalendarioReservasComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'gestion-reservas',
    component: CalendarioGestionReservasComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'reservas-crear',
    component: ReservasCrearComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'reservas-editar',
    component: ReservasEditarComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'recorridos-ente',
    component: RecorridosEnteComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'recorridos-crear',
    component: RecorridosCrearComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'recorridos-editar',
    component: RecorridosEditarComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'farmacias',
    component: FarmaciasComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },

  {
    path: 'ecommerce-configuracion',
    component: EcommerceConfiguracionComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'ecommerce-categorias',
    component: CategoriasArticulosComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'ecommerce-categorias-crear',
    component: CategoriasArticulosCrearComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'ecommerce-categorias-editar',
    component: CategoriasArticulosEditarComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'ecommerce-articulos',
    component: ArticulosComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'ecommerce-articulos-crear',
    component: ArticulosCrearComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'ecommerce-articulos-editar',
    component: ArticulosEditarComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },

  {
    path: 'members-peticiones',
    component: PeticionesComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'peticion-mensaje',
    component: PeticionMensajeComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'farmacias',
    component: FarmaciasComponent,
    canActivate: [AuthGuard],

    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },

  {
    path: 'gestionmesas',
    component: ReservasMesasComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'comidas',
    component: ComidastipoComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'comidas-crear',
    component: ComidastipoCrearComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'comidas-editar',
    component: ComidastipoEditarComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'restaurantes',
    component: RestaurantesComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'restaurantes-crear',
    component: ResturanteCrearComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'restaurantes-editar',
    component: ResturanteEditarComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'mesas',
    component: MesasComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'mesas-crear',
    component: MesasCrearComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'mesas-editar',
    component: MesasEditarComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'mesas/editar',
    component: MesasDetalleComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'comedores',
    component: ComedoresComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'comedores-editar',
    component: ComedoresDetalleComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'restaurante-reservas',
    component: ReservaAdminComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'restaurante-reserva',
    component: ReservaComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'gestion-reservas-restaurante',
    component: ReservasComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'configuracion-restaurante',
    component: ConfiguracionRestauranteComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'restaurante-calendarios',
    component: CalendariosRestauranteComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'videos',
    component: VideosComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'acceso-directo',
    component: AccesosDirectosComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'menu-modulos',
    component: MenuModulosComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'modulos-editar',
    component: ModulosEditarComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'menu-main',
    component: MenuMainComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'modulo-html',
    component: ModuloHtmlComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'modulo-directo',
    component: ModuloDirectoComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'modulo-categoria',
    component: ModuloCategoriaComponent,
    canActivate: [AuthGuard],
    data: {permisos: ['GestionUsuarios==1']},
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'modulo-url',
    component: ModuloUrlComponent,
    canActivate: [AuthGuard],
    data: {permisos: ['GestionUsuarios==1']},
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'crear-modulo',
    component: CreateModuloComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'equipos',
    component: EquiposComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'equipos-crear',
    component: EquiposCrearComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'equipos-editar',
    component: EquiposEditarComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'jugadores-crear',
    component: JugadoresComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'jugadores-editar',
    component: JugadoresEditarComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  
  //Pruebas
  {
    path: 'pruebas',
    component: PruebaComponent, //PatrocinadoresMultinivelComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  }, /*
  {
    path: 'pruebas2',
    component: Prueba2Component, //PatrocinadoresMultinivelComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  //{
  //  path: 'a',
  //  component: TestComponent, //PatrocinadoresMultinivelComponent,
  //  canActivate: [AuthGuard],
  //  data: { permisos: ['GestionUsuarios==1'] },
  //  runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  //},
  {
    path: 'pruebas3',
    component: Prueba3Component, //PatrocinadoresMultinivelComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },*/
  //End Pruebas

  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
