export class Sitio {
    codigo: number;
    nombre1: string;
    nombre2: string;
    descripcion1: string;
    descripcion2: string;
    foto: string;
    fotoContent: string;
    link1: string;
    link2: string;
    latitud: string;
    longitud: string;
    poblacion: string;
    direccion: string;
    tipositio: string;
    gpx: string;
    gpxFileName: string;
}