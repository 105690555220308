<kendo-scheduler
  [kendoSchedulerBinding]="listaReservasData"
  [selectedDate]="selectedDate"
  [(selectedViewIndex)]="tabIndex"
  [editable]="true"
  (remove)="removeHandler($event)"
  (dragEnd)="dragEndHandler($event)"
  (resizeEnd)="resizeEndHandler($event)"
  (eventDblClick)="editEventHandler($event)"
  (slotClick)="newEventHandler($event)"
  scrollTime="08:00"
  style="height: 600px"
>
  <ng-template
    kendoSchedulerEventTemplate
    let-event="event"
    let-resources="resources"
  >
    <div [ngClass]="event.isConfirmada ? 'confirmada' : 'sinconfirmar'">
      [ {{ event.title }} ]
      <!--No hereda los valores de la clase , solo de la interfaz, así que hay que hacer este telerikpetacho-->
      <div *ngFor="let resource of listaReservasData">
        <div *ngIf="resource.id == event.id && resource.isConfirmada">
          <div [ngClass]="'confirmada'">
            {{ "recintos.confirmada" | translate }}
          </div>
        </div>
        
      </div>
    </div>
  </ng-template>

  <kendo-scheduler-day-view> </kendo-scheduler-day-view>

  <!-- <kendo-scheduler-week-view startTime="07:00" endTime="18:00"> </kendo-scheduler-week-view> -->
  <kendo-scheduler-week-view > </kendo-scheduler-week-view>

  <kendo-scheduler-month-view> </kendo-scheduler-month-view>

  <kendo-scheduler-timeline-view> </kendo-scheduler-timeline-view>

  <kendo-scheduler-agenda-view> </kendo-scheduler-agenda-view>

  <kendo-scheduler-messages
    today="{{ 'recintos.hoy' | translate }}"
    allDay="{{ 'recintos.allday' | translate }}"
    dayViewTitle="{{ 'recintos.dia' | translate }}"
    weekViewTitle="{{ 'recintos.semana' | translate }}"
    monthViewTitle="{{ 'recintos.mes' | translate }}"
    showWorkDay="{{ 'recintos.showlaborales' | translate }}"
    showFullDay="{{ 'recintos.showdiacompleto' | translate }}"
  >
  </kendo-scheduler-messages>
</kendo-scheduler>

<style>
  kendo-scheduler .k-event > div,
  .k-event > div {
    height: 100%;
    position: absolute;
    width: 100%;
  }
  .confirmada {
    background: green;
  }
  .sinconfirmar {
    background: grey;
  }
</style>
