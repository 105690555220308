<div class="row">
  <div class="">
    <div class="card">
      <kendo-tabstrip (tabSelect)="onTabSelect($event)">
        <!-- Reservas Actuales Día Calendario-->
        <kendo-tabstrip-tab title="{{ 'restaurante.proximasreservas' | translate }}" [selected]="true">
          <ng-template kendoTabContent>
            <div class="content">
              <kendo-grid [kendoGridBinding]="listaReservasActuales" [sortable]="true" kendoGridSelectBy="idReserva"
                [navigable]="true" [filterable]="'menu'" (cellClick)="cellClick($event)"
                [(selectedKeys)]="seleccionados" scrollable="virtual" [rowHeight]="36" [rowClass]="rowCallback"
                [height]="250" [pageSize]="6"
                [sortable]="{allowUnsort: allowUnsort, mode: multiple ? 'multiple' : 'single' }" [sort]="sort">
                <kendo-grid-checkbox-column [width]="1" showSelectAll="true"></kendo-grid-checkbox-column>
                <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="nombreComedor"
                  title="{{ 'restaurante.grid_lbl_nombrecomedor' | translate }}" *ngIf="verComedores">
                </kendo-grid-column>
                <kendo-grid-checkbox-column [width]="1" showSelectAll="true"></kendo-grid-checkbox-column>
                <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="idTipoComida"
                  title="{{ 'restaurante.grid_lbl_nombrecomida' | translate }}" *ngIf="false"></kendo-grid-column>
                <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="nombreComida"
                  title="{{ 'restaurante.grid_lbl_nombrecomida' | translate }}"></kendo-grid-column>
                <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="numeroMesas"
                  title="{{ 'restaurante.grid_lbl_nummesa' | translate }}"></kendo-grid-column>
                <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="comensales"
                  title="{{ 'restaurante.grid_lbl_numcomensales' | translate }}"></kendo-grid-column>
                <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="nombreUsuario"
                  title="{{ 'restaurante.grid_lbl_nombreusuario' | translate }}"></kendo-grid-column>
                <kendo-grid-column field="modo" title="{{ 'restaurante.modo' | translate }}" [width]="15">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div *ngIf="!dataItem.modo">
                      <i class="fas fa-mobile-alt"></i>
                    </div>
                    <div *ngIf="dataItem.modo">
                      <i class="fas fa-phone"></i>
                    </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="fechaCompleta" title="{{ 'restaurante.horareserva' | translate }}" [width]="15">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div>
                      {{ dataItem.fechaCompleta | date: "HH:mm" }}
                    </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="estado" title="{{ 'restaurante.estado' | translate }}" [width]="15">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div [ngStyle]="{'background-color' : dataItem.estado == -1 ? '#ffcccb'
                                                        : dataItem.estado == 1 ? 'lightgreen'
                                                        : yellow}">
                      <div *ngIf="dataItem.estado == -1">
                        {{ "restaurante.cancelada" | translate }}
                      </div>
                      <div *ngIf="dataItem.estado == 0">
                        {{ "restaurante.sinconfirmar" | translate }}
                      </div>
                      <div *ngIf="dataItem.estado == 1">
                        {{ "restaurante.confirmada" | translate }}
                      </div>
                    </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="isGestionado" title="Gestionar" [width]="15">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div *ngIf="dataItem.isGestionado">
                      Gestionada
                    </div>
                    <button kendoButton (click)="gestionarReserva(dataItem)"
                      *ngIf="!dataItem.isGestionado">Gestionar</button>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}"
                  filterAfterOperator="{{'filterAfterOperator' | translate}}"
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                  filterAndLogic="{{'filterAndLogic' | translate}}"
                  filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                  filterBooleanAll="{{'filterBooleanAll' | translate}}"
                  filterClearButton="{{'filterClearButton' | translate}}"
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                  filterDateToday="{{'filterDateToday' | translate}}"
                  filterDateToggle="{{'filterDateToggle' | translate}}"
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                  filterEqOperator="{{'filterEqOperator' | translate}}"
                  filterFilterButton="{{'filterFilterButton' | translate}}"
                  filterGtOperator="{{'filterGtOperator' | translate}}"
                  filterGteOperator="{{'filterGteOperator' | translate}}"
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                  filterIsFalse="{{'filterIsFalse' | translate}}"
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                  filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                  filterLteOperator="{{'filterLteOperator' | translate}}"
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                  filterOrLogic="{{'filterOrLogic' | translate}}"
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                  loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                  lock="{{'lock' | translate}}" noRecords="{{'noRecords' | translate}}"
                  unlock="{{'unlock' | translate}}">
                </kendo-grid-messages>
              </kendo-grid>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>

        <!-- Todas las reservas a partir de hoy -->
        <kendo-tabstrip-tab title="{{ 'restaurante.proximasreservastodas' | translate }}">
          <ng-template kendoTabContent>
            <div class="content">
              <kendo-grid [kendoGridBinding]="listaReservasTotales" [sortable]="true" kendoGridSelectBy="idReserva"
                [navigable]="true" [filterable]="'menu'" (cellClick)="cellClick($event)"
                [(selectedKeys)]="seleccionados" scrollable="virtual" [rowHeight]="36" [height]="250" [pageSize]="6"
                [sortable]="{allowUnsort: allowUnsort, mode: multiple ? 'multiple' : 'single' }" [sort]="sort">
                <kendo-grid-checkbox-column [width]="1" showSelectAll="true"></kendo-grid-checkbox-column>
                <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="nombreComedor"
                  title="{{ 'restaurante.grid_lbl_nombrecomedor' | translate }}" *ngIf="verComedores">
                </kendo-grid-column>
                <kendo-grid-checkbox-column [width]="1" showSelectAll="true"></kendo-grid-checkbox-column>
                <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="idTipoComida"
                  title="{{ 'restaurante.grid_lbl_nombrecomida' | translate }}" *ngIf="false"></kendo-grid-column>
                <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="nombreComida"
                  title="{{ 'restaurante.grid_lbl_nombrecomida' | translate }}"></kendo-grid-column>
                <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="numeroMesas"
                  title="{{ 'restaurante.grid_lbl_nummesa' | translate }}"></kendo-grid-column>
                <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="comensales"
                  title="{{ 'restaurante.grid_lbl_numcomensales' | translate }}"></kendo-grid-column>
                <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="nombreUsuario"
                  title="{{ 'restaurante.grid_lbl_nombreusuario' | translate }}"></kendo-grid-column>
                <kendo-grid-column field="modo" title="{{ 'restaurante.modo' | translate }}" [width]="15">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div *ngIf="!dataItem.modo">
                      <i class="fas fa-mobile-alt"></i>
                    </div>
                    <div *ngIf="dataItem.modo">
                      <i class="fas fa-phone"></i>
                    </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="fechaCompleta" title="{{ 'restaurante.horareserva' | translate }}" [width]="15">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div>
                      {{ dataItem.fechaCompleta | date: "HH:mm" }}
                    </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="estado" title="{{ 'restaurante.estado' | translate }}" [width]="15">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div [ngStyle]="{'background-color' : dataItem.estado == -1 ? '#ffcccb'
                                                        : dataItem.estado == 1 ? 'lightgreen'
                                                        : yellow}">
                      <div *ngIf="dataItem.estado == -1">
                        {{ "restaurante.cancelada" | translate }}
                      </div>
                      <div *ngIf="dataItem.estado == 0">
                        {{ "restaurante.sinconfirmar" | translate }}
                      </div>
                      <div *ngIf="dataItem.estado == 1">
                        {{ "restaurante.confirmada" | translate }}
                      </div>
                    </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}"
                  filterAfterOperator="{{'filterAfterOperator' | translate}}"
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                  filterAndLogic="{{'filterAndLogic' | translate}}"
                  filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                  filterBooleanAll="{{'filterBooleanAll' | translate}}"
                  filterClearButton="{{'filterClearButton' | translate}}"
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                  filterDateToday="{{'filterDateToday' | translate}}"
                  filterDateToggle="{{'filterDateToggle' | translate}}"
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                  filterEqOperator="{{'filterEqOperator' | translate}}"
                  filterFilterButton="{{'filterFilterButton' | translate}}"
                  filterGtOperator="{{'filterGtOperator' | translate}}"
                  filterGteOperator="{{'filterGteOperator' | translate}}"
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                  filterIsFalse="{{'filterIsFalse' | translate}}"
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                  filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                  filterLteOperator="{{'filterLteOperator' | translate}}"
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                  filterOrLogic="{{'filterOrLogic' | translate}}"
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                  loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                  lock="{{'lock' | translate}}" noRecords="{{'noRecords' | translate}}"
                  unlock="{{'unlock' | translate}}">
                </kendo-grid-messages>
              </kendo-grid>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>

        <!-- Reservas Canceladas-->
        <kendo-tabstrip-tab title="{{ 'restaurante.reservascanceladas' | translate }}">
          <ng-template kendoTabContent>
            <kendo-grid [kendoGridBinding]="listaReservasCanceladas" [sortable]="true" kendoGridSelectBy="idReserva"
              [navigable]="true" [filterable]="'menu'" (cellClick)="cellClick($event)" [(selectedKeys)]="seleccionados"
              scrollable="virtual" [rowHeight]="36" [height]="250" [pageSize]="6"
              [sortable]="{allowUnsort: allowUnsort, mode: multiple ? 'multiple' : 'single' }" [sort]="sort">
              <kendo-grid-checkbox-column [width]="1" showSelectAll="true"></kendo-grid-checkbox-column>
              <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="nombreComedor"
                title="{{ 'restaurante.grid_lbl_nombrecomedor' | translate }}" *ngIf="verComedores">
              </kendo-grid-column>
              <kendo-grid-checkbox-column [width]="1" showSelectAll="true"></kendo-grid-checkbox-column>
              <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="idTipoComida"
                title="{{ 'restaurante.grid_lbl_nombrecomida' | translate }}" *ngIf="false"></kendo-grid-column>
              <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="nombreComida"
                title="{{ 'restaurante.grid_lbl_nombrecomida' | translate }}"></kendo-grid-column>
              <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="numeroMesas"
                title="{{ 'restaurante.grid_lbl_nummesa' | translate }}"></kendo-grid-column>
              <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="comensales"
                title="{{ 'restaurante.grid_lbl_numcomensales' | translate }}"></kendo-grid-column>
              <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="nombreUsuario"
                title="{{ 'restaurante.grid_lbl_nombreusuario' | translate }}"></kendo-grid-column>
              <kendo-grid-column field="modo" title="{{ 'restaurante.modo' | translate }}" [width]="15">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div *ngIf="!dataItem.modo">
                    <i class="fas fa-mobile-alt"></i>
                  </div>
                  <div *ngIf="dataItem.modo">
                    <i class="fas fa-phone"></i>
                  </div>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="fechaCompleta" title="{{ 'restaurante.horareserva' | translate }}" [width]="15">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div>
                    {{ dataItem.fechaCompleta | date: "HH:mm" }}
                  </div>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="estado" title="{{ 'restaurante.estado' | translate }}" [width]="15">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div [ngStyle]="{'background-color' : dataItem.estado == -1 ? '#ffcccb'
                                                        : dataItem.estado == 1 ? 'lightgreen'
                                                        : yellow}">
                    <div *ngIf="dataItem.estado == -1">
                      {{ "restaurante.cancelada" | translate }}
                    </div>
                    <div *ngIf="dataItem.estado == 0">
                      {{ "restaurante.sinconfirmar" | translate }}
                    </div>
                    <div *ngIf="dataItem.estado == 1">
                      {{ "restaurante.confirmada" | translate }}
                    </div>
                  </div>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}"
                filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}"
                filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}"
                filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}"
                filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}"
                filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}"
                filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                lock="{{'lock' | translate}}" noRecords="{{'noRecords' | translate}}" unlock="{{'unlock' | translate}}">
              </kendo-grid-messages>
            </kendo-grid>
          </ng-template>
        </kendo-tabstrip-tab>

        <!-- Historial Reservas pasadas -->
        <kendo-tabstrip-tab title="{{ 'restaurante.reservashistorial' | translate }}">
          <ng-template kendoTabContent>
            <kendo-grid [kendoGridBinding]="listaReservasHistorial" [sortable]="true" kendoGridSelectBy="idReserva"
              [navigable]="true" [filterable]="'menu'" (cellClick)="cellClick($event)" [(selectedKeys)]="seleccionados"
              scrollable="virtual" [rowHeight]="36" [height]="250" [pageSize]="6"
              [sortable]="{allowUnsort: allowUnsort, mode: multiple ? 'multiple' : 'single' }" [sort]="sort">
              <kendo-grid-checkbox-column [width]="1" showSelectAll="true"></kendo-grid-checkbox-column>
              <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="nombreComedor"
                title="{{ 'restaurante.grid_lbl_nombrecomedor' | translate }}" *ngIf="verComedores">
              </kendo-grid-column>
              <kendo-grid-checkbox-column [width]="1" showSelectAll="true"></kendo-grid-checkbox-column>
              <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="idTipoComida"
                title="{{ 'restaurante.grid_lbl_nombrecomida' | translate }}" *ngIf="false"></kendo-grid-column>
              <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="nombreComida"
                title="{{ 'restaurante.grid_lbl_nombrecomida' | translate }}"></kendo-grid-column>
              <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="numeroMesas"
                title="{{ 'restaurante.grid_lbl_nummesa' | translate }}"></kendo-grid-column>
              <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="comensales"
                title="{{ 'restaurante.grid_lbl_numcomensales' | translate }}"></kendo-grid-column>
              <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="nombreUsuario"
                title="{{ 'restaurante.grid_lbl_nombreusuario' | translate }}"></kendo-grid-column>
              <kendo-grid-column field="modo" title="{{ 'restaurante.modo' | translate }}" [width]="15">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div *ngIf="!dataItem.modo">
                    <i class="fas fa-mobile-alt"></i>
                  </div>
                  <div *ngIf="dataItem.modo">
                    <i class="fas fa-phone"></i>
                  </div>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="fechaCompleta" title="{{ 'restaurante.horareserva' | translate }}" [width]="15">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div>
                    {{ dataItem.fechaCompleta | date: "HH:mm" }}
                  </div>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="estado" title="{{ 'restaurante.estado' | translate }}" [width]="15">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div [ngStyle]="{'background-color' : dataItem.estado == -1 ? '#ffcccb'
                                                        : dataItem.estado == 1 ? 'lightgreen'
                                                        : yellow}">
                    <div *ngIf="dataItem.estado == -1">
                      {{ "restaurante.cancelada" | translate }}
                    </div>
                    <div *ngIf="dataItem.estado == 0">
                      {{ "restaurante.sinconfirmar" | translate }}
                    </div>
                    <div *ngIf="dataItem.estado == 1">
                      {{ "restaurante.confirmada" | translate }}
                    </div>
                  </div>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}"
                filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}"
                filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}"
                filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}"
                filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}"
                filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}"
                filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                lock="{{'lock' | translate}}" noRecords="{{'noRecords' | translate}}" unlock="{{'unlock' | translate}}">
              </kendo-grid-messages>
            </kendo-grid>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-comedor" *ngIf="verComedor">
    <div class="card">
      <div class="card-header">
        <h3>
          <label CssClass="">{{ 'restaurante.comedor' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <kendo-tabstrip (tabSelect)="onPerfilSelected($event)">
          <kendo-tabstrip-tab *ngFor="let perfil of listaPerfiles; let i = index" [title]="perfil.nombre" [selected] = "this.selectedPerfil === perfil.id">
            <ng-template kendoTabContent>
              <div class="example-boundary" [ngStyle]="{'background-image': 'url(' + fondo + ')'}"
              [style.width.px]="tamano_panel.ancho" [style.height.px]="tamano_panel.alto"
              style="background-size: cover; background-repeat: no-repeat;  background-position: center;">
              <div *ngFor="let mesa of listaMesasPanel" [id]="mesa.id" class="example-box2" [ngStyle]="{'z-index': (mesa.isDragging ? 2 : 1),
                        'height': mesa.height + 'px',
                        'width': mesa.width + 'px',
                        'cursor': 'pointer'}">
                <img [src]="mesa.urlFoto" style="position: absolute; width: 100%; height: 100%;" />
                <div class="capaColor" [ngStyle]="{'background': mesa.color? mesa.color : 'white'}"
                  style="position: absolute; width: 100%; height: 100%; opacity: 0.5;"></div>
                <div class="clipArriba"
                  [ngStyle]="{'background': (mesa.relacionadoArriba.estaRelacionado ? 'yellow' : '#aaa'),
                          'visibility': ((someOneDragging() || mesa.relacionadoArriba.estaRelacionado) ? 'visible' : 'hidden')}">
                </div>
                <div class="clipAbajo"
                  [ngStyle]="{'background': (mesa.relacionadoAbajo.estaRelacionado ? 'yellow' : '#aaa'),
                          'visibility': ((someOneDragging() || mesa.relacionadoAbajo.estaRelacionado) ? 'visible' : 'hidden')}">
                </div>
                <div class="clipIzquierda"
                  [ngStyle]="{'background': (mesa.relacionadoIzquierda.estaRelacionado ? 'yellow' : '#aaa'),
                          'visibility': ((someOneDragging() || mesa.relacionadoIzquierda.estaRelacionado) ? 'visible' : 'hidden')}">
                </div>
                <div class="clipDerecha"
                  [ngStyle]="{'background': (mesa.relacionadoDerecha.estaRelacionado ? 'yellow' : '#aaa'),
                          'visibility': ((someOneDragging() || mesa.relacionadoDerecha.estaRelacionado) ? 'visible' : 'hidden')}">
                </div>
                <span class="identificador-mesa" style="position:absolute">{{mesa.numMesa}}</span>
              </div>
            </div>
            </ng-template>
          </kendo-tabstrip-tab>
        </kendo-tabstrip>
      </div>
    </div>
  </div>
  <div class="col-datos-comedor">
    <div class="card">
      <div class="card-header">
        <h3>
          <label CssClass="">{{ 'restaurante.comedor' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-6" *ngIf="comidaActiva">
            <div class="clearfix">
              <div class="float-left mr-2" *ngIf="listaComedores && listaComedores.length>1">
                <label>{{ 'restaurante.seleccionaComedor' | translate }}</label>
                <div class="caja">
                  <kendo-dropdownlist (selectionChange)="valueChangeComedor($event)" [data]="listaComedores"
                    style="width: 295px;" [(ngModel)]="selectedComedor" [textField]="'nombre'" [valueField]="'id'">
                  </kendo-dropdownlist>
                </div>
              </div>
              <div class="float-left mr-2" *ngIf="comidaActiva && listaTiposComida && listaTiposComida.length>1">
                <label>{{ 'restaurante.seleccionaComida' | translate }}</label>
                <div class="caja">
                  <kendo-dropdownlist (selectionChange)="valueChangeComida($event)" [data]="listaTiposComida"
                    style="width: 295px;" [(ngModel)]="selectedComida" [textField]="'nombre'" [valueField]="'id'">
                  </kendo-dropdownlist>
                </div>
              </div>
              <div class="float-left mr-2 mt-2" *ngIf="verComedor">
                <button type="button" class="btn btn-outline-primary" (click)="crearReservaModal()">
                  {{ "restaurante.crearreserva" | translate }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div>
              <div class="card-body">
                <div class="" *ngIf="calendarioActivo">
                  <kendo-calendar (activeViewChange)="onActiveViewChange($event)"
                    (activeViewDateChange)="onActiveDateChange($event)" (valueChange)="onChange($event)"
                    (navigate)="onNavigate($event)" [disabledDates]="disabledDates" [(ngModel)]="selectedDate">
                    <kendo-calendar-messages today="{{ 'restaurante.hoy' | translate }}"></kendo-calendar-messages>
                  </kendo-calendar>
                </div>
                <div class="">
                  <div class="card-body">
                    <kendo-label text="{{ 'restaurante.totalreservas' | translate }}">
                    </kendo-label>
                    {{ cantidadReservas }}
                  </div>
                  <div class="card-body" *ngIf="verCanceladasActivo">
                    <kendo-label class="mr-2" text="{{ 'restaurante.vercanceladas' | translate }}">
                    </kendo-label>
                    <kendo-switch (valueChange)="onVerCanceladasChange($event)" [(ngModel)]="verCanceladas"
                      [onLabel]="' '" [offLabel]="' '">
                    </kendo-switch>
                  </div>
                  <div class="card-body" *ngIf="verComedoresActivo">
                    <kendo-label class="mr-2" text="{{'restaurante.vercomedores' | translate }}">
                    </kendo-label>
                    <kendo-switch [(ngModel)]="verComedores" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="leyenda" *ngIf="verComedor">
          <div id="cuadradoVerde"></div>
          <p>{{'restaurante.mesaDisponible' | translate}}</p>
          <div id="cuadradoRojo"></div>
          <p>{{'restaurante.mesaNoDisponible' | translate}}</p>
          <div id="cuadradoNaranja"
            *ngIf="configuracionRestaurante!=undefined && (configuracionRestaurante.modoReserva==2 || (configuracionRestaurante.modoReserva==1 && configuracionRestaurante.rangoTiempoReservaActivado))">
          </div>
          <p
            *ngIf="configuracionRestaurante!=undefined && (configuracionRestaurante.modoReserva==2 || (configuracionRestaurante.modoReserva==1 && configuracionRestaurante.rangoTiempoReservaActivado))">
            {{'restaurante.mesaDisponibleConReservas' | translate}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" [hidden]="selectedNumeroMesa == 0">
  <div class="card">
    <div class="card-header">
      <h3>
        <label CssClass="">{{ 'restaurante.reservasMesa' | translate }}</label>
      </h3>
      <div class="plegarpanel"></div>
    </div>
    <div class="card-body">
      <div class="content">
        <kendo-grid [kendoGridBinding]="listaReservasActualesMesa" [sortable]="true" kendoGridSelectBy="idReserva"
          [navigable]="true" [filterable]="'menu'" (cellClick)="cellClick($event)" [(selectedKeys)]="seleccionados"
          scrollable="virtual" [rowHeight]="36" [rowClass]="rowCallback" [height]="250" [pageSize]="6"
          [sort]="sort">
          <kendo-grid-checkbox-column [width]="1" showSelectAll="true"></kendo-grid-checkbox-column>
          <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="nombreComedor"
            title="{{ 'restaurante.grid_lbl_nombrecomedor' | translate }}" *ngIf="verComedores">
          </kendo-grid-column>
          <kendo-grid-checkbox-column [width]="1" showSelectAll="true"></kendo-grid-checkbox-column>
          <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="idTipoComida"
            title="{{ 'restaurante.grid_lbl_nombrecomida' | translate }}" *ngIf="false"></kendo-grid-column>
          <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="nombreComida"
            title="{{ 'restaurante.grid_lbl_nombrecomida' | translate }}"></kendo-grid-column>
          <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="numeroMesas"
            title="{{ 'restaurante.grid_lbl_nummesa' | translate }}"></kendo-grid-column>
          <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="comensales"
            title="{{ 'restaurante.grid_lbl_numcomensales' | translate }}"></kendo-grid-column>
          <kendo-grid-column [width]="20" [style]="{ 'text-align': 'left' }" field="nombreUsuario"
            title="{{ 'restaurante.grid_lbl_nombreusuario' | translate }}"></kendo-grid-column>
          <kendo-grid-column field="modo" title="{{ 'restaurante.modo' | translate }}" [width]="15">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div *ngIf="!dataItem.modo">
                <i class="fas fa-mobile-alt"></i>
              </div>
              <div *ngIf="dataItem.modo">
                <i class="fas fa-phone"></i>
              </div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="fechaCompleta" title="{{ 'restaurante.horareserva' | translate }}" [width]="15">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div>
                {{ dataItem.fechaCompleta | date: "HH:mm" }}
              </div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="estado" title="{{ 'restaurante.estado' | translate }}" [width]="15">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [ngStyle]="{'background-color' : dataItem.estado == -1 ? '#ffcccb'
                                                  : dataItem.estado == 1 ? 'lightgreen'
                                                  : yellow}">
                <div *ngIf="dataItem.estado == -1">
                  {{ "restaurante.cancelada" | translate }}
                </div>
                <div *ngIf="dataItem.estado == 0">
                  {{ "restaurante.sinconfirmar" | translate }}
                </div>
                <div *ngIf="dataItem.estado == 1">
                  {{ "restaurante.confirmada" | translate }}
                </div>
              </div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="isGestionado" title="Gestionar" [width]="15">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div *ngIf="dataItem.isGestionado">
                Gestionada
              </div>
              <button kendoButton (click)="gestionarReserva(dataItem)" *ngIf="!dataItem.isGestionado">Gestionar</button>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
            filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}"
            noRecords="{{'noRecords' | translate}}" unlock="{{'unlock' | translate}}">
          </kendo-grid-messages>
        </kendo-grid>
      </div>
    </div>
  </div>
</div>

<button (click)="btnBack()" Class="btnBerria btn btn-info btn-sm mr-1">
  {{ "restaurante.atras" | translate }}
</button>

<!--POPUP RESERVA DETALLES/CANCELAR -->
<ng-template #popUpAbrirReserva let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "restaurante.reserva" | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="selectedReserva" class="">
      <h5>
        <b>{{ "restaurante.detallesreserva" | translate }}</b>
      </h5>
      <div class="form-group">
        <kendo-label text="{{ 'restaurante.comedor' | translate }}"></kendo-label>
        <kendo-dropdownlist [disabled]="true" [data]="listaComedores" textField="nombre" valueField="id"
          [(ngModel)]="selectedReserva.comedor" (selectionChange)="valueChangeComedor($event)">
        </kendo-dropdownlist>
      </div>
      <div class="form-group">
        <kendo-label text="{{ 'restaurante.tipocomida' | translate }}"></kendo-label>
        <kendo-dropdownlist [disabled]="true" [data]="listaTiposComida" textField="nombre" valueField="id"
          [(ngModel)]="selectedReserva.comida" (selectionChange)="valueChangeComida($event)">
        </kendo-dropdownlist>
      </div>
      <div class="form-group">
        <kendo-label text="{{ 'restaurante.mesacomedor' | translate }}"></kendo-label>
        <kendo-dropdownlist [disabled]="listaMesasConectadas.length==1"
          (selectionChange)="valueChangeCambioMesaReserva($event)" [data]="listaMesasConectadas" textField="mesas"
          valueField="id" [(ngModel)]="selectedReserva.mesas">
        </kendo-dropdownlist>
      </div>
      <div class="form-group"
        *ngIf="configuracionRestaurante.modoReserva==2 || (configuracionRestaurante.modoReserva==1 && configuracionRestaurante.rangoTiempoReservaActivado) ">
        <kendo-label text="{{ 'restaurante.horaReserva' | translate }}"></kendo-label>
        <kendo-dropdownlist [disabled]="listaHorasAUtilizar.length==1" textField="hora" valueField="hora"
          [data]="listaHorasAUtilizar" [(ngModel)]="selectedReserva.horaSeleccionada">
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            <span *ngIf="dataItem!=undefined">{{ dataItem.hora | kendoDate:'HH:mm' }}</span>
          </ng-template>
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span *ngIf="dataItem!=undefined">{{ dataItem.hora | kendoDate:'HH:mm' }}</span>
          </ng-template>
        </kendo-dropdownlist>
      </div>
      <div class="form-group">
        <kendo-label text="{{ 'restaurante.numcomensales' | translate }}"></kendo-label><br>
        <kendo-numerictextbox [style.width.px]="200" [(ngModel)]="selectedReserva.numComensales" [format]="'n'"
          [decimals]="0" [min]="1" [max]="selectedReserva.capacidadMax">
        </kendo-numerictextbox>
      </div>

      <div class="form-group">
        <kendo-label text="{{ 'restaurante.nombreusuario' | translate }}"></kendo-label>
        <kendo-textbox [style.width.px]="200" placeholder="Nombre del reservante" [clearButton]="true"
          [(ngModel)]="selectedReserva.nombreUsuario">
        </kendo-textbox>
      </div>

      <div class="form-group">
        <kendo-label text="{{ 'restaurante.telefono' | translate }}"></kendo-label>
        <kendo-textbox [style.width.px]="200" placeholder="Número de teléfono" [clearButton]="true"
          [(ngModel)]="selectedReserva.telefono">
        </kendo-textbox>
      </div>
      <div class="form-group">
        <kendo-label text="{{ 'restaurante.observaciones' | translate }}"></kendo-label>
        <kendo-textbox [style.width.px]="200" placeholder="Observaciones" [clearButton]="true"
          [(ngModel)]="selectedReserva.observaciones">
        </kendo-textbox>
      </div>
      <div *ngIf="selectedReserva.estado == -1" style="background-color: #ffcccb">
        <b>{{ "restaurante.estado" | translate }}</b>{{ "restaurante.cancelada" | translate }}
      </div>
      <div *ngIf="selectedReserva.estado == 0" style="background-color: yellow">
        <b>{{ "restaurante.estado" | translate }}</b>{{ "restaurante.sinconfirmar" | translate }}
      </div>
      <div *ngIf="selectedReserva.estado == 1" style="background-color: #8ad183">
        <b>{{ "restaurante.estado" | translate }}</b>{{ "restaurante.confirmada" | translate }}
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">
      {{ "restaurante.atras" | translate }}
    </button>
    <button type="button" class="btn btn-info" (click)="actualizarReserva()">
      {{ "restaurante.actualizar" | translate }}
    </button>
    <button *ngIf="selectedTab != 2" type="button" class="btn btn-danger" (click)="cancelarReserva('selectedReserva')">
      {{ "restaurante.cancelarreserva" | translate }}
    </button>
  </div>
</ng-template>

<!--POPUP RRESERVA DETALLES/CANCELAR -->
<!--POPUP CREAR RESERVA -->
<ng-template #popUpCrearReserva let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "restaurante.crearreserva" | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="">
      <div class="form-group">
        <kendo-label text="{{ 'restaurante.comedor' | translate }}"></kendo-label>
        <kendo-dropdownlist [disabled]="listaComedores.length==1" [data]="listaComedores" textField="nombre"
          valueField="id" [(ngModel)]="selectedComedor" (selectionChange)="valueChangeComedor($event)">
        </kendo-dropdownlist>
      </div>
      <div class="form-group">
        <kendo-label text="{{ 'restaurante.tipocomida' | translate }}"></kendo-label>
        <kendo-dropdownlist [disabled]="listaTiposComida.length==1" [data]="listaTiposComida" textField="nombre"
          valueField="id" [(ngModel)]="selectedComida" (selectionChange)="valueChangeComida($event)">
        </kendo-dropdownlist>
      </div>
      <div class="form-group">
        <kendo-label text="{{ 'restaurante.mesacomedor' | translate }}"></kendo-label>
        <kendo-dropdownlist [disabled]="listaMesasConectadas.length==1"
          (selectionChange)="valueChangeCambioMesa($event)" [data]="listaMesasConectadas" textField="mesas"
          valueField="id" [(ngModel)]="mesaSeleccionada">
        </kendo-dropdownlist>
      </div>
      <div class="form-group"
        *ngIf="configuracionRestaurante.modoReserva==2 || (configuracionRestaurante.modoReserva==1 && configuracionRestaurante.rangoTiempoReservaActivado) ">
        <kendo-label text="{{ 'restaurante.horaReserva' | translate }}"></kendo-label>
        <kendo-dropdownlist [disabled]="listaHorasAUtilizar.length==1" textField="hora" valueField="hora"
          (selectionChange)="valueChangeCambioHora($event)" [data]="listaHorasAUtilizar" [(ngModel)]="horaSeleccionada">
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            <span *ngIf="dataItem!=undefined">{{ dataItem.hora | kendoDate:'HH:mm' }}</span>
          </ng-template>
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span *ngIf="dataItem!=undefined">{{ dataItem.hora | kendoDate:'HH:mm' }}</span>
          </ng-template>
        </kendo-dropdownlist>
      </div>
      <div class="form-group">
        <kendo-label text="{{ 'restaurante.numcomensales' | translate }}"></kendo-label><br>
        <kendo-numerictextbox [style.width.px]="200" [(ngModel)]="numComensales" [format]="'n'" [decimals]="0" [min]="1"
          [max]="numComensalesMax">
        </kendo-numerictextbox>
      </div>

      <div class="form-group">
        <kendo-label text="{{ 'restaurante.nombreusuario' | translate }}"></kendo-label>
        <kendo-textbox [style.width.px]="200" placeholder="Nombre del reservante" [clearButton]="true"
          [(ngModel)]="nombreComensalReserva">
        </kendo-textbox>
      </div>

      <div class="form-group">
        <kendo-label text="{{ 'restaurante.telefono' | translate }}"></kendo-label>
        <kendo-textbox [style.width.px]="200" placeholder="Número de teléfono" [clearButton]="true"
          [(ngModel)]="numTelefono">
        </kendo-textbox>
      </div>
      <div class="form-group">
        <kendo-label text="{{ 'restaurante.observaciones' | translate }}"></kendo-label>
        <kendo-textbox [style.width.px]="200" placeholder="Observaciones" [clearButton]="true"
          [(ngModel)]="reservaObservaciones">
        </kendo-textbox>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">
      {{ "restaurante.atras" | translate }}
    </button>
    <button type="button" class="btn btn-success" [disabled]="!botonCrearReserva" (click)="crearReserva()">
      {{ "restaurante.crearreserva" | translate }}
    </button>
  </div>
</ng-template>