import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, MenuService } from '@app/_services';
import { EquiposService } from '@app/_services/equipos.service';
import { EncuestasService } from '@app/_services/encuestas.service';
import { Organizacion } from '@app/_models/organizacion';
@Component({
  selector: 'app-equipos',
  templateUrl: './equipos.component.html',
})
export class EquiposComponent implements OnInit {

  listaEquipos=[];
  listaEntesAdministrados=[];
  selectedOrg:Organizacion;
  lang;
  public seleccionados: number[] = [];
  private translate: TranslateService;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  constructor(private equiposService: EquiposService,
    private encuestasService: EncuestasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    public router: Router,
    translate: TranslateService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private modalService: NgbModal,) {
      this.translate = translate;
      this.menuService.titulo = this.translate.instant('equipos.titulo')
    }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();

    this.encuestasService.getEntesAdministrados().subscribe((result) => {
      this.listaEntesAdministrados = result.data;
      if(this.listaEntesAdministrados.length>0){
        this.selectedOrg=this.listaEntesAdministrados[0];

      }
    });
    this.equiposService.GetAll(this.selectedOrg.id).subscribe((result)=>{
      this.listaEquipos = result.data;
    });
  }
  loadEquipos(e,d){
    this.equiposService.GetAll(this.selectedOrg.id).subscribe((result)=>{
      this.listaEquipos = result.data;
    });
  }

  onClickNuevo() {
    if (this.selectedOrg == null) {
      this.alertService.warn(
        this.translateService.instant('publicaciones.selectente')
        );
    } else {
      this.router.navigate(['equipos-crear',{ idorg:this.selectedOrg.id }]);
    }
  }

  onClickEliminar(content){
    console.log('Eliminando...');
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  onClickEditar(){
    this.router.navigate(['equipos-editar',{idorg:this.selectedOrg.id, idEquipo:this.seleccionados[0] }]);
  }

  cellClick(e){
    this.router.navigate(['equipos-editar',{idorg:this.selectedOrg.id, idEquipo:this.seleccionados[0] }]);
  }

  eliminarRegistro(contentloading){
    console.log('Eliminar registro');
    this.equiposService.delete({ codigos: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.equiposService.GetAll(this.selectedOrg.id).subscribe((result) => {
            this.listaEquipos = result.data;
          }
          );
        }
        this.modalReferenceloading.close();
      }
    );
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }

}
