<div class="row p-4" style="width: 40vw;">
  <div class="card w-100">
    <div class="card-header">
      <h3>
        <label CssClass="">{{ 'menu-main.datos' | translate }}</label>
      </h3>
      <div class="plegarpanel"></div>
    </div>
    <div class="card-body">
      <div class="row p-1">
        <div class="col-3">
          <span>{{'menu-main.idioma' | translate}}</span>
          <kendo-dropdownlist [data]="idiomas" [(ngModel)]="selectedIdioma" valueField="codigo" textField="nombre" (valueChange)="updateSelectedIdioma($event)"></kendo-dropdownlist>
        </div>
      </div>
      <div class="row p-1 mt-1">
        <div class="col">
          <span>{{'menu-main.nombre' | translate}}</span>
          <kendo-textbox [disabled]="isUpdate"  [(ngModel)]="nombre" (valueChange)="updateName($event)"></kendo-textbox>
        </div>
      </div>
      <div class="row p-1 mt-1">
        <div class="col-6">
          <span>{{'menu-main.direccion' | translate}}</span>
          <kendo-dropdownlist [data]="direccionTipos" [value]="selectedDireccion" [(ngModel)]="selectedDireccion"
          valuePrimitive="true"></kendo-dropdownlist>
        </div>
        <div class="col-6">
          <span>{{'menu-main.elementoSuperior' | translate}}</span>
          <kendo-dropdownlist [data]="elementosSuperiores" [value]="selectedPadre" valueField="id" textField="nombre"
            [(ngModel)]="selectedPadre" valuePrimitive="true" (valueChange)="updateOrden($event)"></kendo-dropdownlist>
        </div>
      </div>
      <div class="row p-1 mt-1">
        <div class="col">
          <span>{{'menu-main.contenido' | translate}}</span>
          <kendo-editor (valueChange)="updateContenido($event)" style="height: 50vh;" [(ngModel)]="contenido"></kendo-editor>
        </div>
      </div>
      <div class="row p-1 mt-1">
        <div class="col-6">
          <span>{{'menu-main.icono' | translate}}</span>
          <kendo-dropdownlist [data]="iconos" [(ngModel)]="selectedIcono" valueField="codigoIcono" textField="nombreIcono" valuePrimitive="true">
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span [ngStyle]="{'content': dataItem?.codigoIcono}"></span>{{dataItem?.nombreIcono}}
            </ng-template>
          </kendo-dropdownlist>
        </div>
      </div>
      <div class="row" *ngIf="!isUpdate">
        <div class="col">
          <button type="button" class="btn btn-primary btn-sm mr-1" (click)="btnAddTraduccion()">
            {{ "publicaciones.anadir" | translate }}
          </button>
          <div class="listado-traducciones">
            <div class="traduccion" *ngFor="let idioma of listaTraducciones">
              {{ idioma.nombre }}
              <button type="button" class="btn btn-info btn-sm mr-1" (click)="btnDeleteTraduccion(idioma)">
                X
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row p-1 mt-1">
        <div class="col-md-4 form-group">
          <button (click)="onSubmit()" class="btn btn-success btn-sm mr-1">
            {{ 'botones.guardar' | translate}}
          </button>
          <button class="btnBerria btn btn-danger btn-sm mr-1" (click)="onClickCancelar($event)">
            {{ 'botones.cancelar' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>