import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services/menu.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { AlertService, DepartamentosService } from '@app/_services';
import { Recinto, RecintoTrad } from '@app/_models/recinto';
import { RecintosService } from '@app/_services/recintos.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { EcommerceService } from '@app/_services/ecommerce.service';
import { Articulo, ArticuloTrad } from '@app/_models/ecommerce';
import { FileInfo, FileRestrictions, RemoveEvent, SelectEvent } from '@progress/kendo-angular-upload';

@Component({
  selector: 'app-articulos-crear',
  templateUrl: './articulos-crear.component.html',
})
export class ArticulosCrearComponent implements OnInit {
  //Listas
  listaIdiomas: Idioma[] = [];
  listaIdiomasTotales: Idioma[] = [];
  listaTraducciones: Idioma[] = [];

  //Traducciones
  listaTraduccionesEnviar: ArticuloTrad[] = [];
  //Data
  nombreRecinto: string = '';
  descripcionRecinto: string = '';
  stock: number = 0;
  isOferta: boolean = false;
  porcentajeOferta: string='';
  imagenB64: string = '';
  selectedIdioma: Idioma;
  articuloActivo: boolean = true;
  myArticulo: Articulo = new Articulo();
  filePath: string;
  isRedirect:boolean=false;
  urlRedirect:string='';
  precio:string='';

  public imagePreviews: any[] = [];


  lang;
  private translate: TranslateService;
  idOrg: string;
  idCategoria:number=0;

  constructor(
    private ecommerceService: EcommerceService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant(
      'ecommerce.cabeceraarticulos'
    );
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.idOrg = this.route.snapshot.params['idorg'];
    this.idCategoria = this.route.snapshot.params['idcategoria'];
    //Load Langs
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomasTotales = result.data;
      console.log('Cargados idiomas = ' + this.listaIdiomasTotales.length);
      this.listaIdiomasTotales.forEach((x) => {
        const trad = new ArticuloTrad();
        trad.filled = false;
        trad.idIdioma = x.codigo;
        trad.nombreIdioma = x.nombre;
        this.listaTraduccionesEnviar.push(trad);
      });
    });

    this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
      if (this.listaIdiomas.length > 0)
        this.selectedIdioma = this.listaIdiomas[0];
    });
  }

  btnCrearArticulo() {
    console.log( this.imagePreviews);
    console.log('Creando Articulo');
    //Validación de los datos
    if (this.listaTraducciones.length <= 0) {
      this.alertService.info(
        this.translateService.instant('publicaciones.errortrads')
      );
      return;
    }

    //AutoCompletado de Idiomas
    //seleccionado
    let seleccionado: ArticuloTrad = new ArticuloTrad();
    let encontrado: boolean = false;
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (trad.filled && !encontrado) {
        seleccionado = trad;
        encontrado = true;
        return;
      }
    });
    //Fill UnFilled
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (!trad.filled) {
        trad.nombre = seleccionado.nombre;
        trad.descripcion = seleccionado.descripcion;
      }
    });

    console.log(this.listaTraduccionesEnviar);
    //Subir Foto  y EnviarNoticia
    this.myArticulo.idOrg = this.idOrg;
    this.myArticulo.idCategoria = this.idCategoria;
    this.myArticulo.precio = this.precio;
    this.myArticulo.activo = this.articuloActivo;
    this.myArticulo.descripcion = this.descripcionRecinto;
    this.myArticulo.nombre = this.nombreRecinto;
    this.myArticulo.stock = this.stock;
    this.myArticulo.isOferta = this.isOferta;
    this.myArticulo.isRedirect = this.isRedirect;
    this.myArticulo.urlRedirect = this.urlRedirect;
    this.myArticulo.porcentajeOferta = this.porcentajeOferta;
    let listaImagenes:string[]=[];
    this.imagePreviews.forEach(element => {
      listaImagenes.push(element.src.split(',')[1]);
    });
    this.myArticulo.listaImagenes = listaImagenes;
    this.myArticulo.listaTraducciones = this.listaTraduccionesEnviar;
    console.log('Evinado Articulo');
    console.log(this.myArticulo);
    this.ecommerceService.crearArticulo(this.myArticulo).subscribe((result) => {
      if (!result.error) {
        this.alertService.success(
          this.translateService.instant('ecommerce.success')
        );
        this.router.navigate(['ecommerce-articulos']);
      } else {
        console.log('Error enviando traducciones publicacion');
        this.alertService.error(
          this.translateService.instant('ecommerce.error')
        );
        return;
      }
    });
  }

  btnAddTraduccion() {
    console.log('Comprobando datos');
    if (
      this.selectedIdioma == null ||
      this.nombreRecinto.length <= 0 ||
      this.descripcionRecinto.length <= 0
    ) {
      if (this.listaTraducciones.length <= 0) {
        this.alertService.info(
          this.translateService.instant('publicaciones.validacion')
        );
        return;
      }
    }
    console.log('Datos validados --->');
    if (!this.listaTraducciones.includes(this.selectedIdioma)) {
      this.listaTraducciones.push(this.selectedIdioma);

      this.listaTraduccionesEnviar.map((x) => {
        if (x.idIdioma == this.selectedIdioma.codigo) {
          x.filled = true;
          x.nombre = this.nombreRecinto;
          x.descripcion = this.descripcionRecinto;
        }
      });
    } else {
      console.log('Idioma ya añadido');
    }

    console.log(this.listaTraduccionesEnviar);
  }

  btnDeleteTraduccion(idioma: Idioma) {
    console.log(idioma);
    this.listaTraducciones = this.listaTraducciones.filter(
      (x) => x.codigo != idioma.codigo
    );

    this.listaTraduccionesEnviar.map((x) =>
      x.idIdioma == idioma.codigo ? (x.filled = false) : console.log()
    );

    console.log(this.listaTraduccionesEnviar);
  }

  //Imagen

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  btnCancelar() {
    this.router.navigate(['ecommerce-articulos']);
  }

  public fileRestrictions: FileRestrictions = {
    allowedExtensions: [".jpg", ".png"],
    maxFileSize: 4194304,
  };

  public valueChange(file: FileInfo[]) {
   // this.log(`valueChange event`);
  }

  public remove(e: RemoveEvent): void {
   // this.log(`remove event: ${e.files[0].name}`);

    const index = this.imagePreviews.findIndex(
      (item) => item.uid === e.files[0].uid
    );

    if (index >= 0) {
      this.imagePreviews.splice(index, 1);
    }
  }

  public select(e: SelectEvent): void {
    const that = this;

    e.files.forEach((file) => {

      if (!file.validationErrors) {
        const reader = new FileReader();

        reader.onload = function (ev) {
          const image = {
            src: ev.target["result"],
            uid: file.uid,
          };

          that.imagePreviews.unshift(image);
        };

        reader.readAsDataURL(file.rawFile);
      }
    });
  }

}
