import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import {
  Evento,
  EventoModel,
  EventoTraduccion,
  Recurrencia,
} from '@app/_models/evento';
import {
  DataService,
  DropDownListComponent,
} from '@progress/kendo-angular-dropdowns';
import { AlertService, DepartamentosService } from '@app/_services';
import { EventosService } from '@app/_services/eventos.service';
import {
  Encuesta,
  EncuestaOpcion,
  EncuestaOpcionTraduccion,
  EncuestaTipo,
  EncuestaTraduccion,
} from '@app/_models/encuesta';
import { EncuestasService } from '@app/_services/encuestas.service';
import { TimeSelectorComponent } from '@progress/kendo-angular-dateinputs';
import { DrawerComponent } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-encuestas-editar',
  templateUrl: './encuestas-editar.component.html',
})
export class EncuestasEditarComponent implements OnInit {
  //Listas
  listaEntesAdministrados: Ente[] = [];
  listaIdiomas: Idioma[] = [];
  listaTiposEncuesta: EncuestaTipo[] = [];
  listaTraducciones: Idioma[] = [];
  listaCategorias: Categoria[] = [];
  listaOpciones: EncuestaOpcion[] = [];
  listaOpcionesEnviar: EncuestaOpcion[] = [];
  listaTraduccionesOpcionesEnviar: EncuestaOpcionTraduccion[] = [];
  //Traducciones
  listaTraduccionesEnviar: EncuestaTraduccion[] = [];
  //Data
  titulo: string = '';
  cuerpo: string = '';
  selectedIdioma: Idioma;
  selectedOrg: Ente;
  selectedCategoria: Categoria = new Categoria();
  edadMin: number = 0;
  edadMax: number = 120;
  showResults: boolean;
  isMembersOnly: boolean;
  numOpcionesSeleccionables: number = 1;
  numOpciones: number = 1;
  isPuntuada: boolean;
  saldo: number = 0;

  myEncuesta: Encuesta = new Encuesta();
  public fechaInicio: Date = new Date();
  public fechaFin: Date = new Date();
  public horaInicio: Date = new Date();
  public horaFin: Date = new Date();
  public format = 'dd/MM/yyyy HH:mm';

  idOrg: string;
  idEncuesta: number;
  isBusy: boolean = false;

  lang;
  private translate: TranslateService;

  constructor(
    private encuestaService: EncuestasService,
    private eventoService: EventosService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('encuestas.cabecera');
    this.eventoService.getEntesAdministrados().subscribe((result) => {
      this.listaEntesAdministrados = result.data;
    });

    this.idOrg = this.route.snapshot.params['idorg'];
    this.idEncuesta = this.route.snapshot.params['idencuesta'];
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomas = result.data;
      console.log('Cargados idiomas = ' + this.listaIdiomas.length);
      this.selectedIdioma = this.listaIdiomas[0];
      console.log('Get Encuesta::' + this.idOrg);
      console.log(this.idEncuesta);
      this.loadEncuestaWIthLang();
    });
  }
  loadEncuestaWIthLang() {
    this.encuestaService
      .getEmcuestaByIdLang(this.idEncuesta, this.selectedIdioma.codigo)
      .subscribe((result) => {
        console.log(result.data);
        this.myEncuesta = result.data[0];
        console.log('Encuesta');
        console.log(this.myEncuesta);
        this.titulo = this.myEncuesta.titulo;
        this.cuerpo = this.myEncuesta.descripcion;
        this.edadMax = this.myEncuesta.edadMax;
        this.edadMin = this.myEncuesta.edadMin;

        //fechas
        this.myEncuesta.fecha_inicio = new Date(this.myEncuesta.fecha_inicio);
        this.myEncuesta.fecha_fin = new Date(this.myEncuesta.fecha_fin);

        this.fechaInicio = new Date(
          this.myEncuesta.fecha_inicio.getFullYear(),
          this.myEncuesta.fecha_inicio.getMonth(),
          this.myEncuesta.fecha_inicio.getDate()
        );
        this.horaInicio = new Date(this.myEncuesta.fecha_inicio.getTime());
        this.fechaFin = new Date(
          this.myEncuesta.fecha_fin.getFullYear(),
          this.myEncuesta.fecha_fin.getMonth(),
          this.myEncuesta.fecha_fin.getDate()
        );
        this.horaFin = new Date(this.myEncuesta.fecha_fin.getTime());

        this.showResults = this.myEncuesta.showResults;
        this.isMembersOnly = this.myEncuesta.isMembersOnly;
        this.saldo = this.myEncuesta.saldo;

        //seleccionar categoria
        this.encuestaService
          .loadCategorias(this.selectedIdioma.codigo.toString(), this.idOrg)
          .subscribe((result) => {
            this.listaCategorias = result.data;
            console.log(this.listaCategorias);
            this.listaCategorias.forEach((element) => {
              if (element.id == this.myEncuesta.idCategoria) {
                this.selectedCategoria = element;
              }
            });
          });
        //seleccionar tipo encuesta
        if (this.myEncuesta.tipo == 'PorPuntos') {
          this.isPuntuada = true;
        }

        //crear opciones
        this.encuestaService
          .getOpcionesFromEncuesta(this.idEncuesta, 1)
          .subscribe((result) => {
            console.log('OPCIONES');
            console.log(result);
            this.listaOpciones = result.data;
            this.listaOpcionesEnviar = result.data;
            this.numOpcionesSeleccionables =
              this.myEncuesta.num_opciones_seleccion;
            this.numOpciones = this.listaOpciones.length;
          });
      });
  }
  loadIdiomas(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Cargando idiomas de :' + value.id);
    this.idiomaService.getIdiomasFromEnte(value.id).subscribe((result) => {
      console.log('Idiomas');
      console.log(result.data);
      this.listaIdiomas = result.data;
    });
  }

  loadCategorias(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    // console.log('Cargando Categorias de :' + value.codigo);
    // this.encuestaService
    //   .loadCategorias(value.codigo.toString(), this.idOrg)
    //   .subscribe((result) => {
    //     this.listaCategorias = result.data;
    //     this.selectedCategoria = this.listaCategorias[0];
    //   });
    this.loadEncuestaWIthLang();
  }

  checkTipoEncuesta(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Tipo Seleccionada :' + value.id);
    this.isPuntuada = value.id == 4; //4 = Puntuada
  }

  btnActualizarEncuesta() {
    if (this.isBusy) return;
    //Validación de los datos
    if (
      this.selectedCategoria == null ||
      this.titulo.length < 1 ||
      this.cuerpo.length < 1
    ) {
      this.alertService.warn(
        this.translateService.instant('encuestas.validaciondatos')
      );
      return;
    }
    if (this.fechaInicio > this.fechaFin) {
      this.alertService.warn(
        this.translateService.instant('encuestas.fechaerror')
      );
      return;
    }
    let error = false;
    this.listaOpciones.forEach((element) => {
      if (element.opcion.length == 0 && !element.borrar) error = true;
    });
    if (error) {
      this.alertService.warn(
        this.translateService.instant('encuestas.validaciondatos')
      );
      return;
    }

    console.log('Actualizando Encuesta');
    this.isBusy = true;
    this.myEncuesta.idCategoria = this.selectedCategoria.id;
    this.myEncuesta.categoria = this.selectedCategoria.nombre;
    this.myEncuesta.idOrg = this.idOrg;
    this.myEncuesta.isMembersOnly = this.isMembersOnly;
    this.myEncuesta.saldo = this.saldo;
    this.myEncuesta.num_opciones_seleccion = this.numOpcionesSeleccionables;
    this.myEncuesta.showResults = this.showResults;
    this.myEncuesta.edadMax = this.edadMax;
    this.myEncuesta.edadMin = this.edadMin;
    this.myEncuesta.titulo = this.titulo;
    this.myEncuesta.descripcion = this.cuerpo;
    this.myEncuesta.idIdioma = this.selectedIdioma.codigo;

    console.log('Configurando Fechas...');
    this.myEncuesta.fecha_inicio = new Date(
      this.fechaInicio.getFullYear(),
      this.fechaInicio.getMonth(),
      this.fechaInicio.getDate(),
      this.horaInicio.getHours(),
      this.horaInicio.getMinutes(),
      this.horaInicio.getSeconds()
    );
    this.myEncuesta.fecha_fin = new Date(
      this.fechaFin.getFullYear(),
      this.fechaFin.getMonth(),
      this.fechaFin.getDate(),
      this.horaFin.getHours(),
      this.horaFin.getMinutes(),
      this.horaFin.getSeconds()
    );
      this.listaOpciones.forEach(element => {
        element.idIdioma = this.selectedIdioma.codigo;
      });
    this.myEncuesta.listaOpciones = this.listaOpciones;

    //Actualizar Encuesta
    this.encuestaService
      .actualizarEncuesta(this.myEncuesta)
      .subscribe((result) => {
        if (!result.error) {
          this.isBusy = false;
          this.alertService.success(
            this.translateService.instant('encuestas.successupdate')
          );
        } else {
          this.isBusy = false;
          this.alertService.error(
            this.translateService.instant('encuestas.errorupdate')
          );
        }
      });
  }

  btnAddOption() {
    if (this.listaTraducciones.length > 0) {
      this.alertService.warn(
        this.translateService.instant('encuestas.alerteditopcion')
      );
      return;
    }
    this.numOpciones++;
    let tmpOpcion: EncuestaOpcion = new EncuestaOpcion();
    tmpOpcion.id = -this.listaOpciones.length;
    tmpOpcion.opcion = '';
    tmpOpcion.puntuacion = 0;
    tmpOpcion.nuevo = true;
    this.listaOpciones.push(tmpOpcion);
  }

  btnDeleteOption(opcion: EncuestaOpcion) {
    let numBorrar: number = 0;
    this.listaOpciones.forEach((element) => {
      if (element.borrar) numBorrar++;
    });
    if (numBorrar >= this.listaOpciones.length - 1) {
      return;
    }
    this.numOpciones--;
    //this.listaOpciones = this.listaOpciones.filter(x=>x.id!=opcion.id);
    this.listaOpciones.map((x) => {
      if (x.id == opcion.id) {
        x.borrar = true;
      }
    });
    console.log('Borrar numOpcionesSeleccionables');
    console.log(this.numOpcionesSeleccionables);
    console.log(numBorrar);
    if (this.numOpcionesSeleccionables > numBorrar)
      this.numOpcionesSeleccionables--;
  }

  btnCancelar() {
    this.router.navigate(['encuestas']);
  }
}
