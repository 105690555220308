import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PatrocinadoresService } from "../_services/patrocinadores.service";

@Component({
  selector: 'app-patrocinadores',
  templateUrl: './patrocinadores.component.html',

})
export class PatrocinadoresComponent implements OnInit {

  public dataPatrocinadores: [];
  lang;
  public seleccionados: number[] = [];
  private translate: TranslateService;


  constructor(
    private contactosService: PatrocinadoresService,
    public router: Router,
    translate: TranslateService,
    private translateService: TranslateService,
  ) {
    this.translate = translate;
    this.contactosService.GetAll().subscribe((result) => {
      this.dataPatrocinadores = result.data;
    })
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.contactosService.GetAll().subscribe((result) => {
      this.dataPatrocinadores = result.data;
      console.log('dataContactos Loaded :'+this.dataPatrocinadores.length.toString())
    });
  }

}
