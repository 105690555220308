import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Recinto, Reserva, Sala } from '@app/_models/recinto';
import { environment } from '@environments/environment';
import { SchedulerEvent } from '@progress/kendo-angular-scheduler';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/recinto`;

@Injectable({
  providedIn: 'root',
})
export class RecintosService {
  public fechaInicio: Date = new Date();
  public evento: SchedulerEvent;

  constructor(private http: HttpClient) {}

  public GetById(id: Number): Observable<any> {
    return this.http.get(baseUrl + '/' + id);
  }

  public GetRecintoByIdLang(idrecinto: Number, lang: number): Observable<any> {
    return this.http.get(baseUrl + '/get/' + idrecinto + '/' + lang);
  }

  public getEntesAdministrados(): Observable<any> {
    return this.http.get(
      environment.apiUrl + '/organizacion/getentesuser/' + '1'
    );
  }

  public getRecintosFromEnte(idOrg: string): Observable<any> {
    return this.http.get(baseUrl + '/ente/' + idOrg.toString());
  }

  public crearRecinto(recinto: Recinto): Observable<any> {
    console.log('[S] Recinto crear');
    console.log(recinto);
    return this.http.post(baseUrl + '/create/', JSON.stringify(recinto), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  public actualizarRecinto(recinto: Recinto): Observable<any> {
    console.log(recinto);
    return this.http.post(baseUrl + '/update/', JSON.stringify(recinto), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  delete(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminar', formData, {
      withCredentials: true,
    });
  }

  //SALAS
  public getSalasFromRecinto(idRecinto: number): Observable<any> {
    return this.http.get(baseUrl + '/getsalas/' + idRecinto + '/1'); //1=lang
  }

  public crearSala(sala: Sala): Observable<any> {
    console.log('[S] enviando Sala');
    console.log(sala);
    return this.http.post(baseUrl + '/createsala/', JSON.stringify(sala), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  public actualizarSala(sala: Sala): Observable<any> {
    console.log('[S] actualizarSala');
    console.log(sala);
    return this.http.post(baseUrl + '/updatesala/', JSON.stringify(sala), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  public getSalaByIdLang(idsala: Number, lang: number): Observable<any> {
    return this.http.get(baseUrl + '/getsala/' + idsala + '/' + lang);
  }

  getAllUsersWithAdminsAsignados(idSala): Observable<any> {
    return this.http.get(baseUrl + '/getadmins/' + idSala);
  }
  getAllUsersWithUsuariosAsignados(idSala): Observable<any> {
    return this.http.get(baseUrl + '/getusers/' + idSala);
  }

  deleteSalas(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminarsalas', formData, {
      withCredentials: true,
    });
  }
  //Reservas
  public getReservasFromSala(idsala: Number): Observable<any> {
    return this.http.get(baseUrl + '/getreservas/' + idsala);
  }

  public getReserva(idReserva: Number): Observable<any> {
    return this.http.get(baseUrl + '/getreserva/' + idReserva);
  }
  public deleteReserva(idsala: Number): Observable<any> {
    return this.http.get(baseUrl + '/deletereserva/' + idsala);
  }

  public crearReserva(reserva: Reserva): Observable<any> {
    console.log('[S] enviando crear Reserva');
    console.log(reserva);
    reserva.start = new Date(this.dateToYYYYMMDDtHHmmSSz(reserva.start));
    reserva.end = new Date(this.dateToYYYYMMDDtHHmmSSz(reserva.end));
    return this.http.post(
      baseUrl + '/createreserva/',
      JSON.stringify(reserva),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public actualizarReserva(reserva: Reserva): Observable<any> {
    console.log('[S] enviando actualizar Reserva');
    console.log(reserva);
    reserva.start = new Date(this.dateToYYYYMMDDtHHmmSSz(reserva.start));
    reserva.end = new Date(this.dateToYYYYMMDDtHHmmSSz(reserva.end));
    return this.http.post(
      baseUrl + '/updatereserva/',
      JSON.stringify(reserva),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public confirmarReserva(idReserva: Number): Observable<any> {
    return this.http.get(baseUrl + '/confirmar/' + idReserva);
  }

  public cancelarReserva(idReserva: Number): Observable<any> {
    return this.http.get(baseUrl + '/cancelar/' + idReserva);
  }

  //Data
  public getFechaInicioReserva() {
    return this.fechaInicio;
  }
  public setFechaInicioReserva(fecha: Date) {
    this.fechaInicio = fecha;
  }

  public getEventoScheduler() {
    return this.evento;
  }
  public setEventoScheduler(evento: SchedulerEvent) {
    this.evento = evento;
  }

  //Helpers
  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return (
      año +
      '-' +
      this.addZero(mes) +
      '-' +
      this.addZero(dia) +
      'T' +
      this.addZero(hora) +
      ':' +
      this.addZero(minutos) +
      ':' +
      this.addZero(segundos) +
      'Z'
    );
  }

  addZero(num) {
    if (num < 10) return '0' + num;
    else return num;
  }
}
