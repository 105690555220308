import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Usuario } from '@app/_models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';

const baseUrl = `${environment.apiUrl}/sitios`;

@Injectable()
export class SitiosService {

  constructor(private http: HttpClient) { }

  public GetById(id: Number): Observable<any> {
    return this.http.get(baseUrl + "/" + id);
  }

  public GetAll(): Observable<any> {
    return this.http.get(baseUrl);
  }

  public GetTipos(): Observable<any> {
    return this.http.get(baseUrl + "/tipos/");
  }

  download(imagen): Observable<any> {
    return this.http.get(baseUrl + "/download/" + imagen);
  }

  upload(data): Observable<any> {
    return this.http.post<any>(baseUrl + "/upload/", { foto: data.foto, fotoContent: data.fotoContent }, { withCredentials: true });
  }


  insert(data) {
    return this.http.post<any>(baseUrl + "/crear", { codigo: -1, tipositio: data.tipositio, nombre1: data.nombre1, nombre2: data.nombre2,
      poblacion: data.poblacion, telefono: data.telefono, direccion: data.direccion, email: data.email, descripcion1: data.descripcion1, descripcion2: data.descripcion2,
      link1: data.link1, link2: data.link2, foto: data.foto, latitud: data.latitud, longitud: data.longitud }, { withCredentials: true });
  }
  
  update(data) {
    //console.log(JSON.stringify(data));
    //console.log(idTipoPerdida_DAT);
    return this.http.post<any>(baseUrl + "/editar", { codigo: data.codigo, tipositio: data.tipositio, nombre1: data.nombre1, nombre2: data.nombre2,
      poblacion: data.poblacion, telefono: data.telefono, direccion: data.direccion, email: data.email, descripcion1: data.descripcion1, descripcion2: data.descripcion2,
      link1: data.link1, link2: data.link2, foto: data.foto, latitud: data.latitud, longitud: data.longitud }, { withCredentials: true });
  }

  updateImagen(data) {
    return this.http.post<any>(baseUrl + "/editarImagen", { codigo: data.codigo, foto: data.foto });
  }

  delete(data?: any): Observable<any> {

    //console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append("codigos", data.codigos);
    //console.log(JSON.stringify(formData));

    return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });
  }

  deleteImagen(data) {
    return this.http.post<any>(baseUrl + "/eliminarImagen", { codigo: data.codigo });
  }

}
