<div class="row">
    <div class="col-lg-7">
        <div class="card">
            <div class="card-header">
                <h3>
                    <label CssClass="">{{ 'datos' | translate }}</label>
                </h3>
                <div class="plegarpanel"></div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-xl-4">
                        <label class="">{{ 'numerosocio' | translate }}</label>
                        <kendo-multiselect kendoMultiSelectSummaryTag [data]="socios"
                                                [textField]="'numsocio'"
                                                [valueField]="'codigo'"
                                                [autoClose]="true"
                                                [(ngModel)]="socioSeleccionado"
                                                style="width: 100%;"
                                                [kendoDropDownFilter]="{operator: 'contains'}"
                                                (valueChange)="handleValue($event)">
                            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                <span class="k-icon k-i-arrow-s"></span>
                                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].numsocio }}</ng-container>
                            </ng-template>
                            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                            </kendo-multiselect>
                    </div>
                    <div class="col-xl-8">
                        <label class="">{{ 'socio' | translate }}</label>
                        <kendo-multiselect kendoMultiSelectSummaryTag [data]="socios"
                                                [textField]="'nombreAux'"
                                                [valueField]="'codigo'"
                                                [autoClose]="true"
                                                [(ngModel)]="socioSeleccionado"
                                                style="width: 100%;"
                                                [kendoDropDownFilter]="{operator: 'contains'}"
                                                (valueChange)="handleValue($event)">
                            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                <span class="k-icon k-i-arrow-s"></span>
                                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreAux }}</ng-container>
                            </ng-template>
                            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                            </kendo-multiselect>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-xl-4">
                        <label>{{ 'fecha' | translate }}</label>
                        <kendo-datepicker #picker [(value)]="fecha2" disabled>
                        </kendo-datepicker>
                    </div>
                    <div class="col-xl-4">
                        <label>{{ 'comida' | translate }}</label>
                        <kendo-dropdownlist [data]="listItemsComida" [(ngModel)]="comidaNum" valuePrimitive="true"
                            [textField]="'value'" [valueField]="'id'"
                            disabled>
                        </kendo-dropdownlist>
                    </div>
                    <div class="col-xl-4">
                        <label>{{ 'comedor' | translate }}</label>
                        <kendo-dropdownlist [data]="listItemsComedor" [(ngModel)]="comedorNum" valuePrimitive="true"
                            [textField]="'value'" [valueField]="'id'" 
                            disabled>
                        </kendo-dropdownlist>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <label>{{ 'estado' | translate }}</label>
                        <kendo-dropdownlist [data]="listItemsEstado" [(ngModel)]="EstadoNum" valuePrimitive="true"
                            [textField]="'value'" [valueField]="'id'" 
                            disabled>
                        </kendo-dropdownlist>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-5">
        <div class="card">
            <div class="card-header">
                <h3>
                    <label CssClass="">{{ 'mesa' | translate }}</label>
                </h3>
                <div class="plegarpanel"></div>
            </div>
            <div class="card-body">
                <div class="tablasReserva row">
                    <div class="tablasReserva1 col-xl-4">
                        <img [src]="imagenFondo" [ngStyle]="{'border': color }"
                            style="width: 100%; max-width: 180px; max-height: 150px;">
                    </div>
                    <div class="tablasReserva2 col-xl-8 datosReservaDetalle">
                        <div class="row">
                            <div class="col-xl-4">
                                <label>{{ 'num' | translate }}</label>
                                <kendo-dropdownlist id="id" [(ngModel)]="id" [data]="listaIds"
                                    (valueChange)="actualizar(id)"> </kendo-dropdownlist>
                            </div>
                            <!-- <div class="col-xl-4" >
                                <label>{{ 'plazaslibres' | translate }}</label>
                                <textarea id="libres" name="libres" rows="2" cols="10" [value]="comensalesMax"></textarea>
                            </div> -->
                            <div class="col-xl-4 formularios-numeros">
                                <label>{{ 'plazaslibres' | translate }}</label>
                                <kendo-textbox class="p-0 m-0" id="libres" name="libres" [value]="plazasLibresTotal"
                                    disabled></kendo-textbox>
                            </div>
                            <div class="col-xl-4">
                                <label>{{ 'comensales' | translate }}</label>
                                <kendo-floatinglabel class="p-0 m-0" text="">
                                    <kendo-numerictextbox id="num-comensales" [(value)]="plazasLibres" name="comensales"
                                        [min]="0" [max]="plazasLibresTotal" [autoCorrect]="true" [format]="'n'">
                                    </kendo-numerictextbox>
                                </kendo-floatinglabel>
                            </div>
                        </div>
                        <div class="clearfix text-center">
                            <button kendoButton class="btn mt-4 mr-3 btn-primary w-50" *ngIf="plazasLibres != 0"
                                (click)="InsertarReserva()">{{ 'anadir' | translate}}</button>
                        </div>
                        <kendo-dialog title="" *ngIf="opened" (close)="close('cancel')" [minWidth]="250" [width]="450">
                            <p style="margin: 30px; text-align: center;">{{mensajeError | translate}}</p>
                            <kendo-dialog-actions>
                                <button kendoButton (click)="close()">{{'cerrar' | translate}}</button>
                            </kendo-dialog-actions>
                        </kendo-dialog>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="clearfix">
    <div class="card">
        <div class="card-header">
            <h3>
                <label CssClass="">{{ 'reservas' | translate }}</label>
            </h3>
            <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
            <ul class="list-group list-group-flush">
                <kendo-grid [kendoGridBinding]="reserva2" [sortable]="true" [navigable]="true" filterable="menu">
                    <kendo-grid-column width="15%" field="numeromesa" title="{{ 'num' | translate}}" [style]="{'text-align': 'right'}">
                    </kendo-grid-column>
                    <kendo-grid-column width="65%" field="nombre" title="{{ 'nombre' | translate}}">
                    </kendo-grid-column>
                    <kendo-grid-column width="10%" field="comensales" title="{{ 'comensales' | translate}}" [style]="{'text-align': 'right'}">
                    </kendo-grid-column>
                    <kendo-grid-column width="10%" field="comensalesmesa" title="{{ 'reservado' | translate}}" [style]="{'text-align': 'right'}">
                    </kendo-grid-column>
                    <kendo-grid-column width="5%" field="eliminar" title="">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <i class="fas fa-times eliminarIrudia" (click)="EliminarReserva(dataItem)"></i>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                        filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                        filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                        filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                        filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                        filterContainsOperator="{{'filterContainsOperator' | translate}}"
                        filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                        filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                        filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                        filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                        filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                        filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                        filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                        filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                        filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                        filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                        filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                        filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                        filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                        filterOrLogic="{{'filterOrLogic' | translate}}"
                        filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                        groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}"
                        noRecords="{{'noRecords' | translate}}" unlock="{{'unlock' | translate}}">
                    </kendo-grid-messages>
                </kendo-grid>
            </ul>
        </div>
    </div>
</div>
<div class="clearfix text-center">
    <button kendoButton class="btn btn-primary pl-5 pr-5" (click)="Atras()">{{ 'atras' | translate}}</button>
</div>