import { Component, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Usuario } from '@app/_models';
import { MenuService, UsuariosService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RestauranteService } from '@app/_services/restaurante.service';


@Component({
  selector: 'app-comedores',
  templateUrl: './comedores.component.html',
})
export class ComedoresComponent implements OnInit {
  user = this.usuariosService.userValue;
  navigationSubscription;
  public comedores: any = []; //Guarda todos las comedores
  public comedoresSeleccionados: any = []; //Guarda todos los comedores seleccionados
  private translate: TranslateService;
  isDeleting = false;
  @ViewChild('popupBorrar') popupBorrar: NgbModalRef;
  modalReference: NgbModalRef;
  idOrg: string;
  idRestaurante: number;

  constructor(private restauranteService: RestauranteService,
    private menuService: MenuService, public router: Router, public route: ActivatedRoute,
    private translateService: TranslateService,
    private usuariosService: UsuariosService, private modalService: NgbModal) {
    this.translate = translateService;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/comedores') {
          this.menuService.titulo = this.translateService.instant('comedores');
          this.cargarDatos();
        }
      }
    });
  }

  ngOnInit(): void {
    this.idOrg = this.route.snapshot.params['idorg'];
    this.idRestaurante = this.route.snapshot.params['idrestaurante'];
    this.cargarDatos();
  }

  cargarDatos() {
    console.log('Loading comedores...');
    this.restauranteService.getComedores(this.idRestaurante).subscribe((result) => {
      console.log(result);
      this.comedores = result.data;
    });
  }

  onClickNuevo() {
    this.router.navigate(['comedores-editar', { idorg: this.idOrg, idrestaurante: this.idRestaurante, idcomedor: -1 }]);
  }

  onClickEliminar() {
    this.modalReference = this.modalReference = this.modalService.open(this.popupBorrar, { backdrop: 'static', size: 'xxl', keyboard: false, centered: true });
  }

  cancelarModal() {
    this.modalReference.close();
  }

  btnBorrarAceptar() {
    this.comedoresSeleccionados.forEach(element => {
      if (element > 0) {
        this.isDeleting = true;
        var ids: string = "";
        this.comedoresSeleccionados.forEach(
          ap => {
            if (ids == "")
              ids += ap;
            else
              ids += ',' + ap;
          }
        );
        this.restauranteService.deleteComedores(ids).subscribe(() => {
          this.isDeleting = false;
          this.
            cargarDatos();
        });
        ids.split(',').forEach(id => {
          this.restauranteService.getPerfiles(+id).subscribe(result => {
            let perfiles: number[] = result.data.map(perfil => perfil.id);
            perfiles.forEach(idPerfil => {
              this.restauranteService.deletePerfil(idPerfil).subscribe(result => {
                console.log(result);
              })
            })
          })
        });
      }
      this.modalReference.close();
    });
  }

  cellClick(e) {
    if (e.columnIndex > 0)
      this.router.navigate(['comedores-editar', { idorg: this.idOrg, idrestaurante: this.idRestaurante, idcomedor: e.dataItem.id }]);
  }

  btnBack() {
    this.router.navigate([
      'restaurantes-editar',
      { idorg: this.idOrg, idrecinto: this.idRestaurante },
    ]);
  }
}
