
<div class="row">
  <div class="col-lg-4"><!--DATU GARRANTZITSUENAK:-->

    <!--DATOS-->
        <div class="card">
          <div class="card-header">
            <h3>{{ "organizaciones.datos" | translate }}</h3>
          </div>
          <div class="card-body">

            <div class="form-group">
              <!--Titulo-->
              <kendo-label text="{{ 'organizaciones.nombre' | translate }}">
              <kendo-textbox
                placeholder="{{ 'organizaciones.PHNombreOrg' | translate }}"
                [clearButton]="true"
                [(ngModel)]="nombre"
                [class.requerido]="nombreRequerido"
              >
                
              </kendo-textbox>
            </kendo-label>
            <span style="color: red" *ngIf="nombreRequerido">{{ "organizaciones.valNombreOrg" | translate }}</span>
            </div>


            <kendo-label text="{{ 'organizaciones.avatar' | translate }}"></kendo-label>
            <div class="row">
              <div class="col-10">
                <kendo-fileselect [(ngModel)]="logoDocumentoAgregar"
                  class="form-control"
                  [restrictions]="restrictions"
                  [multiple]="false"
                  (select)="selectEventHandler($event)"
                  (remove)="removeEventHandler($event)"
                >
                  <kendo-upload-messages
                    cancel="{{ 'botones.cancelar' | translate }}"
                    dropFilesHere="{{ 'organizaciones.dropFoto' | translate }}"
                    invalidFileExtension="{{ 'organizaciones.extFotoIn' | translate}}"
                    invalidMaxFileSize="{{'organizaciones.invalidMax' | translate}}"
                    remove="{{ 'organizaciones.remFoto' | translate }}"
                    select="{{ 'organizaciones.slFoto' | translate }}"
                  >
                  </kendo-upload-messages>
                </kendo-fileselect>
              </div>

              <div class="col-lg-12 col-xl-2">
                <div *ngIf="!avatarBorrado && avatarPreview">
                  <button kendoButton (click)="onClickEliminarImagen(content)" class="btn-sm btn-danger mr-1" [disabled]="isNotInsA">
                    {{ "botones.eliminar" | translate }}
                  </button>
                </div>
              </div>
            </div>

            <ng-template #notImConf>
              <span
                ><i>{{ "organizaciones.noimagen" | translate }}</i></span
              >
            </ng-template>

            <div *ngIf="avatarPreview; else notImConf">
              <img [src]="avatarPreview" style="max-height: 113px" />
            </div>

            <kendo-label text="{{ 'organizaciones.cabecera' | translate }}">
            </kendo-label>

            <div class="row">
              <div class="col-10">
                <kendo-fileselect [(ngModel)]="logoDocumentoAgregar"
                  class="form-control"
                  [restrictions]="restrictions"
                  [multiple]="false"
                  (select)="selectEventHandlerCabecera($event)"
                  (remove)="removeEventHandlerCabecera($event)"
                >
                  <kendo-upload-messages
                    cancel="{{ 'botones.cancelar' | translate }}"
                    dropFilesHere="{{ 'organizaciones.dropFoto' | translate }}"
                    invalidFileExtension="{{ 'organizaciones.extFotoIn' | translate }}"
                    invalidMaxFileSize="{{'organizaciones.invalidMax' | translate }}"
                    remove="{{ 'organizaciones.remFoto' | translate }}"
                    select="{{ 'organizaciones.slFoto' | translate }}"
                  >
                  </kendo-upload-messages>
                </kendo-fileselect>
              </div>

              <div class="col-lg-12 col-xl-2">
                <div *ngIf="!cabeceraBorrada && cabeceraPreview">
                  <button kendoButton (click)="onClickEliminarImagen(content2)" class="btn-sm btn-danger mr-1" [disabled]="isNotInsC">
                    {{ "botones.eliminar" | translate }}
                  </button>
                </div>
              </div>
            </div>

            <ng-template #notImConf>
              <span><i>{{ "organizaciones.nocabecera" | translate }}</i></span>
            </ng-template>

            <div *ngIf="cabeceraPreview; else notImConf">
              <img [src]="cabeceraPreview" style="max-height: 113px" />
            </div>

            <kendo-label text="{{ 'organizaciones.activo' | translate }}">
            </kendo-label>

            <div class="caja">
              <kendo-switch [(ngModel)]="activoOrganizacion" [ngModelOptions]="{ standalone: true }"></kendo-switch>
            </div>
          </div>
        </div>
        <!--END DATOS-->

        <!-- CORREO-->
        <div class="card">
          <div class="card-header">
            <h3>{{ "organizaciones.email" | translate }}</h3>
          </div>
          <div class="card-body">
            <div class="form-group">
              <kendo-label text="{{ 'organizaciones.email' | translate }}">
                <kendo-textbox [(ngModel)]="email" type="email"  placeholder="email@dominio.com" >
                </kendo-textbox>
                <span style="color: red" *ngIf="emailValidado">{{ "organizaciones.ValEmail" | translate }}</span>
              </kendo-label>
            </div>

            <div class="row">
              <div class="col-lg-8">
                <div class="form-group">
                  <kendo-label text="{{ 'organizaciones.smtp' | translate }}">
                    <kendo-textbox [(ngModel)]="smtp" placeholder="smtp.dominio.com">
                    </kendo-textbox>
                  </kendo-label>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <kendo-label text="{{ 'organizaciones.puerto' | translate }}">
                    <div class="caja">
                      <kendo-numerictextbox
                        [(value)]="puerto"
                        placeholder="1-9999" min="1" max="99999"  decimals="0" format="n"   >
                      </kendo-numerictextbox>
                    </div>
                  </kendo-label>
                </div>
              </div>
            </div>

            <div class="form-group">
              <kendo-label text="{{ 'configuracion.usuario' | translate }}">
              </kendo-label>
            </div>
            <div class="form-group">
              <kendo-textbox   [(ngModel)]="emailUsuario" placeholder="{{ 'organizaciones.PHusuario' | translate }}"
                [class.requerido]="emailUsuarioRequerido"  >
              </kendo-textbox>
              <span style="color: red" *ngIf="emailUsuarioRequerido">{{ "organizaciones.PHusuario" | translate }}</span>
              <span style="color: red" *ngIf="emailUsuarioValidado">{{ "organizaciones.ValEmail" | translate }}</span>

            </div>

            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <kendo-label
                    text="{{ 'organizaciones.cambiarcontraseña' | translate }}"
                  >
                  </kendo-label>

                  <div class="form-group">
                    <kendo-switch
                      [(ngModel)]="activoGuardarPassword"
                      [ngModelOptions]="{ standalone: true }" name="guardarPassword"></kendo-switch>
                  </div>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="form-group">
                  <div class="form-group">
                    <kendo-label text="{{ 'configuracion.contrasena' | translate }}">
                    </kendo-label>
                  </div>

                  <input kendoTextBox type="password" [(ngModel)]="contrasena" [class.requerido]="contrasenaRequerido"           />
                  <span style="color: red" *ngIf="contrasenaRequerido">{{ "organizaciones.PHPass" | translate }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--END CORREO-->

        <!-- IDIOMAS-->
        <div class="card">
          <div class="card-header">
            <h3>{{ "organizaciones.idiomas" | translate }}</h3>
          </div>
          <div class="card-body">
            <kendo-buttongroup selection="multiple">
              <button
                *ngFor="let button of listaIdiomas"
                kendoButton
                [toggleable]="true"
                (selectedChange)="selectedLangsChange($event, button)"
                [selected]="button.selected"
              >
                {{ button.nombre }}
              </button>
            </kendo-buttongroup>
          </div>
        </div>
        <!-- END IDOMAS-->

  </div>

  <div class="col-lg-4">
          <!-- INFORMACION-->
          <div class="card">
            <div class="card-header">
              <h3>{{ "configuracion.informacion" | translate }}</h3>
            </div>
            <div class="card-body">
              <div class="row form-group">
                <div class="col">
                  <kendo-label text="{{ 'organizaciones.notificaciones' | translate }}"></kendo-label>
                  <div class="caja">
                    <kendo-switch [disabled]="notificacionesDisabled" [(ngModel)]="activoNotificaciones"
                      [ngModelOptions]="{ standalone: true }"></kendo-switch>
                  </div>
                </div>
                <div class="col">
                  <kendo-label text="{{ 'organizaciones.farmacias' | translate }}">
                  </kendo-label>
                  <div class="caja">
                    <kendo-switch [(ngModel)]="activoFarmacias" [ngModelOptions]="{ standalone: true }">
                    </kendo-switch>
                  </div>
                </div>
                <div class="col">
                  <kendo-label text="{{ 'organizaciones.faq' | translate }}">
                  </kendo-label>
                  <div class="caja">
                    <kendo-switch [disabled]="faqDisabled" [(ngModel)]="activoFAQ" [ngModelOptions]="{ standalone: true }">
                    </kendo-switch>
                  </div>
                </div>
                <div class="col">
                  <kendo-label text="{{ 'organizaciones.gesdocumental' | translate }}">
                  </kendo-label>
                  <div class="caja">
                    <kendo-switch [disabled]="gesDocDisabled" [(ngModel)]="activoGesDoc" [ngModelOptions]="{ standalone: true }"></kendo-switch>
                  </div>
                </div>
                <div class="col">
                  <kendo-label text="{{ 'organizaciones.contactos' | translate }}">
                  </kendo-label>
                  <div class="caja">
                    <kendo-switch [disabled]="contactosDisabled" [(ngModel)]="activoContactos" [ngModelOptions]="{ standalone: true }"></kendo-switch>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END INFORMACION-->

            <!-- AGENDA-->
            <div class="card">
              <div class="card-header">
                <h3>{{ "organizaciones.agenda" | translate }}</h3>
              </div>
              <div class="card-body">
                <div class="row form-group">
                  <div class="col-sm-2">
                    <kendo-label text="{{ 'organizaciones.agenda' | translate }}">
                    </kendo-label>
                    <div class="caja">
                      <kendo-switch [disabled]="agendaDisabled" [(ngModel)]="activoAgenda"  [ngModelOptions]="{ standalone: true }" >
                      </kendo-switch>
                    </div>
                  </div>
                  <div class="col">
                    <kendo-label text="{{ 'organizaciones.agendaEdadmin' | translate }}" > </kendo-label>
                    <div class="caja">
                      <kendo-numerictextbox
                        [(value)]="agendaEdadMin"
                        decimals="0" min="0" max="120" format="n"  >
                      </kendo-numerictextbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- END AGENDA-->

            <!-- MENSAJERIA-->
            <div class="card">
              <div class="card-header">
                <h3>{{ "organizaciones.mensajeria" | translate }}</h3>
              </div>

              <div class="card-body">
                <div class="form-group row">
                  <div class="col-sm-3">
                    <kendo-label text="{{ 'organizaciones.mensajeria' | translate }}" > </kendo-label>
                    <div class="caja">
                      <kendo-switch [disabled]="mensajesDisabled" [(ngModel)]="activoMensajeria" [ngModelOptions]="{ standalone: true }"
                        name="activoMensajeria" ></kendo-switch>
                    </div>
                  </div>
                  <div class="col">
                    <kendo-label text="{{ 'organizaciones.enviarIncidencias' | translate }}"> </kendo-label>
                    <div class="caja">
                      <kendo-switch [(ngModel)]="activoIncidenciasDepUnico" [ngModelOptions]="{ standalone: true }"
                        name="activoIncidenciasDepUnico" ></kendo-switch>
                    </div>
                  </div>
                  <!--
                  <div class="col">
                    <kendo-label text="{{ 'organizaciones.departamento' | translate }}" >
                    </kendo-label>
                    <div class="caja">
                      <kendo-dropdownlist [data]="departamentosDatos" [value]="selectedDepartamento" valuePrimitive="true"
                        [textField]="'nombre'" [valueField]="'id'"
                          (valueChange)="actualizarDepartamento($event)"
                          class="form-control"   > 
                      </kendo-dropdownlist> 
                    </div>
                  </div>
                -->
                </div>
              </div>
            </div>
        <!-- END MENSAJERIA-->

        <!-- RESERVAS-->
        <div class="card">
          <div class="card-header">
            <h3>{{ "organizaciones.reservas" | translate }}</h3>
          </div>
          <div class="card-body">
            <div class="form-group">
              <div class="row">
                <div class="col">
                  <kendo-label text="{{ 'organizaciones.reservas' | translate }}" > </kendo-label>
                  <div class="caja">
                    <kendo-switch [disabled]="reservasDisabled" [(ngModel)]="activoReservas" [ngModelOptions]="{ standalone: true }" ></kendo-switch>
                  </div>
                </div>
                <div class="col">
                  <kendo-label text="{{ 'organizaciones.confirmarreservas' | translate }}">
                  </kendo-label>
                  <div class="caja">
                    <kendo-switch [(ngModel)]="activoConfirmReserva" [ngModelOptions]="{ standalone: true }"> </kendo-switch>
                  </div>
                </div>
                <div class="col">
                  <kendo-label text="{{ 'organizaciones.mostrarusuario' | translate }}"></kendo-label>
                  <div class="caja">
                    <kendo-switch [(ngModel)]="activoMostrarUsuarioCalendario" [ngModelOptions]="{ standalone: true }" > </kendo-switch>
                  </div>
                </div>
              </div>

              <div class="row form-group">
                <div class="col">
                  <kendo-label    text="{{ 'organizaciones.mindiaslab' | translate }}" >
                  </kendo-label>
                  <div class="caja">
                    <kendo-numerictextbox
                        [(value)]="mindiasresrva"
                        decimals="0" min="0" max="999" format="n"   >
                    </kendo-numerictextbox>
                  </div>
                </div>
                <div class="col">
                  <kendo-label  text="{{ 'organizaciones.aperturareservas' | translate }}" > </kendo-label>
                  <div class="caja">
                   <!-- <kendo-formfield>-->
                      <kendo-dropdownlist  [data]="aperturaRangos" [(value)]="apertura" >
                      </kendo-dropdownlist>

                      <!-- <kendo-dropdownlist [data]="listaIdiomas" [textField]="'nombre'" [valueField]="'nombre'"
                      [(value)]="listaIdiomasSelected" [class.requerido]="listaIdiomasRequerido"
                      (valueChange)="useLanguageSinUser($event)">
                    </kendo-dropdownlist> -->
                      
                    <!-- </kendo-formfield> -->
                  </div>
                </div>
              </div>

              <div class="form-group">
                <kendo-label text="{{ 'organizaciones.correos' | translate }}">
                  <textarea  kendoTextArea [(ngModel)]="correosReservas"
                     style="resize: none" rows="5" 
                     [class.requerido]="correosReservasRequerido" >
                  </textarea>
                </kendo-label>
              </div>
            </div>
          </div>
        </div>
        <!-- END RESERVAS-->

                <!-- COORDENADAS-->
                <div class="card">
                  <div class="card-header">
                    <h3>{{ "organizaciones.coordenadas" | translate }}</h3>
                  </div>
                  <div class="card-body">
                    <div class="row form-group">
                      <div class="col">
                        <kendo-label text="{{ 'organizaciones.latitud' | translate }}">
                          <kendo-textbox [(ngModel)]="latitud"  placeholder="Ej: -12.02" [clearButton]="true">  </kendo-textbox>
                          </kendo-label>
                      </div>
        
                      <div class="col">
                        <kendo-label text="{{ 'organizaciones.longitud' | translate }}">
                          <kendo-textbox [(ngModel)]="longitud"  placeholder="Ej: 6.02">  </kendo-textbox>
                        </kendo-label>
                      </div>
                      <div class="col">
                        <kendo-label text="{{ 'organizaciones.zoom' | translate }}">
                          <kendo-numerictextbox
                            [(value)]="zoom" placeholder="1-20"
                            decimals="0" min="1" max="20" format="n"   >
                          </kendo-numerictextbox>
                        </kendo-label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END COORDENADAS-->

    </div> <!--col-->

    <div class="col-lg-4">
      <!-- FORO-->
      <div class="card">
        <div class="card-header">
          <h3>{{ "organizaciones.foro" | translate }}</h3>
        </div>
        <div class="card-body">
          <div class="row form-group">
            <div class="col-sm-2">
              <kendo-label text="{{ 'organizaciones.foro' | translate }}"> </kendo-label>
              <div class="caja">
                <kendo-switch [disabled]="foroDisabled" [(ngModel)]="activoForo" [ngModelOptions]="{ standalone: true }"></kendo-switch>
              </div>
            </div>
            <div class="col">
              <kendo-label text="{{ 'organizaciones.edadminparticipar' | translate }}">
              </kendo-label>
              <div class="caja">
                <kendo-numerictextbox [(value)]="edadforo"
                  min="0" max="120" decimals="0" format="n" >
                </kendo-numerictextbox>
              </div>
            </div>
            <div class="col">
              <kendo-label text="{{ 'organizaciones.foropermusuarios' | translate }}" > </kendo-label>
              <div class="caja">
                <kendo-switch [(ngModel)]="foroPermUsuariosCrearPost" [ngModelOptions]="{ standalone: true }"></kendo-switch>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END FORO-->

      <!-- ENCUESTAS-->
      <div class="card">
        <div class="card-header">
          <h3>{{ "organizaciones.encuestas" | translate }}</h3>
        </div>
        <div class="card-body">
          <div class="form-group row">
            <div class="col-sm-2">
              <kendo-label       text="{{ 'organizaciones.encuestas' | translate }}"></kendo-label>
              <div class="caja">
                <kendo-switch [disabled]="encuetasDisabled" [(ngModel)]="activoEncuestas"   [ngModelOptions]="{ standalone: true }" ></kendo-switch>
              </div>
            </div>
            <div class="col">
              <kendo-label
                text="{{ 'organizaciones.edadminparticipar' | translate }}"
              >
              </kendo-label>
              <div class="caja">
                <kendo-numerictextbox [(value)]="edadencuestasmin"
                  min="0" max="120"  decimals="0" format="n" >
                </kendo-numerictextbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END ENCUENTAS-->

      <!-- RRSS-->
              <div class="card">
                <div class="card-header">
                  <h3>{{ "organizaciones.rrss" | translate }}</h3>
                </div>
                <div class="card-body">
                  <div class="row form-group">
                    <div class="col-sm-2">
                      <kendo-label text="{{ 'organizaciones.facebook' | translate }}"></kendo-label>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-sm-2">
                      <kendo-switch  [disabled]="facebookDisabled"  [(ngModel)]="activoFacebook"  [ngModelOptions]="{ standalone: true }"  ></kendo-switch>
                    </div>
                    <div class="col">
                      <kendo-textbox [(ngModel)]="fblink" [class.requerido]="fblinkRequerido" ></kendo-textbox>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-sm-2">
                      <kendo-label text="{{ 'organizaciones.twitter' | translate }}">
                      </kendo-label>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-sm-2">
                      <kendo-switch  [disabled]="twitterDisabled"   [(ngModel)]="activoTwitter"  [ngModelOptions]="{ standalone: true }" ></kendo-switch>
                    </div>
                    <div class="col">
                      <kendo-textbox  [(ngModel)]="twlink" placeholcer="" [class.requerido]="twlinkRequerido"  > </kendo-textbox>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-sm-2">
                      <kendo-label text="{{ 'organizaciones.flicker' | translate }}">
                      </kendo-label>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-sm-2">
                      <kendo-switch [disabled]="flickrDisabled"  [(ngModel)]="activoFlicker"  [ngModelOptions]="{ standalone: true }"></kendo-switch>
                    </div>
                    <div class="col">
                      <kendo-textbox [(ngModel)]="fllink"  [class.requerido]="fllinkRequerido" ></kendo-textbox>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-sm-2">
                      <kendo-label
                        text="{{ 'organizaciones.instagram' | translate }}"
                      >
                      </kendo-label>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-sm-2">
                      <kendo-switch [disabled]="instagramDisabled" [(ngModel)]="activoInstagram" [ngModelOptions]="{ standalone: true }"></kendo-switch>
                    </div>
                    <div class="col">
                      <kendo-textbox [(ngModel)]="iglink" [class.requerido]="iglinkRequerido"> </kendo-textbox>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-sm-2">
                      <kendo-label text="{{ 'organizaciones.youtube' | translate }}">
                      </kendo-label>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-sm-2">
                      <kendo-switch  [disabled]="youtubeDisabled"  [(ngModel)]="activoYoutube"  [ngModelOptions]="{ standalone: true }"></kendo-switch>
                    </div>
                    <div class="col">
                      <kendo-textbox  [(ngModel)]="ytlink"  [class.requerido]="ytlinkRequerido"></kendo-textbox>
                    </div>
                  </div>
                  <div class="form-group">
                    <kendo-label text="{{ 'organizaciones.multimedia' | translate }}"></kendo-label>

                    <textarea kendoTextArea [(ngModel)]="media"
                      style="resize: none"  rows="5"
                      [class.requerido]="mediaRequerido" >
                    </textarea>
                  </div>
                  <div class="clearfix">
                    <label>* {{ "organizaciones.separar" | translate }}</label>
                    <label>* {{ "organizaciones.mediaapp" | translate }}</label>
                  </div>
                </div>
              </div>
              <!-- END RRSS-->

              <div class="row">
                <!-- AGENTES-->
                <div class="col">
                  <div class="card">
                    <div class="card-header">
                      <h3>{{ "organizaciones.agentes" | translate }}</h3>
                    </div>
                    <div class="card-body">
                      <div class="row form-group">
                        <div class="col">
                          <kendo-label text="{{ 'organizaciones.agentes' | translate }}">
                            <div class="caja">
                              <kendo-switch [disabled]="agentesDisabled" [(ngModel)]="activoAgentes" [ngModelOptions]="{ standalone: true }"
                                name="activoAgentes"></kendo-switch>
                            </div>
                          </kendo-label>
                        </div>

                        <div class="col">
                          <kendo-label  text="{{ 'organizaciones.tipos' | translate }}" >
                            <div class="caja">
                              <kendo-switch  [(ngModel)]="activoTipos"   [ngModelOptions]="{ standalone: true }"
                                name="activoTipos"></kendo-switch>
                            </div>
                          </kendo-label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END AGENTES-->

                <!-- INTERCOMUNICACION USUARIOS-->
          <div class="col">
            <div class="card">
              <div class="card-header">
                <h3>{{ "agentes.comunicacion" | translate }}</h3>
              </div>
              <div class="card-body">
                <div class="row form-group">
                  <div class="col">
                    <kendo-label text="{{ 'agentes.publicar' | translate }}">
                      <div class="caja">
                        <kendo-switch [disabled]="publicarDisabled" [(ngModel)]="permUsuariosPublicar"
                          [ngModelOptions]="{ standalone: true }"  name="permUsuariosPublicar"></kendo-switch>
                      </div>
                    </kendo-label>
                  </div>
                  <div class="col">
                    <kendo-label text="{{ 'agentes.comentar' | translate }}">
                      <div class="caja">
                        <kendo-switch [disabled]="comentarDisabled"  [(ngModel)]="permUsuariosComentar"
                          [ngModelOptions]="{ standalone: true }"  name="permUsuariosComentar"></kendo-switch>
                      </div>
                    </kendo-label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END INTERCOMUNICACION USUARIOS-->


              </div>

    </div>  <!--col-->
</div>

  <!-- Relleno Idiomas-->
  <div class="card">
    <div class="card-body">
      <div class="clearfix">
        <kendo-label text="{{ 'organizaciones.idiomas' | translate }}">
        </kendo-label>
  
        <kendo-dropdownlist #dropdown [data]="selectedLangs" [defaultItem]="{ text: 'Select gender', value: null }"
          [textField]="'nombre'" [valueField]="'codigo'" (valueChange)="valueIdiomaCamposChange($event, dropdown)">
        </kendo-dropdownlist>
      </div>
  
      <!--Campos Idiomas generacion basada en seleccin de diomnas del buttongroup-->
      <div id="camposIdiomas" *ngFor="let container of containersCampos">

        <div *ngIf="camposSelectedLang === container" id="{{ container }}">

          <div class="clearfix">
            <div class="card-body">
              <h3>{{ "organizaciones.descripcion" | translate }}</h3>
          
              <div class="form-group">
                <kendo-label text="{{ 'organizaciones.descripcion' | translate }}">
                  <textarea kendoTextArea placeholder="{{ 'organizaciones.descripcionPH' | translate }}"
                    id="descripcion{{ container }}" [(ngModel)]="camposValoresDescripcion[container]"
                    [ngModelOptions]="{ standalone: true }" style="resize: none" rows="5"></textarea>
                </kendo-label>
              </div>
            </div>
          </div>
  
          <div class="clearfix">
            <div class="card-body">
              <h3>{{ "organizaciones.colaboradores" | translate }}</h3>
  
              <div class="form-group">
                <kendo-label text="{{ 'organizaciones.nombre' | translate }}">
                  <kendo-textbox [disabled]="colaboradoresDisabled"
                    placeholder="{{'organizaciones.colaboradoresPH' | translate}}" id="colaboradores{{ container }}"
                    [(ngModel)]="camposValoresColaboradores[container]" [ngModelOptions]="{ standalone: true }">
                  </kendo-textbox>
                </kendo-label>
              </div>
            </div>
          </div>
          
          <!--
          <div class="clearfix">
            <div class="card-body">
              <h3>{{ "organizaciones.faq" | translate }}</h3>
  
              <div class="form-group">
                <kendo-label text="{{ 'organizaciones.faq' | translate }}">
                  <textarea kendoTextArea placeholder="{{ 'organizaciones.faq' | translate }}" id="faq{{ container }}"
                    [(ngModel)]="camposValoresFAQ[container]" [ngModelOptions]="{ standalone: true }" style="resize: none"
                    rows="5">
                  </textarea>
                </kendo-label>
              </div>
            </div>
          </div>
        -->
  
          <div class="clearfix">
            <div class="card-body">
              <h3>{{ "organizaciones.privacidad" | translate }}</h3>
              <kendo-label text="{{ 'organizaciones.texto' | translate }}">
                <textarea kendoTextArea placeholder="{{ 'organizaciones.politicaPH' | translate }}" id="privacidad{{ container }}"
                  [(ngModel)]="camposValoresPrivacidad[container]" [ngModelOptions]="{ standalone: true }" style="resize: none"
                  rows="5">
                        </textarea>
              </kendo-label>
            </div>
          </div>
        </div>
      </div>
      <!-- End Div campos dinámicos-->
    </div>
  </div>


  <div class="form-group">
    <button  kendoButton  [disabled]="loading"  (click)="onClickGuardar()"  class="btn mr-1 btn-primary">
      <span  *ngIf="loading"class="spinner-border spinner-border-sm mr-1"></span>
      {{ "botones.guardar" | translate }}
    </button>
    <a routerLink="/organizaciones" class="btn mr-1 btn-danger">{{"botones.cancelar" | translate}}</a>
  </div>

  <!--TODO: Juntar los dos popups pasando un paramtero a ng-template-->
  <!--POPUP ELIMINAR AVATAR -->
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button  type="button"  class="close"  aria-label="Close"  (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ "enlaces.preguntaeliminarimagen" | translate }}</p>
    </div>
    <div class="modal-footer">
      <button  type="button"  class="btn btn-outline-secondary"  (click)="modal.dismiss('cancel click')">
        {{ "botones.no" | translate }}
      </button>
      <button  type="button"  class="btn btn-danger"  (click)="eliminarImagen('avatar')">
        {{ "botones.si" | translate }}
      </button>
    </div>
  </ng-template>


  <!--POPUP ELIMINAR CABECERA -->
  <ng-template #content2 let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button  type="button"  class="close"  aria-label="Close"  (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ "enlaces.preguntaeliminarimagen" | translate }}</p>
    </div>
    <div class="modal-footer">
      <button  type="button"  class="btn btn-outline-secondary"  (click)="modal.dismiss('cancel click')">
        {{ "botones.no" | translate }}
      </button>
      <button  type="button"  class="btn btn-danger"  (click)="eliminarImagen('cabecera')">
        {{ "botones.si" | translate }}
      </button>
    </div>
  </ng-template>

  
