<div class="row">
    <div class="col-xl-4 col-md-6">
      <div class="card">
        <!--GESTION AGENTE-->
        <div class="card-header">
          <h3>{{ "menu_modulos.titulo" | translate }}</h3>
        </div>
        <div class="card-body">
  
          <div class="form-group">
            <!--Lista idiomas-->
  
            <kendo-label text="{{ 'menu_modulos.idioma' | translate }}">
            </kendo-label>
  
            <div class="caja">
              <kendo-dropdownlist #dropdownI
                                  [data]="listaIdiomas"
                                  textField="nombre"
                                  valueField="codigo"
                                  [(ngModel)]="selectedIdioma"
                                  (valueChange)="idiomaChange($event)" ></kendo-dropdownlist>
  
            </div>
          </div>
  
          <div class="form-group">
            <label>{{ "menu_modulos.nombre" | translate}}</label>
            <div class="caja">
              <!--Nombre-->
              <kendo-textbox placeholder="{{ 'menu_modulos.nombre_place' | translate }}"
                             [clearButton]="true"
                             [(ngModel)]="nombre">
                <ng-template kendoTextBoxPrefixTemplate>
                </ng-template>
              </kendo-textbox>
            </div>
          </div>

          <div class="form-group">
            <!--Lista opciones-->
  
            <kendo-label text="{{ 'menu_modulos.direccion' | translate }}">
            </kendo-label>
  
            <div class="caja">
              <kendo-dropdownlist #dropdownI
                                  [data]="listaModulos"
                                  textField="nombre"
                                  valueField="id"
                                  [(ngModel)]="selectedModulo"
                                  [valuePrimitive]="true"
                                  (valueChange)="valueChange($event)" ></kendo-dropdownlist>
  
            </div>
          </div>

          <!--Url-->
          <div class="form-group" *ngIf=showUrl>
            <label>{{ "menu_modulos.url" | translate}}</label>
            <div class="caja">
              <kendo-textbox [clearButton]="true"
                             [(ngModel)]="urlModulo"
                             disabled="'true'">
                <ng-template kendoTextBoxPrefixTemplate>
                </ng-template>
              </kendo-textbox>
            </div>
          </div>
          
          <!--Contenido-->
          <div class="form-group" *ngIf=showHtml>
            <label>{{ "menu_modulos.contenido" | translate}}</label>
            <div class="caja">
              <kendo-textbox [clearButton]="true"
                             [(ngModel)]="html">
                <ng-template kendoTextBoxPrefixTemplate>
                </ng-template>
              </kendo-textbox>
            </div>
          </div>

          <!--Imagen-->
          <label class="image-upload-container btn btn-bwm">
            <span>{{ "menu_modulos.imagen" | translate}}</span>
            <input #imageInput
                   type="file"
                   accept="image/*"
                   (change)="imagePreview($event)">
          </label>
  
          <div *ngIf="filePath && filePath !== ''">
            <img [src]="filePath" style="height: 200px;">
          </div>

          <!-- Traducciones -->
          <div class="form-group">
            <button type="button"
                    class="btn btn-primary btn-sm mr-1"
                    (click)="btnAddTraduccion()">
              {{ "publicaciones.anadir" | translate }}
            </button>
            <div class="listado-traducciones">
              <div class="traduccion" *ngFor="let idioma of listaTraducciones">
                {{idioma.nombre}}
  
                <button type="button"
                        class="btn btn-info btn-sm mr-1"
                        (click)="btnDeleteTraduccion(idioma)">
                  X
                </button>
  
              </div>
            </div>
  
            <!--Boton Guardar-->
            <button type="button"
                    class="btn btn-success btn-sm mr-1"
                    (click)="btnCrearModulo()">
              {{ "botones.guardar" | translate }}
            </button>
            <button type="button"
            class="btn btn-danger btn-sm mr-1"
            (click)="btnCancelar()">
            {{ "botones.cancelar" | translate }}
          </button>
          </div>
  
        </div>
      </div>
    </div>
  </div>