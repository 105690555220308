<form [formGroup]="form" (ngSubmit)="onSubmit()">

    <div class="row">
  
      <div class="col-lg-4">
        <!--Form datos Departametno-->
        <div class="card">
          <div class="card-header">
            <h3 *ngIf="isAddMode">{{ 'contactos-ente.titulo_crear' | translate}}</h3>
          </div>
          <div class="card-body">
            <div class="form-group">
              <kendo-label text="{{ 'contactos-ente.nombre' | translate}}">
                <kendo-textbox formControlName="nombre" placeholder="Nombre" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
                </kendo-textbox>
                <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                  <div *ngIf="f.nombre.errors.required">{{ 'agentes.nombretipo-requerido' | translate}}</div>
                </div>
              </kendo-label>
            </div>
            <div class="form-group">
              <kendo-label text="{{ 'contactos-ente.email' | translate}}">
                <kendo-textbox formControlName="email" placeholder="user@domain.com" [ngClass]="{ 'is-invalid': submitted && f.descripcion.errors }">
                </kendo-textbox>
              </kendo-label>
            </div>
            <div class="form-group">
              <kendo-label text="{{ 'contactos-ente.phone' | translate}}">
                <kendo-textbox formControlName="phone" placeholder="684333333" [ngClass]="{ 'is-invalid': submitted && f.descripcion.errors }">
                </kendo-textbox>
              </kendo-label>
            </div>
  
          </div>
  
        </div>
  
      </div>
  
          <!--End Grid Usuarios-->
  
        </div>
        <div class="form-group">
          <button kendoButton [disabled]="loading" class="btn mr-1  btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            {{ 'botones.guardar' | translate}}
          </button>
          <a [routerLink]="['/contactos-ente', {idorg: this.idOrg}]" class="btn mr-1  btn-danger">{{ 'botones.cancelar' | translate}}</a>
          </div>
    </form>
  
  