import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Usuario } from '@app/_models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';

const baseUrl = `${environment.apiUrl}/transportes`;

@Injectable()
export class TransportesService {

  constructor(private http: HttpClient) { }

  public GetById(id: Number): Observable<any> {
    return this.http.get(baseUrl + "/" + id);
  }

  public GetAll(): Observable<any> {
    return this.http.get(baseUrl);
  }

  insert(data) {
    return this.http.post<any>(baseUrl + "/crear", {codigo: -1, nombre: data.nombre, foto: data.foto, link: data.link}, { withCredentials: true });
  }

  update(data) {
    //console.log(JSON.stringify(data));
    //console.log(idTipoPerdida_DAT);
    return this.http.post<any>(baseUrl + "/editar", { codigo: data.codigo, nombre: data.nombre, link: data.link }, { withCredentials: true });
  }

  updateFoto(data) {
    return this.http.post<any>(baseUrl + "/editarFoto", { codigo: data.codigo, foto: data.foto });
  }

  delete(data?: any): Observable<any> {

    //console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append("codigos", data.codigos);
    //console.log(JSON.stringify(formData));

    return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });
  }

  deleteFoto(data) {
    return this.http.post<any>(baseUrl + "/eliminarFoto", { codigo: data.codigo });
  }


}
