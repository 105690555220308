import { Component, OnInit } from '@angular/core';
import { Enlace } from '@app/_models';
import { Router, NavigationEnd } from "@angular/router"

import { EnlacesService } from '@app/_services/enlaces.service';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '../_services';

@Component({
  selector: 'app-enlaces',
  templateUrl: './enlaces.component.html'
})
export class EnlacesComponent implements OnInit {


  public seleccionados: number[] = [];
  private translate: TranslateService;
  public dataEnlaces: [];
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  navigationSubscription;
  lang;

  constructor(private enlacesService: EnlacesService,
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private modalService: NgbModal) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('enlaces.enlaces').toUpperCase();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/enlaces') {
          this.menuService.titulo = this.translateService.instant('enlaces.enlaces');
          this.enlacesService.GetAll().subscribe((result) => {
            this.dataEnlaces = result.data;
          }
          );
        } else {
         
        }
      }
    });
  }

  ngOnInit(): void {
    this.enlacesService.GetAll().subscribe((result) => {
      this.dataEnlaces = result.data;
      }
    );
    this.lang = this.translateService.getDefaultLang();
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = this.translateService.currentLang;
      this.menuService.titulo = this.translateService.instant('enlaces.enlaces');
    });
  }

  onClickNuevo() {
    this.router.navigate(['enlaces/crear/']);
  }

  onClickEditar() {
    if (this.seleccionados[0] > 0) {
      this.router.navigate(['enlaces/editar/', this.seleccionados[0]]);
    }
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['enlaces/editar/', this.seleccionados[0]]);
    }
  }

  onClickEliminar(content) {
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  private eliminarRegistro(contentloading) {
    this.enlacesService.delete({ codigos: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.enlacesService.GetAll().subscribe((result) => {
            this.dataEnlaces = result.data;
          }
          );
        }
        this.modalReferenceloading.close();
      }
    );
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }


}
