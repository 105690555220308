import { SchedulerEvent } from '@progress/kendo-angular-scheduler';
import { Usuario } from '.';

export class Recinto {
  id: number;
  idOrg: string;
  idIdioma: number;
  nombre: string;
  descripcion: string;
  imagen: string;
  superficie: string;
  activo: boolean;
  color: string;
  updateFoto: boolean;
  listaTraducciones: RecintoTrad[];
}

export class RecintoTrad {

id:number;
nombre:string;
descripcion:string;
idRecorrido:number;
idIdioma:number;
nombreIdioma:string;
filled: boolean;
}

export class Sala {
  id: number;
  idRecinto: number;
  idIdioma: number;
  nombre: string;
  descripcion: string;
  imagen: string;
  aforo: number;
  activo: boolean;
  idNormativa: boolean;
  isPrivado: boolean;
  superficie: number;
  longitud: string;
  latitud: string;
  emails: string;
  horasReservablesDia: number;
  numDiasReserva:number;
  idOrg: string;
  tarifaActiva: boolean;
  confirmarReserva: boolean;
  listaTraducciones: SalaTrad[];
  listaAdminsSala: Usuario[];
  listaUsuariosSala: Usuario[];
  updateFoto: boolean;
}

export class SalaTrad {
  idIdioma: number;
  nombreIdioma: string;
  idSala: number;
  nombre: string;
  descripcion: string;
  filled: boolean;
}
export class Reserva implements SchedulerEvent {
  id: number;
  start: Date;
  end: Date;
  ends:Date;
  title: string;
  description: string;
  idUser: number;
  isConfirmada: boolean;
  idSala: number;
  idRecinto: number;
  recurrenceRule:string;
  recurrenceId:number;
  startTimezone:string;
  endTimezone:string;
  isAllDay:boolean;
  
}
