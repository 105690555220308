<div class="card" style="width: 600px;">
  <div class="card-body">
    <div class="selec-organizacion">
      <!--Lista Entes-->
      <kendo-label text="{{ 'publicaciones.selectente' | translate }}">
      </kendo-label>
      <kendo-dropdownlist
        #dropdown
        [data]="listaEntesAdministrados"
        textField="nombre"
        valueField="id"
        [(ngModel)]="selectedEnte"
        (valueChange)="loadConfiguracion($event, dropdown)"
      >
      </kendo-dropdownlist>
    </div>

    <div class="form-group">
        <kendo-label
          class="k-checkbox-label"
          text="{{ 'ecommerce.ismultitienda' | translate }}"
        ></kendo-label>
        <div class="caja">
          <kendo-switch
            [(ngModel)]="config.isMultiTienda"
            onLabel="{{ 'switch.on' | translate }}"
            offLabel="{{ 'switch.off' | translate }}"
          ></kendo-switch>
        </div>
      </div>

      <!--Server data-->
        <kendo-label
        text="{{ 'ecommerce.dbserver' | translate }}"
      ></kendo-label>
        <kendo-textbox
        [style.width.px]="200"
        [clearButton]="true"
        [(ngModel)]="config.dbserver"
      >
      </kendo-textbox>

        <kendo-label text="{{ 'ecommerce.dbport' | translate }}"><br>
          <kendo-numerictextbox
            format="n0"
            [min]="0"
            [step]="1"
            [(ngModel)]="config.dbport"
          ></kendo-numerictextbox>
        </kendo-label><br>

        <kendo-label
        text="{{ 'ecommerce.dbname' | translate }}"
      ></kendo-label>
        <kendo-textbox
        [style.width.px]="200"
        [clearButton]="true"
        [(ngModel)]="config.dbname"
      >
      </kendo-textbox>

      <kendo-label
      text="{{ 'ecommerce.dbuser' | translate }}"
    ></kendo-label>
      <kendo-textbox
      [style.width.px]="200"
      [clearButton]="true"
      [(ngModel)]="config.dbuser"
    >
    </kendo-textbox>
    
    <kendo-label
    text="{{ 'ecommerce.dbpass' | translate }}"
    ></kendo-label>
    <input kendoTextBox type="password" [(ngModel)]="config.dbpass" />
    
    <kendo-label
    text="{{ 'ecommerce.dbprefix' | translate }}"
  ></kendo-label>
    <kendo-textbox
    placeholder="{{ 'ecommerce.opcional' | translate }}"
    [style.width.px]="200"
    [clearButton]="true"
    [(ngModel)]="config.dbprefix"
  >
  </kendo-textbox>

      <!--Modulos Órden-->
      <kendo-label
      text="{{ 'ecommerce.ordenmodulos' | translate }}"
    ></kendo-label>
      <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
        <div class="example-box" *ngFor="let modulo of modulos" cdkDrag>
            {{modulo.nombre}}
        </div>
      </div>

  </div>

  <button
  type="button"
  class="btn btn-success"
  (click)="updateCOnfiguracion()"
>
  {{ "botones.actualizar" | translate }}
</button>

</div>


<style>
    .example-list {
  width: 300px;
  max-width: 100%;
  border: solid 1px #ccc;
  min-height: 60px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.example-box {
  padding: 20px 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 0.5);
}

.example-box:last-child {
  border: none;
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 0.5);
}
</style>
