import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Idioma } from '@app/_models/idioma';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { AlertService, DepartamentosService } from '@app/_services';
import { EventosService } from '@app/_services/eventos.service';
import { Recorrido, RecorridoTrad } from '@app/_models/recorrido';
import { RecorridosEnteService } from '@app/_services/recorridos-ente.service';
import { FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-recorridos-editar',
  templateUrl: './recorridos-editar.component.html',
})
export class RecorridosEditarComponent implements OnInit {
  //Listas
  listaIdiomas: Idioma[] = [];
  listaTraducciones: Idioma[] = [];
  //Data
  myRecorrido: Recorrido = new Recorrido();
  nombre: string = '';
  descripcion: string = '';
  distanciaKm: number = 0;
  idOrg: string = '0a0a0a0a-a4cb-4e0a-97b7-1784c9735130';
  selectedIdioma: Idioma;
  filePath: string;
  idRecorrido: number;

  gpxFileName;
  gpxContent;

  lang;
  private translate: TranslateService;

  public restrictionsGpx: FileRestrictions = {
    allowedExtensions: ['.xml', '.gpx'],
    maxFileSize: 1048576,
  };

  constructor(
    private recorridosService: RecorridosEnteService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('global.recorridos');
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.idRecorrido = this.route.snapshot.params['idrecorrido'];
    console.log('Consultando recorrido');

    this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
      if (this.listaIdiomas.length > 0) {
        this.selectedIdioma = this.listaIdiomas[0];
        this.recorridosService
          .GetById(this.idRecorrido, this.selectedIdioma.codigo)
          .subscribe((resultRecorrido) => {
            console.log('LLega recorido de ' + this.idRecorrido);
            console.log(resultRecorrido);
            this.myRecorrido = resultRecorrido.data[0];
            this.descripcion = this.myRecorrido.nombre;
            this.nombre = this.myRecorrido.descripcion;
            this.distanciaKm = this.myRecorrido.distanciaKm;
            this.filePath = this.myRecorrido.urlFoto;
            this.gpxContent = this.myRecorrido.urlGpxFile;
          });
      }
    });
  }

  btnActualizarRecorrido() {
    console.log('Actualizando Recorrido');
    if (this.filePath != this.myRecorrido.urlFoto) {
      console.log('Obteniendo en BASE64...');
      this.myRecorrido.urlFoto = this.filePath.split(',')[1]; //Borramos cabecera data: base
      this.myRecorrido.updateFoto = true;
    }
    if (this.gpxContent != this.myRecorrido.urlGpxFile) {
      this.myRecorrido.urlFoto = this.gpxContent;
      this.myRecorrido.updateGpx = true;
    }

    this.myRecorrido.idIdioma = this.selectedIdioma.codigo;
    this.myRecorrido.nombre = this.nombre;
    this.myRecorrido.descripcion = this.descripcion;
    this.myRecorrido.distanciaKm = this.distanciaKm;

    console.log(this.myRecorrido);

    this.recorridosService
      .update(this.myRecorrido)
      .subscribe((resultUpdate) => {
        if (!resultUpdate.error) {
          this.alertService.success(
            this.translateService.instant('recintos.successupdaterecorrido')
          );
        } else {
          this.alertService.error(
            this.translateService.instant('recintos.errorupdaterecorrido')
          );
        }
      });
  }

  btnCancelar() {
    this.router.navigate(['recorridos-ente']);
  }
  loadRecinto(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Cargando Recinto Idioma :' + value.codigo);
    this.recorridosService
      .GetById(this.myRecorrido.id, this.selectedIdioma.codigo)
      .subscribe((result) => {
        this.nombre = result.data[0].nombre;
        this.descripcion = result.data[0].descripcion;
      });
  }

  //FILES

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  public selectEventHandlerGpx(e: SelectEvent): void {
    const that = this;

    e.files.forEach((fileGpx) => {
      if (!fileGpx.validationErrors) {
        const readerGpx = new FileReader();

        readerGpx.onload = function (ev) {
          const gpx = {
            src: ev.target['result'],
            uid: fileGpx.uid,
          };
          that.gpxFileName = fileGpx.name;
          that.gpxContent = gpx.src;
        };
        readerGpx.readAsText(fileGpx.rawFile);
      }
    });
  }
}
