<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <div class="row">

    <div class="col-lg-4">
      <!--Form datos Departametno-->
      <div class="card">
        <div class="card-header">
          <h3 *ngIf="isAddMode">{{ 'agentes.creardepartamento' | translate}}</h3>
          <h3 *ngIf="!isAddMode">{{ 'agentes.editardepartamento' | translate}}</h3>
        </div>
        <div class="card-body">
          <div class="form-group">
            <kendo-label text="{{ 'agentes.nombredep' | translate}}">
              <kendo-textbox formControlName="nombre" placeholder="Club" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
              </kendo-textbox>
              <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                <div *ngIf="f.nombre.errors.required">{{ 'agentes.nombretipo-requerido' | translate}}</div>
              </div>
            </kendo-label>
          </div>
          <div class="form-group">
            <kendo-label text="{{ 'agentes.descripcion' | translate}}">
              <kendo-textbox formControlName="descripcion" [ngClass]="{ 'is-invalid': submitted && f.descripcion.errors }">
              </kendo-textbox>
            </kendo-label>
          </div>
          <div class="form-group">
            <kendo-label text="{{ 'agentes.activardep' | translate}}">
              <input type="checkbox" kendoCheckBox formControlName="activo" />
            </kendo-label>
          </div>

        </div>

      </div>

    </div>

        <!--End Grid Usuarios-->

      </div>
      <div class="form-group">
        <button kendoButton [disabled]="loading" class="btn mr-1  btn-primary">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ 'botones.guardar' | translate}}
        </button>
        <a [routerLink]="['/departamentos-ente', {idorg: this.idOrg}]" class="btn mr-1  btn-danger">{{ 'botones.cancelar' | translate}}</a>
        </div>
  </form>

