<ul class="list-group list-group-flush" *ngIf="router.url === '/transportes'">
    <kendo-grid [kendoGridBinding]="dataTransportes"
                [sortable]="true"
                kendoGridSelectBy="codigo"
                [navigable]="true"
                filterable="menu"
                (cellClick)="cellClick($event)"
                [selectedKeys]="seleccionados">
      <ng-template kendoGridToolbarTemplate position="top">
        <button kendoButton (click)="onClickEditar()" class="btn mr-1  btn-success btn-sm mr-1">{{ 'botones.editar' | translate}}</button>
        <button kendoButton (click)="onClickNuevo()" class="btn mr-1  btn-primary btn-sm mr-1">{{ 'botones.nuevo' | translate}}</button>
        <button kendoButton (click)="onClickEliminar(content)" class="btn mr-1  btn-danger btn-sm mr-1" [disabled]="isDeleting">{{ 'botones.eliminar' | translate}}</button>
      </ng-template>
      <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
      <kendo-grid-column width="20%" [style]="{'text-align': 'left'}" field="nombre" title="{{ 'transportes.nombre' | translate}}">
      </kendo-grid-column>
      <kendo-grid-column width="20%" [style]="{'text-align': 'left'}" field="link" title="{{ 'transportes.link' | translate }}">
      </kendo-grid-column>
    </kendo-grid>
  </ul>

<!--POPUP ELIMINAR-->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'transportes.eliminar' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'botones.no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="eliminarRegistro(contentloading)">{{ 'botones.si' | translate }}</button>
  </div>
</ng-template>

<ng-template #contentloading let-modal>
  <div class="modal-body">
    <p>{{ 'transportes.eliminando' | translate }}</p>
    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'transportes.eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>

<router-outlet></router-outlet>