import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendario-gestion-reservas',
  templateUrl: './calendario-gestion-reservas.component.html',
})
export class CalendarioGestionReservasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
