import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Idioma } from '@app/_models/idioma';
import { IdiomasService } from '@app/_services/idiomas.service';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'dropdown-idiomas-ente',
  templateUrl: './dropdown-idiomas-ente.component.html',
})
export class DropdownIdiomasEnteComponent implements OnInit {

  @Input() idOrg : string;
  @Input() autoSelect = true;
  @Output() selectedIdiomaOut = new EventEmitter<Idioma>();
  //@Input() data : [];
  //@Output() calCount = new EventEmitter<Number>();

  listaIdiomas: Idioma[] = [];
  selectedIdioma:Idioma;

  constructor(private idiomaService: IdiomasService) {

   }

  ngOnInit(): void {
    this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
      console.log('Idiomas cargados: '+this.listaIdiomas.length);
      if(this.listaIdiomas.length>0 && this.autoSelect){
        this.selectedIdioma=this.listaIdiomas[0];
        this.selectedIdiomaOut.emit( this.selectedIdioma);
      }
    });
  }

  idiomaChanged(value, dropDownControl: DropDownListComponent) {
    this.selectedIdiomaOut.emit(value);
  }


  

}
