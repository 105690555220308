import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-reusable',
  templateUrl: './test-reusable.component.html',
})
export class TestReusableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
