import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Usuario } from '@app/_models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';

const baseUrl = `${environment.apiUrl}/departamentos`;

@Injectable()
export class DepartamentosService {

  constructor(private http: HttpClient) { }

  public GetAll(): Observable<any> {
    return this.http.get(baseUrl);
  }

  public GetById(id: Number): Observable<any> {
    return this.http.get(baseUrl + "/" + id);
  }

  public GetMaxDpto(): Observable<any> {
    return this.http.get(baseUrl + "/maxDpto/");
  }

  public GetUsersDpto(codpto: Number): Observable<any> {
    return this.http.get(baseUrl + "/usDpto/" + codpto);
  }

  public GetUsersNoDpto(codpto: Number): Observable<any> {
    return this.http.get(baseUrl + "/usNoDpto/" + codpto);
  }

  public GetUsersNoDptoT(codpto: Number): Observable<any> {
    return this.http.get(baseUrl + "/usNoDptoT/" + codpto);
  }

  insert(data) {
    return this.http.post<any>(baseUrl + "/crear", { codigo: -1, nombredep1: data.nombredep1, nombredep2: data.nombredep2, activo: data.activo, departamento_cods: data.departamento_cods, nodepartamento_cods: data.nodepartamento_cods }, { withCredentials: true });
  }

  update(data) {
    //console.log(JSON.stringify(data));
    //console.log(idTipoPerdida_DAT);
    return this.http.post<any>(baseUrl + "/editar", { codigo: data.codigo, nombredep1: data.nombredep1, nombredep2: data.nombredep2, activo: data.activo, departamento_cods: data.departamento_cods, nodepartamento_cods: data.nodepartamento_cods }, { withCredentials: true });
  }

  delete(data?: any): Observable<any> {

    //console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append("codigos", data.codigos);
    //console.log(JSON.stringify(formData));

    return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });
  }

  // Parte Emails
  public GetEmailsById(id: Number): Observable<any> {
    return this.http.get(baseUrl + "/email/" + id);
  }

  public GetEmailById(id: Number): Observable<any> {
    return this.http.get(baseUrl + "/emailDpto/" + id);
  }

  public DeleteEmailsDpt(data?: any): Observable<any> {
    //console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append("codigos", data.codigos);
    //console.log(JSON.stringify(formData));

    return this.http.post<JSON>(baseUrl + "/eliminarEmails", formData, { withCredentials: true });
  }

  insertEmail(data) {
    return this.http.post<any>(baseUrl + "/crearEmailDpto", { codigo: -1, coddepartamento: data.coddepartamento,
      nombre: data.nombre, email: data.email, activo: data.activo }, { withCredentials: true });
  }

  updateEmail(data) {
    return this.http.post<any>(baseUrl + "/editarEmailDpto", { codigo: data.codigo, coddepartamento: data.coddepartamento,
      nombre: data.nombre, email: data.email, activo: data.activo }, { withCredentials: true });
  }

}
