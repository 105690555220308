<div class="card">
   <div class="card-header">
     <h3>{{ "app.registrar" | translate }}</h3>
   </div>
   <div class="card-body">
     <div class="form-group">
      <div class="form-group">
         <label class="control-label"> Nombre </label>
         <div class="input-group">
           <div class="input-group-addon">
             <i class="fa fa-envelope-o"></i>
           </div>
           <input
             class="form-control"
             type="text"
             [(ngModel)]="nombre"
           />
         </div>
       </div>
       
       <div class="form-group">
         <label class="control-label"> Descripción </label>
         <div class="input-group">
           <div class="input-group-addon">
             <i class="fa fa-envelope-o"></i>
           </div>
           <input
             class="form-control"
             type="text"
             [(ngModel)]="descripcion"
           />
         </div>
       </div>
       
       <div class="form-group">
         <label class="control-label"> EntryPoint </label>
         <div class="input-group">
           <div class="input-group-addon">
             <i class="fa fa-envelope-o"></i>
           </div>
           <input
           placeholder="http://..."
             class="form-control"
             type="text"
             [(ngModel)]="entryPoint"
           />
         </div>
       </div>
       
       <div class="form-group">
         <label class="control-label"> ApiKey </label>
         <div class="input-group">
           <div class="input-group-addon">
             <i class="fa fa-envelope-o"></i>
           </div>
           <input
           value="0x0000012345"
             class="form-control"
             type="text"
             [(ngModel)]="apiKey"
           />
         </div>

         <div class="form-group">
            <!--Imagen Publicacion-->
            <label class="image-upload-container btn btn-bwm">
              <span>Select Image</span>
              <input
                #imageInput
                type="file"
                accept="image/*"
                (change)="imagePreview($event)"
              />
            </label>
  
            <div *ngIf="filePath && filePath !== ''">
              <img [src]="filePath" style="height: 200px" />
            </div>
          </div>
       
         <button
           type="button"
           class="btn btn-success btn-sm mr-1"
           (click)="btnCrearApp()"
         >
           Registrar App
         </button>
       
         <button
           type="button"
           class="btn btn-danger btn-sm mr-1"
           (click)="btnCancelar()"
         >
           {{ "botones.cancelar" | translate }}
         </button>
       </div>
     </div>
   </div>
 </div>


