import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from '@app/_services/menu.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Idioma } from '@app/_models/idioma';
import {
  AlertService,
} from '@app/_services';
import  {Equipo, EquipoTrad, Jugador} from '@app/_models/equipo'
import { EquiposService } from '@app/_services/equipos.service';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-equipos-editar',
  templateUrl: './equipos-editar.component.html',
})
export class EquiposEditarComponent implements OnInit {
  idOrg:string;
  //Listas
  listaIdiomas: Idioma[] = [];
  listaIdiomasTotales: Idioma[] = [];
  listaTraducciones: Idioma[] = [];
  //Traducciones
  listaTraduccionesEnviar: EquipoTrad[] = [];
  //Data
  nombreEquipo:string='';
  descripcionEquipo:string;
  localidad:string='';
  equipoActivo:boolean=true;
  imagenB64: string = '';
  selectedIdioma: Idioma;
  myEquipo: Equipo = new Equipo();
  filePath: string;
  lang;
  idEquipo:number;
  listaJugadores:Jugador[]=[];
  private translate: TranslateService;

  public seleccionados: number[] = [];
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  constructor(
    private equiposService: EquiposService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private modalService: NgbModal,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('equipos.cabecera');
  }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('equipos.edit_equipo');
    this.idEquipo =this.route.snapshot.params['idEquipo'];
    this.idOrg =this.route.snapshot.params['idorg'];
    this.lang = this.translateService.getDefaultLang();

    //Load Langs
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomasTotales = result.data;
      console.log('Cargados idiomas = ' + this.listaIdiomasTotales.length);
      this.listaIdiomasTotales.forEach((x) => {
        const trad = new EquipoTrad();
        trad.filled = false;
        trad.idIdioma = x.codigo;
        trad.nombreIdioma = x.nombre;
        this.listaTraduccionesEnviar.push(trad);
      });
    });

    this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
      if (this.listaIdiomas.length > 0){
        this.selectedIdioma = this.listaIdiomas[0];
        this.equiposService.GetById(this.idEquipo,this.selectedIdioma.codigo).subscribe((result)=>{
          if(result!=undefined){
              this.myEquipo.localidad = result.data[0].localidad;
              this.myEquipo.descripcion = result.data[0].descripcion;
              this.myEquipo.nombre = result.data[0].nombre;
              this.myEquipo.activo = result.data[0].activo;
              this.myEquipo.idOrg = result.data[0].idorg;
              this.myEquipo.urlFoto = result.data[0].urlFoto;
              this.filePath = result.data[0].urlFoto;
          }
        });
      }
    });

    this.equiposService.GetAllJugadores(this.idEquipo).subscribe((result)=>{
        this.listaJugadores = result.data;
    });

  }

  loadEquipo(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Cargando Equipo Idioma :' + value.codigo);
    this.equiposService
      .GetById(this.idEquipo, this.selectedIdioma.codigo)
      .subscribe((result) => {
        this.myEquipo.descripcion = result.data[0].descripcion;
        this.myEquipo.nombre = result.data[0].nombre;
      });
  }

  btnActualizarEquipo() {
    console.log('Actualizando Sala');
    if (this.filePath != this.myEquipo.urlFoto) {
      console.log('Obteniendo en BASE64...');
      this.myEquipo.urlFoto = this.filePath.split(',')[1]; //Borramos cabecera data: base
      this.myEquipo.updateFoto = true;
    }
  }

  btnGestionJugadores(){
    this.router.navigate([
      'jugadores',
      { idorg: this.idOrg, idequipo:this.idEquipo },
    ]);
  }

  //Imagen

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }


  btnCancelar() {
    this.router.navigate(['equipos']);
  }

  //Grid usuarios

  onClickNuevo() {
      this.router.navigate(['jugadores-crear',{ idequipo:this.idEquipo,idorg:this.idOrg }]);
  }

  onClickEliminar(content){
    console.log('Eliminando...');
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  onClickEditar(){
    this.router.navigate(['jugadores-editar',{idequipo:this.idEquipo, idjugador:this.seleccionados[0] ,idorg:this.idOrg}]);
  }

  cellClick(e){
    this.router.navigate(['jugadores-editar',{idequipo:this.idEquipo, idjugador:this.seleccionados[0],idorg:this.idOrg }]);
  }

  eliminarRegistro(contentloading){
    console.log('Eliminar registro');
    console.log(this.seleccionados);
    this.equiposService.deleteJugadores({ codigos: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.equiposService.GetAllJugadores(this.idEquipo).subscribe((result) => {
            this.listaJugadores = result.data;
          }
          );
        }
        this.modalReferenceloading.close();
      }
    );
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }

}
