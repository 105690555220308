import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, TransportesService, MenuService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FileInfo, FileRestrictions, SelectEvent, RemoveEvent } from '@progress/kendo-angular-upload';
import { first } from 'rxjs/operators';

// Editado
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-transportes-detalle',
  templateUrl: './Transportes-detalle.component.html'
})
export class TransportesDetalleComponent implements OnInit {

  form: FormGroup;
  codigo: number;
  isAddMode: boolean;
  fotoTransporte: any;
  public events: string[] = [];
  public imagePreview;
  loading = false;
  submitted = false;
  modalReference: NgbModalRef;
  fotoAnt: any;
  isNotIns = false;
  fotoBorrada = false;
  formImErr = false;

  regexLink = '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$';
  public restrictions: FileRestrictions = {
    allowedExtensions: ['.png', '.jpeg', '.jpg'],
    maxFileSize: 1048576
  };

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private transportesService: TransportesService,
    private alertService: AlertService,
    private menuService: MenuService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('transportes.transportes');

    this.codigo = this.route.snapshot.params['id'];

    this.isAddMode = !this.codigo;

    this.form = this.formBuilder.group({
      codigo: this.route.snapshot.params['id'],
      nombre: new FormControl('', [Validators.required]),
      link: new FormControl('', [Validators.required, Validators.pattern(this.regexLink)]),
      foto: new FormControl('')
    });

    if (!this.isAddMode) {
      this.transportesService.GetById(this.codigo)
        .pipe()
        .subscribe((result) => {
          this.form = this.formBuilder.group({
            codigo: this.route.snapshot.params['id'],
            nombre: new FormControl(result.data[0].nombre, [Validators.required]),
            link: new FormControl(result.data[0].link, [Validators.required, Validators.pattern(this.regexLink)]),
            foto: new FormControl('')
          });

          if (result.data[0].foto) {
            this.fotoTransporte = result.data[0].foto;
            this.fotoAnt = result.data[0].foto;
            this.createImageFromBase64(this.fotoTransporte);
          } else {
            this.fotoAnt = [];
            this.fotoTransporte = [];
          }

        });
    }
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      // TODO This as a workaround.
      this.menuService.titulo = this.translateService.instant('transportes.transportes');
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid || this.formImErr) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.crearTransporte();
    } else {
      this.updateTransporte();
    }
  }

  private crearTransporte() {
    // Caso transporte tiene foto
    this.form.value.foto = this.fotoTransporte;

    this.transportesService.insert(this.form.value)
      .subscribe((result) => {
        if (!result.error) {
          this.alertService.success(this.translateService.instant('botones.creadocorrectamente'), { keepAfterRouteChange: true });
          this.router.navigate(['../'], { relativeTo: this.route });
        } else {
          this.alertService.error(this.translateService.instant('transportes.errorcrear'));
          this.loading = false;
        }
      });
  }

  private updateTransporte() {
    var error = null;
    this.form.value.foto = this.fotoTransporte;
    if (this.fotoBorrada) {
      // Caso de foto borrada
      this.transportesService.deleteFoto(this.form.value).subscribe((result) => {
        if (result.error)
          error = result.error;
      });
    } else {
      if (this.fotoTransporte != this.fotoAnt && this.fotoTransporte != []) {
        // Caso de foto actualizada
        this.transportesService.updateFoto(this.form.value).subscribe((result) => {
          if (result.error)
            error = result.descripcion;
        });
      }
    }

    // Caso actualización de otra información
    this.transportesService.update(this.form.value)
      .pipe(first())
      .subscribe((result) => {
        if (!result.error && !error) {
          this.alertService.success(this.translateService.instant('botones.editadocorrectamente'), { keepAfterRouteChange: true });
          this.router.navigate(['../../'], { relativeTo: this.route });
        } else {
          this.alertService.error(this.translateService.instant('transportes.erroreditar'));
          this.loading = false;
        }
      });
  }

  // Parte foto
  createImageFromBase64(image) {
    let objectURL = 'data:image/jpeg;base64,' + image;
    this.imagePreview = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  onClickEliminarFoto(content) {
    this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  eliminarFoto() {
    this.fotoBorrada = true;
    this.fotoTransporte = [];
    this.imagePreview = null;
    this.modalReference.close();
  }

  public selectEventHandler(e: SelectEvent): void {
    const that = this;

    e.files.forEach((file) => {

      if (!file.validationErrors) {
        this.formImErr = false;
        const reader = new FileReader();

        reader.onload = function (ev) {
          const image = {
            src: ev.target['result'],
            uid: file.uid
          };

          that.imagePreview = image.src.toString();

          that.fotoTransporte = image.src.toString().split(",", 2)[1];

          that.fotoBorrada = false;

          that.isNotIns = true;
        };
        reader.readAsDataURL(file.rawFile);
      } else {
        this.formImErr = true;
      }
    });
  }

  public removeEventHandler(e: RemoveEvent): void {
    this.formImErr = false;
    this.fotoTransporte = [];
    this.imagePreview = null;
  }

}
