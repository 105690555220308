export class DepartamentoEnte {
    id: number;
    nombre: string;
    color: string;
    activo: boolean;
    idOrg: string;
    constructor(id: number, nombre: string,activo:boolean, idOrg: string) {
        this.id = id;
        this.nombre = nombre;
        this.activo = activo;
        this.idOrg = idOrg;
      }
}