import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Usuario } from '@app/_models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';
import { Equipo, Jugador } from '@app/_models/equipo';

const baseUrl = `${environment.apiUrl}/equipos`;

@Injectable()
export class EquiposService {

  constructor(private http: HttpClient) { }

  public GetById(id: Number,lang): Observable<any> {
    return this.http.get(baseUrl + "/get/" + id + "/"+lang);
  }

  public GetAll(idOrg:string): Observable<any> {
    return this.http.get(baseUrl+ '/getall/'+idOrg);
  }

  public crearEquipo(myEquipo:Equipo):Observable<any>{
    console.log('[S] Equipo crear');
    console.log(myEquipo);
    return this.http.post(baseUrl + '/create/', JSON.stringify(myEquipo), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  public updateEquipo(myEquipo:Equipo):Observable<any>{
    console.log('[S] Equipo crear');
    console.log(myEquipo);
    return this.http.post(baseUrl + '/update/', JSON.stringify(myEquipo), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }
  delete(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminar', formData, {
      withCredentials: true,
    });
  }

  //Jugadores
  public GetAllJugadores(idEquipo:number): Observable<any> {
    return this.http.get(baseUrl+ '/getalljugadores/'+idEquipo);
  }
  public GetJugadorById(id: Number,lang): Observable<any> {
    return this.http.get(baseUrl + "/getjugador/" + id + "/"+lang);
  }

  public crearJugador(myJugador:Jugador):Observable<any>{
    myJugador.fechaFinContrato =  new Date(this.dateToYYYYMMDDtHHmmSSz(myJugador.fechaFinContrato ));
    myJugador.fechaNacimiento =  new Date(this.dateToYYYYMMDDtHHmmSSz(myJugador.fechaNacimiento ));
    return this.http.post(baseUrl + '/createjugador/', JSON.stringify(myJugador), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  public updatejugador(myJugador:Jugador):Observable<any>{
    console.log('[S] Update Jugador: ');
    console.log(myJugador);

    return this.http.post(baseUrl + '/updatejugador/', JSON.stringify(myJugador), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  deleteJugadores(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminarjugadores', formData, {
      withCredentials: true,
    });
  }

  public vinculacionUsuario(myJugador:Jugador):Observable<any>{
    console.log('[S] Update Jugador: ');
    console.log(myJugador);

    return this.http.post(baseUrl + '/vinculacion/', JSON.stringify(myJugador), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

    //Helpers
    dateToYYYYMMDDtHHmmSSz(fecha: Date) {
      //2020-10-25T23:00:00Z
      console.log(fecha)
      var año = fecha.getFullYear();
      var mes = fecha.getMonth() + 1;
      var dia = fecha.getDate(); //getDay da el dia de la semana!
      var hora = fecha.getHours();
      var minutos = fecha.getMinutes();
      var segundos = fecha.getSeconds();
      return (
        año +
        '-' +
        this.addZero(mes) +
        '-' +
        this.addZero(dia) +
        'T' +
        this.addZero(hora) +
        ':' +
        this.addZero(minutos) +
        ':' +
        this.addZero(segundos) +
        'Z'
      );
    }
    addZero(num) {
      if (num < 10) return '0' + num;
      else return num;
    }

}
