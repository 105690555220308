import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Modulo } from '@app/_models/modulo';

const baseUrl = `${environment.apiUrl}/modulo`;

@Injectable()
export class ModuloService {
  constructor(private http: HttpClient) {}


  public createModulo(modulo: Modulo): Observable<any> {

    return this.http.post(
      baseUrl + '/createm/',
      JSON.stringify(modulo),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public addTraduccionesModulo(listaTraducciones, idModulo): Observable<any> {
    console.log('Enviando Traducciones...');

    return this.http.post(
      baseUrl + '/addtradsm/' + idModulo.toString(),
      JSON.stringify(listaTraducciones),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public updateIndex(idModulo: number, index: number): Observable<any> {
    console.log('Actualizando Indices...');
    console.log(baseUrl + '/update/' + idModulo + '/' + index);
    return this.http.get(baseUrl + '/update/' + idModulo + '/' + index);
  }
  
  public updateFoto(modulo: Modulo): Observable<any> {
    console.log('Actualizando Foto...');
    console.log(modulo);
    return this.http.post(
      baseUrl + '/updatephoto/',
      JSON.stringify(modulo),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public getAllModulos(): Observable<any> {
    return this.http.get(baseUrl + '/getmodulos/');
  }

  public getModuloById(idModulo: number):  Observable<any> {
    return this.http.get(baseUrl + '/get/' + idModulo);
  }

  public getModuloTraduccion(idModulo: number):  Observable<any> {
    return this.http.get(baseUrl + '/gettrad/' + idModulo);
  }

  delete(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminar', formData, {
      withCredentials: true,
    });
  }

  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return año + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
    }

    addZero(num){
      if(num<10) return '0'+num;
      else return num;
    }
}
