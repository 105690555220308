import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services/menu.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { AlertService, DepartamentosService } from '@app/_services';

@Component({
  selector: 'app-publicaciones-lista',
  templateUrl: './publicaciones-lista.component.html',
  styleUrls: [],
})
export class PublicacionesListaComponent implements OnInit {
  listaEntesAdministrados: Ente[] = [];
  selectedOrg: Ente;
  listaPublicaciones: Noticia[] = [];
  public seleccionados: number[] = [];

  lang;
  private translate: TranslateService;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  constructor(
    private publicacionesService: PublicacionesService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    public router: Router,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal,
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('publicaciones.cabecera');
    this.publicacionesService.getEntesAdministrados().subscribe((result) => {
      this.listaEntesAdministrados = result.data;
      if(this.listaEntesAdministrados.length>0){
        this.selectedOrg=this.listaEntesAdministrados[0];
        this.publicacionesService
        .getPublicacionesFromEnte(this.selectedOrg.id)
        .subscribe((result) => {
          console.log(result);
          this.listaPublicaciones = result.data;
          console.log(
            'Publicaciones cargadas: ' + this.listaPublicaciones.length
          );
        });
      }
    });
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
  }

  loadPublicaciones(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Cargando noticias de :' + value.id);
    this.publicacionesService
      .getPublicacionesFromEnte(this.selectedOrg.id)
      .subscribe((result) => {
        console.log(result);
        this.listaPublicaciones = result.data;
        console.log(
          'Publicaciones cargadas: ' + this.listaPublicaciones.length
        );
      });
  }

  onClickNuevo() {
    if (this.selectedOrg == null) {
      this.alertService.warn(
        this.translateService.instant('publicaciones.selectente')
        );
    } else {
      this.router.navigate(['publicaciones',{ idorg:this.selectedOrg.id }]);
    }
  }

  onClickEliminar(content){
    console.log('Eliminando...');
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  onClickEditar(){
    this.router.navigate(['publicaciones-editar',{idorg:this.selectedOrg.id, idnoticia:this.seleccionados[0] }]);
  }

  onClickPortada(){

    if (this.seleccionados.length > 0) {
      console.log('Actualizando portada...');
      this.publicacionesService
      .actualizarPortada(this.seleccionados)
      .subscribe((result) => {
        if (!result.error) {
          this.alertService.success(
            this.translateService.instant('videos.success')
          );
        } else {
          this.alertService.error(
            this.translateService.instant('videos.error')
          );
          return;
        }
      });
    } else{
      this.alertService.info(
        this.translateService.instant('publicaciones.selectnoticia')
      );
    }
  }

  cellClick(){
    console.log('Noticia Seleccionada: ');
    console.log(this.seleccionados[0]);
    this.router.navigate(['publicaciones-editar',{idorg:this.selectedOrg.id, idnoticia:this.seleccionados[0] }]);
  }

  eliminarRegistro(contentloading){
    console.log('Eliminar registro');
    this.publicacionesService.delete({ codigos: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.publicacionesService.getPublicacionesFromEnte(this.selectedOrg.id).subscribe((result) => {
            this.listaPublicaciones = result.data;
          }
          );
        }
        this.modalReferenceloading.close();
      }
    );
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }

  onClickCategorias(){
    this.router.navigate(['publicaciones-categorias',{idorg:this.selectedOrg.id }]);
  }

  selectedKeysChange(rows: number[]) {
    this.seleccionados = rows;
    console.log("Seleccionados IDs: " + this.seleccionados)
  }
}
