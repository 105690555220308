import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DatosService } from '@app/_services/datos.service';
import { EditService } from '@app/_services/edit.service';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { Keys } from '@progress/kendo-angular-common';
import { FilterExpression } from '@progress/kendo-angular-filter';
import { GridDataResult } from '@progress/kendo-angular-grid';
import {
  CompositeFilterDescriptor,
  filterBy,
  State,
} from "@progress/kendo-data-query";
import { Observable } from 'rxjs/internal/Observable';


@Component({
  selector: 'app-prueba',
  templateUrl: './prueba.component.html',
})
export class PruebaComponent implements OnInit {
  public listaMesas = [];
  public listaFilter = [];

  public listaColumnas = [];

  public view: Observable<GridDataResult>;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
  };

  public changes: any = {};
  
  lang;
  public seleccionados: number[] = [];
  private translate: TranslateService;

  //Filters
  //Por defecto 
  //public filterValue: CompositeFilterDescriptor = { logic: "and", filters: [] };
  public value: CompositeFilterDescriptor;

  public filters: FilterExpression[] = [
    {
      field: "nombre",
      title: "Nombre",
      editor: "string",
    },
    {
      field: "numComensales",
      title: "Nº Comensales",
      editor: "number",
    },
    {
      field: "fecha",
      title: "Fecha",
      editor: "date",
    },
    {
      field: "activa",
      editor: "boolean",
    },
  ];


  constructor(
    private datosService: DatosService,
    public editService: EditService,
    private formBuilder: FormBuilder,
    public router: Router,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    
    this.listaMesas = [
      {
        id: '1',
        nombre: 'Mesa 1',
        numComensales: 10,
        fecha: new Date(),
        activa: true,
      },
      {
        id: '2',
        nombre: 'Mesa 2',
        numComensales: 8,
        fecha: new Date(),
        activa: true,
      },
      {
        id: '3',
        nombre: 'Mesa 3',
        numComensales: 5,
        fecha: new Date(),
        activa: false,
      },
      {
        id: '4',
        nombre: 'Mesa 4',
        numComensales: 7,
        fecha: new Date(),
        activa: true,
      },
    ];

    this.value = this.datosService.getListaFiltros();

    this.listaFilter =[...this.listaMesas];

    this.listaColumnas = this.datosService.getOrdenColumnas();

    this.applyFilter(this.value);
  }

  reordenar(e) {
    console.log(e);
    console.log(e.newIndex);
    console.log(e.oldIndex);
    var tmpColumnOld = this.listaColumnas[e.oldIndex];
    var tmpColumnNew = this.listaColumnas[e.newIndex];
    this.listaColumnas[e.newIndex] = tmpColumnOld;
    this.listaColumnas[e.oldIndex] = tmpColumnNew;
    console.log(this.listaColumnas);
    this.datosService.updateOrdenColumnas(this.listaColumnas);
  }

  resizeColumn(e){
    console.log(e);
    console.log(e[0].column.field);
    console.log(e[0].newWidth);
    console.log(e[0].oldWidth);
    this.listaColumnas.forEach((elem)=>{
      if(elem.field==e[0].column.field){
        elem.width=e[0].newWidth+'px';
      }
    });
    this.datosService.updateOrdenColumnas(this.listaColumnas);
  }

  
  public onStateChange(state: State) {
    this.gridState = state;

    this.editService.read();
  }
  public cellClickHandler({
    sender,
    rowIndex,
    columnIndex,
    dataItem,
    isEdited,
  }) {
    if (!isEdited) {
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
    }
  }

  public cellCloseHandler(args: any) {
    console.log('Saliendo para guardar');
    const { formGroup, dataItem } = args;

    if (!formGroup.valid) {
      console.log('Valores inválidos, no puedes guardr esto');
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      if (args.originalEvent && args.originalEvent.keyCode === Keys.Escape) {
        console.log('Saliendo sin guardar');
        return;
      }
      console.log('Enviando a service');
      this.editService.assignValues(dataItem, formGroup.value);
      this.editService.update(dataItem);
    }
  }

  public createFormGroup(dataItem: any): FormGroup {
    var tmpGroup = {};
    this.listaColumnas.forEach((elem)=>{
      tmpGroup[elem.field]="dataItem."+elem.field;
    });

    return this.formBuilder.group({
      nombre: dataItem.nombre,
      numComensales: dataItem.numComensales,
      fecha: dataItem.fecha,
      activa: dataItem.activa,
    });
  }

  addColumn(){
    this.listaColumnas.push({ field: 'id', filter: true, tipo: 'numeric', prioridad: 5,width:'50px' });
    this.datosService.updateOrdenColumnas(this.listaColumnas);
  }


  //Filters
  public applyFilter(value: CompositeFilterDescriptor): void {
    console.log(value);
    this.listaFilter = filterBy(this.listaMesas, value);
    this.value = value;
  }
  public borrarFiltro(){
    this.value = { logic: "and", filters: [] };
    this.listaFilter = this.listaMesas;
  }

  onFilterChange(value: CompositeFilterDescriptor) {
    console.log('Cambia el filtro: ');
    console.log(value);
  }
}
