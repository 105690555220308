<div class="row">
  <div class="col-xl-4 col-md-6">
    <div class="card">
      <!--GESTION AGENTE-->
      <div class="card-header">
        <h3>{{ "ecommerce.lbl_crear" | translate }}</h3>
      </div>
      <div class="card-body">
        <div class="form-group">
          <!--Lista idiomas-->

          <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
          </kendo-label>

          <div class="caja">
            <kendo-dropdownlist
              #dropdownI
              [data]="listaIdiomas"
              textField="nombre"
              valueField="codigo"
              [(ngModel)]="selectedIdioma"
            ></kendo-dropdownlist>
          </div>
        </div>

        <div class="form-group">
          <label>{{ "ecommerce.nombrecategoria" | translate }}</label>
          <div class="caja">
            <!--Titulo-->
            <kendo-textbox
              placeholder="{{ 'ecommerce.ph_nombre' | translate }}"
              [clearButton]="true"
              [(ngModel)]="nombreRecinto"
            >
              <ng-template kendoTextBoxPrefixTemplate> </ng-template>
            </kendo-textbox>
          </div>
        </div>

        <!--Cuerpo-->
        <div class="form-group">
          <kendo-label
            text="{{ 'ecommerce.lbl_descripcioncategoria' | translate }}"
          >
            <textarea
              kendoTextArea
              style="resize: none"
              rows="5"
              [(ngModel)]="descripcionRecinto"
              placeholder="{{ 'ecommerce.ph_descripcioncategoria' | translate }}"
            >
            </textarea>
          </kendo-label>
        </div>
        <!-- Color CAtegoria-->

        <div class="form-group">
          <kendo-label text="{{ 'ecommerce.colorcategoria' | translate }}">
              <kendo-colorpicker icon="edit-tools"
                view="gradient"
                format="hex"
                [(value)]="colorCategoria"
              >
              </kendo-colorpicker>
          </kendo-label>
        </div>

                <!--Redireccionar a web -->
                <div class="form-group">
                  <kendo-label
                    class="k-checkbox-label"
                    text="{{ 'ecommerce.isRedirect' | translate }}"
                  ></kendo-label>
                  <div class="caja">
                    <kendo-switch
                      [(ngModel)]="isRedirect"
                      onLabel="{{ 'switch.activo' | translate }}"
                      offLabel="{{ 'switch.inactivo' | translate }}"
                    ></kendo-switch>
                  </div>
                  <div *ngIf="isRedirect" class="form-group">
                    <kendo-label text="{{ 'ecommerce.lbl_urlredirect' | translate }}">
                      <kendo-textbox
                        placeholder="{{ 'ecommerce.ph_url' | translate }}"
                        [clearButton]="true"
                        [(ngModel)]="urlRedirect"
                      ></kendo-textbox>
                    </kendo-label>
                  </div>
                </div>

        <!--Imagen Publicacion-->
        <label class="image-upload-container btn btn-bwm">
          <span>{{ 'ecommerce.seleccionaimagencategoria' | translate }}</span>
          <input
            #imageInput
            type="file"
            accept="image/*"
            (change)="imagePreview($event)"
          />
        </label>

        <div *ngIf="filePath && filePath !== ''">
          <img [src]="filePath" style="height: 200px" />
        </div>

        <!--CheckBox Activo-->
        <div class="form-group">
          <kendo-label
            class="k-checkbox-label"
            text="{{ 'ecommerce.activa' | translate }}"
          ></kendo-label>
          <div class="caja">
            <kendo-switch
              [(ngModel)]="categoriaActiva"
              onLabel="{{ 'switch.activo' | translate }}"
              offLabel="{{ 'switch.inactivo' | translate }}"
            ></kendo-switch>
          </div>
        </div>
        <!-- Traducciones -->
        <div class="form-group">
          <button
            type="button"
            class="btn btn-primary btn-sm mr-1"
            (click)="btnAddTraduccion()"
          >
            {{ "publicaciones.anadir" | translate }}
          </button>
          <div class="listado-traducciones">
            <div class="traduccion" *ngFor="let idioma of listaTraducciones">
              {{ idioma.nombre }}

              <button
                type="button"
                class="btn btn-info btn-sm mr-1"
                (click)="btnDeleteTraduccion(idioma)"
              >
                X
              </button>
            </div>
          </div>
          <!--Boton Publicar-->
          <button
            type="button"
            class="btn btn-success btn-sm mr-1"
            (click)="btnCrearCategoria()"
          >
            {{ "ecommerce.btn_crear" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm mr-1"
            (click)="btnCancelar()"
          >
            {{ "botones.cancelar" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
