import { Component, OnInit } from '@angular/core';
import { Transporte } from '@app/_models';
import { Router, NavigationEnd } from "@angular/router"

import { TransportesService } from '@app/_services/transportes.service';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '../_services';

@Component({
  selector: 'app-transportes',
  templateUrl: './transportes.component.html'
})
export class TransportesComponent implements OnInit {

    public seleccionados: number[] = [];
    private translate: TranslateService;
    public dataTransportes: [];
    modalReference: NgbModalRef;
    modalReferenceloading: NgbModalRef;
    closeResult = '';
    navigationSubscription;
  
    constructor(private transportesService: TransportesService,
      translate: TranslateService,
      private menuService: MenuService, public router: Router,
      private translateService: TranslateService,
      private modalService: NgbModal) {
  
      this.translate = translate;
      this.menuService.titulo = this.translate.instant('transportes.transportes').toUpperCase();
  
      this.navigationSubscription = this.router.events.subscribe((e: any) => {
        // If it is a NavigationEnd event re-initalise the component
        if (e instanceof NavigationEnd) {
          if (this.router.url == '/transportes') {
            this.menuService.titulo = this.translateService.instant('transportes.transportes');
            this.transportesService.GetAll().subscribe((result) => {
              this.dataTransportes = result.data;
            }
            );
          } else {
           
          }
        }
      });
    }
  
    ngOnInit(): void {
      this.transportesService.GetAll().subscribe((result) => {
        this.dataTransportes = result.data;
        }
      );
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.menuService.titulo = this.translateService.instant('transportes.transportes');
      });
    }
  
    onClickNuevo() {
      this.router.navigate(['transportes/crear/']);
    }
  
    onClickEditar() {
      if (this.seleccionados[0] > 0) {
        this.router.navigate(['transportes/editar/', this.seleccionados[0]]);
      }
    }
  
    cellClick(e) {
      if (e.columnIndex > 0) {
        this.router.navigate(['transportes/editar/', this.seleccionados[0]]);
      }
    }
  
    onClickEliminar(content) {
      if (this.seleccionados.length > 0) {
        this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
      }
    }
  
    private eliminarRegistro(contentloading) {
      this.transportesService.delete({ codigos: this.seleccionados }).subscribe(
        (data) => {
          if (data.error == false) {
            this.transportesService.GetAll().subscribe((result) => {
              this.dataTransportes = result.data;
            }
            );
          }
          this.modalReferenceloading.close();
        }
      );
      this.modalReference.close();
      this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
      this.seleccionados = [];
    }

}
