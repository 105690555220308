export class Recorrido {
    id: number;
    idOrg: string;
    idIdioma: number;
    nombre: string;
    descripcion: string;
    urlGpxFile: string;
    idUser: number;
    created: Date;
    urlFoto: string;
    distanciaKm: number;
    listaTraducciones: RecorridoTrad[];
    updateFoto:boolean;
    updateGpx:boolean;
  }
  
  export class RecorridoTrad {
    idIdioma: number;
    nombreIdioma: string;
    idRecorrido: number;
    nombre: string;
    descripcion: string;
    filled: boolean;
  }