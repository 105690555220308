import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UsuariosService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private usuariosService: UsuariosService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.usuariosService.userValue;
        if (user) {
            // check if route is restricted by permisos

            if (route.data.permisos && route.data.permisos.includes("GestionUsuarios==2")) {
                if (user.gestionUsuarios < 2) {
                    this.router.navigate(['/']);
                    return false;
                }
            } else if (route.data.permisos && route.data.permisos.includes("GestionUsuarios==1")) {
                if (user.gestionUsuarios < 1) {
                    this.router.navigate(['/']);
                    return false;
                }
            } else if (route.data.permisos) {
                //role not authorized so redirect to home page
                this.router.navigate(['/']);
                return false;
            }
            // authorized so return true
            return true;
        }

        // not logged in so redirect to login page with the return url 
        this.router.navigate(['login/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}