import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DepartamentoEnte } from '@app/_models/departamentoente';
import { DepartamentoUsuario } from '@app/_models/departamento-usuario';

const baseUrl = `${environment.apiUrl}/departamentosente`;

@Injectable()
export class DepartamentosEnteService {

  constructor(private http: HttpClient) { }

  public GetById(id: String): Observable<any> {
    return this.http.get(baseUrl + "/" + id);
  }

  public GetDepById(id: number): Observable<any> {
    return this.http.get(baseUrl + "/getdep/" + id);
  }

  

  public getAllDepartamentosList(idOrg:string): Observable<any> {
    return this.http.get<DepartamentoEnte[]>(baseUrl+'/departamentoslist/'+idOrg);
  }
  
  delete(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminar', formData, {
      withCredentials: true,
    });
  }

  insertDepartamento(data,id) {
    console.log(JSON.stringify(data));
    return this.http.post<any>(
      baseUrl + '/crear',
      {
        nombre: data.nombre,
        descripcion: data.descripcion,
        activo: data.activo,
        idOrg:id
      },
      { withCredentials: true }
    );
  }

  updateDepartamento(data) {
    console.log(JSON.stringify(data));
    return this.http.post<any>(
      baseUrl + '/editar',
      {
        id: data.codigo,
        nombre: data.nombre,
        descripcion: data.descripcion,
        activo: data.activo,
        idOrg:data.idOrg
      },
      { withCredentials: true }
    );
  }

  public getUsersFromDep(id: number): Observable<any> {
    return this.http.get(baseUrl + "/users/" + id); 
  }

  public updateUsersFromDepartment(lista,idDep):Observable<any>{
    console.log('Enviando esto:');
    console.log(JSON.stringify(lista));
    return this.http.post<any>(
      baseUrl + '/editarusuarios/'+idDep,
      JSON.stringify(lista),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  // updateAdmins(listaAdmins,idOrg):Observable<any>{
  //   console.log('Updating admins from ente...:');
  //   return this.http.post<any>(
  //     baseUrl + '/admins/update/'+idOrg,
  //     JSON.stringify(listaAdmins),
  //     {
  //       headers: new HttpHeaders({
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json',
  //         charset: 'utf-8',
  //       }),
  //       withCredentials: true,
  //     }
  //   );
  //   }
  

}

