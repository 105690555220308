<div class="row">
  <div class="col-lg-5">
    <div class="card">
      <div class="card-header">
        <h3>{{ "restaurante.modoreservas" | translate }}</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
          <div class="form-group">
            <kendo-dropdownlist #dropdown
                                [data]="listaEntesAdministrados"
                                textField="nombre"
                                valueField="id"
                                [(ngModel)]="selectedOrg"
                                (valueChange)="loadRestaurantes($event, dropdown)">
            </kendo-dropdownlist>
          </div>
          </div>
          <div class="col">
          <div class="form-group">
            <kendo-dropdownlist #dropdown
                                [data]="listaRestaurantesAdministrados"
                                textField="nombre"
                                valueField="id"
                                [(ngModel)]="selectedRestaurante"
                                (valueChange)="loadConfiguracion($event, dropdown)">
            </kendo-dropdownlist>
          </div>
          </div>
        </div>

        <div class="clearfix">
          <kendo-buttongroup selection="single">
            <button
              kendoButton
              (click)="onSelectionTab('1')"
              [toggleable]="true"
              [selected]="modoReserva==1"
            >
              {{ "restaurante.modosimple" | translate }}
            </button>
            <!-- <button kendoButton (click)="onSelectionTab('2')" [toggleable]="true">
                    {{ "restaurante.modorangos" | translate }}
                  </button> -->
            <button
              kendoButton
              (click)="onSelectionTab('2')"
              [toggleable]="true"
              [selected]="modoReserva==2"
            >
              {{ "restaurante.modopasado" | translate }}
            </button>
          </kendo-buttongroup>
        </div>
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <kendo-label text="{{ 'restaurante.rangohorario' | translate }}">
                  </kendo-label>
                  <div class="caja">
                    <kendo-switch [onLabel]="' '" [offLabel]="' '"
                    class="float-left mr-2 mt-1" [(ngModel)]="rangoHorasReservaActivado"
                                  [ngModelOptions]="{ standalone: true }">
                    </kendo-switch>


                    <kendo-timepicker class="float-left" *ngIf="rangoHorasReservaActivado"
                                      #timepicker
                                      format="HH:mm"
                                      [min]="min"
                                      [max]="max"
                                      [steps]="steps"
                                      [(ngModel)]="rangoHorasReserva"></kendo-timepicker>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <kendo-label text="{{ 'restaurante.reservasporpersonadia' | translate }}">
                <div class="caja">
                  <kendo-numerictextbox format="n0"
                                        [min]="0"
                                        [step]="1"
                                        [(ngModel)]="maxReservasUsuario"></kendo-numerictextbox>
                </div>
              </kendo-label>
            </div>

            <div class="form-group">
              <kendo-label text="{{ 'restaurante.reservasmaximastotales' | translate }}">
                <div class="caja">
                  <kendo-numerictextbox format="n0"
                                        [min]="0"
                                        [step]="1"
                                        [(ngModel)]="maxReservasTotales"></kendo-numerictextbox>
                </div>
              </kendo-label>

            </div>

            <div class="form-group">
              <kendo-label text="{{ 'restaurante.numcomensalesmax' | translate }}">
                <div class="caja">
                  <kendo-numerictextbox format="n0"
                                        [min]="1"
                                        [step]="1"
                                        [(ngModel)]="maxComensalesReserva"></kendo-numerictextbox>
                </div>
              </kendo-label>
            
          </div>

            <div class="form-group">
              <kendo-label text="{{ 'restaurante.tiempocancelacion' | translate }}">
                <div class="caja">
                  <kendo-timepicker #timepicker
                                    format="HH:mm"
                                    [min]="min"
                                    [max]="max"
                                    [steps]="steps"
                                    [(ngModel)]="tiempoCancelarReserva"></kendo-timepicker>
                </div>
              </kendo-label>
            </div>

            <div class="form-group">
              <kendo-label
                text="{{ 'restaurante.permitirSeleccionMesa' | translate }}"
              >
              </kendo-label>
              <div class="caja">
                <kendo-switch [onLabel]="' '" [offLabel]="' '"
                  [(ngModel)]="permitirSeleccionMesa"
                  [ngModelOptions]="{ standalone: true }"
                >
                </kendo-switch>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <button
        kendoButton
        [disabled]="loading"
        (click)="onSubmit()"
        class="btn mr-1 btn-success"
      >
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
        {{ "botones.actualizar" | translate }}
      </button>
      <!-- <a routerLink="/configuracion-inicial" class="btn mr-1 btn-danger">
            {{ "botones.cancelar" | translate }}
          </a> -->
    </div>
  </div>
</div>
