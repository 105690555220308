import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/patrocinadores`;

@Injectable({
  providedIn: 'root'
})
export class PatrocinadoresService {

  constructor(private http: HttpClient) { }



  public GetById(id: Number): Observable<any> {
    return this.http.get(baseUrl + "/" + id);
  }

  public GetAll(): Observable<any> {
    return this.http.get(baseUrl);
  }

  insert(data) {
    return this.http.post<any>(baseUrl + "/crear", {codigo: -1, nombre1: data.nombre1, nombre2: data.nombre2, email: data.email, telefono: data.telefono}, { withCredentials: true });
  }

  update(data) {
    //console.log(JSON.stringify(data));
    //console.log(idTipoPerdida_DAT);
    return this.http.post<any>(baseUrl + "/editar", { codigo: data.codigo, nombre1: data.nombre1, nombre2: data.nombre2, email: data.email, telefono: data.telefono}, { withCredentials: true });
  }

  delete(data?: any): Observable<any> {

    //console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append("codigos", data.codigos);
    //console.log(JSON.stringify(formData));

    return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });
  }
}
