export class Restaurante {
  id: number;
  idOrg: string;
  nombre:string;
  descripcion:string;
  urlImagen:string;
  created:Date;
  activo:boolean;
  idIdioma:number;
  listaTraducciones:RestauranteTrad[];
  updateFoto:boolean;
}
export class RestauranteTrad {
  id: number;
  idRestaurante:number;
  idIdioma:number;
  nombre:string;
  descripcion:string;
  filled:boolean;
  nombreIdioma:string;
}
export class ComidaTipo {
    id: number;
    idRestaurante: string;
    nombre:string;
    horaInicio:Date;
    horaFin:Date;
    horasReserva:string;
    listaTraducciones:ComidaTipoTrad[];
    idIdioma:number;
    activo:boolean;
  }

  export class ComidaTipoTrad {
    id: number;
    idRestaurante:number;
    idIdioma:number;
    idComida:number;
    nombre:string;
    filled:boolean;
    nombreIdioma:string;
  }

  export class Comedor {
    id: number;
    idRestaurante: number;
    nombre:string;
    urlFondo:string;
    created:Date;
    alto:number;
    ancho:number;
    idIdioma:number;
    listaTraducciones:ComedorTrad[];
    updateFoto:boolean;
    listaMesaComedor:MesaComedor[];
    mesas: number;
    comensales:number;
  }
  export class ComedorTrad {
    id: number;
    idComedor:number;
    idIdioma:number;
    nombre:string;
    filled:boolean;
    nombreIdioma:string;
  }

  export class Mesa {
    id: number;
    idRestaurante: number;
    nombre:string;
    urlFoto:string;
    comensales:number;
    numMesa:string;
    conectable:boolean;
    asignada:boolean;
    isExterior:boolean;
    isAccesible:boolean;
    restaComensalesLargo:number;
    restaComensalesAlto:number;
    idComedorAsignado:number;
    created:Date;
    idIdioma:number;
    listaTraducciones:MesaTrad[];
    updateFoto:boolean;
    zIndex:number;
    posX:number;
    posY:number;
    altura:number;
    anchura:number;
  }
  export class MesaTrad {
    id: number;
    idMesa:number;
    idIdioma:number;
    nombre:string;
    filled:boolean;
    nombreIdioma:string;
  }

  export class MesaComedor {
    id: number;
    idComedor: number;
    numeromesa:number;
    comensales:number;
    urlFoto:string;
    posX:number;
    posY:number;
    rotacion:number;
  }
  export class ReservaRestaurante{
    id:number;
    idUser:number;
    idRestaurante:number;
    idComedor:number;
    idMesa:number;
    idTipoComida:number;
    numComensales:number;
    estado:number;
    fecha:Date;
    fechaCompleta:Date;
    created:Date;
    observaciones:string;
    nombreUsuario:string;
    numMesa:number;
    telefono:string;
    comedor:Comedor;
    mesa: MesaComedor[];
    tipoComida:ComidaTipo;
    nombreReservaCliente:string;
    isViaWeb:boolean;
    isGestionado: boolean;
  }

  export class ReservaPeticion{
    idComedor:number;
    fechaIni:Date;
    fechaFin:Date;
  }
  export class ReservaRestaurantePeticion{
    idRestaurante:number;
    idComedor:number;
    idTipoComida:number;
    numeromesa: string;
    fecha:Date;
    day:number;
    month:number;
    year:number;
  }

  export class ConfiguracionRestaurante{
    idRestaurante:number;
    modoReserva:number;
    rangoTiempoReservaActivado:boolean;
    rangoTiempoReserva:Date;
    reservasMaxDia:number;
    reservasMaxTotales:number;
    numComensalesMax:number;
    tiempoCancelacion:Date;
    permitirSeleccionMesa:boolean;
  }
  export class CalendarioLaboral{
    idRestaurante:number;
    activoLunes:boolean;
    activoMartes:boolean;
    activoMiercoles:boolean;
    activoJueves:boolean;
    activoViernes:boolean;
    activoSabado:boolean;
    activoDomingo:boolean;
    listaFechasPersonalizadas:FechaPersonalizada[];
  }
  export class FechaPersonalizada{
    fecha:Date;
    activa:boolean;
  }
  export class ComidaPersonalizadaDia{
    idRestaurante:number;
    idTipoComida:number;
    fecha:Date;
  }

  export class Perfil {
    nombre: string;
    listaMesas: any[];
    listaMesasOrden: any[];
    listaMesasPanel: any[];
    comensales: number;
    mesas: number;
  }

  export class Tamano_Panel{
    ancho:number;
    alto:number;

    constructor(ancho: number, alto: number) {
      this.ancho = ancho;
      this.alto = alto;
    } 
  }
  