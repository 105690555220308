import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { AlertService, DepartamentosService } from '@app/_services';
import { stringify } from '@angular/compiler/src/util';
import { Recinto, Sala } from '@app/_models/recinto';
import { RecintosService } from '@app/_services/recintos.service';

@Component({
  selector: 'app-salas',
  templateUrl: './salas.component.html',
})
export class SalasComponent implements OnInit {
  listaEntesAdministrados: Ente[] = [];
  listaRecintosAdministrados: Recinto[] = [];
  selectedOrg: Ente;
  selectedRecinto: Recinto;
  listaSalasAdministradas: Sala[] = [];
  public seleccionados: number[] = [];

  lang;
  private translate: TranslateService;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  constructor(
    private recintoService: RecintosService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('recintos.cabecera_salas');
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();

    this.recintoService.getEntesAdministrados().subscribe((resultEntes) => {
      this.listaEntesAdministrados = resultEntes.data;
      if (this.listaEntesAdministrados.length > 0) {
        this.selectedOrg = this.listaEntesAdministrados[0];
        this.recintoService
          .getRecintosFromEnte(this.selectedOrg.id)
          .subscribe((resultRecintos) => {
            console.log(
              'Recintos que llegan de la org: ' + this.selectedOrg.nombre
            );
            console.log(resultRecintos.data);
            this.listaRecintosAdministrados = resultRecintos.data;
            if (this.listaRecintosAdministrados.length > 0) {
              this.selectedRecinto = this.listaRecintosAdministrados[0];

              this.recintoService
                .getSalasFromRecinto(this.listaRecintosAdministrados[0].id)
                .subscribe((resultSalas) => {
                  console.log('Salas que llegan');
                  console.log(resultSalas);
                  this.listaSalasAdministradas = resultSalas.data;
                });
            }
          });
      }
    });
  }

  loadRecintos(value, dropDownControl: DropDownListComponent) {
    console.log('Cargando recintos de :' + value.id);
    this.recintoService
      .getRecintosFromEnte(this.selectedOrg.id)
      .subscribe((result) => {
        console.log(result);
        this.listaRecintosAdministrados = result.data;
        if (this.listaRecintosAdministrados.length > 0) {
          console.log('Hay Recintos');
          this.selectedRecinto = this.listaRecintosAdministrados[0];
        } else this.selectedRecinto = null;
      });
  }

  loadSalas(value, dropDownControl: DropDownListComponent) {
    console.log('Cargando recintos de :' + value.id);
    this.recintoService
      .getSalasFromRecinto(this.selectedRecinto.id)
      .subscribe((resultSalas) => {
        console.log(resultSalas);
        this.listaSalasAdministradas = resultSalas.data;
      });
  }

  onClickNuevo() {
    if (this.selectedOrg == null) {
      this.alertService.warn(
        this.translateService.instant('publicaciones.selectente')
      );
    } else {
      this.router.navigate([
        'salas-crear',
        { idorg: this.selectedOrg.id, idrecinto: this.selectedRecinto.id },
      ]);
    }
  }

  onClickEliminar(content) {
    console.log('Eliminando...');
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, {
        backdrop: 'static',
        size: 'lg',
        keyboard: false,
        centered: true,
      });
    }
  }

  onClickEditar() {
    this.router.navigate([
      'salas-editar',
      {
        idorg: this.selectedOrg.id,
        idrecinto: this.selectedRecinto.id,
        idsala: this.seleccionados[0],
      },
    ]);
  }

  cellClick() {
    this.router.navigate([
      'salas-editar',
      {
        idorg: this.selectedOrg.id,
        idrecinto: this.selectedRecinto.id,
        idsala: this.seleccionados[0],
      },
    ]);
  }

  eliminarRegistro(contentloading) {
    console.log('Eliminar registro');
    this.recintoService
      .deleteSalas({ codigos: this.seleccionados })
      .subscribe((data) => {
        if (data.error == false) {
          this.recintoService
            .getSalasFromRecinto(this.selectedRecinto.id)
            .subscribe((result) => {
              this.listaSalasAdministradas = result.data;
            });
        }
        this.modalReferenceloading.close();
      });
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, {
      backdrop: 'static',
      size: 'sm',
      keyboard: false,
      centered: true,
    });
    this.seleccionados = [];
  }
}
