import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Idioma } from '@app/_models/idioma';

import {
  CalendarioLaboral,
  ComidaPersonalizadaDia,
  ComidaTipo,
  FechaPersonalizada,
  Mesa,
  MesaTrad,
} from '@app/_models/restaurante';
import { AlertService, MenuService, UsuariosService } from '@app/_services';
import { IdiomasService } from '@app/_services/idiomas.service';
import { RestauranteService } from '@app/_services/restaurante.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Day } from '@progress/kendo-date-math';

@Component({
  selector: 'app-calendarios-restaurante',
  templateUrl: './calendarios-restaurante.component.html',
})
export class CalendariosRestauranteComponent implements OnInit {
  idRestaurante: number;
  idOrg: string;
  activoLunes: boolean;
  activoMartes: boolean;
  activoMiercoles: boolean;
  activoJueves: boolean;
  activoViernes: boolean;
  activoSabado: boolean;
  activoDomingo: boolean;
  listaFechasDesabilitadas: Date[] = [];
  listaFechasActivas: Date[] = [];

  selectedFechas = [];
  listaComidas: ComidaTipo[] = [];
  listaComidasPersonalizada: ComidaPersonalizadaDia[] = [];
  activarTodasComidas: boolean = true;
  listaComidasDiaSeleccionado: ComidaTipo[] = [];
  today: Date = new Date();
  private translate: TranslateService;
  lang;

  constructor(
    private restauranteService: RestauranteService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translateService;
  }

  ngOnInit(): void {
    const start = new Date().getTime();
    this.lang = this.translateService.getDefaultLang();
    this.idOrg = this.route.snapshot.params['idorg'];
    this.idRestaurante = this.route.snapshot.params['idrestaurante'];
    this.initCalendario();
  }

  /**
   * Funcion que inicializa los dias laborables/festivos y comidas pertinentes de cada dia en el calendario
   */
  initCalendario() {
    this.restauranteService
      .getCalendarioLaboral(this.idRestaurante)
      .subscribe((res) => {
        if (res.data?.length > 0) {
          this.activoLunes = res.data[0].activoLunes;
          this.activoMartes = res.data[0].activoMartes;
          this.activoMiercoles = res.data[0].activoMiercoles;
          this.activoJueves = res.data[0].activoJueves;
          this.activoViernes = res.data[0].activoViernes;
          this.activoSabado = res.data[0].activoSabado;
          this.activoDomingo = res.data[0].activoDomingo;
        }
        if (res.fechas?.length > 0) {
          res.fechas.forEach((element) => {
            if (element.activo) {
              this.listaFechasActivas.push(
                new Date(this.dateToYYYYMMDDtHHmmSSz(new Date(element.fecha)))
              );
            } else {
              this.listaFechasDesabilitadas.push(
                new Date(this.dateToYYYYMMDDtHHmmSSz(new Date(element.fecha)))
              );
            }
          });
        }
        this.getCalendarioComidas()
      });
  }

  /**
   * Obtiene las comidas del calendario para un restaurante en espcifico usando el id de este.
   */
  getCalendarioComidas() {
    this.restauranteService
      .GetComidasFromRestaurante(this.idRestaurante.toString())
      .subscribe((result) => {
        if (result.data) {
          this.listaComidas = this.listaComidasDiaSeleccionado = result.data;
        }
        // Si la fecha esta desabilitida no se deberian de mostrar las comidas especificas del dia
        if (this.disabledDates(this.today)) {
          this.listaComidasDiaSeleccionado = [];
        } else {
          this.selectedFechas.push(this.today);
          this.getComidasFecha(this.today);
        }
      });
  }

  /**
   * Funcion que reacciona a la seleccion de una o varias fechas en el calendario, y el cual se encarga de actualizar la lista de comidas.
   * En el caso de varias fechas la lista de comida a obtener sera el del primer valor de la lista.
   * @param fechas Fechas seleccionadas.
   */
  public onChange(fechas: Date[]): void {
    this.selectedFechas = fechas;
    if (this.disabledDates(fechas[0])) {
      this.listaComidasDiaSeleccionado = [];
    } else {
      this.getComidasFecha(this.selectedFechas[0]);
    }
  }

  /**
   * Funcion que logra de la base de datos la lista de comidas de un dia en especifico. Si el resultado es en blanco significa que el dia posee todas las comidas.
   * @param fecha Dia para el que obtener las comidas
   */
  getComidasFecha(fecha: Date) {
    var comidaPersonalizada: ComidaPersonalizadaDia = new ComidaPersonalizadaDia();
    comidaPersonalizada.fecha = new Date(this.dateToYYYYMMDDtHHmmSSz(fecha));
    comidaPersonalizada.idRestaurante = this.idRestaurante;
    this.restauranteService
      .getComidasCalendarioFecha(comidaPersonalizada)
      .subscribe((result) => {
        if (result.data?.length > 0) {
          console.log('COMIDAS PERSONALIZADAS ESTE DIA');
          this.listaComidasDiaSeleccionado.forEach(comida => {
            comida.activo = false;
          });
          result.data.forEach(rComida => {
            let index = this.listaComidasDiaSeleccionado.findIndex((comida: ComidaTipo) => comida.nombre === rComida.nombre);
            if (index > -1) {
              this.listaComidasDiaSeleccionado[index].activo = true;
            }
          });
        } else {
          console.log('COMIDAS DIA NORMAL');
          if (this.listaComidasDiaSeleccionado, length === 0) {
            this.listaComidasDiaSeleccionado = this.listaComidas;
          }
          this.listaComidasDiaSeleccionado.forEach(comida => {
            comida.activo = true;
          });
        }
        this.updateListaComidasPersonalizada(this.listaComidasDiaSeleccionado);
      });
  }

  /**
   * Inserta en la lista de comidas especilaes las comidas de un dia
   * @param comidasDia lista de comidaas de @param fecha
   * @param fecha dia de @param comidas dia
   */
  updateListaComidasPersonalizada(comidasDia: ComidaTipo[]) {
    this.selectedFechas.forEach(fecha => {
      comidasDia.forEach((comida: ComidaTipo) => {
        let comidaPersonalizada: ComidaPersonalizadaDia = new ComidaPersonalizadaDia();
        comidaPersonalizada = {
          idRestaurante: comida.idRestaurante as unknown as number,
          fecha: new Date(this.dateToYYYYMMDDtHHmmSSz(fecha)),
          idTipoComida: comida.id
        }
        if (comida.activo) {
          if (!this.isComidaPersonalizadaOnList(comidaPersonalizada)) {
            this.listaComidasPersonalizada.push(comidaPersonalizada);
          }
        }
      });
    });
  }

  /**
   * Funcion que determina si una fecha es un dia laborable o no
   * @param date Fecha
   * @returns si la fecha el laborable
   */
  public isWorkingDay(date: Date) {
    var iguales: boolean = false;
    this.listaFechasActivas.forEach((elem) => {
      var tmpFecha2 = new Date(elem);
      if (
        tmpFecha2.getFullYear() == date.getFullYear() &&
        tmpFecha2.getMonth() == date.getMonth() &&
        tmpFecha2.getDate() == date.getDate()
      ) {
        iguales = true;
      }
    });
    if (iguales) return 'laboral';

    var iguales2: boolean = false;
    this.listaFechasDesabilitadas.forEach((elem) => {
      var tmpFecha2 = new Date(elem);
      if (
        tmpFecha2.getFullYear() == date.getFullYear() &&
        tmpFecha2.getMonth() == date.getMonth() &&
        tmpFecha2.getDate() == date.getDate()
      ) {
        iguales2 = true;
      }
    });
    if (iguales2) return 'nolaboral';

    if (this.listaFechasActivas.includes(date)) return 'laboral'; //esto no funciona :)
    if (
      (Day.Monday == date.getDay() && !this.activoLunes) ||
      (Day.Tuesday == date.getDay() && !this.activoMartes) ||
      (Day.Wednesday == date.getDay() && !this.activoMiercoles) ||
      (Day.Thursday == date.getDay() && !this.activoJueves) ||
      (Day.Friday == date.getDay() && !this.activoViernes) ||
      (Day.Saturday == date.getDay() && !this.activoSabado) ||
      (Day.Sunday == date.getDay() && !this.activoDomingo)
    )
      return 'nolaboral';
    return 'laboral';
  }

  /**
   * Activa el periodo de fehcas seleccionadas
   */
  activatePeriodo() {
    console.log('Activando Periodo ');
    this.selectedFechas.forEach((elem) => {
      var igual: boolean = false;
      this.listaFechasActivas.forEach((fecha) => {
        if (this.isDatesEqual(fecha, elem)) igual = true;
      });
      if (!igual)
        this.listaFechasActivas.push(
          new Date(this.dateToYYYYMMDDtHHmmSSz(elem))
        );
      //borramos de desctivadas si estuviera
      this.listaFechasDesabilitadas = this.listaFechasDesabilitadas.filter((x) => {
        if (
          x.getFullYear() == elem.getFullYear() &&
          x.getMonth() == elem.getMonth() &&
          x.getDate() == elem.getDate()
        )
          return false;
        return true;
      });
      this.getComidasFecha(elem);
    });
    console.log('Fechas Activadas= ');
    console.log(this.listaFechasActivas);
  }

  /**
   * Desactiva el periodo de fehcas seleccionadas
   */
  disablePeriodo() {
    console.log('Desactivando Periodo ');
    this.selectedFechas.forEach((elem) => {
      var igual: boolean = false;
      this.listaFechasDesabilitadas.forEach((fecha) => {
        if (this.isDatesEqual(fecha, elem)) igual = true;
      });
      if (!igual)
        this.listaFechasDesabilitadas.push(
          new Date(this.dateToYYYYMMDDtHHmmSSz(elem))
        );
      //borramos de activadas si estuviera
      this.listaFechasActivas = this.listaFechasActivas.filter((x) => {
        if (
          x.getFullYear() == elem.getFullYear() &&
          x.getMonth() == elem.getMonth() &&
          x.getDate() == elem.getDate()
        )
          return false;
        return true;
      });
    });
    console.log('Fechas Desabilitadas = ');
    console.log(this.listaFechasDesabilitadas);
  }

  /**
   * Actualiza el estado de los dias activos/inactivos y de las comidas por dia del calendario
   */
  guardarEstadoDelCalendario() {
    console.log("Actualizando estado del calendario...");
    this.actualizarCalendario();
    this.gestionarComidas();
    this.listaComidasPersonalizada = [];
  }

  /**
   * Actualiza los dias activos e inactivos del restaurante
   */
  actualizarCalendario() {
    let calendario: CalendarioLaboral = new CalendarioLaboral();
    calendario.listaFechasPersonalizadas = [];
    calendario.idRestaurante = this.idRestaurante;
    calendario.activoLunes = this.activoLunes;
    calendario.activoMartes = this.activoMartes;
    calendario.activoMiercoles = this.activoMiercoles;
    calendario.activoJueves = this.activoJueves;
    calendario.activoViernes = this.activoViernes;
    calendario.activoSabado = this.activoSabado;
    calendario.activoDomingo = this.activoDomingo;
    this.listaFechasActivas.forEach((elem) => {
      var tmpFecha: FechaPersonalizada = new FechaPersonalizada();
      tmpFecha.activa = true;
      tmpFecha.fecha = elem;
      calendario.listaFechasPersonalizadas.push(tmpFecha);
    });
    this.listaFechasDesabilitadas.forEach((elem) => {
      var tmpFecha: FechaPersonalizada = new FechaPersonalizada();
      tmpFecha.activa = false;
      tmpFecha.fecha = elem;
      calendario.listaFechasPersonalizadas.push(tmpFecha);
    });
    this.restauranteService
      .updateCalendarioLaboral(calendario)
      .subscribe((result) => {
        if (!result.error) {
          this.alertService.success(
            this.translateService.instant('restaurante.calendarioactualizado')
          );
        } else {
          this.alertService.success(
            this.translateService.instant(
              'restaurante.errorcalendarioactualizado'
            )
          );
        }
      });
  }

  /**
   * Actualiza las comidas de los dias seleccionados
   */
  gestionarComidas() {
    let fechas: Date[] = this.listaComidasPersonalizada.reduce((result: Date[], item: ComidaPersonalizadaDia) => {
      let found: boolean = false;
      result.forEach((date: Date) => {
        if(this.isDatesEqual(date, item.fecha)){
          found = true;
          return;
        }
      });
      if(!found) {
        result.push(item.fecha);
      }
      return result;
    }, []);
    fechas.forEach(fecha => {
      let comidas: ComidaPersonalizadaDia[] = this.listaComidasPersonalizada.reduce((result: ComidaPersonalizadaDia[], item: ComidaPersonalizadaDia) => {
        if (this.isDatesEqual(item.fecha, fecha)) {
          result.push(item);
        }
        return result;
      }, []);
      this.borrarComidaPersonalizada(comidas);
    });
  }

  /**
   * Actualiza las comidaas de un dia en la base de datos
   * @param listaComidasPersonalizada Lista de comidas a insertar
   */
  actualizarComidasDia(listaComidasPersonalizada: ComidaPersonalizadaDia[]) {
    console.log('Actualizando Comidas del dias ');
    this.restauranteService
      .crearCalendarioComidasFechas(listaComidasPersonalizada)
      .subscribe((result) => {
        if (!result.error) {
          //this.listaComidasPersonalizada = [];
          this.alertService.success(
            this.translateService.instant('restaurante.successregistro')
          );
          this.getComidasFecha(this.selectedFechas[0]);
        } else {
          this.alertService.error(
            this.translateService.instant('recintos.errorregistro')
          );
        }
      });
  }

  /**
   * Borra una lista de comidas de la base de datos
   * @param listaComidasPersonalizada Lista de comidas a borrar
   */
  borrarComidaPersonalizada(listaComidasPersonalizada: ComidaPersonalizadaDia[]) {
    console.log('Borrando comidas personalizadas'); // Por defecto tendra todas las comidas disponibles
    this.restauranteService
      .borrarComidasPersonalizadasFechas(listaComidasPersonalizada)
      .subscribe((result) => {
        if (!result.error) {
          this.alertService.success(
            this.translateService.instant('restaurante.successregistro')
          );
        } else {
          this.alertService.error(
            this.translateService.instant('recintos.errorregistro')
          );
        }
        if (listaComidasPersonalizada.length != this.listaComidas.length) {
          this.actualizarComidasDia(listaComidasPersonalizada);
        }
      });
  }

  /**
   * Actualiza la lista personalizada de comidas en base a @param event
   * @param event Estado del toggle. True/False
   * @param comida Comida a insertar o eliminar de la lista personalizada
   */
  onComidaActiva(event, comida) {
    console.log('Comida a activar');
    console.log(comida);
    if (event) {
      //añadimos la comida a la lista personalizada para enviar
      this.selectedFechas.forEach((fecha) => {
        let tmpComidaPersonalizada: ComidaPersonalizadaDia = new ComidaPersonalizadaDia();
        tmpComidaPersonalizada.idRestaurante = this.idRestaurante;
        tmpComidaPersonalizada.idTipoComida = comida.id;
        tmpComidaPersonalizada.fecha = new Date(
          this.dateToYYYYMMDDtHHmmSSz(fecha)
        );
        if (!this.isComidaPersonalizadaOnList(tmpComidaPersonalizada)) {
          this.listaComidasPersonalizada.push(tmpComidaPersonalizada);
        }
      });
    } else {
      //borrar de la lista si esta la comida ese dia
      this.selectedFechas.forEach((fecha) => {
        var tmpFecha: Date = new Date(this.dateToYYYYMMDDtHHmmSSz(fecha));
        this.listaComidasPersonalizada = this.listaComidasPersonalizada.filter(
          (x) => {
            if (!this.isDatesEqual(x.fecha, tmpFecha) || x.idTipoComida !== comida.id) {
              return true;
            }
            return false;
          }
        );
      });
    }
  }

  /**
   * Funciona que dictamina si una fecha esta activa o no.
   * @param date Fecha a comprobar
   * @returns Si la fecha deberia estar desactivada
   */
  public disabledDates = (date: Date): boolean => {
    return (
      this.listaFechasDesabilitadas.find(item => { return this.isDatesEqual(item, date) }) !== undefined ||
      (Day.Monday == date.getDay() && !this.activoLunes) ||
      (Day.Tuesday == date.getDay() && !this.activoMartes) ||
      (Day.Wednesday == date.getDay() && !this.activoMiercoles) ||
      (Day.Thursday == date.getDay() && !this.activoJueves) ||
      (Day.Friday == date.getDay() && !this.activoViernes) ||
      (Day.Saturday == date.getDay() && !this.activoSabado) ||
      (Day.Sunday == date.getDay() && !this.activoDomingo)
    );
  };

  /**
   * Comprueba que dos fechas son iguales
   * @param date1 Fecha que comparar
   * @param date2 Fecha contra la que comparar
   * @returns si @param date1 es igual a @param date2
   */
  isDatesEqual(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  /**
   * Funcion que cambia la fecha a un formato aceptable para la base de datos
   * @param fecha Fecha a la que cambiar el formato
   * @returns fecha con el nuevo formato
   */
  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return (
      año +
      '-' +
      this.addZero(mes) +
      '-' +
      this.addZero(dia) +
      'T' +
      this.addZero(hora) +
      ':' +
      this.addZero(minutos) +
      ':' +
      this.addZero(segundos) +
      'Z'
    );
  }

  addZero(num) {
    if (num < 10) return '0' + num;
    else return num;
  }

  /**
   * Determina si la comida esta o no en la lista de comidas personalizadas
   * @param comidaPersonalizada comida a buscar en la lista de comidas personalizadas
   * @returns si @param comida esta en la lista
   */
  isComidaPersonalizadaOnList(comidaPersonalizada: ComidaPersonalizadaDia) {
    return this.listaComidasPersonalizada.filter((item: ComidaPersonalizadaDia) => this.isDatesEqual(item.fecha, comidaPersonalizada.fecha) && item.idTipoComida === comidaPersonalizada.idTipoComida).length !== 0
  }

}
