import { ConstantPool } from '@angular/compiler';
import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Usuario } from '@app/_models';
import { MenuService, UsuariosService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { filter, first } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { DatePipe } from '@angular/common';
import { RestauranteService } from '@app/_services/restaurante.service';

@Component({
  selector: 'app-reserva-admin',
  templateUrl: './reserva-admin.component.html',
})
export class ReservaAdminComponent  {
  user = this.usuariosService.userValue;
  public idioma: string;
  public seleccionados: number[] = [];
  public fecha: Date = new Date();
  public fecha2: any;
  public fechaInicio: Date = new Date();
  public fechaFin: Date = new Date();
  public fechaFin2: any;
  public listItemsComida: any = [];
  public listItemsComedor: any = [];
  public comedorNum: any;
  public comidaNum: any;
  dataUsuarios: Usuario[];
  interval: number;
  public datos: any = [];
  public fondo: any = [];
  public expandido: any;
  public comensales: any = [];
  public mesas: any = [];
  public objetos: any = [];
  public foto: any = [];
  public mesa: any = [];
  public imagenFondo: any;
  public imagenes: any = [];
  public reserva: any = []; //Guarda todas las mesas
  public reserva2: any = []; //Guarda ÚNICAMENTE las reservas
  public reservaCanceladas: any = []; //Guarda ÚNICAMENTE las reservadas canceladas
  public reservaAuxiliar: any = []; //Es una tabla auxiliar que se usa para cambiar los datos que se muestran al clickar en ver canceladas
  public prueba: any;
  public estado: number = 1;
  public contReservado: number = 0;
  public contReservado2: number = 0;
  public plazasLibres: number;
  public plazasLibres2: number;
  public configDatos: any;
  public aperturaReservas: any;
  public numDiasCalendario: any;
  public diasSinLimitacion: any;
  public limiteReservas: any;
  public mesasPorReserva: any;
  public comensalesPorReserva: any;
  public maximosComensales: number = 0;

  public fotoObjetos: any = [];
  public fotoObjetos2: any = [];

  private translate: TranslateService;
  navigationSubscription;

  private calendarioGlobal: any; //Tiene los datos de todos los comedores y comidas
  private calendario: any; //Tiene los datos del comedor y comida actuales
  private calendario2: any;
  public disabledDates;
  public diaActivo = false;
  public opened = false;
  public mensajeError: any;
  public verCanceladas: boolean = false;
  public reservasRealizadas: any;
  public reservaPorMesas: any;
  public reservaPorComensalesCompletas: any;
  public limitlunesjueves: any;
  public comensalesReservados: any;
  public mesasReservadas: any;
  public fechaMaxima;
  public fechaMinima;

  public verCanceladasActivo: any;
  public calendarioConfig: any;

  public fechaSeleccionada: any;
  public comidaSeleccionada: any;
  public comedorSeleccionado: any;

  //Data
  idRestaurante:number=4;

  constructor(private usuariosService: UsuariosService,
    translate: TranslateService,
    private menuService: MenuService,
    public router: Router,
    private translateService: TranslateService,
    private restauranteService: RestauranteService,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private cdref: ChangeDetectorRef, private route: ActivatedRoute) {
    this.translate = translate;

    
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
          const navigation = this.router.getCurrentNavigation();
          this.fechaSeleccionada = navigation.extras.state ? navigation.extras.state.fecha : null;
          this.comidaSeleccionada = navigation.extras.state ? navigation.extras.state.comida : null;
          this.comedorSeleccionado = navigation.extras.state ? navigation.extras.state.comedor : null;
          this.menuService.titulo = this.translateService.instant('reservas');
          this.inicializarDesplegables();
      }
    });
  }

  ngAfterViewInit() {
    this.cdref.detectChanges();
  }

  inicializarDesplegables() {
    this.listItemsComida = [];
    this.listItemsComedor = [];

    //Inicializamos los desplegables
    var r1, r2 = false;
    this.restauranteService.getComedores(this.idRestaurante).subscribe((result) => {
      var auxi = result.data;
      if (result.data.length > 0) {
        auxi.forEach(element => { //por cada comedor
          if (this.user.idioma == "es")
            this.listItemsComedor.push({ id: element.codigo, value: element.nombre1 });
          else if (this.user.idioma == "eu")
            this.listItemsComedor.push({ id: element.codigo, value: element.nombre2 })
        });
        this.comedorNum = this.listItemsComedor[0];
      }
      r1 = true;
      if (r1 && r2)
        this.cargarDatosPrimero();
    });

    this.restauranteService.GetComidasFromRestaurante(this.idRestaurante.toString()).subscribe((result) => {
      var auxi = result.data;
      if (result.data.length > 0) {
        var horaActual = (new Date().getHours()<10? "0" + new Date().getHours().toString() : new Date().getHours().toString()).toString()
         + ":" + (new Date().getMinutes()<10? "0" + new Date().getMinutes().toString() : new Date().getMinutes().toString()).toString() + ":" + 
        (new Date().getSeconds()<10? "0" + new Date().getSeconds().toString() : new Date().getSeconds().toString()).toString();
        var codigoEncontrado = null;
        var auxiArray = [];
        auxi.forEach(element => { //por cada comida
          element.horaC = element.horainicio.split("T")[1];
          element.horaF = element.horafin.split("T")[1];
          var range = [element.horaC, element.horaF];
          if(this.isInRange(horaActual, range)){
            codigoEncontrado = element.codigo;
          }
          if (this.user.idioma == "es"){
            auxiArray.push({ id: element.codigo, value: element.nombre1 });
            this.listItemsComida.push({ id: element.codigo, value: element.nombre1 });
          }
          else if (this.user.idioma == "eu"){
            auxiArray.push({ id: element.codigo, value: element.nombre2 });
            this.listItemsComida.push({ id: element.codigo, value: element.nombre2 });
          }
        });
        if(codigoEncontrado!= null)
          this.comidaNum = this.listItemsComida.filter(x=>x.id == codigoEncontrado)[0];
        else{ //estamos en tierra de nadie
          auxiArray = auxiArray.sort((a, b) => (a.id > b.id) ? 1 : -1);
          this.comidaNum = auxiArray[0]; //Así nos quedamos con la comida cuyo codigo sea el primero
        }
      }
      r2 = true;
      if (r1 && r2)
        this.cargarDatosPrimero();
    });

  }

  isInRange(value, range){
    return value>= range[0] && value <= range[1];
  }

  

  fechaEntreFecha(fecha, fechaInicio, fechaFin) {
    var from = new Date(fechaInicio.getFullYear(), fechaInicio.getMonth(), fechaInicio.getDate());
    var to = new Date(fechaFin.getFullYear(), fechaFin.getMonth(), fechaFin.getDate());
    var check = new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate()); //Necesitamos quitarle las horas

    return (check >= from && check <= to)
  }

  cargarDatosPrimero(): void {
    this.menuService.titulo = this.translateService.instant('reservasAdmin');
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.menuService.titulo = this.translateService.instant('reservasAdmin');
    });
    this.idioma = this.user.idioma;
  }

  cargardatos() {

    if(this.comedorNum == undefined){
      this.listItemsComedor = [];

      //Inicializamos los desplegables
      this.restauranteService.getComedores(this.idRestaurante).subscribe((result) => {
        var auxi = result.data;
        if (result.data.length > 0) {
          auxi.forEach(element => { //por cada comedor
            if (this.user.idioma == "es")
              this.listItemsComedor.push({ id: element.codigo, value: element.nombre1 });
            else if (this.user.idioma == "eu")
              this.listItemsComedor.push({ id: element.codigo, value: element.nombre2 })
          });
          this.comedorNum = this.listItemsComedor[0];
        }
      });
    }

    if(this.comidaNum == undefined){
      this.listItemsComida = [];

      //Inicializamos los desplegables
      this.restauranteService.GetComidasFromRestaurante(this.idRestaurante.toString()).subscribe((result) => {
        var auxi = result.data;
        if (result.data.length > 0) {
          auxi.forEach(element => { //por cada comida
            if (this.user.idioma == "es")
              this.listItemsComida.push({ id: element.codigo, value: element.nombre1 });
            else if (this.user.idioma == "eu")
              this.listItemsComida.push({ id: element.codigo, value: element.nombre2 })
          });
          this.comidaNum = this.listItemsComida[0];
        }
      });
    }

    if(this.fechaSeleccionada!= null){
      this.fecha = new Date(this.fechaSeleccionada);
      this.fechaInicio = new Date(this.fechaSeleccionada);
    }
    if(this.comidaSeleccionada!= null){
      this.comidaNum = this.listItemsComida.filter(x=>x.id== this.comidaSeleccionada)[0];
    }
    if(this.comedorSeleccionado!= null){
      this.comedorNum = this.listItemsComedor.filter(x=>x.id== this.comedorSeleccionado)[0];
    }

    this.restauranteService.getMesasComedor(this.comedorNum.id).subscribe((result) => { 
      this.mesas = result.data[0].column1;
    });


    // this.reservasService.GetComensales(this.comedorNum.id).subscribe((result) => {
    //   this.comensales = result.data[0].column1;
    //   this.plazasLibres = this.comensales - this.contReservado;
    // });

    // this.reservasService.getReservasCanceladas(this.fecha, this.comidaNum.id, this.comedorNum.id).subscribe((result) => {
    //   this.reserva2 = result.reservadas;
    //   this.reservaCanceladas = result.canceladas;
    //   this.reservaAuxiliar = result.reservadas; // verCanceladas no está seleecionado cuando se cargan los datos, por lo que lo ponemos con las reservas, no las canceladas
    // });
  }

  createImageFromBase64(fondo) {
    let objectURL = 'data:image/jpeg;base64,' + fondo;
    this.imagenFondo = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    if(!this.expandido){
      this.imagenes = [];
      for (var i = 0; i < 20; i++) {
        this.imagenes.push(this.imagenFondo);
      }
    }else{
      this.imagenes = [this.imagenFondo];
    }
  }

  nuevaReserva(numero, fecha: Date) {
    var codigoComedores2 = this.objetos.filter(x=> x.numero == numero)[0].codigoComedores2; //cogemos la mesa que tine ese numero
    var auxFecha = fecha.getFullYear() + "-" + fecha.getMonth() + "-" + fecha.getDate();
    var auxi = fecha; //correct date
    auxi.setHours(0);
    auxi.setMinutes(0);
    auxi.setSeconds(0);
    auxi.setMilliseconds(0);
    var libre: any = false;
    var anadirAReserva = false;
    var hoy = new Date();
    hoy.setHours(0);
    hoy.setMinutes(0);
    hoy.setSeconds(0);
    hoy.setMilliseconds(0);
        //check first if the booking is free
        if (fecha.getTime() >= hoy.getTime()) {
          var tieneLimites = this.controlarLimites(anadirAReserva, fecha);
          if (tieneLimites.reservar) {
            //si la puede reservar, le redirigimos
            this.router.navigate(['/reservasAdmin/crear', numero, auxFecha, this.comidaNum.id, this.comedorNum.id, this.estado]);
          } else {
            this.opened = true;
            this.mensajeError = tieneLimites.mensajeError;
          }
        }
        else {
          this.opened = true;
          this.mensajeError = "errReservaPasado";
        }

  }

  controlarLimites(anadirAReserva: any, fecha): any {
    var auxFecha = fecha;
    auxFecha.setHours(0);
    auxFecha.setMinutes(0);
    auxFecha.setSeconds(0);
    auxFecha.setMilliseconds(0);
    var hoy = new Date();
    hoy.setHours(0);
    hoy.setMinutes(0);
    hoy.setSeconds(0);
    hoy.setMilliseconds(0);
    var auxi = { reservar: true, mensajeError: "" }; //Esto es lo que nos va a devolver la función, true o false para saber si se puede hacer la reserva y el mensaje de error en caso de que no
    if (this.limitlunesjueves == undefined) { //si la variable no existe en la base de datos la pones como no activa
      this.limitlunesjueves = false;
    }
    if (!this.limitlunesjueves) { //si está activa mirar si estamos entre lunes y jueves
      if (auxFecha.getDay() >= 1 && auxFecha.getDay() <= 4) { //sino devolver false
        return auxi;
      }
    }
    if (this.diasSinLimitacion > 0) { //si tenemos días limitados para reservar
      var auxLimite = new Date();
      auxLimite.setHours(0);
      auxLimite.setMinutes(0);
      auxLimite.setSeconds(0);
      auxLimite.setMilliseconds(0);
      auxLimite.setDate(hoy.getDate() + (this.diasSinLimitacion - 1));
      if (auxFecha.getTime() >= hoy.getTime() && auxFecha.getTime() <= auxLimite.getTime()) {//si está entre los límites se puede reservar
        return auxi;
      }
    }
    if (this.aperturaReservas > 0 && this.limiteReservas > 0) {//Si la apertura de reservas es distinta de siempre y existe un máximo de reservas
      if (!anadirAReserva) {//Si no hay que añadirla a una reserva, hay que crear una nueva reserva
        if (this.reservasRealizadas >= this.limiteReservas) { //comprobar que no se ha superado ya el limite de reservas
          auxi.reservar = false;
          auxi.mensajeError = "errLimReserva";
        }
      }
    }
    if (!anadirAReserva) {//Si no hay que añadirla a una reserva, hay que crear una nueva reserva
      if (this.mesasReservadas >= this.mesasPorReserva) { //si las mesas reservadas actualmente por el cliente ya son mayores o igual que la cantidad máxima permitida por reserva
        auxi.reservar = false;
        auxi.mensajeError = "errMaxMesas";
      }
      //Ahora miramos los comensales
      if (this.comensalesReservados >= this.comensalesPorReserva) { //si los comensales reservadas actualmente por el cliente ya son mayores o igual que la cantidad máxima permitida por reserva
        auxi.reservar = false;
        auxi.mensajeError = "errMaxComensales";
      }
    } else { //si hay que añadirla a una reserva ya realizada previamente
      //hay que contar los comensales que se tendrían con esa mesa, y que habría una mesa más, y ver si no superan los límites
      var mesas = this.mesasReservadas + 1;
      if (mesas > this.mesasPorReserva) { //no se puede reservar por superarse limite de mesas
        auxi.reservar = false;
        auxi.mensajeError = "errMaxMesas";
      }
      //Calculamos los comensales
      var comensales = this.comensalesReservados + this.plazasLibres; //plazas libres son los comensales a reservar
      if (comensales > this.comensalesPorReserva) {
        auxi.reservar = false;
        auxi.mensajeError = "errMaxComensales";
      }
    }
    return auxi;
  }

  nuevaReserva3(e) {
    var fecha = this.fecha;
    var codigoComedores2 = e.dataItem.codigoComedores2; //cogemos la mesa que tine ese numero
    var auxFecha = fecha.getFullYear() + "-" + fecha.getMonth() + "-" + fecha.getDate();
    var auxi = fecha; //correct date
    auxi.setHours(0);
    auxi.setMinutes(0);
    auxi.setSeconds(0);
    auxi.setMilliseconds(0);
    var libre: any = false;
    var anadirAReserva = false;
    var hoy = new Date();
    hoy.setHours(0);
    hoy.setMinutes(0);
    hoy.setSeconds(0);
    hoy.setMilliseconds(0);

        if (fecha.getTime() >= hoy.getTime()) {
          var tieneLimites = this.controlarLimites(anadirAReserva, fecha);
          if (tieneLimites.reservar) { //como es admin siempre saldrá a reservar
            //si la puede reservar, le redirigimos
            this.router.navigate(['/reservasAdmin/crear', e.dataItem.numero, auxFecha, this.comidaNum.id, this.comedorNum.id, this.estado]);
          } else {
            this.opened = true;
            this.mensajeError = tieneLimites.mensajeError;
          }
        }
        else {
          this.opened = true;
          this.mensajeError = "errReservaPasado";
        }

  }

  nuevaReserva2(fecha, e) {
    var codigoComedores2 = e.dataItem.codigoComedores2; //cogemos la mesa que tine ese numero
    var auxFecha = fecha.getFullYear() + "-" + fecha.getMonth() + "-" + fecha.getDate();
    var auxi = fecha; //correct date
    auxi.setHours(0);
    auxi.setMinutes(0);
    auxi.setSeconds(0);
    auxi.setMilliseconds(0);
    var libre: any = false;
    var anadirAReserva = false;
    var hoy = new Date();
    hoy.setHours(0);
    hoy.setMinutes(0);
    hoy.setSeconds(0);
    hoy.setMilliseconds(0);

        if (fecha.getTime() >= hoy.getTime()) {
          var tieneLimites = this.controlarLimites(anadirAReserva, fecha);
          if (tieneLimites.reservar) { //siempre saldrá a reservar porque es admin
            //si la puede reservar, le redirigimos
            var estado;
            if (e.dataItem.comensales == e.dataItem.reservado) {
              estado= 1;
            } else if (e.dataItem.reservado == 0) {
              estado = 2;
            } else {
              estado = 3;
            }  
            this.router.navigate(['/reservasAdmin/crear', e.dataItem.numero, auxFecha, this.comidaNum.id, this.comedorNum.id, estado]);
          } else {
            this.opened = true;
            this.mensajeError = tieneLimites.mensajeError;
          }
        }
        else {
          this.opened = true;
          this.mensajeError = "errReservaPasado";
        }


  }

  EliminarReserva() {
    // //Primero miramos si hay reservas
    // if (this.reserva2.length > 0) {// hay reservas, el admin elimina todas las de ese dia, comedor, y comida
    //   this.reservasService.updateEliminarReservaAdmin(this.fecha, this.comedorNum.id, this.comidaNum.id).subscribe((result) => {
    //     this.cargardatos();
    //   });
    // }
  }


  actualizar(prioridad, primeraVez) {

    this.idioma = this.user.idioma;

    //this.fecha es la fecha seleccionada.
    //CALENDARIO
    if (!primeraVez && prioridad == 1) {
      //Primero cargamos los comedores que estén activos
      var noTodosComedoresHabilitados = true;
      if (this.listItemsComedor.length > 0) { //si hay más de un comedor
        var activo;
        var element;
        for (var i = 0; i < this.listItemsComedor.length; i++) { //por cada comedor
          element = this.listItemsComedor[i];
          activo = false;
          if (this.listItemsComida.length > 0) { //si hay comidas
            this.listItemsComida.forEach(element2 => { //por cada comida
              //Comprobar si está activa
              activo = this.comprobarcalendarioactivo(this.fecha, element.id, element2.id);
              if (activo)
                noTodosComedoresHabilitados = false;
            });
          }
          if (activo) {
            this.diaActivo = true;
            this.comedorNum = element;
            break;
          }
        }
      }
      //Si no hay ningún comedor o comida activa, mostrar mensaje de error, sino comprobar la selección    
      if (noTodosComedoresHabilitados) {
        this.opened = true;
        this.mensajeError = "errReservaCalendario";
        this.diaActivo = false;
      } else {
        this.comprobarSeleccionActiva(prioridad == 1, this.comedorNum.id, this.comidaNum.id);
      }
    } else if (!primeraVez && prioridad == 0) {
      //Ahora cargamos las comidas que estén activas
      var noTodasComidasHabilitadas = true;
      if (this.listItemsComida.length > 0) { //si hay más de una comida
        var activo;
        var element;
        var auxarray = [...this.listItemsComida]; //así clonamos el array para que no se nos modifique
        auxarray.sort((a) => (a.id == this.comidaNum.id) ? -1 : 1); //y lo ordenamos por el que se haya seleccionado por defecto, pues sino mirará primero el primero de la lista y cambiará el por defecto
        for (var i = 0; i < auxarray.length; i++) { //por cada comida
          element = auxarray[i];
          activo = false;
          if (this.listItemsComedor.length > 0) { //si hay comedores
            this.listItemsComedor.forEach(element2 => { //por cada comedor
              //Comprobar si está activo
              activo = this.comprobarcalendarioactivo(this.fecha, element2.id, element.id);
              if (activo)
                noTodasComidasHabilitadas = false;
            });
          }
          if (activo) {
            this.diaActivo = true;
            this.comidaNum = element;
            break;
          }
        }
      }
      //Si no hay ningún comedor o comida activa, mostrar mensaje de error, sino comprobar la selección    
      if (noTodasComidasHabilitadas) {
        this.opened = true;
        this.mensajeError = "errReservaCalendario";
        this.diaActivo = false;
      } else {
        this.comprobarSeleccionActiva(prioridad == 1, this.comedorNum.id, this.comidaNum.id);
      }
    }
    else {
      //Primero cargamos los comedores que estén activos
      var noTodosComedoresHabilitados = true;
      if (this.listItemsComedor.length > 0) { //si hay más de un comedor
        var activo;
        var element;
        for (var i = 0; i < this.listItemsComedor.length; i++) { //por cada comedor
          element = this.listItemsComedor[i];
          activo = false;
          if (this.listItemsComida.length > 0) { //si hay comidas
            this.listItemsComida.forEach(element2 => { //por cada comida
              //Comprobar si está activa
              activo = this.comprobarcalendarioactivo(this.fecha, element.id, element2.id);
              if (activo)
                noTodosComedoresHabilitados = false;
            });
          }
          if (activo) {
            this.diaActivo = true;
            this.comedorNum = element;
            break;
          }
        }
      }

      //Ahora cargamos las comidas que estén activas
      var noTodasComidasHabilitadas = true;
      if (this.listItemsComida.length > 0) { //si hay más de una comida
        var activo;
        var element;
        var auxarray = [...this.listItemsComida]; //así clonamos el array para que no se nos modifique
        auxarray.sort((a) => (a.id == this.comidaNum.id) ? -1 : 1); //y lo ordenamos por el que se haya seleccionado por defecto, pues sino mirará primero el primero de la lista y cambiará el por defecto
        for (var i = 0; i < auxarray.length; i++) { //por cada comida
          element = auxarray[i];
          activo = false;
          if (this.listItemsComedor.length > 0) { //si hay comedores
            this.listItemsComedor.forEach(element2 => { //por cada comedor
              //Comprobar si está activo
              activo = this.comprobarcalendarioactivo(this.fecha, element2.id, element.id);
              if (activo)
                noTodasComidasHabilitadas = false;
            });
          }
          if (activo) {
            this.diaActivo = true;
            this.comidaNum = element;
            break;
          }
        }
      }
      //Si no hay ningún comedor o comida activa, mostrar mensaje de error, sino comprobar la selección    
      if (noTodosComedoresHabilitados || noTodasComidasHabilitadas) {
        this.opened = true;
        this.mensajeError = "errReservaCalendario";
        this.diaActivo = false;
      } else {
        this.comprobarSeleccionActiva(prioridad == 1, this.comedorNum.id, this.comidaNum.id);
      }
    }
  }

  comprobarcalendarioactivo(fecha, comedor, comida): any {
    if(this.user.gestionUsuarios==2){ //es admin, siempre podrá reservar
      return true;
    }
    if(!this.calendarioConfig)
      return true;
    var activo = false;
    var auxi = new Date(fecha);
    //Cogemos la regla de dias de este comedor y comida
    var reglaFiltrada = this.calendarioGlobal.data.filter(x => x.ano == auxi.getFullYear() && x.codcomida == comida && x.codcomedor == comedor);
    if (reglaFiltrada.length > 0) { //si hay datos del calendario
      var day = auxi.getDay();
      switch (day) {
        case 1: {
          activo = reglaFiltrada[0].dia1;
          break;
        }
        case 2: {
          activo = reglaFiltrada[0].dia2;
          break;
        }
        case 3: {
          activo = reglaFiltrada[0].dia3;
          break;
        }
        case 4: {
          activo = reglaFiltrada[0].dia4;
          break;
        }
        case 5: {
          activo = reglaFiltrada[0].dia5;
          break;
        }
        case 6: {
          activo = reglaFiltrada[0].dia6;
          break;
        }
        case 0: {
          activo = reglaFiltrada[0].dia7;
          break;
        }
      }
      if (reglaFiltrada[0].ano == auxi.getFullYear()) {
        //Cogemos la regla del calendario2 que se corresponda
        var reglaFiltrada2 = this.calendarioGlobal.data2.filter(x => x.codcalendario == reglaFiltrada[0].codigo);
        if (reglaFiltrada2.length > 0) { //si había alguna restricción
          //compobar si estas coinciden con nuestra fecha 
          var element;
          for(var i= 0; i< reglaFiltrada2.length; i++){
            element = reglaFiltrada2[i];
            var fechaIni = new Date(element.fechainicio.split("T")[0].split("-")[0], element.fechainicio.split("T")[0].split("-")[1]-1, element.fechainicio.split("T")[0].split("-")[2]);
            var fechaFin = new Date(element.fechafin.split("T")[0].split("-")[0], element.fechafin.split("T")[0].split("-")[1]-1, element.fechafin.split("T")[0].split("-")[2]);
            if((auxi.getTime() >= fechaIni.getTime() && auxi.getTime() <= fechaFin.getTime())){
              //el día actual está dentro del rango
              activo = reglaFiltrada2[0].activo;
              break;
            }
          }
        }
      }
    }
    else {
      activo = true;
    }
    return activo;
  }

  comprobarSeleccionActiva(conservarComida, codcomedor, codcomida) {
    if(this.user.gestionUsuarios==2){ //nunca debería entrar aquí, pero por si acaso
      this.diaActivo = true;
      this.cargardatos();
    }
    if (!this.comprobarcalendarioactivo(this.fecha, codcomedor, codcomida)) { //si el día no está activo
      if (conservarComida) {
        //Buscamos el comedor que esté activo preservando esa comida
        var hayActivo = false;
        var element;
        for (var i = 0; i < this.listItemsComedor.length; i++) {
          element = this.listItemsComedor[i];
          if (this.comprobarcalendarioactivo(this.fecha, element.id, codcomida)) {
            this.diaActivo = true;
            this.comedorNum = element;
            this.cargardatos();
            hayActivo = true;
          }
        }
        if (!hayActivo) {//no se puede preservar esa comida porque no está activa en ningún comedor
          //intentamos preservar el comedor
          hayActivo = false;
          for (var i = 0; i < this.listItemsComida.length; i++) {
            element = this.listItemsComida[i];
            if (this.comprobarcalendarioactivo(this.fecha, codcomedor, element.id)) {
              this.diaActivo = true;
              this.comidaNum = element;
              this.cargardatos();
              hayActivo = true;
            }
          }
          if (!hayActivo) {//teoricamente nunca entrará aquí, pero si lo hace, ha vuelto a fallar, por lo que no hay ningún comedor activo tampoco
            this.opened = true;
            this.mensajeError = "errReservaCalendario";
            this.diaActivo = false;
          }
        }
      }
      else {
        //Buscamos la que esté activa preservando ese comedor
        var hayActivo = false;
        var element;
        for (var i = 0; i < this.listItemsComida.length; i++) {
          element = this.listItemsComida[i];
          if (this.comprobarcalendarioactivo(this.fecha, codcomedor, element.id)) {
            this.diaActivo = true;
            this.comidaNum = element;
            this.cargardatos();
            hayActivo = true;
          }
        }
        if (!hayActivo) {//no se puede preservar ese comedor porque no está activo en ninguna comida
          //Buscamos el comedor que esté activo preservando esa comida
          hayActivo = false;
          for (var i = 0; i < this.listItemsComedor.length; i++) {
            element = this.listItemsComedor[i];
            if (this.comprobarcalendarioactivo(this.fecha, element.id, codcomida)) {
              this.diaActivo = true;
              this.comedorNum = element;
              this.cargardatos();
              hayActivo = true;
            }
          }
          if (!hayActivo) {//teoricamente nunca entrará aquí, pero si lo hace, ha vuelto a fallar, por lo que no hay ninguna comida activa tampoco
            this.opened = true;
            this.mensajeError = "errReservaCalendario";
            this.diaActivo = false;
          }
        }
      }
    }
    else { //si lo está
      this.diaActivo = true;
      this.cargardatos();
    }
  }



  close() {
    this.opened = false;
  }

  colorLinea(context: RowClassArgs) {
    var item = context.dataItem;
    if (item.reservado === 0) {
      return { reservaVerde: true };
    } else if (item.reservado >= item.comensales) {
      return { reservaRojo: true };
    } else {
      return { reservaNaranja: true }
    }
  }

  valueChangeVerCancelados() {
    if (this.verCanceladas)
      this.reservaAuxiliar = this.reservaCanceladas;
    else
      this.reservaAuxiliar = this.reserva2;
  }

  // Month in JavaScript is 0-indexed (January is 0, February is 1, etc), 
  // but by using 0 as the day it will give us the last day of the prior
  // month. So passing in 1 as the month number will return the last day
  // of January, not February
  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  cambiarDia() {
    if (!this.comprobarcalendarioactivo(this.fecha, this.comedorNum.id, this.comidaNum.id)) {//si la que se ha seleccionado no está activa
      this.diaActivo == false;
     // this.cargarCalendario(1, false);
    }
    else {
      this.diaActivo = true;
      this.cargardatos();
    }
  }

  cambiarCombo(prioridad, primeraVez, e) {
    if (e != undefined) {
      if (prioridad == 1) { //es la comida, la arreglamos
        this.comidaNum = this.listItemsComida.filter(x => x.id == e)[0];
      } else if ( prioridad == 0 ) {
        this.comedorNum = this.listItemsComedor.filter(x => x.id == e)[0];
      }
    }
    
    if (!this.comprobarcalendarioactivo(this.fecha, this.comedorNum.id, this.comidaNum.id)) {//si la que se ha seleccionado no está activa
      this.diaActivo == false;
     // this.cargarCalendario(prioridad, primeraVez);
    }
    else {
      this.diaActivo = true;
      this.cargardatos();
    }
  }
}
