import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Usuario } from '@app/_models';
import { MenuService, UsuariosService, AlertService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { filter, first } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { RestauranteService } from '@app/_services/restaurante.service';


@Component({
  selector: 'app-reserva',
  templateUrl: './reserva.component.html',
})
export class ReservaComponent {
  public value: Date = new Date(2021, 2, 23);
  public listItemsComida: any = [];
  public listItemsComedor: any = [];
  public comedorNum: any;
  public comidaNum: any;
  public listItemsEstado: any = [];
  public EstadoNum: any = 1;
  dataUsuarios: Usuario[];
  interval: number;
  public id: number;
  public libres: number;
  public comensales: number;
  public mesa: any;
  public imagenFondo: any;
  public fondo: any;
  public numerosMesa: Array<Number>;
  public mesas: any;
  user = this.usuariosService.userValue;
  public nombreSocio = this.user.nombre + " " + this.user.apellido1 + " " + this.user.apellido2;
  public socioId = this.user.id;
  public fecha: Date = new Date();
  public fecha2: Date = new Date();
  public fechaIni: Date = new Date();
  public fechaIni2: any;
  public fechaFin: Date = new Date();
  public fechaFin2: any;
  public comensalesMax: number;
  public reserva2: any;
  public reservado: any;
  public reserva: any;
  public listaIds: any;
  public codigoMesa: any;
  public color: any;
  public plazasLibres: number;
  public plazasLibresTotal: number;
  public idioma: string;
  public comidaIdioma: string;
  public comedorIdioma: string;
  public configDatos: any;
  public mesasReservadas: any;
  public mesasReservadasTotales: any;
  public opened = false;
  public aperturaReservas: any;
  public numDiasCalendario: any;
  public diasSinLimitacion: any;
  public limiteReservas: any;
  public mesasPorReserva: any;
  public comensalesPorReserva: any;
  public diaSemana: any;
  public diaMes: any;
  public maximosComensales: number = 0;
  public mensajeError: any;
  public reservasRealizadas: any;
  public cantidadReserva: any;
  public reservaActual: number = -1;
  public reservaPorMesas: any;
  public reservaPorComensalesCompletas: any;
  public limitlunesjueves: any;
  public comensalesReservados: any;

  public reservasMesa: any;

  public socios: any = [];
  public socioSeleccionado: any;

  public calendarioConfig: any;

  //Params
  idRestaurante:number;


  
  constructor(private usuariosService: UsuariosService,
    private menuService: MenuService,
    private restauranteService: RestauranteService,
    public router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private cdref: ChangeDetectorRef,
    private alertService: AlertService) {
    this.inicializarDesplegables();
  }
  


  inicializarDesplegables() {
    this.listItemsComida = [];
    this.listItemsComedor = [];

    //Inicializamos los desplegables
    var r1, r2 = false;
    this.restauranteService.getComedores(this.idRestaurante).subscribe((result) => {
      var auxi = result.data;
      if (result.data.length > 0) {
        auxi.forEach(element => { //por cada comedor
          if (this.user.idioma == "es")
            this.listItemsComedor.push({ id: element.codigo, value: element.nombre1 });
          else if (this.user.idioma == "eu")
            this.listItemsComedor.push({ id: element.codigo, value: element.nombre2 })
        });
        this.comedorNum = this.listItemsComedor[0];
      }
      r1 = true;
      if (r1 && r2)
        this.cargarDatosPrimero();
    });

    this.restauranteService.GetComidasFromRestaurante(this.idRestaurante.toString()).subscribe((result) => {
      var auxi = result.data;
      if (result.data.length > 0) {
        auxi.forEach(element => { //por cada comedor
            this.listItemsComida.push({ id: element.codigo, value: element.nombre });
        });
        this.comidaNum = this.listItemsComida[0];
      }
      r2 = true;
      if (r1 && r2)
        this.cargarDatosPrimero();
    });

  }

  cargarDatosPrimero(){
    this.listItemsEstado.push({ id: 1, value: this.translateService.instant('reservado') });
    this.listItemsEstado.push({ id: 2, value: this.translateService.instant('libre') });
    this.listItemsEstado.push({ id: 3, value: this.translateService.instant('parcialmentereservado') });
    
    this.id = this.route.snapshot.params['id'];
    this.route.params.forEach((urlParams) => {
      var aux = urlParams['fecha']
      this.fecha = new Date();
      this.fecha.setFullYear(aux.split("-")[0]);
      this.fecha.setMonth(aux.split("-")[1]);
      this.fecha.setDate(aux.split("-")[2]);
      this.fecha.setHours(0);
      this.fecha.setMinutes(0);
      this.fecha.setSeconds(0);
      this.comidaNum = this.listItemsComida.filter(x=>x.id = Number(urlParams['comida']))[0];
      this.comedorNum = this.listItemsComedor.filter(x=>x.id = Number(urlParams['comedor']))[0];
    });
    this.cargarDatos();
  }

  ngAfterViewInit() {

    this.cdref.detectChanges();
  }

  ngOnInit(): void {
    
  }

  cargarDatos() {
    this.menuService.titulo = this.translateService.instant('reservaAdmin');
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.menuService.titulo = this.translateService.instant('reservaAdmin');
    });

    this.idioma = this.user.idioma;

    this.fecha2 = new Date(this.fecha);
    this.idioma = this.user.idioma;

    var auxi = new Date(this.fecha); //correct date
    auxi.setHours(0);
    auxi.setMinutes(0);
    auxi.setSeconds(0);
    // this.reservasService.getMesasObjetos(auxi, this.comidaNum.id, this.comedorNum.id).subscribe((result) => {
    //   for (var i = 0; i < result.length; i++) {
    //     let ObjectURL = 'data:image/jpeg;base64,' + result[i].mesa;
    //     result[i]['imagen'] = this.sanitizer.bypassSecurityTrustUrl(ObjectURL);
    //   }
    //   this.reserva = result.filter(x => x.numero == this.id)[0];
    //   this.codigoMesa = this.reserva.codigoComedores2;
    //   this.mesa = this.reserva.mesa;
    //   this.createImageFromBase64(this.mesa);
    //   if (this.reserva.comensales == this.reserva.reservado) {
    //     this.EstadoNum = 1;
    //     this.color = "3px solid tomato";
    //   } else if (this.reserva.reservado == 0) {
    //     this.EstadoNum = 2;
    //     this.color = "3px solid #22c48c";
    //   } else {
    //     this.EstadoNum = 3;
    //     this.color = "3px solid orange";
    //   }
    //   this.comensalesMax = this.reserva.comensales;
    //   this.comensales = this.comensalesMax;
    //   this.plazasLibres = this.reserva.comensales - this.reserva.reservado;
    //   this.plazasLibresTotal = this.reserva.comensales - this.reserva.reservado;
    // });

    this.restauranteService.getMesasComedor(this.comedorNum.id).subscribe((result) => {
      this.mesas = result.data[0].column1;
      var contId = [];
      for (var i = 1; i < this.mesas + 1; i++) {
        contId.push(i);
      }
      this.listaIds = contId;
    });

    // this.configService.GetDatos().subscribe((result) => {
    //   this.configDatos = result.data;
    //   this.reservaPorMesas = result.data[0].reservapormesas;
    //   this.reservaPorComensalesCompletas = result.data[0].reservaporcomensalescompletas;
    //   this.aperturaReservas = result.data[0].aperturamesas;
    //   this.numDiasCalendario = result.data[0].ndiascalendario;
    //   this.limiteReservas = result.data[0].limitereservas;
    //   this.diasSinLimitacion = result.data[0].ndiassinlimitacion;
    //   this.comensalesPorReserva = result.data[0].nmaxcomensales;
    //   this.mesasPorReserva = result.data[0].nmaxmesas;
    //   this.limitlunesjueves = result.data[0].aplicarlim;
    //   this.fechaFin.setDate(this.fechaIni.getDate());
    //   this.fechaFin.setDate(this.fechaIni.getDate() + this.numDiasCalendario);
    //   this.calendarioConfig = result.data[0].calendario;
    //   //this.controlarApertura();

    //   //auxi es la fecha transformada
    //   this.reservasService.cuentaMesasCliente(auxi, this.comedorNum.id, this.comidaNum.id, this.user.id).subscribe((result) => {
    //     this.mesasReservadas = result.data[0].cont;
    //   });
    //   //auxi es la fecha transformada
    //   this.reservasService.cuentaComensalesCliente(auxi, this.comedorNum.id, this.comidaNum.id, this.user.id).subscribe((result) => {
    //     this.comensalesReservados = result.data[0].cont;
    //   });
    // });

    // //auxi es la fecha transformada
    // this.reservasService.getReservasCanceladas(auxi, this.comidaNum.id, this.comedorNum.id).subscribe((result) => {
    //   this.reserva2 = result.reservadas;
    //   this.reservasMesa = result.reservadas.filter(x=>Number(x.numeromesa)==Number(this.id));
    // });

    // this.sociosService.GetSocios().subscribe((result) => {
    //   this.socios = result;
    //   this.socios.forEach(element => {
    //     if(element.nombre2.trim().length == 0)
    //       element.nombreAux = element.nombre1;
    //     else
    //       element.nombreAux = element.nombre1 + " / " + element.nombre2;
    //   });
    // });
  }

  createImageFromBase64(imagen) {
    let objectURL = 'data:image/jpeg;base64,' + imagen;
    this.imagenFondo = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  InsertarReserva() {
    if(this.socioSeleccionado == null || this.socioSeleccionado == []){
      this.opened = true;
      this.mensajeError = "errElegirSocio";
    }else{
      this.fecha2.setHours(0);
      this.fecha2.setMinutes(0);
      this.fecha2.setSeconds(0);
      this.fecha2.setMilliseconds(0);
      var libre = true;
      var anadirAReserva = false;
      var auxFecha = new Date();
      auxFecha.setHours(0);
      auxFecha.setMinutes(0);
      auxFecha.setSeconds(0);
      auxFecha.setMilliseconds(0);
      //Primero comprobamos que siga libre, por si ha sido reservada desde otra fuente mientras hacíamos click
      var auxi = new Date(this.fecha); //correct date
      auxi.setHours(0);
      auxi.setMinutes(0);
      auxi.setSeconds(0);

      console.log('Creando Reserva...');
  }
}

  controlarLimites(anadirAReserva: any): any {
    var auxFecha = this.fecha2;
    auxFecha.setHours(0);
    auxFecha.setMinutes(0);
    auxFecha.setSeconds(0);
    auxFecha.setMilliseconds(0);
    var hoy = new Date();
    hoy.setHours(0);
    hoy.setMinutes(0);
    hoy.setSeconds(0);
    hoy.setMilliseconds(0);
    var auxi = { reservar: true, mensajeError: "" }; //Esto es lo que nos va a devolver la función, true o false para saber si se puede hacer la reserva y el mensaje de error en caso de que no
    if(this.user.gestionUsuarios==2){//es admin, no tiene limites
      return auxi;
    }
    if (this.limitlunesjueves == undefined) { //si la variable no existe en la base de datos la pones como no activa
      this.limitlunesjueves = false;
    }
    if (!this.limitlunesjueves) { //si está activa mirar si estamos entre lunes y jueves
      if (auxFecha.getDay() >= 1 && auxFecha.getDay() <= 4) { //sino devolver false
        return auxi;
      }
    }
    if (this.diasSinLimitacion > 0) { //si tenemos días limitados para reservar
      var auxLimite = new Date();
      auxLimite.setHours(0);
      auxLimite.setMinutes(0);
      auxLimite.setSeconds(0);
      auxLimite.setMilliseconds(0);
      auxLimite.setDate(hoy.getDate() + (this.diasSinLimitacion - 1));
      if (auxFecha.getTime() >= hoy.getTime() && auxFecha.getTime() <= auxLimite.getTime()) {//si está entre los límites se puede reservar
        return auxi;
      }
    }
    if (this.aperturaReservas > 0 && this.limiteReservas > 0) {//Si la apertura de reservas es distinta de siempre y existe un máximo de reservas
      if (!anadirAReserva) {//Si no hay que añadirla a una reserva, hay que crear una nueva reserva
        if (this.reservasRealizadas >= this.limiteReservas) { //comprobar que no se ha superado ya el limite de reservas
          auxi.reservar = false;
          auxi.mensajeError = "errLimReserva";
        }
      }
    }
    if (!anadirAReserva) {//Si no hay que añadirla a una reserva, hay que crear una nueva reserva
      if (this.mesasReservadas >= this.mesasPorReserva) { //si las mesas reservadas actualmente por el cliente ya son mayores o igual que la cantidad máxima permitida por reserva
        auxi.reservar = false;
        auxi.mensajeError = "errMaxMesas";
      }
      //Ahora miramos los comensales
      if (this.comensalesReservados >= this.comensalesPorReserva) { //si los comensales reservadas actualmente por el cliente ya son mayores o igual que la cantidad máxima permitida por reserva
        auxi.reservar = false;
        auxi.mensajeError = "errMaxComensales";
      }
    } else { //si hay que añadirla a una reserva ya realizada previamente
      //hay que contar los comensales que se tendrían con esa mesa, y que habría una mesa más, y ver si no superan los límites
      var mesas = this.mesasReservadas + 1;
      if (mesas > this.mesasPorReserva) { //no se puede reservar por superarse limite de mesas
        auxi.reservar = false;
        auxi.mensajeError = "errMaxMesas";
      }
      //Calculamos los comensales
      var comensales = this.comensalesReservados + this.plazasLibres; //plazas libres son los comensales a reservar
      if (comensales > this.comensalesPorReserva) {
        auxi.reservar = false;
        auxi.mensajeError = "errMaxComensales";
      }
    }
    return auxi;
  }

  // Month in JavaScript is 0-indexed (January is 0, February is 1, etc), 
  // but by using 0 as the day it will give us the last day of the prior
  // month. So passing in 1 as the month number will return the last day
  // of January, not February
  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  close() {
    this.opened = false;
  }

  EliminarReserva(dataItem) {
      console.log('Eliminando Reserva...');
  }

  actualizar(codigo) {
    this.router.navigate(['/reservasAdmin/crear', codigo, this.fecha.getFullYear() + "-" + this.fecha.getMonth() + "-" + this.fecha.getDate() , this.comidaNum.id, this.comedorNum.id, this.EstadoNum]);
    this.cargarDatos();
  }

  Atras() {
    this.router.navigateByUrl('/restaurante-reservas');
  }

  public handleValue(selected) {
    if(selected.length>1){
      this.socioSeleccionado = [selected[1]]; //porque este será el último seleccionado
    }else if(selected.length==1){
      this.socioSeleccionado = [selected[0]]; 
    }
  }
}