<div class="row">
  <div class="col-xl-4 col-md-6">
    <div class="card">
      <!--GESTION AGENTE-->
      <div class="card-header">
        <h3>{{ "recintos.lbl_editsala" | translate }}</h3>
      </div>
      <div class="card-body">
        <div class="form-group">
          <!--Boton Gestion Reservas-->
          <button
            type="button"
            class="btn btn-info btn-sm mr-1"
            (click)="btnGestionReservas()"
          >
            {{ "recintos.gestionreservas" | translate }}
          </button>
        </div>

        <div class="form-group">
          <!--Lista idiomas-->

          <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
          </kendo-label>

          <div class="caja">
            <kendo-dropdownlist
              #dropdownI
              [data]="listaIdiomas"
              textField="nombre"
              valueField="codigo"
              [(ngModel)]="selectedIdioma"
              (valueChange)="loadSala($event, dropdownI)"
            ></kendo-dropdownlist>
          </div>
        </div>

        <div class="form-group">
          <label>{{ "recintos.lbl_nombre_sala" | translate }}</label>
          <div class="caja">
            <!--Titulo-->
            <kendo-textbox
              placeholder="{{ 'recintos.ph_nombre' | translate }}"
              [clearButton]="true"
              [(ngModel)]="nombreSala"
            >
              <ng-template kendoTextBoxPrefixTemplate> </ng-template>
            </kendo-textbox>
          </div>
        </div>

        <!--Cuerpo-->
        <div class="form-group">
          <kendo-label text="{{ 'recintos.lbl_descrip_sala' | translate }}">
            <textarea
              kendoTextArea
              style="resize: none"
              rows="5"
              [(ngModel)]="descripcionSala"
              placeholder="{{ 'recintos.ph_descripcion' | translate }}"
            >
            </textarea>
          </kendo-label>
        </div>
        <!-- Superficie-->

        <div class="form-group">
          <kendo-label text="{{ 'recintos.lbl_superficie' | translate }}">
            <kendo-numerictextbox
              format="n3"
              [step]="0.1"
              [min]="0"
              [(ngModel)]="superficieSala"
            ></kendo-numerictextbox>
          </kendo-label>
        </div>

        <!-- Dias ANtelacion-->
        <div class="form-group">
          <kendo-label text="{{ 'recintos.lbl_numdias' | translate }}">
            <kendo-numerictextbox
              format="n0"
              [step]="1"
              [min]="0"
              [(ngModel)]="numDiasReserva"
            ></kendo-numerictextbox>
          </kendo-label>
        </div>

        <!-- horas max Reserva-->
        <div class="form-group">
          <kendo-label text="{{ 'recintos.lbl_horasmax' | translate }}">
            <kendo-numerictextbox
              format="n0"
              [step]="1"
              [min]="0"
              [(ngModel)]="horasReservableDia"
            ></kendo-numerictextbox>
          </kendo-label>
        </div>

        <!-- Aforo Sala-->
        <div class="form-group">
          <kendo-label text="{{ 'recintos.lbl_aforo' | translate }}">
            <kendo-numerictextbox
              format="n0"
              [step]="1"
              [min]="0"
              [(ngModel)]="aforo"
            ></kendo-numerictextbox>
          </kendo-label>
        </div>

        <!--Imagen Publicacion-->
        <label class="image-upload-container btn btn-bwm">
          <span>Select Image</span>
          <input
            #imageInput
            type="file"
            accept="image/*"
            (change)="imagePreview($event)"
          />
        </label>

        <div *ngIf="filePath && filePath !== ''">
          <img [src]="filePath" style="height: 200px" />
        </div>

        <!--CheckBox Activo-->
        <div class="form-group">
          <kendo-label
            class="k-checkbox-label"
            text="{{ 'recintos.recintoactivo' | translate }}"
          ></kendo-label>
          <div class="caja">
            <kendo-switch
              [(ngModel)]="salaActiva"
              onLabel="{{ 'switch.activo' | translate }}"
              offLabel="{{ 'switch.inactivo' | translate }}"
            ></kendo-switch>
          </div>

          <!-- <zitu-selector-usuarios
          (listaUsuarios)="getUsuariosSeleccionados($event)"
          (listaSeleccionados)="listaSeleccionados"
        >
        </zitu-selector-usuarios> -->
        </div>

        <div class="form-group">
          <!--Boton Publicar-->
          <button
            type="button"
            class="btn btn-success btn-sm mr-1"
            (click)="btnActualizarSala()"
          >
            {{ "recintos.btn_actualizar_sala" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm mr-1"
            (click)="btnCancelar()"
          >
            {{ "botones.cancelar" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Columna 2 -->
  <div class="col-xl-4 col-md-6">
    <div class="card">
      <div class="card-header">
        <h3>{{ "recintos.lbl_crearsala" | translate }}</h3>
      </div>
      <div class="card-body">
        <div class="form-group">
          <kendo-label
            class="k-checkbox-label"
            text="{{ 'recintos.lbl_privado' | translate }}"
          ></kendo-label>
          <div class="caja">
            <kendo-switch
              [(ngModel)]="salaPrivada"
              onLabel="{{ 'switch.activo' | translate }}"
              offLabel="{{ 'switch.inactivo' | translate }}"
            ></kendo-switch>
          </div>

          <kendo-label
            class="k-checkbox-label"
            text="{{ 'recintos.lbl_tarifa' | translate }}"
          ></kendo-label>
          <div class="caja">
            <kendo-switch
              [(ngModel)]="salaHasTarifa"
              onLabel="{{ 'switch.activo' | translate }}"
              offLabel="{{ 'switch.inactivo' | translate }}"
            ></kendo-switch>
          </div>

          <kendo-label
            class="k-checkbox-label"
            text="{{ 'recintos.lbl_confirmar' | translate }}"
          ></kendo-label>
          <div class="caja">
            <kendo-switch
              [(ngModel)]="salaMustConfirmar"
              onLabel="{{ 'switch.activo' | translate }}"
              offLabel="{{ 'switch.inactivo' | translate }}"
            ></kendo-switch>

            <div class="form-group">
              <kendo-label text="{{ 'recintos.lbl_correos' | translate }}">
                <textarea
                  kendoTextArea
                  style="resize: none"
                  rows="5"
                  [(ngModel)]="correosEnviarReserva"
                  placeholder="{{ 'recintos.ph_correos' | translate }}"
                >
                </textarea>
              </kendo-label>
            </div>

            <div class="form-group">
              <kendo-label text="{{ 'organizaciones.latitud' | translate }}">
                <kendo-textbox [(ngModel)]="latitud" placeholder="Ej: -12.02">
                </kendo-textbox>
              </kendo-label>

              <kendo-label text="{{ 'organizaciones.latitud' | translate }}">
                <kendo-textbox [(ngModel)]="longitud" placeholder="Ej: -12.02">
                </kendo-textbox>
              </kendo-label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Columna 3 -->
  <div class="col-xl-4 col-md-6">
    <div class="card">
      <div class="card-header">
        <h3>{{ "recintos.lbl_crearsala" | translate }}</h3>
      </div>
      <div class="card-body"></div>
      <h4>Admins</h4>
      <!--Grid Admins-->
      <kendo-grid
        [kendoGridBinding]="listaAdminsSala"
        kendoGridSelectBy="id"
        filterable="menu"
        [pageSize]="10"
        [pageable]="true"
        [navigable]="true"
        [sortable]="true"
        [filterable]="true"
        scrollable="virtual"
        [rowHeight]="36"
        [height]="500"
        [resizable]="true"
        [selectable]="{
          cell: false,
          checkboxOnly: false,
          drag: true,
          enabled: true,
          mode: 'multiple'
        }"
      >
        <!--checkbox-->
        <kendo-grid-checkbox-column width="7%">
          <ng-template kendoGridHeaderTemplate>
            <input
              class="k-checkbox"
              id="selectAllCheckboxId"
              kendoGridSelectAllCheckbox
              [state]="selectAllState"
              (selectAllChange)="onSelectAllChange($event)"
            />
            <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
          </ng-template>
        </kendo-grid-checkbox-column>

        <kendo-grid-column
          field="nombreUsuario"
          title="{{ 'organizaciones.nombre' | translate }}"
          width="10%"
        ></kendo-grid-column>
        <kendo-grid-column
          field="email"
          title="{{ 'agentes.email' | translate }}"
          width="10%"
        ></kendo-grid-column>
        <kendo-grid-column
          title="{{ 'agentes.asignado' | translate }}"
          width="20%"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <input
              type="checkbox"
              [checked]="dataItem.asignado"
              id="comentarC{{ dataItem.id }}"
              [(ngModel)]="dataItem.asignado"
              [ngModelOptions]="{ standalone: true }"
            />
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>

      <!-- Grid Usuarios-->
      <div *ngIf="salaPrivada">
        <h4>Usuarios</h4>

        <kendo-grid
          [kendoGridBinding]="listaUsuariosSala"
          kendoGridSelectBy="id"
          filterable="menu"
          [pageSize]="10"
          [pageable]="true"
          [navigable]="true"
          [sortable]="true"
          [filterable]="true"
          scrollable="virtual"
          [rowHeight]="36"
          [height]="500"
          [resizable]="true"
          [selectable]="{
            cell: false,
            checkboxOnly: false,
            drag: true,
            enabled: true,
            mode: 'multiple'
          }"
        >
          <!--checkbox-->
          <kendo-grid-checkbox-column width="7%">
            <ng-template kendoGridHeaderTemplate>
              <input
                class="k-checkbox"
                id="selectAllCheckboxId"
                kendoGridSelectAllCheckbox
                [state]="selectAllState"
                (selectAllChange)="onSelectAllChange($event)"
              />
              <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
            </ng-template>
          </kendo-grid-checkbox-column>

          <kendo-grid-column
            field="nombreUsuario"
            title="{{ 'organizaciones.nombre' | translate }}"
            width="10%"
          ></kendo-grid-column>
          <kendo-grid-column
            field="email"
            title="{{ 'agentes.email' | translate }}"
            width="10%"
          ></kendo-grid-column>
          <kendo-grid-column
            title="{{ 'agentes.asignado' | translate }}"
            width="20%"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <input
                type="checkbox"
                [checked]="dataItem.asignado"
                id="comentarC{{ dataItem.id }}"
                [(ngModel)]="dataItem.asignado"
                [ngModelOptions]="{ standalone: true }"
              />
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
  </div>
</div>
