<div class="row">
  <div class="col-6">
    <span>Choose moodule type:</span>
    <kendo-dropdownlist [data]="tiposModulo" [value]="selectedTipo"  [(ngModel)]="selectedTipo" valuePrimitive="true" style="background-color: white !important;"></kendo-dropdownlist>
  </div>
</div>
<div class="row">
  <app-modulo-directo *ngIf="selectedTipo === 'directo'"></app-modulo-directo>
  <app-modulo-html *ngIf="selectedTipo === 'html'"></app-modulo-html>
  <app-modulo-categoria *ngIf="selectedTipo === 'categoria'" ></app-modulo-categoria>
  <app-modulo-url *ngIf="selectedTipo === 'URL'"></app-modulo-url>
</div>
