import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Recinto, Reserva, Sala } from '@app/_models/recinto';
import { environment } from '@environments/environment';
import { SchedulerEvent } from '@progress/kendo-angular-scheduler';
import { Observable } from 'rxjs';
import {
  CalendarioLaboral,
  Comedor,
  ComidaPersonalizadaDia,
  ComidaTipo,
  ConfiguracionRestaurante,
  Mesa,
  ReservaPeticion,
  ReservaRestaurante,
  ReservaRestaurantePeticion,
  Restaurante,
} from '@app/_models/restaurante';
const baseUrl = `${environment.apiUrl}/restaurante`;

@Injectable({
  providedIn: 'root',
})
export class RestauranteService {
  constructor(private http: HttpClient) {}

  //Restaurantes
  public getRestaurantesFromEnte(idOrg: string): Observable<any> {
    return this.http.get(baseUrl + '/restaurantes/' + idOrg);
  }
  public GetRestauranteByIdLang(id: number, lang: number): Observable<any> {
    return this.http.get(baseUrl + '/restaurantes/get/' + id + '/' + lang);
  }

  public crearRestaurante(restaurante: Restaurante): Observable<any> {
    return this.http.post(
      baseUrl + '/createrestaurante/',
      JSON.stringify(restaurante),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public updateRestaurante(restaurante: Restaurante): Observable<any> {
    return this.http.post(
      baseUrl + '/updaterestaurante/',
      JSON.stringify(restaurante),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  deleteRestaurantes(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminarrestaurantes', formData, {
      withCredentials: true,
    });
  }
  //Comidas Tipos
  public GetComidasFromRestaurante(idRestaurante: string): Observable<any> {
    return this.http.get(baseUrl + '/comida/' + idRestaurante);
  }

  public getComidaByIdLang(idcomida: number, lang: number): Observable<any> {
    return this.http.get(baseUrl + '/comida/get/' + idcomida + '/' + lang);
  }

  public crearComidaTipo(comida: ComidaTipo): Observable<any> {
    comida.horaInicio = new Date(
      this.dateToYYYYMMDDtHHmmSSz(comida.horaInicio)
    );
    comida.horaFin = new Date(this.dateToYYYYMMDDtHHmmSSz(comida.horaFin));
    return this.http.post(baseUrl + '/createcomida/', JSON.stringify(comida), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  public updateComidaTipo(comida: ComidaTipo): Observable<any> {
    comida.horaInicio = new Date(
      this.dateToYYYYMMDDtHHmmSSz(comida.horaInicio)
    );
    comida.horaFin = new Date(this.dateToYYYYMMDDtHHmmSSz(comida.horaFin));
    return this.http.post(baseUrl + '/updatecomida/', JSON.stringify(comida), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  deleteComidasTipos(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminar', formData, {
      withCredentials: true,
    });
  }

  public borrarComidasPersonalizadasFechas(listaComidasPersonalizada): Observable<any> {
    return this.http.post(baseUrl + '/borrarcalendariocomidas/', JSON.stringify(listaComidasPersonalizada), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }
  
  public crearCalendarioComidasFechas(listaComidasPersonalizada): Observable<any> {
    return this.http.post(baseUrl + '/crearcalendariocomidas/', JSON.stringify(listaComidasPersonalizada), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  public getComidasCalendarioFecha(comida:ComidaPersonalizadaDia): Observable<any> {
    return this.http.post(baseUrl + '/getcomidastipofecha/', JSON.stringify(comida), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  //COMEDORES
  public getComedores(idRestaurante: number): Observable<any> {
    return this.http.get(baseUrl + '/comedores/' + idRestaurante);
  }

  public getComedoresByIdLang(id: number, lang: number): Observable<any> {
    return this.http.get(baseUrl + '/comedores/get/' + id + '/' + lang);
  }

  public createComedor(comedor: Comedor): Observable<any> {
    return this.http.post(
      baseUrl + '/createcomedor/',
      JSON.stringify(comedor),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public updateComedor(comedor: Comedor): Observable<any> {
    return this.http.post(
      baseUrl + '/updatecomedor/',
      JSON.stringify(comedor),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  deleteComedores(data?: any): Observable<any> {
    var formData: any = new FormData();
    formData.append('codigos', data);
    return this.http.post<JSON>(baseUrl + '/eliminarcomedores', formData, {
      withCredentials: true,
    });
  }

  // PERFILES
  public getPerfiles(idComerdor: number): Observable<any> {
    return this.http.get(baseUrl + '/perfiles/' + idComerdor );
  }

  public getComensalesMesasPerfil(idPerfil: number): Observable<any> {
    return this.http.get(baseUrl + '/getcomensalesmesas/' + idPerfil);
  }

  public createPerfil(nombrePerfil: string, idComedor: number, comensales: number, mesas: number, isSeleccionado: boolean): Observable<any>{
    return this.http.post(baseUrl + '/createperfil', {nombrePerfil, idComedor, comensales, mesas, isSeleccionado} , {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8'
      }),
      withCredentials: true
    });
  }

  public deletePerfil(idPerfil: number) {
    return this.http.post(baseUrl + '/eliminarperfil' , idPerfil, {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8'
      }),
      withCredentials: true
    });
  }
  //MESAS
  public getMesas(idRestaurante: number, idComedor: number): Observable<any> {
    return this.http.get(baseUrl + '/mesas/' + idRestaurante + "/" + idComedor);
  }

  public getMesasPlano(idComedor: number, idPerfil: number): Observable<any> {
    return this.http.get(baseUrl + '/mesasPlano/' + idComedor + "/" + idPerfil);
  }

  public getMesaByIdLang(idMesa: number,lang:number): Observable<any> {
    return this.http.get(baseUrl + '/mesas/get/' + idMesa + '/'+ lang);
  }

  public createMesa(mesa: Mesa): Observable<any> {
    return this.http.post(baseUrl + '/createmesa/', JSON.stringify(mesa), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  public updateMesa(mesa: Mesa): Observable<any> {
    return this.http.post(baseUrl + '/updatemesa/', JSON.stringify(mesa), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  deleteMesas(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminarmesas', formData, {
      withCredentials: true,
    });
  }
  //MESAS COMEDOR
  public getMesasComedor(idComedor: number): Observable<any> {
    return this.http.get(baseUrl + '/comedor/mesas/' + idComedor);
  }

  deleteMesasComedor(idComedor, idPerfil): Observable<any> {
    return this.http.post<JSON>(baseUrl + '/deleteMesasComedor', { idComedor, idPerfil }, { withCredentials: true });
  }

  setMesasComedor(dt) {
    return this.http.post<JSON>(`${baseUrl}/setMesasComedor`, { dt }, { withCredentials: true });
  }

  deleteOrdenMesasComedor(idComedor, idPerfil): Observable<any> {
    return this.http.post<JSON>(baseUrl + '/deleteOrdenMesasComedor', { idComedor, idPerfil }, { withCredentials: true });
  }

  setOrdenMesasComedor(dt) {
    return this.http.post<JSON>(`${baseUrl}/setOrdenMesasComedor`, { dt }, { withCredentials: true });
  }

  insertMesasOrden(mesas) {
    return this.http.post<JSON>(baseUrl + "/insertmesasorden", {mesas}, {withCredentials: true});
  }
  //RESERVAS
  public getReservasFromComedor(peticion: ReservaPeticion): Observable<any> {
    peticion.fechaIni = new Date(
      this.dateToYYYYMMDDtHHmmSSz(peticion.fechaIni)
    );
    peticion.fechaFin = new Date(
      this.dateToYYYYMMDDtHHmmSSz(peticion.fechaFin)
    );
    return this.http.post(baseUrl + '/getreservas/', JSON.stringify(peticion), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  public getReservasActuales(
    peticion: ReservaRestaurantePeticion
  ): Observable<any> {
    peticion.fecha = new Date(this.dateToYYYYMMDDtHHmmSSz(peticion.fecha));
    return this.http.post(
      baseUrl + '/getreservasfecha/',
      JSON.stringify(peticion),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public getReservasCanceladas(
    peticion: ReservaRestaurantePeticion
  ): Observable<any> {
    peticion.fecha = new Date(this.dateToYYYYMMDDtHHmmSSz(peticion.fecha));
    return this.http.post(
      baseUrl + '/getreservascanceladas/',
      JSON.stringify(peticion),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public getReservasProximas(idRestaurante: number): Observable<any> {
    return this.http.get(baseUrl + '/getreservasproximas/' + idRestaurante);
  }
  public getReservasHistorial(idRestaurante: number): Observable<any> {
    return this.http.get(baseUrl + '/getreservashistorial/' + idRestaurante);
  }

  public cancelarReserva(idReserva: number): Observable<any> {
    return this.http.get(baseUrl + '/cancelarreserva/' + idReserva);
  }

  public actualizarReserva(reserva:any): Observable<any> {
    reserva.fecha = new Date(this.dateToYYYYMMDDtHHmmSSz(reserva.fechaCompleta));
    return this.http.post(
      baseUrl + '/updateservawweb/',
      JSON.stringify(reserva),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  /**
   * actualizarGestion
   */
  public actualizarGestion(reserva:any):  Observable<any>{
    // Transformacion del parametro al tipo correspondiente 
    let reservaRestaurante: ReservaRestaurante = new ReservaRestaurante();
    reservaRestaurante.id = reserva.idReserva;
    reservaRestaurante.isGestionado = reserva.isGestionado;
    return this.http.post(
      baseUrl + '/updategestionreserva/',
      JSON.stringify(reservaRestaurante),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }
  public crearReservaAdmin(peticion: ReservaRestaurante): Observable<any> {
    peticion.fecha = new Date(this.dateToYYYYMMDDtHHmmSSz(peticion.fecha));
    return this.http.post(
      baseUrl + '/createreservawweb/',
      JSON.stringify(peticion),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  //CONFIGURACION
  public getConfiguracionFromRestaurante(
    idRestaurante: number
  ): Observable<any> {
    return this.http.get(baseUrl + '/getconfig/' + idRestaurante);
  }

  public updateConfiguracionRestaurante(
    config: ConfiguracionRestaurante
  ): Observable<any> {
    config.rangoTiempoReserva = new Date(
      this.dateToYYYYMMDDtHHmmSSz(config.rangoTiempoReserva)
    );
    config.tiempoCancelacion = new Date(
      this.dateToYYYYMMDDtHHmmSSz(config.tiempoCancelacion)
    );
    return this.http.post(baseUrl + '/updateconfig/', JSON.stringify(config), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }
  //CALENDARIO
  public getCalendarioLaboral(
    idRestaurante: number
  ): Observable<any> {
    return this.http.get(baseUrl + '/getcalendariolaboral/' + idRestaurante);
  }
  public updateCalendarioLaboral(config:CalendarioLaboral): Observable<any> {
    config.listaFechasPersonalizadas.forEach(element => {
      element.fecha=new Date(
        this.dateToYYYYMMDDtHHmmSSz(element.fecha)
      );
    });
    return this.http.post(baseUrl + '/updatecalendariolaboral/', JSON.stringify(config), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  //GLOBALS
  public getEntesAdministrados(): Observable<any> {
    return this.http.get(
      environment.apiUrl + '/organizacion/getentesuser/' + '1'
    );
  }

  public getRestaurantesAdministrados(): Observable<any> {
    return this.http.get(environment.apiUrl + '/restaurante/getadministrados/');
  }

  //Helpers
  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    console.log(fecha)
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return (
      año +
      '-' +
      this.addZero(mes) +
      '-' +
      this.addZero(dia) +
      'T' +
      this.addZero(hora) +
      ':' +
      this.addZero(minutos) +
      ':' +
      this.addZero(segundos) +
      'Z'
    );
  }

  addZero(num) {
    if (num < 10) return '0' + num;
    else return num;
  }
}
