import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Usuario } from '@app/_models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';
import { Recorrido } from '@app/_models/recorrido';

const baseUrl = `${environment.apiUrl}/recorrido`;

@Injectable()
export class RecorridosEnteService {

    constructor(private http: HttpClient) { }

    public GetById(id: Number,lang:number): Observable<any> {
        return this.http.get(baseUrl + "/get/" + id + "/"+ lang );
    }

    public GetAll(): Observable<any> {
        return this.http.get(baseUrl + "/0a0a0a0a-a4cb-4e0a-97b7-1784c9735130"); //Siempre los globales, id Org Principal siempre será la misma y no se podrá borrar por especificaciones
    }

    insert(recorrido:Recorrido) : Observable<any> {
      console.log('[S] recorrido crear');
      console.log(recorrido);
      return this.http.post(baseUrl + '/create/', JSON.stringify(recorrido), {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      });
    }

  
    update(recorrido:Recorrido) : Observable<any> {
      console.log('[S] recorrido update');
      console.log(recorrido);
      return this.http.post(baseUrl + '/update/', JSON.stringify(recorrido), {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      });
    }
  
    updateImagen(data) {
      return this.http.post<any>(baseUrl + "/editarFoto", { codigo: data.codigo, foto: data.foto }, { withCredentials: true });
    }
     
    delete(data?: any): Observable<any> {
   
       //console.log(JSON.stringify(data));
       var formData: any = new FormData();
       formData.append("codigos", data.codigos);
       //console.log(JSON.stringify(formData));
   
       return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });
    }

    deleteImagen(data) {
      return this.http.post<any>(baseUrl + "/eliminarFoto", { codigo: data.codigo });
    }

    download(imagen): Observable<any> {
      return this.http.get(baseUrl + "/sitios/download/" + imagen);
    }

    upload(data): Observable<any> {
      return this.http.post<any>(baseUrl + "/recorridos/upload/", { gpxFilename: "pureba.gpx", gpx: data }, { withCredentials: true });
    }
}
