<div class="card" *ngIf="router.url === '/usuarios-tipos'">
    <kendo-grid [kendoGridBinding]="dataUsuariosTipos"
                [sortable]="true"
                kendoGridSelectBy="id"
                [navigable]="true"
                filterable="menu"
                (cellClick)="cellClick($event)"
                [selectedKeys]="mySelection"> 
      <ng-template kendoGridToolbarTemplate position="top">
        <button kendoButton (click)="onClickEditar()" class="btn mr-1  btn-success btn-sm mr-1">{{ 'botones.editar' | translate}}</button>
        <button kendoButton (click)="onClickNuevo()" class="btn mr-1  btn-primary btn-sm mr-1">{{ 'botones.nuevo' | translate}}</button>
        <button kendoButton (click)="onClickEliminar()" class="btn mr-1  btn-danger btn-sm mr-1" [disabled]="isDeleting">{{ 'botones.eliminar' | translate}}</button>
      </ng-template>
      <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
      <kendo-grid-column width="90%" field="nombre" title="{{ 'usuarios.nombre' | translate}}">   </kendo-grid-column>
    </kendo-grid>
  </div>
  
  <router-outlet></router-outlet>
  
