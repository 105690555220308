import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services/menu.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { AlertService, DepartamentosService } from '@app/_services';
import { Recinto, RecintoTrad } from '@app/_models/recinto';
import { RecintosService } from '@app/_services/recintos.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { EcommerceService } from '@app/_services/ecommerce.service';
import { Articulo, ArticuloTrad, Imagen } from '@app/_models/ecommerce';
import {
  FileInfo,
  FileRestrictions,
  RemoveEvent,
  SelectEvent,
} from '@progress/kendo-angular-upload';

@Component({
  selector: 'app-articulos-editar',
  templateUrl: './articulos-editar.component.html',
})
export class ArticulosEditarComponent implements OnInit {
  //Listas
  listaIdiomas: Idioma[] = [];
  listaTraducciones: Idioma[] = [];

  //Traducciones
  listaTraduccionesEnviar: ArticuloTrad[] = [];
  //Data
  nombreRecinto: string = '';
  descripcionRecinto: string = '';
  stock: number = 0;
  isOferta: boolean = false;
  porcentajeOferta: number;
  imagenB64: string = '';
  selectedIdioma: Idioma;
  articuloActivo: boolean = true;
  myArticulo: Articulo = new Articulo();
  filePath: string;
  isRedirect: boolean = false;
  urlRedirect: string = '';
  precio: number ;
  listaImagenes:Imagen[]=[];

  public imagePreviews: any[] = [];

  lang;
  private translate: TranslateService;
  idOrg: string;
  idarticulo: number = 0;

  constructor(
    private ecommerceService: EcommerceService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant(
      'ecommerce.cabeceraarticulos'
    );
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.idOrg = this.route.snapshot.params['idorg'];
    this.idarticulo = this.route.snapshot.params['idarticulo'];

    this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
      if (this.listaIdiomas.length > 0){
        this.selectedIdioma = this.listaIdiomas[0];
        this.ecommerceService.getArticuloLang(this.idarticulo,this.selectedIdioma.codigo).subscribe((result)=>{
          console.log('Articulo cargado = ');
          console.log(result);
          this.myArticulo = result.data[0];
          this.nombreRecinto = this.myArticulo.nombre;
          this.descripcionRecinto = this.myArticulo.descripcion;
          this.precio = Number(this.myArticulo.precio);
          this.stock = this.myArticulo.stock;
          this.isOferta = this.myArticulo.isOferta;
          this.porcentajeOferta = Number(this.myArticulo.porcentajeOferta);
          this.isRedirect = this.myArticulo.isRedirect;
          this.urlRedirect = this.myArticulo.urlRedirect;
          this.articuloActivo = this.myArticulo.activo;
          this.ecommerceService.getImagesFromArticulo(this.idarticulo).subscribe((resultImages)=>{
            console.log('IMAGENES');
            console.log(resultImages);
            this.listaImagenes = resultImages.data;
          });
        });
      }
    });
  }

  loadArticulo(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Cargando Articulo Idioma :' + value.codigo);
    this.ecommerceService
      .getArticuloLang(this.idarticulo, this.selectedIdioma.codigo)
      .subscribe((result) => {
        console.log('Articulo cargado: ');
        console.log(result);
        this.nombreRecinto = result.data[0].nombre;
        this.descripcionRecinto = result.data[0].descripcion;
      });
  }


  btnActualizarArticulo() {
    console.log('Actualizando Articulo');

    this.myArticulo.idIdioma = this.selectedIdioma.codigo;
    this.myArticulo.precio = this.precio.toString();
    this.myArticulo.activo = this.articuloActivo;
    this.myArticulo.descripcion = this.descripcionRecinto;
    this.myArticulo.nombre = this.nombreRecinto;
    this.myArticulo.stock = this.stock;
    this.myArticulo.isOferta = this.isOferta;
    this.myArticulo.isRedirect = this.isRedirect;
    this.myArticulo.urlRedirect = this.urlRedirect;
    this.myArticulo.porcentajeOferta = this.porcentajeOferta.toString();
    if(this.imagePreviews.length>0){
      this.myArticulo.updateFoto=true;
      let listaImagenes:string[]=[];
      this.imagePreviews.forEach(element => {
        listaImagenes.push(element.src.split(',')[1]);
      });
      this.myArticulo.listaImagenes = listaImagenes;
    }
    console.log('Evinado Articulo');
    console.log(this.myArticulo);
    this.ecommerceService.actualizarArtículo(this.myArticulo).subscribe((resuoltUpdate)=>{
        if(!resuoltUpdate.error){
          this.alertService.success(
            this.translateService.instant('eccomerce.successupdateart')
          );
          this.router.navigate(['ecommerce-articulos']);
        }
        else{
          this.alertService.error(
            this.translateService.instant('eccomerce.errorupdateart')
          );
        }
    });
  }

  btnBorrarImagen(imagen){
    console.log('Borrando '+ imagen.imagen);
    this.ecommerceService.deleteImagen(imagen.id).subscribe((resultDelete)=>{
      console.log(resultDelete);
      if(!resultDelete.error){
        this.listaImagenes= this.listaImagenes.filter((x)=>x.id!=imagen.id);
        this.alertService.success(
          this.translateService.instant('eccomerce.imagenborrada')
        );
      }
      else{
        this.alertService.error(
          this.translateService.instant('eccomerce.errorimgborrada')
        );
      }
    });
  }


  //Imagen

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  btnCancelar() {
    this.router.navigate(['ecommerce-articulos']);
  }

  public fileRestrictions: FileRestrictions = {
    allowedExtensions: ['.jpg', '.png'],
    maxFileSize: 4194304,
  };

  public valueChange(file: FileInfo[]) {
    // this.log(`valueChange event`);
  }

  public remove(e: RemoveEvent): void {
    // this.log(`remove event: ${e.files[0].name}`);

    const index = this.imagePreviews.findIndex(
      (item) => item.uid === e.files[0].uid
    );

    if (index >= 0) {
      this.imagePreviews.splice(index, 1);
    }
  }

  public select(e: SelectEvent): void {
    const that = this;

    e.files.forEach((file) => {
      if (!file.validationErrors) {
        const reader = new FileReader();

        reader.onload = function (ev) {
          const image = {
            src: ev.target['result'],
            uid: file.uid,
          };

          that.imagePreviews.unshift(image);
        };

        reader.readAsDataURL(file.rawFile);
      }
    });
  }
}
