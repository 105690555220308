import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ente } from '@app/_models/ente';
import {
  ConfiguracionRestaurante,
  Restaurante,
} from '@app/_models/restaurante';
import { AlertService, MenuService } from '@app/_services';
import { IdiomasService } from '@app/_services/idiomas.service';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { RestauranteService } from '@app/_services/restaurante.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-configuracion-restaurante',
  templateUrl: './configuracion-restaurante.component.html',
})
export class ConfiguracionRestauranteComponent implements OnInit {
  listaEntesAdministrados: Ente[] = [];
  selectedOrg: Ente;
  selectedRestaurante: Restaurante;
  listaRestaurantesAdministrados: Restaurante[] = [];
  selectedModoReserva;
  selectedModoPasado;
  modoReserva: number = 1;
  maxReservasUsuario: number = 1;
  maxReservasTotales: number = this.maxReservasUsuario;
  maxComensalesReserva: number = 0;
  rangoHorasReserva: Date;
  min = new Date(2022, 2, 1, 0, 0);
  max = new Date(2022, 2, 2, 23, 59);
  public steps: any = { hour: 1, minute: 5 };
  rangoHorasReservaActivado: any;
  tiempoCancelarReserva: Date;
  permitirSeleccionMesa:boolean;

  config: ConfiguracionRestaurante = new ConfiguracionRestaurante();

  loading: boolean = false;
  lang;
  private translate: TranslateService;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  constructor(
    private restauranteService: RestauranteService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('restaurante.cabecera');
  }

  ngOnInit(): void {
    this.restauranteService.getEntesAdministrados().subscribe((result) => {
      this.listaEntesAdministrados = result.data;
      if (this.listaEntesAdministrados.length > 0) {
        this.selectedOrg = this.listaEntesAdministrados[0];
        this.restauranteService
          .getRestaurantesFromEnte(this.listaEntesAdministrados[0].id)
          .subscribe((result) => {
            this.listaRestaurantesAdministrados = result.data;
            if (this.listaRestaurantesAdministrados.length > 0) {
              this.selectedRestaurante = this.listaRestaurantesAdministrados[0];
              this.loadConfiguracionRestaurante(this.selectedRestaurante.id);
            }
          });
      }
    });
  }

  loadRestaurantes(value, dropDownControl: DropDownListComponent) {
    this.restauranteService
      .getRestaurantesFromEnte(this.selectedOrg.id)
      .subscribe((result) => {
        this.listaRestaurantesAdministrados = result.data;
        if (this.listaRestaurantesAdministrados.length > 0) {
          this.selectedRestaurante = this.listaRestaurantesAdministrados[0];
          this.loadConfiguracionRestaurante(this.selectedRestaurante.id);
        }
      });
  }

  loadConfiguracion(value, dropDownControl: DropDownListComponent) {
    this.loadConfiguracionRestaurante(this.selectedRestaurante.id);
  }

  loadConfiguracionRestaurante(id) {
    this.restauranteService
      .getConfiguracionFromRestaurante(id)
      .subscribe((result) => {
        if(result.data?.length>0){
        this.config.idRestaurante = result.data[0].idRestaurante;
        this.modoReserva = result.data[0].modoReserva;
        this.maxComensalesReserva = result.data[0].numComensalesMax;
        this.maxReservasUsuario = result.data[0].reservasMaxDia;
        this.maxReservasTotales = result.data[0].reservasMaxTotales;
        this.tiempoCancelarReserva = new Date(result.data[0].tiempoCancelacion);
        this.rangoHorasReservaActivado = result.data[0].rangoTiempoReservaActivado;
        this.rangoHorasReserva =new Date(result.data[0].rangoTiempoReserva);
        this.permitirSeleccionMesa = result.data[0].permitirSeleccionMesa;
        }
      });
  }

  onSelectionTab(num) {
    this.modoReserva = num;
  }

  onSubmit() {
    this.loading = true;
    console.log('Enviando datos...');
    this.config.idRestaurante = this.selectedRestaurante.id;
    this.config.modoReserva = this.modoReserva;
    this.config.numComensalesMax = this.maxComensalesReserva;
    this.config.reservasMaxDia = this.maxReservasUsuario;
    this.config.reservasMaxTotales = this.maxReservasTotales;
    this.config.tiempoCancelacion = this.tiempoCancelarReserva;
    this.config.rangoTiempoReserva = this.rangoHorasReserva;
    this.config.rangoTiempoReservaActivado = this.rangoHorasReservaActivado;
    this.config.permitirSeleccionMesa = this.permitirSeleccionMesa;

    this.restauranteService
      .updateConfiguracionRestaurante(this.config)
      .subscribe((result) => {
        console.log('Update Result');
        if (!result.error) {
          this.alertService.success(
            this.translateService.instant('restaurante.actualizado')
          );
        } else {
          this.alertService.error(
            this.translateService.instant('restaurante.errrorupdate')
          );
        }
        this.loading = false;
      });
  }
}
