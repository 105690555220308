<button type="button" class="btn btn-info" (click)="btnObtenerUsuario()">
  {{ "publicaciones.obtenerganador" | translate }}
</button>
<div class="form-group">
    <kendo-label text="{{ 'publicaciones.lbl_num_ganadores' | translate }}">
      <kendo-numerictextbox
        format="n0"
        [min]="0"
        [step]="1"
        [(ngModel)]="numeroGanadores"
      ></kendo-numerictextbox>
    </kendo-label>
  </div>

<div *ngIf="listaGanadores.length>0">
    <h5>{{ "publicaciones.numeroGanadores" | translate }}</h5>
    <div *ngFor="let user of listaGanadores">
        <h3>{{ user.nombreUsuario }} {{ user.email }}</h3>
    </div>

  <button type="button" class="btn btn-info" (click)="btnAsignarGanador()">
    {{ "publicaciones.confirmarganador" | translate }}
  </button>
</div>


<h5> {{ "publicaciones.likedusers" | translate }}</h5>
<kendo-grid
  [kendoGridBinding]="listaUsuarios"
  kendoGridSelectBy="id"
  filterable="menu"
  [pageSize]="10"
  [pageable]="true"
  [navigable]="true"
  [sortable]="true"
  [filterable]="true"
  scrollable="virtual"
  [rowHeight]="36"
  [height]="500"
  [resizable]="true"
  [selectable]="{
    cell: false,
    checkboxOnly: false,
    drag: true,
    enabled: true,
    mode: 'multiple'
  }"
>
  <!--checkbox-->
  <kendo-grid-checkbox-column width="1%">
    <ng-template kendoGridHeaderTemplate>
      <input
        class="k-checkbox"
        id="selectAllCheckboxId"
        kendoGridSelectAllCheckbox
        [state]="selectAllState"
        (selectAllChange)="onSelectAllChange($event)"
      />
      <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
    </ng-template>
  </kendo-grid-checkbox-column>

  <kendo-grid-column
    field="nombreUsuario"
    title="{{ 'organizaciones.nombre' | translate }}"
    width="10%"
  ></kendo-grid-column>
  <kendo-grid-column
    field="email"
    title="{{ 'agentes.email' | translate }}"
    width="10%"
  ></kendo-grid-column>
  <!-- <kendo-grid-column
  title="{{ 'agentes.asignado' | translate }}"
  width="20%"
>
  <ng-template kendoGridCellTemplate let-dataItem>
    <input
      type="checkbox"
      [checked]="dataItem.asignado"
      id="comentarC{{ dataItem.id }}"
      [(ngModel)]="dataItem.asignado"
      [ngModelOptions]="{ standalone: true }"
    />
  </ng-template>
</kendo-grid-column> -->
</kendo-grid>
