import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { AlertService, DepartamentosService } from '@app/_services';
import { stringify } from '@angular/compiler/src/util';

@Component({
  selector: 'app-publicaciones-editar',
  templateUrl: './publicaciones-editar.component.html',
})
export class PublicacionesEditarComponent implements OnInit {
  //Listas
  listaIdiomas: Idioma[] = [];
  listaIdiomasTotales: Idioma[] = [];
  listaTraducciones: Idioma[] = [];
  listaCategorias: Categoria[] = [];

  //Data

  titulo: string = '';
  cuerpo: string = '';
  imagenB64: string = '';
  selectedIdioma: Idioma;
  selectedCategoria: Categoria;
  isProgramada: boolean;
  hasVideo:boolean=false;
  urlVideo:string='';
  isYoutubeVideo:boolean=false;
  isMembersOnly: boolean;
  myNoticia: Noticia = new Noticia();
  filePath: string;
  public fecha: Date = new Date();
  public format = 'dd/MM/yyyy HH:mm';

  lang;
  private translate: TranslateService;
  idOrg: string;
  idNoticia:number;

  constructor(
    private publicacionesService: PublicacionesService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('publicaciones.cabecera');
    this.idNoticia = this.route.snapshot.params['idnoticia']
    this.idOrg = this.route.snapshot.params['idorg']
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();

    //Load Langs
    this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
      this.selectedIdioma=this.listaIdiomas[0];
      //LoadNoticia
      this.publicacionesService.getPublicacionWithLang(this.idNoticia,this.selectedIdioma.codigo).subscribe((result) => {
        console.log('Noticia result:');
        console.log(result.data);
        this.myNoticia=result.data[0];
        console.log('Noticia:');
        console.log(this.myNoticia);
        this.fecha=new Date(this.myNoticia.fecha);
        this.filePath=this.myNoticia.fotoUrl;
        this.titulo=this.myNoticia.titulo;
        this.cuerpo=this.myNoticia.cuerpo;
        this.isMembersOnly=this.myNoticia.privada;
        this.isProgramada=true;
        this.hasVideo = this.myNoticia.hasVideo;
        this.isYoutubeVideo = this.myNoticia.isYoutubeVideo;
        this.urlVideo = this.myNoticia.urlVideo;
        this.publicacionesService.loadCategorias(this.selectedIdioma.codigo.toString(),this.idOrg).subscribe((result) => {
              this.listaCategorias= result.data;
              this.listaCategorias.forEach(element => {
                if(element.id.toString()==this.myNoticia.idCategoria.toString()){
                  console.log('Categoria selected cambiada');
                    this.selectedCategoria=element;
                }
              });
        });
        });
      });
  }

  loadNoticia(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Cargando Categorias de :' + value.codigo);
    this.publicacionesService.getPublicacionWithLang(this.idNoticia,this.selectedIdioma.codigo).subscribe((result) => {
      console.log('Noticia result:');
      console.log(result.data);
      this.myNoticia=result.data[0];
      console.log('Noticia:');
      console.log(this.myNoticia);
      this.fecha=new Date(this.myNoticia.fecha);
      this.filePath=this.myNoticia.fotoUrl;
      this.titulo=this.myNoticia.titulo;
      this.cuerpo=this.myNoticia.cuerpo;
      this.isMembersOnly=this.myNoticia.privada;
      this.isProgramada=true;

      });
  }

  btnActualizarPublicacion() {
    console.log('Actualizando Publicacion');
    //Validación de los datos
      console.log(this.myNoticia);
    if (this.selectedCategoria == null || this.filePath == null) {
      this.alertService.info(
        this.translateService.instant('publicaciones.validaciondatos')
      );
      return;
    }

      this.myNoticia.titulo=this.titulo;
      this.myNoticia.cuerpo=this.cuerpo;
      this.myNoticia.idIdioma=this.selectedIdioma.codigo;
      this.myNoticia.fecha=new Date(this.fecha);
      this.myNoticia.privada= this.isMembersOnly;

      this.myNoticia.idCategoria=this.selectedCategoria.id;
      this.myNoticia.programarPublicacion=this.isProgramada;
      console.log('FOTO');
      console.log(this.myNoticia.fotoUrl);
      if(this.filePath!=this.myNoticia.fotoUrl){
        console.log('Obteniendo en BASE64...');
        this.myNoticia.fotoUrl = this.filePath.split(',')[1]; //Borramos cabecera data: base
        this.myNoticia.updateFoto=true;
      }

      //Actualizar Noticia
      this.publicacionesService.actualizarPublicacion(this.myNoticia).subscribe((result) => {
          if(result.error){
            this.alertService.error(
              this.translateService.instant('publicaciones.errorupdate')
            );
          }
          else{
            this.alertService.success(
              this.translateService.instant('publicaciones.successupdate')
            );
          }
      });

  }

  btnNavigateSorteos(){
    this.router.navigate(['publicaciones-sorteo',{ idnoticia:this.idNoticia }]);
  }
  //Imagen

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  btnCancelar(){
    this.router.navigate(['publicaciones-lista']);
  }
}
