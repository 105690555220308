import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-app-crear',
  templateUrl: './app-crear.component.html',
})
export class AppCrearComponent implements OnInit {

  nombre:string;
  descripcion:string;
  imageUrl:string;
  avatarUrl:string;
  entryPoint:string;
  apiKey:string;

  filePath: string;

  constructor(
    public router: Router,
  ) { }

  ngOnInit(): void {
  }


  btnCrearApp(){
    if(this.nombre.length<=0 || this.descripcion.length<=0 || this.entryPoint.length<=5 || this.apiKey.length<=0){
      
      return;
    }
    //Creando App
    
    
  }

  btnCancelar(){
    this.router.navigate(['home']);
  }


  
  //Imagen

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

}
