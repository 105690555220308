export class Idioma {
  codigo: number;
  id: number;
  idIdioma: number;
  idOrg: string;
  nombre: string;
  nombreIdioma: string;
  titulo: string;
  filled: boolean;
  html: string;
  idModulo:number;
  constructor(id: number, idIdioma: number, idOrg: string) {
    this.id = id;
    this.idIdioma = idIdioma;
    this.idOrg = idOrg;
  }
  //hhhhh
}
