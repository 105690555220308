import { Component, OnInit } from '@angular/core';
import {
  SchedulerEvent,
  CreateFormGroupArgs,
  SaveEvent,
  RemoveEvent,
  SlotClickEvent,
  CrudOperation,
  EventClickEvent,
} from '@progress/kendo-angular-scheduler';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
} from '@angular/forms';
import '@progress/kendo-date-math/tz/regions/Europe';
import '@progress/kendo-date-math/tz/regions/NorthAmerica';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services/menu.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import {
  AlertService,
  DepartamentosService,
  UsuariosService,
} from '@app/_services';
import {
  Recinto,
  RecintoTrad,
  Reserva,
  Sala,
  SalaTrad,
} from '@app/_models/recinto';
import { RecintosService } from '@app/_services/recintos.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Usuario } from '@app/_models';

enum EditMode {
  Event,
  Occurrence,
  Series,
}

@Component({
  selector: 'app-calendario-reservas',
  templateUrl: './calendario-reservas.component.html',
})
export class CalendarioReservasComponent implements OnInit {
  public selectedDate: Date;

  public listaReservasData: Reserva[] = [];

  public tabIndex: number = 1;
  lang;
  private translate: TranslateService;
  idOrg: string;
  idSala: number;
  idRecinto: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private recintosService: RecintosService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private usuariosService: UsuariosService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('recintos.cabecera_reservas');
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.idOrg = this.route.snapshot.params['idorg'];
    this.idRecinto = this.route.snapshot.params['idrecinto'];
    this.idSala = this.route.snapshot.params['idsala'];

    //DEBUG
    if (this.idSala == null) this.idSala = 3;

    this.recintosService
      .getReservasFromSala(this.idSala)
      .subscribe((resultReservas) => {
        resultReservas.data.map((resrevaItem) => {
          resrevaItem.start = new Date(resrevaItem.start);
          resrevaItem.end = new Date(resrevaItem.ends); //end palabra  sintáxis en sqlserver
        });
        this.listaReservasData = resultReservas.data;
        this.selectedDate = new Date();
      });
  }

  public editEventHandler({ sender, event }): void {
    console.log('edit handler');
    console.log(event);
    console.log(sender);
    this.router.navigate([
      'reservas-editar',
      { idorg: this.idOrg, idrecinto: this.idRecinto, idsala: this.idSala,idreserva:event.id },
    ]);
  }

  public newEventHandler({
    sender,
    start,
    end,
    isAllDay,
  }: SlotClickEvent): void {
    console.log('Create Event handler');
    console.log(start);
    this.recintosService.setFechaInicioReserva(start);
    this.router.navigate([
      'reservas-crear',
      { idorg: this.idOrg, idrecinto: this.idRecinto, idsala: this.idSala },
    ]);
  }

  //Conf forms
  // public saveHandler({
  //   sender,
  //   formGroup,
  //   isNew,
  //   dataItem,
  //   mode,
  // }: SaveEvent): void {
  //   sender.addEvent(this.formGroup);
  //   if (isNew) {
  //     console.log('Creando');
  //     console.log(dataItem);
  //     this.router.navigate(["reservas-crear",{idorg:this.idOrg,idrecinto:this.idRecinto,idsala:this.idSala}]);
  //     //crear en el service
  //   } else {
  //     console.log('Actualizando');
  //     console.log(dataItem);
  //     this.router.navigate(["reservas-editar"]);
  //     //actualizar en el service
  //   }
  // }

  public removeHandler({ sender, dataItem }: RemoveEvent): void {
    sender.openRemoveConfirmationDialog().subscribe((shouldRemove) => {
      console.log('shouldRemove: ' + shouldRemove);
      if (shouldRemove) {
        console.log('Borrado');
        console.log(dataItem);
        this.recintosService
          .deleteReserva(dataItem.id)
          .subscribe((resultDelete) => {
            console.log(resultDelete);
            if (!resultDelete.error) {
              this.alertService.success(
                this.translateService.instant('recintos.successborrarreserva')
              );
              this.redirectTo('reservas');
            } else {
              this.alertService.error(
                this.translateService.instant('recintos.errorborrarreserva')
              );
            }
          });
      }
    });
  }

  public dragEndHandler({ sender, event, start, end, isAllDay }): void {
    console.log('DragEnd!');
    console.log(event);
  }

  public resizeEndHandler({ sender, event, start, end }): void {
    console.log('Resized!');
    console.log(event);
    let dataItem = event.dataItem;
  }

  public getNextId(): number {
    const len = this.listaReservasData.length;

    return len === 0
      ? 1
      : this.listaReservasData[this.listaReservasData.length - 1].id + 1;
  }

  public startEndValidator: ValidatorFn = (fg: FormGroup) => {
    const start = fg.get('start').value;
    const end = fg.get('end').value;

    if (start !== null && end !== null && start.getTime() < end.getTime()) {
      return null;
    } else {
      return { range: 'End date must be greater than Start date' };
    }
  };

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([
        uri,
        {
          idorg: this.idOrg,
          idrecinto: this.idRecinto,
          idsala: this.idSala,
        },
      ])
    );
  }
}
