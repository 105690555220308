export class DepartamentoEmail {
    codigo: number;
    coddepartamento: number;
    nombre: string;
    usuario: string;
    email: string;
    contraseña: string;
    smtp: string;
    puerto: number;
    activo: boolean;
}