import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services/menu.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import {
  AlertService,
  DepartamentosService,
  UsuariosService,
} from '@app/_services';
import {
  Recinto,
  RecintoTrad,
  Reserva,
  Sala,
  SalaTrad,
} from '@app/_models/recinto';
import { RecintosService } from '@app/_services/recintos.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Usuario } from '@app/_models';

@Component({
  selector: 'app-reservas-editar',
  templateUrl: './reservas-editar.component.html',
})
export class ReservasEditarComponent implements OnInit {
  lang;
  private translate: TranslateService;
  idOrg: string;
  idSala: number;
  idRecinto: number = 0;
  idReserva: number;

  myReserva: Reserva = new Reserva();
  titulo: string = '';
  descripcion: string = '';
  fechaInicio: Date = new Date();
  fechaFin: Date = new Date();

  constructor(
    private recintosService: RecintosService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private usuariosService: UsuariosService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant(
      'recintos.cabecera_reservas'
    );
  }

  ngOnInit(): void {
    this.idOrg = this.route.snapshot.params['idorg'];
    this.idRecinto = this.route.snapshot.params['idrecinto'];
    this.idSala = this.route.snapshot.params['idsala'];
    this.idReserva = this.route.snapshot.params['idreserva'];
    this.fechaInicio = this.recintosService.getFechaInicioReserva();
    this.fechaFin = new Date(this.fechaInicio);
    this.fechaFin.setHours(this.fechaFin.getHours() + 1);

    this.recintosService
      .getReserva(this.idReserva)
      .subscribe((resultReserva) => {
        console.log('Reserva que se carga:');
        console.log(resultReserva);
        this.myReserva = resultReserva.data[0];
        this.titulo = this.myReserva.title;
        this.descripcion = this.myReserva.description;
        this.fechaInicio = new Date(this.myReserva.start);
        this.fechaFin = new Date(this.myReserva.ends);
      });
  }

  btnActualizarReserva() {
    if (this.titulo.length <= 0 && this.descripcion.length <= 0) {
      this.alertService.warn(
        this.translateService.instant('recintos.validacionreserva')
      );
      return;
    }
    console.log('Creando Reserva');
    this.myReserva.title = this.titulo;
    this.myReserva.description = this.descripcion;
    this.myReserva.start = this.fechaInicio;
    this.myReserva.end = this.fechaFin;
    this.myReserva.idRecinto = this.idRecinto;
    this.myReserva.idSala = this.idSala;

    this.recintosService
      .actualizarReserva(this.myReserva)
      .subscribe((resultCrear) => {
        console.log(resultCrear);
        if (!resultCrear.error) {
          this.alertService.success(
            this.translateService.instant('recintos.successupdatereservs')
          );
          this.router.navigate([
            'reservas',
            {
              idorg: this.idOrg,
              idrecinto: this.idRecinto,
              idsala: this.idSala,
            },
          ]);
        } else {
          this.alertService.error(
            this.translateService.instant('recintos.errorupdatereserva')
          );
        }
      });
  }

  btnConfirmarReserva() {
    this.myReserva.isConfirmada = true;
    this.recintosService
      .confirmarReserva(this.myReserva.id)
      .subscribe((resultReserva) => {
        if (!resultReserva.error) {
          this.alertService.success(
            this.translateService.instant('recintos.reservaconfirmada')
          );
        } else {
          this.alertService.error(
            this.translateService.instant('recintos.errorcancelarreserva')
          );
        }
      });
  }

  btnCancelarReserva() {
    this.myReserva.isConfirmada = false;
    this.recintosService
      .cancelarReserva(this.myReserva.id)
      .subscribe((resultReserva) => {
        if (!resultReserva.error) {
          this.alertService.success(
            this.translateService.instant('recintos.reservacancelada')
          );
        } else {
          this.alertService.error(
            this.translateService.instant('recintos.errorcancelarreserva')
          );
        }
      });
  }

  btnBorrarReserva() {
    this.recintosService
      .deleteReserva(this.myReserva.id)
      .subscribe((resultDelete) => {
        console.log(resultDelete);
        if (!resultDelete.error) {
          this.alertService.success(
            this.translateService.instant('recintos.successborrarreserva')
          );
          this.router.navigate([
            'reservas',
            {
              idorg: this.idOrg,
              idrecinto: this.idRecinto,
              idsala: this.idSala,
            },
          ]);
        } else {
          this.alertService.error(
            this.translateService.instant('recintos.errorborrarreserva')
          );
        }
      });
  }

  btnCancelar() {
    this.router.navigate([
      'reservas',
      { idorg: this.idOrg, idrecinto: this.idRecinto, idsala: this.idSala },
    ]);
  }
}
