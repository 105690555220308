<kendo-grid [kendoGridBinding]="comedores" kendoGridSelectBy="id" filterable="menu"
    [(selectedKeys)]="comedoresSeleccionados"
     [navigable]="true" [sortable]="true" scrollable="virtual" [rowHeight]="36"
        [height]="800" [pageSize]="50"
        [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}"
        [resizable]="true"  (cellClick)="cellClick($event)">

        <ng-template kendoGridToolbarTemplate position="top">
            <div class="clearfix">
                <div class="form-group m-0 float-left">
                    <button id="btnnuevo" (click)="onClickNuevo()" Class="btnBerria btn btn-primary btn-sm mr-1">{{
                                            'botones.nuevo' | translate }}</button>
                    <button id="btneliminar" Class="btnEzabatu btn btn-danger btn-sm mr-1"
                        (click)="onClickEliminar()">{{ 'botones.eliminar' | translate }}</button>
                </div>
            </div>
        </ng-template>

        <!--checkbox-->
        <kendo-grid-checkbox-column showSelectAll="true" width="3%"></kendo-grid-checkbox-column>
        <!-- Necesitamos que sean sortable, y si no se duplican así no lo son -->
        <kendo-grid-column width="50%"  field="nombre" title="{{ 'restaurante.nombre' | translate}}">
          </kendo-grid-column>
          <kendo-grid-column width="12%"  field="alto" title="{{ 'restaurante.largo' | translate}}" [style]="{'text-align': 'right'}">
          </kendo-grid-column>
          <kendo-grid-column width="12%"  field="ancho" title="{{ 'restaurante.ancho' | translate}}" [style]="{'text-align': 'right'}">
          </kendo-grid-column>
          <kendo-grid-column width="12%"  field="nombrePerfil" title="{{ 'restaurante.nombrePerfil' | translate}}" [style]="{'text-align': 'right'}">
          </kendo-grid-column>
          <kendo-grid-messages filter="{{'filter' | translate}}"
            filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}"
            filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}"
            filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}"
            filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}"
            filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
            filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}"
            filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
            filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}"
            noRecords="{{'noRecords' | translate}}" unlock="{{'unlock' | translate}}">
        </kendo-grid-messages>
    </kendo-grid>

<button
(click)="btnBack()"
Class="btnBerria btn btn-info btn-sm mr-1"
>
{{ "restaurante.atras" | translate }}
</button>


<!-- POPUP: Borrar  -->
<ng-template #popupBorrar let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>{{'restaurante.preguntaeliminarpopup' | translate }}</label>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'botones.cancelar' | translate
            }}</button>
        <button type="button" class="btn btn-primary" (click)="btnBorrarAceptar()">{{ 'botones.aceptar' | translate }}</button>
    </div>
</ng-template>

<router-outlet></router-outlet>