<div class="card">
  <div class="card-header">
    <h3>
      <label CssClass="">{{ "mesas" | translate }}</label>
    </h3>
  </div>
  <div class="card-body">
    <kendo-grid
      [kendoGridBinding]="listaMesas"
      kendoGridSelectBy="codigo"
      filterable="menu"
      [(selectedKeys)]="mesasSeleccionadas"
      [navigable]="true"
      [sortable]="true"
      scrollable="virtual"
      [rowHeight]="36"
      [pageSize]="50"
      [selectable]="{
        cell: false,
        checkboxOnly: false,
        drag: true,
        enabled: true,
        mode: 'multiple'
      }"
      [resizable]="true"
      (cellClick)="cellClick($event)"
    >
      <ng-template kendoGridToolbarTemplate position="top">
        <div class="clearfix">
          <div class="form-group m-0 float-left">
            <button
              id="btnnuevo"
              (click)="onClickNuevo()"
              Class="btnBerria btn btn-primary btn-sm mr-1"
            >
              {{ "botones.nuevo" | translate }}
            </button>
            <button
              id="btneliminar"
              Class="btnEzabatu btn btn-danger btn-sm mr-1"
              (click)="onClickEliminar()"
            >
              {{ "botones.eliminar" | translate }}
            </button>
          </div>
        </div>
      </ng-template>
      <kendo-grid-checkbox-column
        showSelectAll="true"
        width="3%"
      ></kendo-grid-checkbox-column>
      <kendo-grid-column
        width="15%"
        field="nombre"
        title="{{ 'restaurante.nombre' | translate }}"
      >
      </kendo-grid-column>
      <kendo-grid-column
        width="5%"
        field="numMesa"
        title="{{ 'restaurante.nummesa' | translate }}"
        [style]="{ 'text-align': 'right' }"
      >
      </kendo-grid-column>
      <kendo-grid-column
        width="5%"
        [style]="{ 'text-align': 'left' }"
        field="conectable"
        title="{{ 'restaurante.conectable' | translate }}"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <input type="checkbox" [checked]="dataItem.conectable" disabled />
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        width="5%"
        [style]="{ 'text-align': 'left' }"
        field="asignada"
        title="{{ 'restaurante.asignada' | translate }}"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <input type="checkbox" [checked]="dataItem.asignada" disabled />
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        width="5%"
        field="comensales"
        title="{{ 'restaurante.numComensales' | translate }}"
        [style]="{ 'text-align': 'right' }"
      >
      </kendo-grid-column>
      <generico-grid-traducciones></generico-grid-traducciones>
    </kendo-grid>

  </div>
</div>

<button
(click)="btnBack()"
Class="btnBerria btn btn-info btn-sm mr-1"
>
{{ "restaurante.atras" | translate }}
</button>
<router-outlet></router-outlet>
