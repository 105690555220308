export class Modulo {
    id:number;
    nombre:string;
    tipo:number;
    direccion:string;
    icono:string;
    idPadre:number;
    orden:number;
    contenido:string;
    listaTraducciones: ModuloTrads[];
    listaHijos: Modulo[];
}

export class iconoModulo {
    nombreIcono: string;
    codigoIcono: string   
}

export class ModuloTrads {
    id:number;
    idModulo:number;
    idIdioma:number;
    nombre:string;
    filled:boolean;
    nombreIdioma:string;
}

export class ModulosRoot {
    nombre: string;
    id: number;
}