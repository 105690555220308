<div class="row">
    <div class="col-xl-4 col-md-6">
      <div class="card">
        <!--GESTION AGENTE-->
        <div class="card-header">
          <h3>{{ "equipos.datosjugador" | translate }}</h3>
        </div>
        <div class="card-body">
          <div class="form-group">
            <!--Lista idiomas-->
  
            <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
            </kendo-label>
  
            <div class="caja">
              <kendo-dropdownlist
                #dropdownI
                [data]="listaIdiomas"
                textField="nombre"
                valueField="codigo"
                [(ngModel)]="selectedIdioma"
                (valueChange)="loadJugador($event, dropdownI)"
              ></kendo-dropdownlist>
            </div>
          </div>
  
          <div class="form-group">
            <label>{{ "equipos.nombrejugador" | translate }}</label>
            <div class="caja">
              <!--Nombe-->
              <kendo-textbox
                [clearButton]="true"
                [(ngModel)]="myJugador.nombre"
              >
                <ng-template kendoTextBoxPrefixTemplate> </ng-template>
              </kendo-textbox>
            </div>
          </div>
          <div class="form-group">
            <label>{{ "equipos.apellidos" | translate }}</label>
            <div class="caja">
              <!--Apeliidos-->
              <kendo-textbox
                [clearButton]="true"
                [(ngModel)]="myJugador.apellidos"
              >
                <ng-template kendoTextBoxPrefixTemplate> </ng-template>
              </kendo-textbox>
            </div>
          </div>
          <!--Posicion-->
          <div class="form-group">
            <label>{{ "equipos.posicion" | translate }}</label>
            <div class="caja">
              <!--Apeliidos-->
              <kendo-textbox
                [clearButton]="true"
                [(ngModel)]="myJugador.posicion"
              >
                <ng-template kendoTextBoxPrefixTemplate> </ng-template>
              </kendo-textbox>
            </div>
          </div>
          <!--Lateralidad-->
          <div class="form-group">
            <label>{{ "equipos.lateralidad" | translate }}</label>
            <div class="caja">
              <!--Apeliidos-->
              <kendo-textbox
                [clearButton]="true"
                [(ngModel)]="myJugador.lateralidad"
              >
                <ng-template kendoTextBoxPrefixTemplate> </ng-template>
              </kendo-textbox>
            </div>
          </div>
  
          <!--Observaciones-->
          <div class="form-group">
            <kendo-label text="{{ 'equipos.observaciones' | translate }}">
              <textarea
                kendoTextArea
                style="resize: none"
                rows="5"
                [(ngModel)]="myJugador.observaciones"
                placeholder="{{ 'recintos.ph_descripcion' | translate }}"
              >
              </textarea>
            </kendo-label>
          </div>
          <!--Nacionalidad-->
          <div class="form-group">
            <label>{{ "equipos.nacionalidad" | translate }}</label>
            <div class="caja">
              <!--Titulo-->
              <kendo-textbox
                [clearButton]="true"
                [(ngModel)]="myJugador.nacionalidad"
              >
                <ng-template kendoTextBoxPrefixTemplate> </ng-template>
              </kendo-textbox>
            </div>
          </div>
          <!--Altura-->
          <div class="form-group">
            <label>{{ "equipos.altura" | translate }}</label>
            <div class="caja">
              <!--Titulo-->
              <kendo-textbox
                [clearButton]="true"
                [(ngModel)]="myJugador.altura"
              >
                <ng-template kendoTextBoxPrefixTemplate> </ng-template>
              </kendo-textbox>
            </div>
          </div>
  
                  <!--Peso-->
                  <div class="form-group">
                    <label>{{ "equipos.peso" | translate }}</label>
                    <div class="caja">
                      <!--Titulo-->
                      <kendo-textbox
                        [clearButton]="true"
                        [(ngModel)]="myJugador.peso"
                      >
                        <ng-template kendoTextBoxPrefixTemplate> </ng-template>
                      </kendo-textbox>
                    </div>
                  </div>
  
          <!-- Fecha Nacimiento-->
          <div class="form-group">
            <kendo-label class="k-checkbox-label"
                         text="{{ 'equipos.fechaNacimiento' | translate }}"></kendo-label>
            <div class="caja">
              <kendo-datepicker format="dd/MMM/yyyy" [(ngModel)]="myJugador.fechaNacimiento">
              </kendo-datepicker>
            </div>
          </div>
  
          <!--Fecha FinContrato-->
          <div class="form-group">
            <kendo-label class="k-checkbox-label"
                         text="{{ 'eventos.fechafincontrato' | translate }}"></kendo-label>
            <div class="caja">
              <kendo-datepicker format="dd/MMM/yyyy" [(ngModel)]="myJugador.fechaFinContrato">
              </kendo-datepicker>
            </div>
          </div>
  
          <!--Foto Jugador-->
          <label class="image-upload-container btn btn-bwm">
            <span>{{ 'equipos.selectfoto' | translate }}</span>
            <input
              #imageInput
              type="file"
              accept="image/*"
              (change)="imagePreview($event)"
            />
          </label>

          <div *ngIf="filePath && filePath !== ''">
            <img [src]="filePath" style="height: 200px" />
          </div>
  
                  <!--Foto Fondo-->
                  <label class="image-upload-container btn btn-bwm">
                    <span>{{ 'equipos.selectfondo' | translate }}</span>
                    <input
                      #imageInput
                      type="file"
                      accept="image/*"
                      (change)="imagePreviewFondo($event)"
                    />
                  </label>
          
                  <div *ngIf="filePathFondo && filePathFondo !== ''">
                    <img [src]="filePathFondo" style="height: 200px" />
                  </div>
  
  
          <div class="form-group">
  
            <!--Boton Publicar-->
            <button
              type="button"
              class="btn btn-success btn-sm mr-1"
              (click)="actualizarJugador()"
            >
              {{ "equipos.btn_actualizar" | translate }}
            </button>
            <button
              type="button"
              class="btn btn-danger btn-sm mr-1"
              (click)="btnCancelar()"
            >
              {{ "botones.cancelar" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  
    <!--RRSS-->
    <div class="col-xl-4 col-md-6">
      <div class="card">
        <div class="card-header">
          <h3>{{ "equipos.rrss" | translate }}</h3>
        </div>
        <div class="card-body">
                <div class="row form-group">
                  <div class="col-sm-2">
                    <kendo-label text="{{ 'organizaciones.facebook' | translate }}"></kendo-label>
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-sm-2">
                    <kendo-switch  [(ngModel)]="myJugador.hasFacebook"
                      [ngModelOptions]="{ standalone: true }"></kendo-switch>
                  </div>
                  <div class="col">
                    <kendo-textbox [(ngModel)]="myJugador.urlFacebook" ></kendo-textbox>
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-sm-2">
                    <kendo-label text="{{ 'organizaciones.twitter' | translate }}">
                    </kendo-label>
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-sm-2">
                    <kendo-switch [(ngModel)]="myJugador.hasTwitter"
                      [ngModelOptions]="{ standalone: true }"></kendo-switch>
                  </div>
                  <div class="col">
                    <kendo-textbox [(ngModel)]="myJugador.urlTwitter" placeholcer="" > </kendo-textbox>
                  </div>
                </div>
  
                <div class="row form-group">
                  <div class="col-sm-2">
                    <kendo-label text="{{ 'organizaciones.instagram' | translate }}">
                    </kendo-label>
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-sm-2">
                    <kendo-switch [(ngModel)]="myJugador.hasInstagram"
                      [ngModelOptions]="{ standalone: true }"></kendo-switch>
                  </div>
                  <div class="col">
                    <kendo-textbox [(ngModel)]="myJugador.urlInstagram" placeholcer="" > </kendo-textbox>
                  </div>
                </div>
  
                <div class="row form-group">
                  <div class="col-sm-2">
                    <kendo-label text="{{ 'equipos.tiktok' | translate }}">
                    </kendo-label>
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-sm-2">
                    <kendo-switch [(ngModel)]="myJugador.hasTikTok"
                      [ngModelOptions]="{ standalone: true }"></kendo-switch>
                  </div>
                  <div class="col">
                    <kendo-textbox [(ngModel)]="myJugador.urlTikTok" placeholcer="" > </kendo-textbox>
                  </div>
                </div>
  
                <div class="row form-group">
                  <div class="col-sm-2">
                    <kendo-label text="{{ 'organizaciones.youtube' | translate }}">
                    </kendo-label>
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-sm-2">
                    <kendo-switch [(ngModel)]="myJugador.hasYoutube"
                      [ngModelOptions]="{ standalone: true }"></kendo-switch>
                  </div>
                  <div class="col">
                    <kendo-textbox [(ngModel)]="myJugador.urlYoutube" placeholcer="" > </kendo-textbox>
                  </div>
                </div>
  
                <div class="row form-group">
                  <div class="col-sm-2">
                    <kendo-label text="{{ 'equipos.custom' | translate }}">
                    </kendo-label>
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-sm-2">
                    <kendo-switch [(ngModel)]="myJugador.hasCustomUrl"
                      [ngModelOptions]="{ standalone: true }"></kendo-switch>
                  </div>
                  <div class="col">
                    <kendo-textbox [(ngModel)]="myJugador.customUrl" placeholcer=""> </kendo-textbox>
                  </div>
                </div>
  
        </div>
        
        </div>
        </div>
  
    <!--Vinculación Usuario-->
  
    <div class="col-xl-4 col-md-6">
      <div class="card">
        <div class="card-header">
          <h3>{{ "equipos.vincular" | translate }}</h3>
        </div>
        <div class="card-body">
          <div class="form-group">
              <div *ngIf="isUsuarioVinculado;else vincular">
                  {{usuarioVincular.email}}
                  <button
                  type="button"
                  class="btn btn-danger btn-sm mr-1"
                  (click)="desvincularUsuario()"
                >
                  {{ "equipos.btn_desvincular" | translate }}
                </button>
              </div>
              <ng-template #vincular>
                <zitu-selector-usuarios (listaUsuarios)="getUsuarios($event)">
              </zitu-selector-usuarios>
                <button
                type="button"
                class="btn btn-success btn-sm mr-1"
                (click)="vincularUsuario()"
              >
                {{ "equipos.btn_vincular" | translate }}
              </button>
            </ng-template>
        </div>
        </div>
  
        </div>
        </div>
  
  </div>
  