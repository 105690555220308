export class Usuario {
    id: number;
    email: string;
    nombreUsuario: string;
    nombre: string;
    apellido1: string;
    apellido2: string;
    usuarios_TiposId: number;
    idDb: number;
    idioma: string;
    tema: number;
    menuExpandido: boolean;
    activo: boolean;
    gestionUsuarios: number;
    usuarioTipo: string;
    jwtToken?: string;
    asignado:boolean;
}