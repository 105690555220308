<div class="row">
    <div class="col-xl-4 col-md-6">
      <div class="card">
        <!--GESTION AGENTE-->
        <div class="card-header">
          <h3>{{ "restaurante.update_comida" | translate }}</h3>
        </div>
        <div class="card-body">
          <div class="form-group">
            <!--Lista idiomas-->

            <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
            </kendo-label>

            <div class="caja">
              <kendo-dropdownlist #dropdownI
                                  [data]="listaIdiomas"
                                  textField="nombre"
                                  valueField="codigo"
                                  [(ngModel)]="selectedIdioma"
                                  (valueChange)="loadRecinto($event, dropdownI)"></kendo-dropdownlist>
            </div>
          </div>

          <div class="form-group">
            <label>{{ "restaurante.nombrecmomida" | translate }}</label>
            <div class="caja">
              <!--Titulo-->
              <kendo-textbox placeholder="{{ 'restaurante.ph_nombre' | translate }}"
                             [clearButton]="true"
                             [(ngModel)]="nombreRecinto">
                <ng-template kendoTextBoxPrefixTemplate> </ng-template>
              </kendo-textbox>
            </div>
          </div>
          <!-- Hora Inicio Fin-->
          <div class="form-group">
            <label>{{ "restaurante.hora_ini_comida" | translate }}</label>
            <div class="caja">
              <kendo-timepicker [(ngModel)]="fechaIni"
                                #dateModel="ngModel"></kendo-timepicker>
            </div>
          </div>
          <div class="form-group">
            <label>{{ "restaurante.hora_fin_comida" | translate }}</label>
            <div class="caja">
              <kendo-timepicker [(ngModel)]="fechaFin"
                                #dateModel="ngModel"></kendo-timepicker>
            </div>
          </div>
          <!--Horas Reserva Permitidas-->
          <div class="form-group">
            <label>{{ "restaurante.rangoreserva" | translate }}</label>
            <div class="caja">
              {{rangoHorasReserva}}
              <kendo-slider incrementTitle="Inc"
                            decrementTitle="Dec"
                            [min]="1"
                            [max]="6"
                            [(ngModel)]="rangoHorasReserva"></kendo-slider>
            </div>
          </div>
          <!--CheckBox Activo-->
          <div class="form-group">
            <kendo-label class="k-checkbox-label"
                         text="{{ 'restaurante.resactivo' | translate }}"></kendo-label>
            <div class="caja">
              <kendo-switch [onLabel]="' '" [offLabel]="' '"
              [(ngModel)]="recintoActivo"
                            onLabel="{{ 'switch.activo' | translate }}"
                            offLabel="{{ 'switch.inactivo' | translate }}"></kendo-switch>
            </div>
          </div>
          <div class="form-group">
            <!--Boton Publicar-->
            <button type="button"
                    class="btn btn-success btn-sm mr-1"
                    (click)="btnUpdateComida()">
              {{ "restaurante.btn_actualizar_comida" | translate }}
            </button>
            <button type="button"
                    class="btn btn-danger btn-sm mr-1"
                    (click)="btnCancelar()">
              {{ "botones.cancelar" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
