import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Comedor,
  ComidaPersonalizadaDia,
  ComidaTipo,
  MesaComedor,
  ReservaRestaurante,
  ReservaRestaurantePeticion,
  Tamano_Panel,
} from '@app/_models/restaurante';
import { AlertService, MenuService } from '@app/_services';
import { IdiomasService } from '@app/_services/idiomas.service';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { RestauranteService } from '@app/_services/restaurante.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CalendarView } from '@progress/kendo-angular-dateinputs';
import { IntlService } from '@progress/kendo-angular-intl';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { SortDescriptor } from '@progress/kendo-data-query';
import { LOCALE_ID } from '@angular/core';

import * as $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { CategoryAxisNotesLabelComponent } from '@progress/kendo-angular-charts';

@Component({
  selector: 'app-reservas',
  templateUrl: './reservas.component.html',
})
export class ReservasComponent implements OnInit {
  //Data
  listaNuevasReservas: any[] = [];
  listaReservasActuales: any = [];
  listaReservasActualesResult: ReservaRestaurante[] = [];
  listaReservasTotales: ReservaRestaurante[] = [];
  listaReservasTotalesResult: ReservaRestaurante[] = [];
  listaReservasCanceladas: ReservaRestaurante[] = [];
  listaReservasHistorial: ReservaRestaurante[] = [];
  listaReservasHistorialResult: ReservaRestaurante[] = [];
  listaComidas = [];
  peticionFecha: ReservaRestaurantePeticion = new ReservaRestaurantePeticion();
  idRestaurante: number;
  idOrg: string;
  events: string[] = [];
  selectedDate: Date = new Date();
  calendarioActivo: boolean = true;
  comidaActiva: boolean = true;
  selectedReserva: any;
  myReserva: ReservaRestaurante = new ReservaRestaurante();
  verCanceladas: boolean = false;
  verComedores: boolean = true;
  verComedoresActivo: boolean = true;
  listaComedores: any[];
  selectedComedor: any;
  listaTiposComida: any[];
  selectedComida: any;
  nombreComensalReserva: string = '';
  listaMesasComedor: MesaComedor[];
  selectedMesa: MesaComedor;
  numComensales: number = 1;
  numComensalesMax: number = 1;
  numTelefono: string = '';
  reservaObservaciones: string = '';
  public horaReserva: any;
  selectedPerfil: number = 0;
  listaPerfiles: any[]
  seleccionados: number[] = [];
  selectedTab: number = 0;
  lang;
  private translate: TranslateService;
  modalReference: NgbModalRef;
  public disabledDates;
  public sort: SortDescriptor[] = [
    {
      field: 'idTipoComida',
      dir: 'asc',
    },
  ];

  //Modals
  @ViewChild('popUpAbrirReserva') popUpAbrirReserva: NgbModalRef;
  @ViewChild('popUpCrearReserva') popUpCrearReserva: NgbModalRef;

  // Reservas de una mesa en especifico
  selectedNumeroMesa: number = 0;
  listaReservasActualesMesa: any = [];

  // DbClick touchTime
  touchTime: number = 0;
  private clicks = 0;
  private clickTimeout = null;

  //Componente de mesas
  public mousePosition = { x: 0, y: 0 };
  public listaMesasPanel = [];
  public puntoInicio = { x: 0, y: 0 }; //Indica el punto en el que se ha iniciado el drag de una mesa
  public puntoAnterior = { x: 0, y: 0 }; //Indica el punto del que se ha movido mientras se hace el drag de una mesa
  tamano_panel: Tamano_Panel = new Tamano_Panel(800, 800); //El tamano en px
  tamano_panel_cm: Tamano_Panel = new Tamano_Panel(1000, 1000); //El tamano, a los clientes se les muestra en cm
  escala_panel: any = 1; //valor por el que se deben multiplicar las mesas a la hora de colocarlas en el panel
  public fondo: any;
  public listaMesasConectadas = [];
  public mesaSeleccionada: any;
  public configuracionRestaurante: any;
  public calendarioLaboralRestaurante: any;
  public listaHoras: any = [];  //Esta es la lista de horas general
  public listaHorasAUtilizar: any = [];
  public horaSeleccionada: any;
  public verCanceladasActivo: any = true;
  public cantidadReservas: any;
  public verComedor: any = true;
  public botonCrearReserva: any = true;


  constructor(
    private intl: IntlService,
    private restauranteService: RestauranteService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('restaurante.cabecera');
    this.idRestaurante = this.route.snapshot.params['idrestaurante'];
    this.idOrg = this.route.snapshot.params['idorg'];
    this.configuracionRestaurante = undefined;
    this.cargarDatosRestaurante();
    setInterval(() => {
      console.log("Recargando...");
      this.updateListaReservas();
    }, 60000)
  }

  cargarDatosIniciales() {
    this.listaHoras = [];
    this.listaHorasAUtilizar = [];
    //Aprovechamos que ya tenemos la configuracion y las comidas para crear las horas de reservas
    if (this.configuracionRestaurante.modoReserva == 2) { //Si activado reservas anticipadas a hechas en config
      //Si rango de tiempo por reserva activado o desactivado
      var auxiHoraComienzo = this.selectedComida.horaInicio.getHours();
      var auxiMinutosComienzo = this.selectedComida.horaInicio.getMinutes();
      var auxiMinutosComienzoCompletos = auxiHoraComienzo * 60 + auxiMinutosComienzo;
      var auxiHoraFin = this.selectedComida.horaFin.getHours();
      var auxiMinutosFin = this.selectedComida.horaFin.getMinutes();
      var auxiMinutosFinCompletos = auxiHoraFin * 60 + auxiMinutosFin;
      if (auxiMinutosFinCompletos < auxiMinutosComienzoCompletos) {
        //Hay que sumarle a la de fin 24 horas para que sea mayor, porque ha pasado a la madrugada del dia siguiente
        auxiMinutosFinCompletos += 24 * 60;
      }
      //Por defecto las reservas son cada 15 mins
      var auxHoraTiempo = Number(0);
      var auxMinutosTiempo = Number(15);
      var tiempoActual = auxiHoraComienzo * 60 + auxiMinutosComienzo;
      auxMinutosTiempo = auxHoraTiempo * 60 + auxMinutosTiempo; //Calculamos tiempo completo
      while (tiempoActual <= auxiMinutosFinCompletos) { //No lleguemos a la fecha de fin seguimos agregando horas
        var auxHora = Math.floor(tiempoActual / 60);
        var auxMinutos = tiempoActual % 60;
        var auxFecha = new Date();
        auxFecha.setHours(auxHora);
        auxFecha.setMinutes(auxMinutos);
        auxFecha.setSeconds(0);
        auxFecha.setMilliseconds(0);
        this.listaHoras.push({ hora: auxFecha });
        //Ahora actualizamos el tiempoActual
        tiempoActual += auxMinutosTiempo;
      }
      if (this.listaHoras.length > 0) {
        this.horaSeleccionada = this.listaHoras[0];
      }
    } else if (this.configuracionRestaurante.modoReserva == 1) {//Si reservas por comida activado
      //Si rango de tiempo por reserva activado
      if (this.configuracionRestaurante.rangoTiempoReservaActivado) {
        //Hay que ver a qué hora empieza la comida seleccionada y con eso calcular las horas
        var auxiHoraComienzo = this.selectedComida.horaInicio.getHours() == 0 ? 24 : this.selectedComida.horaInicio.getHours();
        var auxiMinutosComienzo = this.selectedComida.horaInicio.getMinutes();
        var auxiMinutosComienzoCompletos = auxiHoraComienzo * 60 + auxiMinutosComienzo;
        var auxiHoraFin = this.selectedComida.horaFin.getHours() == 0 ? 24 : this.selectedComida.horaFin.getHours();
        var auxiMinutosFin = this.selectedComida.horaFin.getMinutes();
        var auxiMinutosFinCompletos = auxiHoraFin * 60 + auxiMinutosFin;
        if (auxiMinutosFinCompletos < auxiMinutosComienzoCompletos) {
          //Hay que sumarle a la de fin 24 horas para que sea mayor, porque ha pasado a la madrugada del dia siguiente
          auxiMinutosFinCompletos += 24 * 60;
        }
        var fechaConfig = this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":");
        var auxHoraTiempo = Number(fechaConfig[0]);
        var auxMinutosTiempo = Number(fechaConfig[1]);
        var tiempoActual = auxiHoraComienzo * 60 + auxiMinutosComienzo;
        auxMinutosTiempo = auxHoraTiempo * 60 + auxMinutosTiempo; //Calculamos tiempo completo
        while (tiempoActual <= auxiMinutosFinCompletos) { //No lleguemos a la fecha de fin seguimos agregando horas
          var auxHora = Math.floor(tiempoActual / 60);
          var auxMinutos = tiempoActual % 60;
          var auxFecha = new Date();
          auxFecha.setHours(auxHora);
          auxFecha.setMinutes(auxMinutos);
          auxFecha.setSeconds(0);
          auxFecha.setMilliseconds(0);
          this.listaHoras.push({ hora: auxFecha });
          //Ahora actualizamos el tiempoActual
          tiempoActual += auxMinutosTiempo;
        }
        if (this.listaHoras.length > 0) {
          this.horaSeleccionada = this.listaHoras[0];
        }
      } else {//Si desactivado
        //No necesitamos horas porque la reserva es suya toda la comida
      }
    }
    this.peticionFecha.idRestaurante = this.idRestaurante;
    this.peticionFecha.idComedor = this.selectedComedor.id;
    this.peticionFecha.idTipoComida = this.selectedComida.id;
    let fechaActual = this.selectedDate;
    this.peticionFecha.fecha = new Date(
      fechaActual.getFullYear(),
      fechaActual.getMonth(),
      fechaActual.getDate()
    );
    this.peticionFecha.day = fechaActual.getDate();
    this.peticionFecha.month = fechaActual.getMonth() + 1;
    this.peticionFecha.year = fechaActual.getFullYear();
    this.peticionFecha.numeromesa = "0";
    this.restauranteService
      .getReservasActuales(this.peticionFecha)
      .subscribe((result) => {
        this.listaReservasActualesResult = [...result.data];
        if (this.verCanceladas) {
          this.listaReservasActuales = result.data;
        } else {
          this.listaReservasActuales = result.data.filter((x) => x.estado >= 0);
        }
        this.dividirListaReservasInicial();
        this.cantidadReservas = this.listaReservasActuales.length;
        //Cuando obtenemos las reservas del comedor, comida y dia ya tenemos las mesas colocadas, por lo que podemos recorrerlas
        //y sacar bien sus colores
        //También obtenemos la hora de comienzo de la comida seleccionada
        var auxiHoraComienzo = this.selectedComida.horaInicio.getHours();
        var auxiMinutosComienzo = this.selectedComida.horaInicio.getMinutes();
        var auxMinutosActualesTotalInicio = auxiMinutosComienzo + auxiHoraComienzo * 60; //Aqui guardamos el comienzo de la comida actual
        //También obtenemos la hora de fin de la comida seleccionada
        var auxiHoraFin = this.selectedComida.horaFin.getHours();
        var auxiMinutosFin = this.selectedComida.horaFin.getMinutes();
        var auxMinutosActualesTotalFin = auxiMinutosFin + auxiHoraFin * 60; //Aqui guardamos el final de la comida actual
        if (auxMinutosActualesTotalFin < auxMinutosActualesTotalInicio) {
          //Hay que sumarle a la de fin 24 horas para que sea mayor, porque ha pasado a la madrugada del dia siguiente
          auxMinutosActualesTotalFin += 24 * 60;
        }
        //Por cada mesa
        this.listaMesasPanel.forEach(element => {
          element.color = "green"; //Esta libre
          var reservas = this.listaReservasActuales.filter(x => x.numeroMesas.split("-").filter(y => y.toString() == element.numMesa.toString()).length > 0 && x.estado > 0);
          if (reservas != undefined && reservas.length > 0) {
            //Tenemos una o más reservas en la mesa actual, hay que mirar si es naranja o roja
            //Para ello recorremos la mesa desde el comienzo de la comida hasta el final, así vemos los huecos que quedan, 
            //por tanto, para ello necesitamos ordenar las reservas por orden cronologico ascendente
            var reservasOrdenadas = reservas.sort(function (a, b) {
              var auxFechaA = new Date(a.fechaCompleta.replace("T", " "));
              var auxFechaB = new Date(b.fechaCompleta.replace("T", " "));
              if (auxFechaA > auxFechaB) return 1;
              else if (auxFechaA < auxFechaB) return -1;
              else return 0;
            });
            //Ahora usamos la configuracion para ver el color
            var fechaConfig, auxTiempoCompleto;
            if (this.configuracionRestaurante.modoReserva == 2) {
              if (this.configuracionRestaurante.rangoTiempoReservaActivado) {
                fechaConfig = this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":");
                auxTiempoCompleto = Number(fechaConfig[1]) + Number(fechaConfig[0]) * 60;
              } else {//Es una hora
                auxTiempoCompleto = Number(0) + Number(1) * 60;
              }
              //Como las reservas estan ordenadas y nunca podremos poner una detras, solo delante, solo necesitamos mirar la primera
              var reserva = reservasOrdenadas[0];
              var tiempoInicioReserva = reserva.fechaCompleta.split("T")[1].split(":");
              var tiempoCompletoReserva = Number(tiempoInicioReserva[0]) * 60 + Number(tiempoInicioReserva[1]);
              if ((tiempoCompletoReserva - auxMinutosActualesTotalInicio) >= auxTiempoCompleto) {
                //Si la diferencia que hay entre la primera reserva y la hora de inicio de la comida es mayor o igual que el tiempo
                //minimo de la configuracion, es naranja porque se puede meter otra.
                element.color = "orange";
              }
              else {
                //No cabe ninguna, ha de ser roja
                element.color = "red";
              }
            } else {
              if (this.configuracionRestaurante.rangoTiempoReservaActivado) {
                fechaConfig = this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":");
                auxTiempoCompleto = Number(fechaConfig[1]) + Number(fechaConfig[0]) * 60;
                //En este caso hay que mirar todas las reservas teniendo en cuenta que cada reserva solo va a durar el tiempo que ponga en config
                var tiempoActual = auxMinutosActualesTotalInicio; //Empezamos en inicio y vamos recorriendo hasta llegar al final
                while (tiempoActual < auxMinutosActualesTotalFin) { //Mientras no lleguemos a la fecha de fin seguimos avanzando
                  //Revisamos si hay alguna reserva en esa hora
                  var auxHora = Math.floor(tiempoActual / 60);
                  var auxMinutos = tiempoActual % 60;
                  var filtrada = reservasOrdenadas.filter(x => Number(x.fechaCompleta.split("T")[1].split(":")[0]) == auxHora &&
                    Number(x.fechaCompleta.split("T")[1].split(":")[1]) == auxMinutos);
                  if (filtrada.length == 0) {
                    //Hemos encontrado el hueco, naranja y salimos
                    element.color = "orange";
                    break;
                  }
                  //Ahora actualizamos el tiempoActual
                  tiempoActual += auxTiempoCompleto; //Le vamos sumando el tiempo que desde config esta marcado para que dure cada reserva
                }
                if (tiempoActual >= auxMinutosActualesTotalFin) {
                  //Hemos salido sin encontrar ningun hueco, por lo que está ocupada
                  element.color = "red";
                }
              } else {//En este caso la reserva es del cliente toda la comida, por lo que es directamente roja
                element.color = "red";
              }
            }
          } else {
            //no hay reservas en la mesa actual, será verde
            element.color = "green";
          }
        });
      });
  }

  public onTabSelect(e: SelectEvent) {
    this.selectedTab = e.index;
    if (e.index == 0) {
      this.calendarioActivo = true;
      this.comidaActiva = true;
      this.verCanceladasActivo = true;
      this.verComedor = true;
      let fechaActual = new Date();
      this.selectedDate = new Date(
        fechaActual.getFullYear(),
        fechaActual.getMonth(),
        fechaActual.getDate()
      );
      this.restauranteService.getMesasPlano(this.selectedComedor.id, this.selectedPerfil).subscribe((result) => {
        this.colocarMesasEnPlano(result.data);
        this.cargarDatosIniciales(); //Aqui se cogen las reservas actuales
      });
    } else if (e.index == 1) {
      // Todas las reservas , sin calendario, sin comedor
      this.calendarioActivo = false;
      this.comidaActiva = false;
      this.verCanceladasActivo = true;
      this.verComedor = false;
      //COGER LAS RESERVAS DEL DIA ACTUAL EN ADELANTE
      this.getReservasFromRestaurante();
    } else if (e.index == 2) {
      this.calendarioActivo = false;
      this.comidaActiva = false;
      this.verCanceladasActivo = false;
      this.verComedor = false;
      //COGER LAS RESERVAS CANCELADAS TODAS
      this.getReservasCanceladas();
    } else if (e.index == 3) {
      this.calendarioActivo = false;
      this.comidaActiva = false;
      this.verCanceladasActivo = true;
      this.verComedor = false;
      //COGER LAS RESERVAS DEL DIA HACIA ATRAS (SIN CONTAR EL DIA ACTUAL)
      this.getReservasHistorial();
    }
  }

  public onActiveDateChange(value: Date): void {
    this.log('activeDateChange', value);
  }

  public onActiveViewChange(view: CalendarView): void {
    this.events = [`activeViewChanged: ${view}`].concat(this.events);
  }

  public onNavigate(data: {
    activeView: CalendarView;
    focusedDate: Date;
  }): void {
    this.events = [
      `navigate: ${data.activeView}, ${data.focusedDate.toLocaleDateString(
        'en-US'
      )}`,
    ].concat(this.events);
  }

  public onChange(value: Date): void {
    this.selectedNumeroMesa = 0;
    //Para mirar las comidas
    var comidaPersonalizada = new ComidaPersonalizadaDia();
    comidaPersonalizada.fecha = new Date(this.dateToYYYYMMDDtHHmmSSz(value));
    comidaPersonalizada.idRestaurante = this.idRestaurante;
    this.restauranteService
      .getComidasCalendarioFecha(comidaPersonalizada)
      .subscribe((result) => {
        if (result.data.length > 0) {
          var auxiLista = result.data;
          auxiLista.forEach(element => {
            element.horaFin = new Date(element.horaFin.replace("T", " "));
            element.horaInicio = new Date(element.horaInicio.replace("T", " "));
          });
          this.listaTiposComida = result.data;
          if (this.listaTiposComida.length > 0) {
            this.selectedComida = this.listaTiposComida[0];
            this.selectedDate = value;
            this.restauranteService.getMesasPlano(this.selectedComedor.id, this.selectedPerfil).subscribe((result) => {
              this.colocarMesasEnPlano(result.data);
              this.cargarDatosIniciales(); //Aqui se cogen las reservas actuales
            });
            this.log('change', value);
          }
        } else if (result.data.length == 0) {
          this.restauranteService
            .GetComidasFromRestaurante(this.idRestaurante.toString())
            .subscribe((result) => {
              var auxiLista = result.data;
              auxiLista.forEach(element => {
                element.horaFin = new Date(element.horaFin.replace("T", " "));
                element.horaInicio = new Date(element.horaInicio.replace("T", " "));
              });
              this.listaTiposComida = result.data;
              if (this.listaTiposComida.length > 0) {
                this.selectedComida = this.listaTiposComida[0];
                this.selectedDate = value;
                this.restauranteService.getMesasPlano(this.selectedComedor.id, this.selectedPerfil).subscribe((result) => {
                  this.colocarMesasEnPlano(result.data);
                  this.cargarDatosIniciales(); //Aqui se cogen las reservas actuales
                });
                this.log('change', value);
              }
            });
        }
      });

  }

  private log(event: string, value?: Date): void {
    this.events.unshift(`${event}${this.formatValue(value)}`);
  }

  private formatValue(value?: Date): string {
    return value ? ` - ${this.intl.formatDate(value, 'd')}` : '';
  }
  public getReservasFromDate() {
    this.peticionFecha.fecha = this.selectedDate;
    this.peticionFecha.day = this.selectedDate.getDate();
    this.peticionFecha.month = this.selectedDate.getMonth() + 1;
    this.peticionFecha.year = this.selectedDate.getFullYear();
    this.restauranteService
      .getReservasActuales(this.peticionFecha)
      .subscribe((result) => {
        this.listaReservasActualesResult = [...result.data];
        if (this.verCanceladas) {
          this.listaReservasActuales = result.data;
        } else {
          this.listaReservasActuales = result.data.filter((x) => x.estado >= 0);
        }
        this.cantidadReservas = this.listaReservasActuales.length;
      });
  }
  public getReservasCanceladas() {
    this.restauranteService
      .getReservasCanceladas(this.peticionFecha)
      .subscribe((result) => {
        this.listaReservasCanceladas = result.data;
        this.cantidadReservas = this.listaReservasCanceladas.length;
      });
  }
  public getReservasFromRestaurante() {
    this.restauranteService
      .getReservasProximas(this.idRestaurante)
      .subscribe((result) => {
        this.listaReservasTotalesResult = [...result.data];
        if (this.verCanceladas) {
          this.listaReservasTotales = result.data;
        } else {
          this.listaReservasTotales = result.data.filter((x) => x.estado >= 0);
        }
        this.cantidadReservas = this.listaReservasTotales.length;
      });
  }
  public getReservasHistorial() {
    this.restauranteService
      .getReservasHistorial(this.idRestaurante)
      .subscribe((result) => {
        this.listaReservasHistorialResult = [...result.data];
        if (this.verCanceladas) {
          this.listaReservasHistorial = result.data;
        } else {
          this.listaReservasHistorial = result.data.filter((x) => x.estado >= 0);
        }
        this.cantidadReservas = this.listaReservasHistorial.length;
      });
  }

  cancelarReserva() {
    if (this.selectedReserva.estado == -1) {
      this.alertService.info(
        this.translateService.instant('restaurante.reservayacancelada')
      );
      return;
    }
    this.restauranteService
      .cancelarReserva(this.selectedReserva.idReserva)
      .subscribe((result) => {
        if (!result.error) {
          this.modalReference.close();
          this.alertService.success(
            this.translateService.instant('restaurante.reservacancelada')
          );
          switch (this.selectedTab) {
            case 0:
              this.cargarDatosIniciales(); //Aqui se cogen las reservas actuales
              break;
            case 1:
              this.getReservasFromRestaurante();
              break;
            case 2:
              this.getReservasCanceladas();
              break;
            case 3:
              this.getReservasHistorial();
              break;
            default:
              this.cargarDatosIniciales();
          }
        } else {
          this.modalReference.close;
          this.alertService.success(
            this.translateService.instant('restaurante.errorcancelarreserva')
          );
        }
      });
  }

  cargarDatosRestaurante() {
    //Load Calendarios
    this.restauranteService
      .getCalendarioLaboral(this.idRestaurante)
      .subscribe((result) => {
        this.calendarioLaboralRestaurante = result;
        //Aquí podemos bloquear directamente las fechas del calendario, ya que las comparten todos los comedores
        this.bloquearFechasCalendario();
        //Si el día está bloqueado, no se puede establecer
        var auxi = new Date();
        auxi.setDate(this.selectedDate.getDate());
        while (!this.fechaBloqueada(auxi)) {
          auxi.setDate(auxi.getDate() + 1);
        }
        this.selectedDate = auxi;
        //Para mirar las comidas
        var comidaPersonalizada = new ComidaPersonalizadaDia();
        comidaPersonalizada.fecha = new Date(this.dateToYYYYMMDDtHHmmSSz(auxi));
        comidaPersonalizada.idRestaurante = this.idRestaurante;
        var r1, r2, r3;
        //Load Comedores
        this.restauranteService
          .getComedores(this.idRestaurante)
          .subscribe((result) => {
            this.listaComedores = result.data;
            if (this.listaComedores.length == 1) {
              this.verComedoresActivo = false;
              this.verComedores = false;
            } else if (this.listaComedores.length > 1) {
              this.verComedoresActivo = true;
              this.verComedores = true;
            }
            if (this.listaComedores.length > 0) {
              this.selectedComedor = this.listaComedores[0];
              this.restauranteService.getComedoresByIdLang(this.selectedComedor.id, 1).subscribe((result: any) => { //el idioma nos da igual porque no vamos a usar el nombre
                if (result.data.length > 0) {
                  this.fondo = result.data[0].urlFondo;
                  this.tamano_panel_cm = new Tamano_Panel(Number(result.data[0].ancho), Number(result.data[0].alto));
                  var x = 800;
                  var y = 800;
                  if (this.tamano_panel_cm.alto >= this.tamano_panel_cm.ancho) {
                    x = (this.tamano_panel_cm.ancho * 800) / this.tamano_panel_cm.alto;
                    this.escala_panel = this.tamano_panel_cm.ancho / 1000;
                  } else if (this.tamano_panel_cm.alto < this.tamano_panel_cm.ancho) {
                    y = (this.tamano_panel_cm.alto * 800) / this.tamano_panel_cm.ancho;
                    this.escala_panel = this.tamano_panel_cm.alto / 1000;
                  }
                  this.tamano_panel = new Tamano_Panel(x, y);
                  this.restauranteService.getPerfiles(this.selectedComedor.id).subscribe(result => {
                    console.log(result);
                    this.listaPerfiles = result.data;
                    this.selectedPerfil = result.data.filter(perfil => perfil.isSeleccionado === true)[0].id;
                    console.log(this.selectedPerfil);
                    this.restauranteService.getMesasPlano(this.selectedComedor.id, this.selectedPerfil).subscribe((result) => {
                      this.colocarMesasEnPlano(result.data);
                      r1 = true;
                      if (r1 && r2 && r3)
                        this.cargarDatosIniciales();
                    });
                  });
                }
              });

            }
          });
        //Load Comidas
        this.restauranteService
          .getComidasCalendarioFecha(comidaPersonalizada)
          .subscribe((result) => {
            if (result.data.length > 0) {
              var auxiLista = result.data;
              auxiLista.forEach(element => {
                element.horaFin = new Date(element.horaFin.replace("T", " "));
                element.horaInicio = new Date(element.horaInicio.replace("T", " "));
              });
              this.listaTiposComida = result.data;
              if (this.listaTiposComida.length > 0) {
                this.selectedComida = this.listaTiposComida[0];
                r2 = true;
                if (r1 && r2 && r3)
                  this.cargarDatosIniciales();
              }
            } else if (result.data.length == 0) {
              this.restauranteService
                .GetComidasFromRestaurante(this.idRestaurante.toString())
                .subscribe((result) => {
                  var auxiLista = result.data;
                  auxiLista.forEach(element => {
                    element.horaFin = new Date(element.horaFin.replace("T", " "));
                    element.horaInicio = new Date(element.horaInicio.replace("T", " "));
                  });
                  this.listaTiposComida = result.data;
                  if (this.listaTiposComida.length > 0) {
                    this.selectedComida = this.listaTiposComida[0];
                    r2 = true;
                    if (r1 && r2 && r3)
                      this.cargarDatosIniciales();
                  }
                });
            }
          });

        //Load Configuracion
        this.restauranteService
          .getConfiguracionFromRestaurante(this.idRestaurante)
          .subscribe((result) => {
            this.configuracionRestaurante = result.data[0];
            r3 = true;
            if (r1 && r2 && r3)
              this.cargarDatosIniciales();
          });



      });
  }

  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return (
      año +
      '-' +
      this.addZero(mes) +
      '-' +
      this.addZero(dia) +
      'T' +
      this.addZero(hora) +
      ':' +
      this.addZero(minutos) +
      ':' +
      this.addZero(segundos) +
      'Z'
    );
  }

  addZero(num) {
    if (num < 10) return '0' + num;
    else return num;
  }

  bloquearFechasCalendario() {
    this.disabledDates = (date: Date): boolean => {
      date.setMilliseconds(0);
      date.setSeconds(0);
      date.setMinutes(0);
      date.setHours(0);
      var diaActivo = true;
      var weekDay = date.getDay();
      switch (weekDay) {
        case 0: {
          diaActivo = !this.calendarioLaboralRestaurante.data[0].activoDomingo;
          break;
        }
        case 1: {
          diaActivo = !this.calendarioLaboralRestaurante.data[0].activoLunes;
          break;
        }
        case 2: {
          diaActivo = !this.calendarioLaboralRestaurante.data[0].activoMartes;
          break;
        }
        case 3: {
          diaActivo = !this.calendarioLaboralRestaurante.data[0].activoMiercoles;
          break;
        }
        case 4: {
          diaActivo = !this.calendarioLaboralRestaurante.data[0].activoJueves;
          break;
        }
        case 5: {
          diaActivo = !this.calendarioLaboralRestaurante.data[0].activoViernes;
          break;
        }
        case 6: {
          diaActivo = !this.calendarioLaboralRestaurante.data[0].activoSabado;
          break;
        }
      }
      //Ahora miramos los periodos
      this.calendarioLaboralRestaurante.fechas.forEach(element => {
        var auxiFecha = new Date();
        var elementoSplitted = element.fecha.split("T")[0].split("-");
        auxiFecha.setFullYear(elementoSplitted[0]);
        auxiFecha.setMonth(elementoSplitted[1] - 1); //El mes empieza en cero en javascript
        auxiFecha.setDate(elementoSplitted[2]);
        auxiFecha.setMilliseconds(0);
        auxiFecha.setSeconds(0);
        auxiFecha.setMinutes(0);
        auxiFecha.setHours(0);
        if (date.getTime() == auxiFecha.getTime()) {
          diaActivo = !element.activo;
        }
      });
      return diaActivo;
    }
  }

  fechaBloqueada(date: Date) {
    date.setMilliseconds(0);
    date.setSeconds(0);
    date.setMinutes(0);
    date.setHours(0);
    var diaActivo = true;
    var weekDay = date.getDay();
    switch (weekDay) {
      case 0: {
        diaActivo = this.calendarioLaboralRestaurante.data[0].activoDomingo;
        break;
      }
      case 1: {
        diaActivo = this.calendarioLaboralRestaurante.data[0].activoLunes;
        break;
      }
      case 2: {
        diaActivo = this.calendarioLaboralRestaurante.data[0].activoMartes;
        break;
      }
      case 3: {
        diaActivo = this.calendarioLaboralRestaurante.data[0].activoMiercoles;
        break;
      }
      case 4: {
        diaActivo = this.calendarioLaboralRestaurante.data[0].activoJueves;
        break;
      }
      case 5: {
        diaActivo = this.calendarioLaboralRestaurante.data[0].activoViernes;
        break;
      }
      case 6: {
        diaActivo = this.calendarioLaboralRestaurante.data[0].activoSabado;
        break;
      }
    }
    //Ahora miramos los periodos
    this.calendarioLaboralRestaurante.fechas.forEach(element => {
      var auxiFecha = new Date();
      var elementoSplitted = element.fecha.split("T")[0].split("-");
      auxiFecha.setFullYear(elementoSplitted[0]);
      auxiFecha.setMonth(elementoSplitted[1] - 1); //El mes empieza en cero en javascript
      auxiFecha.setDate(elementoSplitted[2]);
      auxiFecha.setMilliseconds(0);
      auxiFecha.setSeconds(0);
      auxiFecha.setMinutes(0);
      auxiFecha.setHours(0);
      if (date.getTime() == auxiFecha.getTime()) {
        diaActivo = element.activo;
      }
    });
    return diaActivo;
  }

  crearReservaModal() {
    this.nombreComensalReserva = '';
    this.numTelefono = '';
    this.reservaObservaciones = '';
    this.numComensales = 1;
    this.modalReference = this.modalService.open(this.popUpCrearReserva, {
      backdrop: 'static',
      size: 'xxl',
      keyboard: false,
      centered: true,
    });
  }

  crearReserva() {
    if (this.numTelefono != "" && !this.numTelefono.match("^\\+?[0-9]{9,12}$")) {
      this.alertService.warn(
        this.translateService.instant('restaurante.telefonoIncorrecto')
      );
      return;
    }

    if (this.configuracionRestaurante.modoReserva == 1 && !this.configuracionRestaurante.rangoTiempoReservaActivado) {
      //En este caso la mesa es del cliente toda la comida y no se eligen horas, 
      //por lo que le ponemos la hora de comienzo de la comida como hora de reserva
      this.horaSeleccionada = { hora: this.selectedComida.horaInicio };
    }

    if (
      this.nombreComensalReserva.length <= 0 ||
      (this.selectedComedor == null || this.selectedComedor == undefined) ||
      (this.selectedComida == null || this.selectedComida == undefined) ||
      (this.mesaSeleccionada == null || this.mesaSeleccionada == undefined) ||
      (this.horaSeleccionada == null || this.horaSeleccionada == undefined)
    ) {
      this.alertService.warn(
        this.translateService.instant('restaurante.rellenadatos')
      );
      return;
    } else {
      //Primero coger las mesas asociadas a la mesa escogida
      var auxMesas = this.listaMesasPanel.filter(x => this.mesaSeleccionada.mesas.split("-").filter(y => y.toString() == x.numMesa.toString()).length > 0);

      //Se mira la configuracion previamente, por lo que si ha llegado hasta aquí es que puede hacer la reserva sí o sí
      this.myReserva.idRestaurante = this.idRestaurante;
      this.myReserva.fecha = this.selectedDate ? this.selectedDate : new Date();
      this.myReserva.fecha.setHours(this.horaSeleccionada.hora.getHours());
      this.myReserva.fecha.setMinutes(this.horaSeleccionada.hora.getMinutes());
      this.myReserva.fecha.setSeconds(0);
      this.myReserva.comedor = this.selectedComedor;
      this.myReserva.tipoComida = this.selectedComida;
      this.myReserva.mesa = auxMesas;
      this.myReserva.nombreUsuario = this.nombreComensalReserva;
      this.myReserva.numComensales = this.numComensales;
      this.myReserva.telefono = this.numTelefono;
      this.myReserva.observaciones = this.reservaObservaciones;
      this.restauranteService
        .crearReservaAdmin(this.myReserva)
        .subscribe((result) => {
          if (!result.error) {
            this.modalReference.close();
            this.alertService.success(
              this.translateService.instant('restaurante.reservacreada')
            );
            this.cargarDatosIniciales();
            this.nombreComensalReserva = '';
            this.numTelefono = '';
            this.reservaObservaciones = '';
          } else {
            this.alertService.error(
              this.translateService.instant('restaurante.errorcrearreserva')
            );
          }
        });
    }
  }

  onVerCanceladasChange(e) {
    switch (this.selectedTab) {
      case 0:
        if (this.verCanceladas) {
          this.listaReservasActuales = this.listaReservasActualesResult;
        } else {
          this.listaReservasActuales = this.listaReservasActualesResult.filter(
            (elem) => elem.estado >= 0
          );
        }
        this.cantidadReservas = this.listaReservasActuales.length;
        break;
      case 1:
        if (this.verCanceladas) {
          this.listaReservasTotales = this.listaReservasTotalesResult;
        } else {
          this.listaReservasTotales = this.listaReservasTotalesResult.filter(
            (elem) => elem.estado >= 0
          );
        }
        this.cantidadReservas = this.listaReservasTotales.length;
        break;
      case 3:
        if (this.verCanceladas) {
          this.listaReservasHistorial = this.listaReservasHistorialResult;
        } else {
          this.listaReservasHistorial = this.listaReservasHistorialResult.filter(
            (elem) => elem.estado >= 0
          );
        }
        this.cantidadReservas = this.listaReservasHistorial.length;
        break;
      default:
        if (this.verCanceladas) {
          this.listaReservasActuales = this.listaReservasActualesResult;
        } else {
          this.listaReservasActuales = this.listaReservasActualesResult.filter(
            (elem) => elem.estado >= 0
          );
        }
        this.cantidadReservas = this.listaReservasActuales.length;
    }
    this.dividirListaReservasInicial();
  }

  onSelectedComedorChange(e) {
    this.restauranteService
      .getMesasComedor(this.selectedComedor.id)
      .subscribe((result) => {
        this.listaMesasComedor = result.data;
        if (this.listaMesasComedor.length > 0)
          this.selectedMesa = this.listaMesasComedor[0];
      });
  }

  /**
   * Funcion que actua de hadleer para el click hecho sore una celula del grid. Este se encargaria de mostar los 
   * detalles de dicha reserver y permitir modificarla
   * @param e Elemento del grid seleccionado
   */
  cellClick(e) {
    if (e.columnIndex < 10) {
      this.selectedReserva = e.dataItem;
      this.selectedReserva.id = e.dataItem.idReserva;
      this.selectedReserva.numComensales = e.dataItem.comensales;
      var comedorReserva = this.listaComedores.filter(x => x.nombre == this.selectedReserva.nombreComedor)[0];
      this.selectedReserva.comedor = comedorReserva;
      var comidaReserva = this.listaTiposComida.filter(x => x.nombre == this.selectedReserva.nombreComida)[0];
      this.selectedReserva.comida = comidaReserva;
      var numeroMesas = this.listaMesasConectadas.filter(x => x.mesas == this.selectedReserva.numeroMesas)[0];
      this.selectedReserva.mesas = numeroMesas;
      //Calculamos la fecha de inicio de la reserva para poner esa como hora seleccionada
      this.selectedReserva.horaSeleccionada = { hora: new Date(e.dataItem.fechaCompleta.replace("T", " ")) };
      var horasTotalReservaInicio = this.selectedReserva.horaSeleccionada.hora.getHours() * 60 + this.selectedReserva.horaSeleccionada.hora.getMinutes();
      var horasTotalReservaFin = comidaReserva.horaFin.getHours() * 60 + comidaReserva.horaFin.getMinutes(); //Por defecto ponemos la de la comida

      //Calcular posibles horas
      var mesaBuscada;
      for (var i = 0; i < this.listaMesasPanel.length; i++) {
        var element = this.listaMesasPanel[i];
        if (this.selectedReserva.mesas.mesas.split("-").filter(y => y.toString() == element.numMesa.toString()).length > 0) {
          mesaBuscada = element;
          break;
        }
      }

      //Primero cogemos solo las reservas de la mesa seleccionada, pero sin contar la reserva que estamos editando
      var reservas = this.listaReservasActuales.filter(x =>
        x.numeroMesas.split("-").filter(y => y.toString() == mesaBuscada.numMesa.toString()).length > 0 && x.estado > 0
        && x.idReserva != this.selectedReserva.idReserva);
      if (reservas.length == 0 || reservas == undefined) {
        //En el comedor y comida actual no hay más reservas con esa mesa, así que puede ponerla a la hora que quiera
        this.listaHorasAUtilizar = [];
        this.listaHoras.forEach(element => {
          this.listaHorasAUtilizar.push({ ...element })
        });
      } else {
        //Hay más reservas que la actual
        //Ahora las ordenamos por hora y revisamos qué debemos hacer segun la configuracion
        var reservasOrdenadas = reservas.sort(function (a, b) {
          var auxFechaA = new Date(a.fechaCompleta.replace("T", " "));
          var auxFechaB = new Date(b.fechaCompleta.replace("T", " "));
          if (auxFechaA > auxFechaB) return 1;
          else if (auxFechaA < auxFechaB) return -1;
          else return 0;
        });
        if (this.configuracionRestaurante.modoReserva == 2) {
          //Primero vamos a ver si hay alguna reserva detras de la nuestra
          //Para entonces sacar su hora tope de fin y dejarle que la modifique en ese rango
          //Basta con coger la primera cuya hora sea mayor porque estan ordenadas
          var auxiReservaFin = reservasOrdenadas.filter(x => (Number(x.fechaCompleta.split("T")[1].split(":")[0]) * 60 +
            Number(x.fechaCompleta.split("T")[1].split(":")[1])) > horasTotalReservaInicio);
          if (auxiReservaFin.length != 0 && auxiReservaFin != undefined) {
            //Hemos encontrado una, editamos la hora de tope
            horasTotalReservaFin = Number(auxiReservaFin[0].fechaCompleta.split("T")[1].split(":")[0]) * 60 +
              Number(auxiReservaFin[0].fechaCompleta.split("T")[1].split(":")[1]); //Es el comienzo de la otra
          }
          var reserva = reservasOrdenadas[0];
          if (this.configuracionRestaurante.rangoTiempoReservaActivado) {
            //Aqui hay que mirar la primera reserva menos el tiempo minimo de reserva y bloquear las horas que vayan de ahí en adelante
            var tiempoActual = Number(reserva.fechaCompleta.split("T")[1].split(":")[1]) +
              Number(reserva.fechaCompleta.split("T")[1].split(":")[0]) * 60;  //Ponemos el tiempo inicio de la reserva
            tiempoActual -= Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[1]) +
              Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[0]) * 60;  //Tiempo avance en config
            var tiempoAQuitar = Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[1]) +
              Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[0]) * 60;  //Tiempo avance en config
          } else {
            //Aqui hay que mirar la primera reserva menos el tiempo minimo de reserva y bloquear las horas que vayan de ahí en adelante
            var tiempoActual = Number(reserva.fechaCompleta.split("T")[1].split(":")[1]) +
              Number(reserva.fechaCompleta.split("T")[1].split(":")[0]) * 60;  //Ponemos el tiempo inicio de la reserva
            tiempoActual -= Number(0) + Number(1) * 60;  //Tiempo avance en config
            var tiempoAQuitar = Number(0) + Number(1) * 60;  //Tiempo avance en config
          }
          horasTotalReservaFin -= tiempoAQuitar;
          this.listaHorasAUtilizar = [];
          this.listaHoras.forEach(element => {
            this.listaHorasAUtilizar.push({ ...element })
          });
          //Hay que bloquear desde la hora de inicio - la hora de la configuracion en adelante
          //hasta el final porque estamos en el modoReserva 2
          for (var i = 0; i < this.listaHorasAUtilizar.length; i++) {
            var element = this.listaHorasAUtilizar[i];
            if (element.hora.getHours() * 60 + element.hora.getMinutes() > tiempoActual) {
              if ((element.hora.getHours() * 60 + element.hora.getMinutes()) >= horasTotalReservaInicio &&
                (element.hora.getHours() * 60 + element.hora.getMinutes()) <= horasTotalReservaFin) {
                //Las dejamos
              } else {
                this.listaHorasAUtilizar[i] = undefined;
              }
              continue;
            }
          }
          this.listaHorasAUtilizar = this.listaHorasAUtilizar.filter(x => x != undefined);
        } else if (this.configuracionRestaurante.modoReserva == 1) {
          if (this.configuracionRestaurante.rangoTiempoReservaActivado) {
            //Aqui hay que ir avanzando en el tiempo marcado en config e ir bloqueando las que tengan una reserva en ese tiempo
            var tiempoActual = Number(this.selectedComida.horaInicio.getMinutes()) +
              Number(this.selectedComida.horaInicio.getHours()) * 60;  //Ponemos el tiempo inicio de la comida
            var tiempoFin = Number(this.selectedComida.horaFin.getMinutes()) +
              Number(this.selectedComida.horaFin.getHours()) * 60;  //Tiempo fin comida
            if (tiempoFin < tiempoActual) {
              //Hay que sumarle a la de fin 24 horas para que sea mayor, porque ha pasado a la madrugada del dia siguiente
              tiempoFin += 24 * 60;
            }
            var auxTiempoCompleto = Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[1]) +
              Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[0]) * 60;  //Tiempo avance en config
            this.listaHorasAUtilizar = [];
            this.listaHoras.forEach(element => {
              this.listaHorasAUtilizar.push({ ...element })
            });
            while (tiempoActual < tiempoFin) { //Mientras no lleguemos a la fecha de fin seguimos avanzando
              //Revisamos si hay alguna reserva en esa hora
              var auxHora = Math.floor(tiempoActual / 60);
              var auxMinutos = tiempoActual % 60;
              var auxHoraFinal = Math.floor(tiempoActual / 60) + Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[0]);
              var auxMinutosFinal = tiempoActual % 60 + Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[1]);
              var auxHoraCompletoInicio = auxHora * 60 + auxMinutos;
              var auxHoraCompletoFin = auxHoraFinal * 60 + auxMinutosFinal;
              var filtrada = reservasOrdenadas.filter(x => (Number(x.fechaCompleta.split("T")[1].split(":")[0]) * 60 +
                Number(x.fechaCompleta.split("T")[1].split(":")[1])) >= auxHoraCompletoInicio &&
                (Number(x.fechaCompleta.split("T")[1].split(":")[0]) * 60 +
                  Number(x.fechaCompleta.split("T")[1].split(":")[1])) < auxHoraCompletoFin);
              if (filtrada.length != 0) {
                //Hemos encontrado una reserva en esa hora, bloqueamos la hora
                var index = this.listaHorasAUtilizar.indexOf(this.listaHorasAUtilizar.filter(x => x.hora != undefined && x.hora.getHours() == auxHora && x.hora.getMinutes() == auxMinutos)[0]);
                if (index != -1) {
                  this.listaHorasAUtilizar[index].hora = undefined;
                }

              }
              //Ahora actualizamos el tiempoActual
              tiempoActual += auxTiempoCompleto; //Le vamos sumando el tiempo que desde config esta marcado para que dure cada reserva
            }
            this.listaHorasAUtilizar = this.listaHorasAUtilizar.filter(x => x.hora != undefined);
          } else {
            //NUNCA DEBERIA ENTRAR, EN ESTE CASO NO LE APARECEN LAS HORAS
          }
        }
      }
      //Calculamos capacidad maxima mesa
      var auxMesas = this.selectedReserva.mesas.mesas.split("-");
      var capacidadMesa = 0;
      auxMesas.forEach(element => { //Por cada numero de mesa, buscar su mesa
        var auxiMesa = this.listaMesasPanel.filter(x => x.numMesa.toString() == element.toString());
        //Ahora que tenemos la mesa, calculamos sus capacidad
        capacidadMesa += auxiMesa[0].capacidad;
      });
      this.selectedReserva.capacidadMax = capacidadMesa;
      this.modalReference = this.modalService.open(this.popUpAbrirReserva, {
        backdrop: 'static',
        size: 'xxl',
        keyboard: false,
        centered: true,
      });
    }
  }

  valueChangeCambioMesaReserva(e) {
    //En cambio mesas tenemos la nueva mesa seleccionada
    var mesaBuscada;
    for (var i = 0; i < this.listaMesasPanel.length; i++) {
      var element = this.listaMesasPanel[i];
      if (e.mesas.split("-").filter(y => y.toString() == element.numMesa.toString()).length > 0) {
        mesaBuscada = element;
        break;
      }
    }


    var horasTotalReservaInicio = this.selectedReserva.horaSeleccionada.hora.getHours() * 60 + this.selectedReserva.horaSeleccionada.hora.getMinutes();
    var horasTotalReservaFin = this.selectedReserva.comida.horaFin.getHours() * 60 + this.selectedReserva.comida.horaFin.getMinutes(); //Por defecto ponemos la de la comida

    //Primero cogemos solo las reservas de la mesa seleccionada, pero sin contar la reserva que estamos editando
    var reservas = this.listaReservasActuales.filter(x =>
      x.numeroMesas.split("-").filter(y => y.toString() == mesaBuscada.numMesa.toString()).length > 0 && x.estado > 0
      && x.idReserva != this.selectedReserva.idReserva);
    if (reservas.length == 0 || reservas == undefined) {
      //En el comedor y comida actual no hay más reservas con esa mesa, así que puede ponerla a la hora que quiera
      this.listaHorasAUtilizar = [];
      this.listaHoras.forEach(element => {
        this.listaHorasAUtilizar.push({ ...element })
      });
      if (this.listaHorasAUtilizar.length > 0) {
        this.selectedReserva.horaSeleccionada = this.listaHorasAUtilizar[0];
      }
    } else {
      //Hay más reservas que la actual
      //Ahora las ordenamos por hora y revisamos qué debemos hacer segun la configuracion
      var reservasOrdenadas = reservas.sort(function (a, b) {
        var auxFechaA = new Date(a.fechaCompleta.replace("T", " "));
        var auxFechaB = new Date(b.fechaCompleta.replace("T", " "));
        if (auxFechaA > auxFechaB) return 1;
        else if (auxFechaA < auxFechaB) return -1;
        else return 0;
      });
      if (this.configuracionRestaurante.modoReserva == 2) {
        //Primero vamos a ver si hay alguna reserva detras de la nuestra
        //Para entonces sacar su hora tope de fin y dejarle que la modifique en ese rango
        //Basta con coger la primera cuya hora sea mayor porque estan ordenadas
        var auxiReservaFin = reservasOrdenadas.filter(x => (Number(x.fechaCompleta.split("T")[1].split(":")[0]) * 60 +
          Number(x.fechaCompleta.split("T")[1].split(":")[1])) > horasTotalReservaInicio);
        if (auxiReservaFin.length != 0 && auxiReservaFin != undefined) {
          //Hemos encontrado una, editamos la hora de tope
          horasTotalReservaFin = Number(auxiReservaFin[0].fechaCompleta.split("T")[1].split(":")[0]) * 60 +
            Number(auxiReservaFin[0].fechaCompleta.split("T")[1].split(":")[1]); //Es el comienzo de la otra
        }
        var reserva = reservasOrdenadas[0];
        if (this.configuracionRestaurante.rangoTiempoReservaActivado) {
          //Aqui hay que mirar la primera reserva menos el tiempo minimo de reserva y bloquear las horas que vayan de ahí en adelante
          var tiempoActual = Number(reserva.fechaCompleta.split("T")[1].split(":")[1]) +
            Number(reserva.fechaCompleta.split("T")[1].split(":")[0]) * 60;  //Ponemos el tiempo inicio de la reserva
          tiempoActual -= Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[1]) +
            Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[0]) * 60;  //Tiempo avance en config
          var tiempoAQuitar = Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[1]) +
            Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[0]) * 60;  //Tiempo avance en config
        } else {
          //Aqui hay que mirar la primera reserva menos el tiempo minimo de reserva y bloquear las horas que vayan de ahí en adelante
          var tiempoActual = Number(reserva.fechaCompleta.split("T")[1].split(":")[1]) +
            Number(reserva.fechaCompleta.split("T")[1].split(":")[0]) * 60;  //Ponemos el tiempo inicio de la reserva
          tiempoActual -= Number(0) + Number(1) * 60;  //Tiempo avance en config
          var tiempoAQuitar = Number(0) + Number(1) * 60;  //Tiempo avance en config
        }
        horasTotalReservaFin -= tiempoAQuitar;
        this.listaHorasAUtilizar = [];
        this.listaHoras.forEach(element => {
          this.listaHorasAUtilizar.push({ ...element })
        });
        //Hay que bloquear desde la hora de inicio - la hora de la configuracion en adelante
        //hasta el final porque estamos en el modoReserva 2
        for (var i = 0; i < this.listaHorasAUtilizar.length; i++) {
          var element = this.listaHorasAUtilizar[i];
          if (element.hora.getHours() * 60 + element.hora.getMinutes() > tiempoActual) {
            if ((element.hora.getHours() * 60 + element.hora.getMinutes()) >= horasTotalReservaInicio &&
              (element.hora.getHours() * 60 + element.hora.getMinutes()) <= horasTotalReservaFin) {
              //Las dejamos
            } else {
              this.listaHorasAUtilizar[i] = undefined;
            }
            continue;
          }
        }
        this.listaHorasAUtilizar = this.listaHorasAUtilizar.filter(x => x != undefined);
        if (this.listaHorasAUtilizar.length > 0) {
          this.selectedReserva.horaSeleccionada = this.listaHorasAUtilizar[0];
        }
      } else if (this.configuracionRestaurante.modoReserva == 1) {
        if (this.configuracionRestaurante.rangoTiempoReservaActivado) {
          //Aqui hay que ir avanzando en el tiempo marcado en config e ir bloqueando las que tengan una reserva en ese tiempo
          var tiempoActual = Number(this.selectedComida.horaInicio.getMinutes()) +
            Number(this.selectedComida.horaInicio.getHours()) * 60;  //Ponemos el tiempo inicio de la comida
          var tiempoFin = Number(this.selectedComida.horaFin.getMinutes()) +
            Number(this.selectedComida.horaFin.getHours()) * 60;  //Tiempo fin comida
          if (tiempoFin < tiempoActual) {
            //Hay que sumarle a la de fin 24 horas para que sea mayor, porque ha pasado a la madrugada del dia siguiente
            tiempoFin += 24 * 60;
          }
          var auxTiempoCompleto = Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[1]) +
            Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[0]) * 60;  //Tiempo avance en config
          this.listaHorasAUtilizar = [];
          this.listaHoras.forEach(element => {
            this.listaHorasAUtilizar.push({ ...element })
          });
          while (tiempoActual < tiempoFin) { //Mientras no lleguemos a la fecha de fin seguimos avanzando
            //Revisamos si hay alguna reserva en esa hora
            var auxHora = Math.floor(tiempoActual / 60);
            var auxMinutos = tiempoActual % 60;
            var auxHoraFinal = Math.floor(tiempoActual / 60) + Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[0]);
            var auxMinutosFinal = tiempoActual % 60 + Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[1]);
            var auxHoraCompletoInicio = auxHora * 60 + auxMinutos;
            var auxHoraCompletoFin = auxHoraFinal * 60 + auxMinutosFinal;
            var filtrada = reservasOrdenadas.filter(x => (Number(x.fechaCompleta.split("T")[1].split(":")[0]) * 60 +
              Number(x.fechaCompleta.split("T")[1].split(":")[1])) >= auxHoraCompletoInicio &&
              (Number(x.fechaCompleta.split("T")[1].split(":")[0]) * 60 +
                Number(x.fechaCompleta.split("T")[1].split(":")[1])) < auxHoraCompletoFin);
            if (filtrada.length != 0) {
              //Hemos encontrado una reserva en esa hora, bloqueamos la hora
              var index = this.listaHorasAUtilizar.indexOf(this.listaHorasAUtilizar.filter(x => x.hora != undefined && x.hora.getHours() == auxHora && x.hora.getMinutes() == auxMinutos)[0]);
              if (index != -1) {
                this.listaHorasAUtilizar[index].hora = undefined;
              }
            }
            //Ahora actualizamos el tiempoActual
            tiempoActual += auxTiempoCompleto; //Le vamos sumando el tiempo que desde config esta marcado para que dure cada reserva
          }
          this.listaHorasAUtilizar = this.listaHorasAUtilizar.filter(x => x.hora != undefined);
          if (this.listaHorasAUtilizar.length > 0) {
            this.selectedReserva.horaSeleccionada = this.listaHorasAUtilizar[0];
          }
        } else {
          //NUNCA DEBERIA ENTRAR, EN ESTE CASO NO LE APARECEN LAS HORAS
        }
      }
    }


    //Calculamos capacidad maxima mesa
    var auxMesas = e.mesas.split("-");
    var capacidadMesa = 0;
    auxMesas.forEach(element => { //Por cada numero de mesa, buscar su mesa
      var auxiMesa = this.listaMesasPanel.filter(x => x.numMesa.toString() == element.toString());
      //Ahora que tenemos la mesa, calculamos sus capacidad
      capacidadMesa += auxiMesa[0].capacidad;
    });
    this.selectedReserva.capacidadMax = capacidadMesa;
    if (this.selectedReserva.numComensales > this.selectedReserva.capacidadMax) {
      this.selectedReserva.numComensales = this.selectedReserva.capacidadMax;
    }
  }

  actualizarReserva() {
    if (this.selectedReserva.telefono != "" && !this.selectedReserva.telefono.match("^\\+?[0-9]{9,12}$")) {
      this.alertService.warn(
        this.translateService.instant('restaurante.telefonoIncorrecto')
      );
      return;
    }
    //Primero coger las mesas asociadas a la mesa escogida
    var auxMesas = this.listaMesasPanel.filter(x => this.selectedReserva.mesas.mesas.split("-").filter(y => y.toString() == x.numMesa.toString()).length > 0);
    this.selectedReserva.mesa = auxMesas;
    this.selectedReserva.fechaCompleta = this.selectedReserva.horaSeleccionada.hora;

    this.restauranteService
      .actualizarReserva(this.selectedReserva)
      .subscribe((result) => {
        if (!result.error) {
          this.modalReference.close();
          this.alertService.success(
            this.translateService.instant('restaurante.reservacancelada')
          );
          switch (this.selectedTab) {
            case 0:
              this.cargarDatosIniciales(); //Aqui se cogen las reservas actuales
              break;
            case 1:
              this.getReservasFromRestaurante();
              break;
            case 2:
              this.getReservasCanceladas();
              break;
            case 3:
              this.getReservasHistorial();
              break;
            default:
              this.cargarDatosIniciales();
          }
        } else {
          this.modalReference.close;
          this.alertService.success(
            this.translateService.instant('restaurante.errorcancelarreserva')
          );
        }
      });
  }

  ngOnInit(): void {
  }

  //Funciones component mesas
  someOneDragging() {
    for (var i = 0; i < this.listaMesasPanel.length; i++)
      if (this.listaMesasPanel[i].isDragging)
        return true;
    return false;
  }

  async colocarMesasEnPlano(listaMesasPlano) {
    var th = this;
    this.listaMesasPanel = [];
    var auxVisitados = {}; //Diccionario con un false por cada enlace visitado (lo usamos al final para sacar los numeros de mesas)
    var auxNumerosMesa = {}; //Diccionario con los numeros de mesa de las mesas del panel(se utiliza al final de esta funcion)
    for (var i = 0; i < listaMesasPlano.length; i++) {

      var dataItem = listaMesasPlano[i];
      var mesa = {
        id: dataItem.idMesa.toString(),
        numMesa: dataItem.numMesa.toString(),
        nombre: dataItem.nombre,
        isDragging: false,
        positionX: dataItem.positionX,
        positionY: dataItem.positionY,
        width: dataItem.width * this.escala_panel,
        height: dataItem.height * this.escala_panel,
        capacidad: dataItem.capacidad,
        restaComensalesAlto: dataItem.restaComensalesAlto,
        restaComensalesLargo: dataItem.restaComensalesLargo,
        urlFoto: dataItem.urlFoto,
        relacionadoArriba: { estaRelacionado: dataItem.relacionadoArriba, idRelacionado: dataItem.relacionadoArriba_idRelacionado },
        relacionadoAbajo: { estaRelacionado: dataItem.relacionadoAbajo, idRelacionado: dataItem.relacionadoAbajo_idRelacionado },
        relacionadoIzquierda: { estaRelacionado: dataItem.relacionadoIzquierda, idRelacionado: dataItem.relacionadoIzquierda_idRelacionado },
        relacionadoDerecha: { estaRelacionado: dataItem.relacionadoDerecha, idRelacionado: dataItem.relacionadoDerecha_idRelacionado },
        conectable: (dataItem.conectable == null || dataItem.conectable == undefined || !dataItem.conectable) ? false : true
      };

      //Corregimos su capacidad
      var capacidad = mesa.capacidad;
      if (mesa.relacionadoArriba.estaRelacionado) {
        capacidad -= mesa.restaComensalesLargo;
      }
      if (mesa.relacionadoAbajo.estaRelacionado) {
        capacidad -= mesa.restaComensalesLargo;
      }
      if (mesa.relacionadoIzquierda.estaRelacionado) {
        capacidad -= mesa.restaComensalesAlto;
      }
      if (mesa.relacionadoDerecha.estaRelacionado) {
        capacidad -= mesa.restaComensalesAlto;
      }
      mesa.capacidad = capacidad;

      this.listaMesasPanel.push(mesa);
      auxVisitados[dataItem.idMesa.toString()] = false;
      auxNumerosMesa[dataItem.idMesa.toString()] = dataItem.numMesa.toString();

      //CREAR DRAG AND DROP EN EL DIV
      //Damos tiempo a que genere los divs
      await this.sleep(1);

      //Colocamos el elemento en una posicion x,y disponible
      //Conseguimos el offset del container
      var offsetContainer = $(".example-boundary").offset();
      var xContainer = offsetContainer.left;
      var yContainer = offsetContainer.top;
      $("#" + mesa.id).offset({ top: yContainer + mesa.positionY, left: xContainer + mesa.positionX });
      document.getElementById(mesa.id.toString()).addEventListener('click', function () { th.clickEvent(this.id) });
    }

    var auxConexiones = [];
    var auxiIndex = 0;

    this.listaMesasPanel.forEach(element => {
      if (!auxVisitados[element.id]) {
        var idsConectados = this.getIdsRelacionados([], element.id, true).sort();
        var numMesas = [];
        idsConectados.push(element.id);
        idsConectados.forEach(element2 => {
          auxVisitados[element2] = true;
          numMesas.push(auxNumerosMesa[element2]);
        });

        //Tenemos todos los numeros, los agregamos
        var auxiNumMesas = numMesas.sort((a, b) => a - b);
        auxConexiones.push({ mesas: auxiNumMesas.join("-"), id: auxiIndex });
        auxiIndex++;
      }
    });
    this.listaMesasConectadas = auxConexiones;
  }

  /**
   * Metodo que ditacmina se he hecho un click o 2 en una mesa
   * @param idMesa id de la mesa seleccionada
   */
  clickEvent(idMesa: string) {
    // if timeout exists, we know it's a second click within the timeout duration
    // AKA double click
    if (this.clickTimeout) {
      // first, clear the timeout to stop it from completing
      clearTimeout(this.clickTimeout);
      this.clickTimeout = null;
      this.clickMesa(idMesa);
    } else {
      // if timeout doesn't exist, we know it's first click
      this.clickTimeout = setTimeout(() => {
        this.clickTimeout = null;
        console.log("Recargando reservas");
        this.getReservasMesa(idMesa);
      }, 400);
    }
  }

  /**
   * Funcion que crea el modal para crear una reserva sobre la mesa seleccionada, 
   * la funcion tb controla si la mesa puede o no aceptar la reserva y el rngo de horas
   * de la misma
   * @param idMesa id  mesa seleccionada
   * @returns error si la mesa no puede aceptar mas reservas
   */
  clickMesa(idMesa) {
    //Cogemos la mesa con ese id
    var mesaBuscada = this.listaMesasPanel.filter(x => Number(x.id) == Number(idMesa));
    var numMesaBuscada = "-1";
    if (mesaBuscada.length > 0 && mesaBuscada[0]?.numMesa != undefined) {
      if (mesaBuscada[0]?.color != undefined && mesaBuscada[0].color == "red") {
        //No se puede reservar esta mesa
        this.alertService.error("La mesa " + mesaBuscada[0].numMesa +" no es capaz de aceptar mas reservas!")
        return;
      } else if (mesaBuscada[0]?.color != undefined && mesaBuscada[0].color == "green") {
        //Si es verde no tiene mas reservas, no tenemos que bloquear horas
        this.listaHorasAUtilizar = [];
        this.listaHoras.forEach(element => {
          this.listaHorasAUtilizar.push({ ...element })
        });
        if (this.listaHorasAUtilizar.length > 0) {
          this.horaSeleccionada = this.listaHorasAUtilizar[0];
        }
      } else {
        //Es naranja, bloqueamos horas
        //Primero cogemos solo las reservas de la mesa seleccionada
        var reservas = this.listaReservasActuales.filter(x => x.numeroMesas.split("-").filter(y => y.toString() == mesaBuscada[0].numMesa.toString()).length > 0 && x.estado > 0);
        //Ahora las ordenamos por hora y revisamos qué debemos hacer segun la configuracion
        var reservasOrdenadas = reservas.sort(function (a, b) {
          var auxFechaA = new Date(a.fechaCompleta.replace("T", " "));
          var auxFechaB = new Date(b.fechaCompleta.replace("T", " "));
          if (auxFechaA > auxFechaB) return 1;
          else if (auxFechaA < auxFechaB) return -1;
          else return 0;
        });
        if (this.configuracionRestaurante.modoReserva == 2) {
          var reserva = reservasOrdenadas[0];
          if (this.configuracionRestaurante.rangoTiempoReservaActivado) {
            //Aqui hay que mirar la primera reserva menos el tiempo minimo de reserva y bloquear las horas que vayan de ahí en adelante
            var tiempoActual = Number(reserva.fechaCompleta.split("T")[1].split(":")[1]) +
              Number(reserva.fechaCompleta.split("T")[1].split(":")[0]) * 60;  //Ponemos el tiempo inicio de la reserva
            tiempoActual -= Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[1]) +
              Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[0]) * 60;  //Tiempo avance en config
          } else {
            //Aqui hay que mirar la primera reserva menos el tiempo minimo de reserva y bloquear las horas que vayan de ahí en adelante
            var tiempoActual = Number(reserva.fechaCompleta.split("T")[1].split(":")[1]) +
              Number(reserva.fechaCompleta.split("T")[1].split(":")[0]) * 60;  //Ponemos el tiempo inicio de la reserva
            tiempoActual -= Number(0) +
              Number(1) * 60;  //Tiempo avance en config
          }
          this.listaHorasAUtilizar = [];
          this.listaHoras.forEach(element => {
            this.listaHorasAUtilizar.push({ ...element })
          });
          //Hay que bloquear desde la hora de inicio - la hora de la configuracion en adelante
          //hasta el final porque estamos en el modoReserva 2
          for (var i = 0; i < this.listaHorasAUtilizar.length; i++) {
            var element = this.listaHorasAUtilizar[i];
            if (element.hora.getHours() * 60 + element.hora.getMinutes() > tiempoActual) {
              this.listaHorasAUtilizar[i] = undefined;
              continue;
            }
          }
          this.listaHorasAUtilizar = this.listaHorasAUtilizar.filter(x => x != undefined);
          if (this.listaHorasAUtilizar.length > 0) {
            this.horaSeleccionada = this.listaHorasAUtilizar[0];
          }
        } else if (this.configuracionRestaurante.modoReserva == 1) {
          if (this.configuracionRestaurante.rangoTiempoReservaActivado) {
            //Aqui hay que ir avanzando en el tiempo marcado en config e ir bloqueando las que tengan una reserva en ese tiempo
            var tiempoActual = Number(this.selectedComida.horaInicio.getMinutes()) +
              Number(this.selectedComida.horaInicio.getHours()) * 60;  //Ponemos el tiempo inicio de la comida
            var tiempoFin = Number(this.selectedComida.horaFin.getMinutes()) +
              Number(this.selectedComida.horaFin.getHours()) * 60;  //Tiempo fin comida
            if (tiempoFin < tiempoActual) {
              //Hay que sumarle a la de fin 24 horas para que sea mayor, porque ha pasado a la madrugada del dia siguiente
              tiempoFin += 24 * 60;
            }
            var auxTiempoCompleto = Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[1]) +
              Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[0]) * 60;  //Tiempo avance en config
            this.listaHorasAUtilizar = [];
            this.listaHoras.forEach(element => {
              this.listaHorasAUtilizar.push({ ...element })
            });
            while (tiempoActual < tiempoFin) { //Mientras no lleguemos a la fecha de fin seguimos avanzando
              //Revisamos si hay alguna reserva en esa hora
              var auxHora = Math.floor(tiempoActual / 60);
              var auxMinutos = tiempoActual % 60;
              var auxHoraFinal = Math.floor(tiempoActual / 60) + Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[0]);
              var auxMinutosFinal = tiempoActual % 60 + Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[1]);
              var auxHoraCompletoInicio = auxHora * 60 + auxMinutos;
              var auxHoraCompletoFin = auxHoraFinal * 60 + auxMinutosFinal;
              var filtrada = reservasOrdenadas.filter(x => (Number(x.fechaCompleta.split("T")[1].split(":")[0]) * 60 +
                Number(x.fechaCompleta.split("T")[1].split(":")[1])) >= auxHoraCompletoInicio &&
                (Number(x.fechaCompleta.split("T")[1].split(":")[0]) * 60 +
                  Number(x.fechaCompleta.split("T")[1].split(":")[1])) < auxHoraCompletoFin);
              if (filtrada.length != 0) {
                //Hemos encontrado una reserva en esa hora, bloqueamos la hora
                var index = this.listaHorasAUtilizar.indexOf(this.listaHorasAUtilizar.filter(x => x.hora != undefined && x.hora.getHours() == auxHora && x.hora.getMinutes() == auxMinutos)[0]);
                if (index != -1) {
                  this.listaHorasAUtilizar[index].hora = undefined;
                }
              }
              //Ahora actualizamos el tiempoActual
              tiempoActual += auxTiempoCompleto; //Le vamos sumando el tiempo que desde config esta marcado para que dure cada reserva
            }
            this.listaHorasAUtilizar = this.listaHorasAUtilizar.filter(x => x.hora != undefined);
            if (this.listaHorasAUtilizar.length > 0) {
              this.horaSeleccionada = this.listaHorasAUtilizar[0];
            }
          } else {
            //Aqui nunca deberia entrar, pues ya estaria marcada como roja
          }
        }
      }
      numMesaBuscada = mesaBuscada[0].numMesa.toString();
      var mesaAuxi = this.listaMesasConectadas.filter(x => x.mesas.split("-").filter(y => y.toString() == numMesaBuscada.toString()).length > 0);
      if (mesaAuxi.length > 0) {
        this.mesaSeleccionada = mesaAuxi[0];
        //Hay que calcular la capacidad del conjunto
        var capacidadAux = 0;
        var auxiMesas = this.mesaSeleccionada.mesas.split("-");
        auxiMesas.forEach(element => { //Por cada una vamos a coger su mesa para calcular su capacidad
          var auxiMesaSeleccionada = this.listaMesasPanel.filter(x => x.numMesa.toString() == element.toString());
          if (auxiMesaSeleccionada.length > 0) {
            capacidadAux += auxiMesaSeleccionada[0].capacidad;
          }
        });
        this.numComensalesMax = capacidadAux;
      }
    }
    //Lanzar modal de la reserva
    this.crearReservaModal();
  }

  /**
   * Obtiene las reservas gestionadas para la mesa seleccionada
   * @param mesaId id de la mesa seleccionada
   */
  getReservasMesa(mesaId: string) {
    let mesaBuscada = this.listaMesasPanel.filter(x => +x.id == +mesaId)[0];
    this.selectedNumeroMesa = mesaBuscada.numMesa;
    this.peticionFecha.numeromesa = mesaBuscada.numMesa;
    this.peticionFecha.fecha = new Date(
      this.selectedDate.getFullYear(),
      this.selectedDate.getMonth(),
      this.selectedDate.getDate()
    )
    this.restauranteService.getReservasActuales(this.peticionFecha).subscribe((response) => {
      this.listaReservasActualesMesa = response.data.filter(reserva => reserva.isGestionado);
    });
  }

  valueChangeCambioMesa(cambio) {
    //En cambio mesas tenemos la nueva mesa seleccionada
    var mesaBuscada;
    for (var i = 0; i < this.listaMesasPanel.length; i++) {
      var element = this.listaMesasPanel[i];
      if (cambio.mesas.split("-").filter(y => y.toString() == element.numMesa.toString()).length > 0) {
        mesaBuscada = element;
        break;
      }
    }

    if (mesaBuscada?.color != undefined && mesaBuscada.color == "red") {
      //No se puede reservar esta mesa
      //Ponemos otra que sí se pueda
      var mesa = this.listaMesasPanel.filter(x => x.color != "red");
      if (mesa != undefined && mesa.length > 0) {
        this.botonCrearReserva = false;
        setTimeout(() => { //Esperamos un poco para que se actualice bien en la vista
          console.log("Recarga Reservas 2");
          this.mesaSeleccionada = this.listaMesasConectadas.filter(x => x.mesas.split("-").filter(y => y.toString() == mesa[0].numMesa.toString()).length > 0)[0];
          this.botonCrearReserva = true;
        }, 500);
      }
      return;
    } else if (mesaBuscada?.color != undefined && mesaBuscada.color == "green") {
      //Si es verde no tiene mas reservas, no tenemos que bloquear horas
      this.mesaSeleccionada = cambio;
      this.listaHorasAUtilizar = [];
      this.listaHoras.forEach(element => {
        this.listaHorasAUtilizar.push({ ...element })
      });
      if (this.listaHorasAUtilizar.length > 0) {
        this.horaSeleccionada = this.listaHorasAUtilizar[0];
      }
    } else {
      this.mesaSeleccionada = cambio;
      //Es naranja, bloqueamos horas
      //Primero cogemos solo las reservas de la mesa seleccionada
      var reservas = this.listaReservasActuales.filter(x => x.numeroMesas.split("-").filter(y => y.toString() == mesaBuscada.numMesa.toString()).length > 0 && x.estado > 0);
      //Ahora las ordenamos por hora y revisamos qué debemos hacer segun la configuracion
      var reservasOrdenadas = reservas.sort(function (a, b) {
        var auxFechaA = new Date(a.fechaCompleta.replace("T", " "));
        var auxFechaB = new Date(b.fechaCompleta.replace("T", " "));
        if (auxFechaA > auxFechaB) return 1;
        else if (auxFechaA < auxFechaB) return -1;
        else return 0;
      });
      if (this.configuracionRestaurante.modoReserva == 2) {
        var reserva = reservasOrdenadas[0];
        if (this.configuracionRestaurante.rangoTiempoReservaActivado) {
          //Aqui hay que mirar la primera reserva menos el tiempo minimo de reserva y bloquear las horas que vayan de ahí en adelante
          var tiempoActual = Number(reserva.fechaCompleta.split("T")[1].split(":")[1]) +
            Number(reserva.fechaCompleta.split("T")[1].split(":")[0]) * 60;  //Ponemos el tiempo inicio de la reserva
          tiempoActual -= Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[1]) +
            Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[0]) * 60;  //Tiempo avance en config
        } else {
          //Aqui hay que mirar la primera reserva menos el tiempo minimo de reserva y bloquear las horas que vayan de ahí en adelante
          var tiempoActual = Number(reserva.fechaCompleta.split("T")[1].split(":")[1]) +
            Number(reserva.fechaCompleta.split("T")[1].split(":")[0]) * 60;  //Ponemos el tiempo inicio de la reserva
          tiempoActual -= Number(0) +
            Number(1) * 60;  //Tiempo avance en config
        }
        this.listaHorasAUtilizar = [];
        this.listaHoras.forEach(element => {
          this.listaHorasAUtilizar.push({ ...element })
        });
        //Hay que bloquear desde la hora de inicio - la hora de la configuracion en adelante
        //hasta el final porque estamos en el modoReserva 2
        for (var i = 0; i < this.listaHorasAUtilizar.length; i++) {
          var element = this.listaHorasAUtilizar[i];
          if (element.hora.getHours() * 60 + element.hora.getMinutes() > tiempoActual) {
            this.listaHorasAUtilizar[i].hora = undefined;
            continue;
          }
        }
        this.listaHorasAUtilizar = this.listaHorasAUtilizar.filter(x => x != undefined);
        if (this.listaHorasAUtilizar.length > 0) {
          this.horaSeleccionada = this.listaHorasAUtilizar[0];
        }
      } else if (this.configuracionRestaurante.modoReserva == 1) {
        if (this.configuracionRestaurante.rangoTiempoReservaActivado) {
          //Aqui hay que ir avanzando en el tiempo marcado en config e ir bloqueando las que tengan una reserva en ese tiempo
          var tiempoActual = Number(this.selectedComida.horaInicio.getMinutes()) +
            Number(this.selectedComida.horaInicio.getHours()) * 60;  //Ponemos el tiempo inicio de la comida
          var tiempoFin = Number(this.selectedComida.horaFin.getMinutes()) +
            Number(this.selectedComida.horaFin.getHours()) * 60;  //Tiempo fin comida
          if (tiempoFin < tiempoActual) {
            //Hay que sumarle a la de fin 24 horas para que sea mayor, porque ha pasado a la madrugada del dia siguiente
            tiempoFin += 24 * 60;
          }
          var auxTiempoCompleto = Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[1]) +
            Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[0]) * 60;  //Tiempo avance en config
          this.listaHorasAUtilizar = [];
          this.listaHoras.forEach(element => {
            this.listaHorasAUtilizar.push({ ...element })
          });
          while (tiempoActual < tiempoFin) { //Mientras no lleguemos a la fecha de fin seguimos avanzando
            //Revisamos si hay alguna reserva en esa hora
            var auxHora = Math.floor(tiempoActual / 60);
            var auxMinutos = tiempoActual % 60;
            var auxHoraFinal = Math.floor(tiempoActual / 60) + Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[0]);
            var auxMinutosFinal = tiempoActual % 60 + Number(this.configuracionRestaurante.rangoTiempoReserva.split("T")[1].split(":")[1]);
            var auxHoraCompletoInicio = auxHora * 60 + auxMinutos;
            var auxHoraCompletoFin = auxHoraFinal * 60 + auxMinutosFinal;
            var filtrada = reservasOrdenadas.filter(x => (Number(x.fechaCompleta.split("T")[1].split(":")[0]) * 60 +
              Number(x.fechaCompleta.split("T")[1].split(":")[1])) >= auxHoraCompletoInicio &&
              (Number(x.fechaCompleta.split("T")[1].split(":")[0]) * 60 +
                Number(x.fechaCompleta.split("T")[1].split(":")[1])) < auxHoraCompletoFin);
            if (filtrada.length != 0) {
              //Hemos encontrado una reserva en esa hora, bloqueamos la hora
              var index = this.listaHorasAUtilizar.indexOf(this.listaHorasAUtilizar.filter(x => x.hora != undefined && x.hora.getHours() == auxHora && x.hora.getMinutes() == auxMinutos)[0]);
              if (index != -1) {
                this.listaHorasAUtilizar[index].hora = undefined;
              }
            }
            //Ahora actualizamos el tiempoActual
            tiempoActual += auxTiempoCompleto; //Le vamos sumando el tiempo que desde config esta marcado para que dure cada reserva
          }
          this.listaHorasAUtilizar = this.listaHorasAUtilizar.filter(x => x.hora != undefined);
          if (this.listaHorasAUtilizar.length > 0) {
            this.horaSeleccionada = this.listaHorasAUtilizar[0];
          }
        } else {
          //Aqui nunca deberia entrar, pues ya estaria marcada como roja
        }
      }
    }

    //Hay que calcular la capacidad del conjunto
    var capacidadAux = 0;
    var auxiMesas = cambio.mesas.split("-");
    auxiMesas.forEach(element => { //Por cada una vamos a coger su mesa para calcular su capacidad
      var auxiMesaSeleccionada = this.listaMesasPanel.filter(x => x.numMesa.toString() == element.toString());
      if (auxiMesaSeleccionada.length > 0) {
        capacidadAux += auxiMesaSeleccionada[0].capacidad;
      }
    });
    this.numComensalesMax = capacidadAux;
    if (this.numComensales > this.numComensalesMax)
      this.numComensales = this.numComensalesMax;
  }

  valueChangeCambioHora(e) {
    this.horaSeleccionada = e;
  }

  movimientoDrag(mesaDiv, dragStop = false) {
    this.calcularColores(mesaDiv, dragStop)

    //Si el div esta relacionado con otro div, movemos el otro div tambien
    var puntoActual = this.calcularPuntoDiv(mesaDiv);
    var idsRelacionados = this.getIdsRelacionados([], mesaDiv.id, true);
    for (var i = 0; i < idsRelacionados.length; i++) {
      var pixelsMovidoEjeX = puntoActual.x - this.puntoAnterior.x;
      var pixelsMovidoEjeY = puntoActual.y - this.puntoAnterior.y;

      var offsetDivRelacionado = $("#" + idsRelacionados[i]).offset();
      var xDivRelacionado = offsetDivRelacionado.left;
      var yDivRelacionado = offsetDivRelacionado.top;

      $("#" + idsRelacionados[i]).offset({ top: yDivRelacionado + pixelsMovidoEjeY, left: xDivRelacionado + pixelsMovidoEjeX });
    }
  }

  calcularColores(mesaDiv, dragStop = false) {

    var th = this;

    var idMesaMov = mesaDiv.id;
    var idsRelacionados = this.getIdsRelacionados([], idMesaMov, true);

    //Calculamos los bordes de los 4 clips del div que estamos moviendo y del propio div
    var bordesMov = th.calcularBordesClipMesa(mesaDiv);

    $('.example-box2:not(#' + mesaDiv.id + ')').each(function () {

      //Calculamos los bordes de los 4 clips de los divs estaticos y del propio div
      var bordesDiv = th.calcularBordesClipMesa(this);

      //Calculamos los bordes del div de los divs estaticos para ver si alguno interfiere con el del div en movimiento
      var a = bordesMov[0];
      var b = bordesDiv[0];
      if (a.left >= b.right || a.top >= b.bottom || a.right <= b.left || a.bottom <= b.top) {
      } else {
        //Si los divs estan relacionados, no sera una collision para nosotros
        //Esto pasa porque cuando estan relacionados y uno replica los movimientos del otro, no lo hace exacto y a veces hace que se solapen por 1 pixel
        var mesa = th.listaMesasPanel.find(x => x.id == mesaDiv.id);
        if (!(mesa.relacionadoArriba.estaRelacionado && mesa.relacionadoArriba.idRelacionado == this.id) &&
          !(mesa.relacionadoAbajo.estaRelacionado && mesa.relacionadoAbajo.idRelacionado == this.id) &&
          !(mesa.relacionadoIzquierda.estaRelacionado && mesa.relacionadoIzquierda.idRelacionado == this.id) &&
          !(mesa.relacionadoDerecha.estaRelacionado && mesa.relacionadoDerecha.idRelacionado == this.id)) {
          a.collision = true;
          b.collision = true;
        }
      }

      //Calculamos los bordes de los 4 clips de los divs estaticos para ver si alguno interfiere con los del div en movimiento
      for (var i = 1; i < bordesMov.length; i++) {
        for (var j = 1; j < bordesDiv.length; j++) {
          var a = bordesMov[i];
          var b = bordesDiv[j];
          if (a.left >= b.right || a.top >= b.bottom || a.right <= b.left || a.bottom <= b.top) {
          } else {
            a.collision = true;
            b.collision = true;
            //Si algun clip esta junto con otro y se ha soltado el elemento (dragStop = true), los unimos
            if (dragStop) {
              var idMesa_a = bordesMov[0].div[0].id;
              var idMesa_b = bordesDiv[0].div[0].id;
              var mesa_a = th.listaMesasPanel.find(x => x.id == idMesa_a);
              var mesa_b = th.listaMesasPanel.find(x => x.id == idMesa_b);
              //Los unimos solo si no es rojo (es decir, no esta solapado con otra mesa)
              //Y si ambas mesas son conectables
              //Tambien miramos que ningun div relacionado esta en rojo
              var hacemosClip = true;
              if (bordesMov[0].div.children(".capaColor").css("background") == "rgb(255, 0, 0) none repeat scroll 0% 0% / auto padding-box border-box")
                hacemosClip = false;
              if (hacemosClip)
                for (var k = 0; k < idsRelacionados.length; k++) {
                  if ($("#" + idsRelacionados[k]).children(".capaColor").css("background") == "rgb(255, 0, 0) none repeat scroll 0% 0% / auto padding-box border-box")
                    hacemosClip = false;
                }
              if (!mesa_a.conectable || !mesa_b.conectable) {
                hacemosClip = false;
                th.revertirMovimiento(mesaDiv);
              }
              if (hacemosClip) {
                if (a.lado == "arriba" && b.lado == "arriba" && !mesa_a.relacionadoArriba.estaRelacionado && !mesa_b.relacionadoArriba.estaRelacionado) {
                  mesa_a.relacionadoArriba = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoArriba = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "arriba" && b.lado == "abajo" && !mesa_a.relacionadoArriba.estaRelacionado && !mesa_b.relacionadoAbajo.estaRelacionado) {
                  mesa_a.relacionadoArriba = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoAbajo = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "arriba" && b.lado == "izquierda" && !mesa_a.relacionadoArriba.estaRelacionado && !mesa_b.relacionadoIzquierda.estaRelacionado) {
                  mesa_a.relacionadoArriba = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoIzquierda = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "arriba" && b.lado == "derecha" && !mesa_a.relacionadoArriba.estaRelacionado && !mesa_b.relacionadoDerecha.estaRelacionado) {
                  mesa_a.relacionadoArriba = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoDerecha = { estaRelacionado: true, idRelacionado: idMesa_a };
                }

                if (a.lado == "abajo" && b.lado == "arriba" && !mesa_a.relacionadoAbajo.estaRelacionado && !mesa_b.relacionadoArriba.estaRelacionado) {
                  mesa_a.relacionadoAbajo = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoArriba = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "abajo" && b.lado == "abajo" && !mesa_a.relacionadoAbajo.estaRelacionado && !mesa_b.relacionadoAbajo.estaRelacionado) {
                  mesa_a.relacionadoAbajo = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoAbajo = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "abajo" && b.lado == "izquierda" && !mesa_a.relacionadoAbajo.estaRelacionado && !mesa_b.relacionadoIzquierda.estaRelacionado) {
                  mesa_a.relacionadoAbajo = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoIzquierda = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "abajo" && b.lado == "derecha" && !mesa_a.relacionadoAbajo.estaRelacionado && !mesa_b.relacionadoDerecha.estaRelacionado) {
                  mesa_a.relacionadoAbajo = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoDerecha = { estaRelacionado: true, idRelacionado: idMesa_a };
                }

                if (a.lado == "izquierda" && b.lado == "arriba" && !mesa_a.relacionadoIzquierda.estaRelacionado && !mesa_b.relacionadoArriba.estaRelacionado) {
                  mesa_a.relacionadoIzquierda = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoArriba = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "izquierda" && b.lado == "abajo" && !mesa_a.relacionadoIzquierda.estaRelacionado && !mesa_b.relacionadoAbajo.estaRelacionado) {
                  mesa_a.relacionadoIzquierda = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoAbajo = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "izquierda" && b.lado == "izquierda" && !mesa_a.relacionadoIzquierda.estaRelacionado && !mesa_b.relacionadoIzquierda.estaRelacionado) {
                  mesa_a.relacionadoIzquierda = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoIzquierda = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "izquierda" && b.lado == "derecha" && !mesa_a.relacionadoIzquierda.estaRelacionado && !mesa_b.relacionadoDerecha.estaRelacionado) {
                  mesa_a.relacionadoIzquierda = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoDerecha = { estaRelacionado: true, idRelacionado: idMesa_a };
                }

                if (a.lado == "derecha" && b.lado == "arriba" && !mesa_a.relacionadoDerecha.estaRelacionado && !mesa_b.relacionadoArriba.estaRelacionado) {
                  mesa_a.relacionadoDerecha = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoArriba = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "derecha" && b.lado == "abajo" && !mesa_a.relacionadoDerecha.estaRelacionado && !mesa_b.relacionadoAbajo.estaRelacionado) {
                  mesa_a.relacionadoDerecha = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoAbajo = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "derecha" && b.lado == "izquierda" && !mesa_a.relacionadoDerecha.estaRelacionado && !mesa_b.relacionadoIzquierda.estaRelacionado) {
                  mesa_a.relacionadoDerecha = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoIzquierda = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "derecha" && b.lado == "derecha" && !mesa_a.relacionadoDerecha.estaRelacionado && !mesa_b.relacionadoDerecha.estaRelacionado) {
                  mesa_a.relacionadoDerecha = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoDerecha = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
              }
            }
          }
        }
      }

      //Si algun div estatico esta colisionando con el div en movimiento, lo resaltamos
      var b = bordesDiv[0];
      if (b.collision)
        b.div.children(".capaColor").css("background", "red");
      else
        b.div.children(".capaColor").css("background", "white");

      //Si algun clip de algun div estatico colisiona con algun clip del div en movimiento, lo resaltamos (solo si el div general no colisiona)
      //Si el div esta asociado a otro (es amarillo), entonces no lo resaltamos
      var divGeneralColisiona = b.collision;
      for (var j = 1; j < bordesDiv.length; j++) {
        var b = bordesDiv[j];
        if (b.div.css("background") != "rgb(255, 255, 0) none repeat scroll 0% 0% / auto padding-box border-box")
          if (b.collision && !divGeneralColisiona)
            b.div.css("background", "blue");
          else
            b.div.css("background", "#aaa");
      }

    });

    //Si algun div estatico esta colisionando con el div en movimiento, lo resaltamos
    var a = bordesMov[0];
    if (a.collision)
      a.div.children(".capaColor").css("background", "red");
    else
      a.div.children(".capaColor").css("background", "white");

    //Si algun clip del div en movimiento colisiona con algun clip de algun div estatico, lo resaltamos (solo si el div general no colisiona)
    //Si el div esta asociado a otro (es amarillo), entonces no lo resaltamos
    var divGeneralColisiona = a.collision;
    for (var i = 1; i < bordesMov.length; i++) {
      var a = bordesMov[i];
      if (a.div.css("background") != "rgb(255, 255, 0) none repeat scroll 0% 0% / auto padding-box border-box")
        if (a.collision && !divGeneralColisiona)
          a.div.css("background", "blue");
        else
          a.div.css("background", "#aaa");
    }

    //Si algun div esta fuera del container (alguno que se mueva por que esta relacionado) lo ponemos en rojo
    $(".example-box2").each(function () {
      var puntosDiv = th.calcularPuntoDiv(this);
      var heightDiv = $(this).height();
      var widthDiv = $(this).width();

      if (puntosDiv.x < 0 || puntosDiv.x + widthDiv > th.tamano_panel.ancho || puntosDiv.y < 0 || puntosDiv.y + heightDiv > th.tamano_panel.alto)
        $(this).children(".capaColor").css("background", "red");
    });

    //Miramos si el div relacionado esta solapado con algun otro div, y lo ponemos en rojo
    for (var i = 0; i < idsRelacionados.length; i++) {
      var idRealcionado = idsRelacionados[i];
      var bordesDivRelacionado = th.calcularBordesClipMesa($("#" + idRealcionado));
      $('.example-box2:not(#' + idRealcionado + ')').each(function () {
        var idMesa = $(this)[0].id;
        if (idMesa != idMesaMov) {
          var bordesDiv = th.calcularBordesClipMesa(this);
          var a = bordesDivRelacionado[0];
          var b = bordesDiv[0];
          if (a.left >= b.right || a.top >= b.bottom || a.right <= b.left || a.bottom <= b.top) { }
          else {
            $("#" + idRealcionado).children(".capaColor").css("background", "red");
            $(this).children(".capaColor").css("background", "red");
          }
        }
      });
    }

  }

  calcularBordesClipMesa(mesaDiv) {
    //Conseguimos el offset del container
    var offsetContainer = $(".example-boundary").offset();
    var xContainer = offsetContainer.left;
    var yContainer = offsetContainer.top;

    //Sacamos los bordes del div
    var offset_Div = $(mesaDiv).offset();
    var xPos_Div = offset_Div.left - xContainer;
    var yPos_Div = offset_Div.top - yContainer;
    var height_Div = $(mesaDiv).height();
    var width_Div = $(mesaDiv).width();
    var borde_Div = {
      left: xPos_Div,
      right: xPos_Div + width_Div,
      top: yPos_Div,
      bottom: yPos_Div + height_Div,
      div: $(mesaDiv),
      collision: false,
      lado: false
    }

    //Sacamos los cuatro bordes de cada clip del div 
    //Arriba
    var offset_Arriba = $(mesaDiv).children(".clipArriba").offset();
    var xPos_Arriba = offset_Arriba.left - xContainer;
    var yPos_Arriba = offset_Arriba.top - yContainer;
    var height_Arriba = $(mesaDiv).children(".clipArriba").height();
    var width_Arriba = $(mesaDiv).children(".clipArriba").width();
    var borde_Arriba = {
      left: xPos_Arriba,
      right: xPos_Arriba + width_Arriba,
      top: yPos_Arriba,
      bottom: yPos_Arriba + height_Arriba,
      div: $(mesaDiv).children(".clipArriba"),
      collision: false,
      lado: "arriba"
    }
    //Abajo
    var offset_Abajo = $(mesaDiv).children(".clipAbajo").offset();
    var xPos_Abajo = offset_Abajo.left - xContainer;
    var yPos_Abajo = offset_Abajo.top - yContainer;
    var height_Abajo = $(mesaDiv).children(".clipAbajo").height();
    var width_Abajo = $(mesaDiv).children(".clipAbajo").width();
    var borde_Abajo = {
      left: xPos_Abajo,
      right: xPos_Abajo + width_Abajo,
      top: yPos_Abajo,
      bottom: yPos_Abajo + height_Abajo,
      div: $(mesaDiv).children(".clipAbajo"),
      collision: false,
      lado: "abajo"
    }
    //Izquierda
    var offset_Izquierda = $(mesaDiv).children(".clipIzquierda").offset();
    var xPos_Izquierda = offset_Izquierda.left - xContainer;
    var yPos_Izquierda = offset_Izquierda.top - yContainer;
    var height_Izquierda = $(mesaDiv).children(".clipIzquierda").height();
    var width_Izquierda = $(mesaDiv).children(".clipIzquierda").width();
    var borde_Izquierda = {
      left: xPos_Izquierda,
      right: xPos_Izquierda + width_Izquierda,
      top: yPos_Izquierda,
      bottom: yPos_Izquierda + height_Izquierda,
      div: $(mesaDiv).children(".clipIzquierda"),
      collision: false,
      lado: "izquierda"
    }
    //Derecha
    var offset_Derecha = $(mesaDiv).children(".clipDerecha").offset();
    var xPos_Derecha = offset_Derecha.left - xContainer;
    var yPos_Derecha = offset_Derecha.top - yContainer;
    var height_Derecha = $(mesaDiv).children(".clipDerecha").height();
    var width_Derecha = $(mesaDiv).children(".clipDerecha").width();
    var borde_Derecha = {
      left: xPos_Derecha,
      right: xPos_Derecha + width_Derecha,
      top: yPos_Derecha,
      bottom: yPos_Derecha + height_Derecha,
      div: $(mesaDiv).children(".clipDerecha"),
      collision: false,
      lado: "derecha"
    }

    return [borde_Div, borde_Arriba, borde_Abajo, borde_Izquierda, borde_Derecha];

  }

  calcularPuntoMesa(mesaDiv) {
    var mesa = this.listaMesasPanel.find(x => x.id == mesaDiv.id);

    mesa.isDragging = false;
    //Le guardamos la nueva posicion a cada mesa
    var offsetContainer = $(".example-boundary").offset();
    var xContainer = offsetContainer.left;
    var yContainer = offsetContainer.top;

    for (var i = 0; i < this.listaMesasPanel.length; i++) {
      var offsetMesa = $("#" + this.listaMesasPanel[i].id).offset();
      this.listaMesasPanel[i].positionX = offsetMesa.left - xContainer;
      this.listaMesasPanel[i].positionY = offsetMesa.top - yContainer;
    }
  }

  revertirMovimiento(divEnMovimiento) {
    //Revierte el movimiento que hemos hecho y tambien el de los divs relacionados
    //Primero calculamos cuanto se ha desplazado desde el inicio del drag
    var mesaActual = this.listaMesasPanel.find(x => x.id == divEnMovimiento.id);
    var puntoActual = this.calcularPuntoDiv(divEnMovimiento);

    var pixelsMovidoEjeX = puntoActual.x - this.puntoInicio.x;
    var pixelsMovidoEjeY = puntoActual.y - this.puntoInicio.y;

    //Revertimos el movimiento del div que estamos moviendo
    var offsetDivMovimeinto = $(divEnMovimiento).offset();
    var xDivMovimeinto = offsetDivMovimeinto.left;
    var yDivMovimeinto = offsetDivMovimeinto.top;
    $(divEnMovimiento).offset({ top: yDivMovimeinto - pixelsMovidoEjeY, left: xDivMovimeinto - pixelsMovidoEjeX });
    $(divEnMovimiento).children(".capaColor").css("background", "white");

    //Revertimos el movimiento de los divs relacionados
    var idsRelacionados = this.getIdsRelacionados([], divEnMovimiento.id, true);
    for (var i = 0; i < idsRelacionados.length; i++) {
      var offsetDivRelacionado = $("#" + idsRelacionados[i]).offset();
      var xDivRelacionado = offsetDivRelacionado.left;
      var yDivRelacionado = offsetDivRelacionado.top;
      $("#" + idsRelacionados[i]).offset({ top: yDivRelacionado - pixelsMovidoEjeY, left: xDivRelacionado - pixelsMovidoEjeX });
      $("#" + idsRelacionados[i]).children(".capaColor").css("background", "white");
    }

    //Les quitamos el color rojo a todos los divs
    $(".example-box2").each(function () {
      $(this).children(".capaColor").css("background", "white");
    });

    //Le quitamos el color azul a todos los clips azules
    $(".clipArriba, .clipAbajo, .clipIzquierda, .clipDerecha").each(function () {
      if ($(this).css("background") == "rgb(0, 0, 255) none repeat scroll 0% 0% / auto padding-box border-box") $(this).css("background", "#aaa");
    });

  }

  getIdsRelacionados(mesasAnalizadas, idMesa, primeraVez = false) {
    //Borramos los duplicados
    return [...new Set(this.getIdsRelacionados2([], idMesa, true))];
  }

  getIdsRelacionados2(mesasAnalizadas, idMesa, primeraVez = false) {

    //Recibe una mesa y devuelve los ids de las mesas que estan relacionadas con esta
    //Tiene en cuenta las mesas que no estan relacionadas directamente con la mesa, si no que estan
    //relacionadas con una mesa de la cual si esta relacionada

    var mesa = this.listaMesasPanel.find(x => x.id == idMesa);

    //Controlamos las mesas analizadas para no caer en un bucle
    if (mesasAnalizadas.includes(idMesa))
      return [];
    else {
      mesasAnalizadas.push(idMesa);
      var respuesta = [];
      if (!primeraVez) respuesta.push(idMesa);
      if (mesa.relacionadoArriba.estaRelacionado && !mesasAnalizadas.includes(mesa.relacionadoArriba.idRelacionado)) {
        respuesta.push(mesa.relacionadoArriba.idRelacionado);
        respuesta = respuesta.concat(this.getIdsRelacionados2(mesasAnalizadas, mesa.relacionadoArriba.idRelacionado));
      }
      if (mesa.relacionadoAbajo.estaRelacionado && !mesasAnalizadas.includes(mesa.relacionadoAbajo.idRelacionado)) {
        respuesta.push(mesa.relacionadoAbajo.idRelacionado);
        respuesta = respuesta.concat(this.getIdsRelacionados2(mesasAnalizadas, mesa.relacionadoAbajo.idRelacionado));
      }
      if (mesa.relacionadoIzquierda.estaRelacionado && !mesasAnalizadas.includes(mesa.relacionadoIzquierda.idRelacionado)) {
        respuesta.push(mesa.relacionadoIzquierda.idRelacionado);
        respuesta = respuesta.concat(this.getIdsRelacionados2(mesasAnalizadas, mesa.relacionadoIzquierda.idRelacionado));
      }
      if (mesa.relacionadoDerecha.estaRelacionado && !mesasAnalizadas.includes(mesa.relacionadoDerecha.idRelacionado)) {
        respuesta.push(mesa.relacionadoDerecha.idRelacionado);
        respuesta = respuesta.concat(this.getIdsRelacionados2(mesasAnalizadas, mesa.relacionadoDerecha.idRelacionado));
      }
      return respuesta;
    }

  }

  calcularPuntoDiv(mesaDiv) {
    //Conseguimos el offset del container
    var offsetContainer = $(".example-boundary").offset();
    var xContainer = offsetContainer.left;
    var yContainer = offsetContainer.top;

    //Sacamos los bordes del div
    var offset_Div = $(mesaDiv).offset();
    var xPos_Div = offset_Div.left - xContainer;
    var yPos_Div = offset_Div.top - yContainer;

    return { x: xPos_Div, y: yPos_Div };
  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  valueChangeComedor(e) {
    this.selectedComedor = e;
    //Cargar la configuracion de mesas del comedor
    this.restauranteService.getPerfiles(e.id).subscribe(result => {
      this.selectedPerfil = result.data.filter(perfil => perfil.isSeleccionado === true)[0].id;
      this.listaPerfiles = result.data;
      this.restauranteService.getComedoresByIdLang(e.id, 1).subscribe((result: any) => { //el idioma nos da igual porque no vamos a usar el nombre
        if (result.data.length > 0) {
          this.fondo = result.data[0].urlFondo;
          this.tamano_panel_cm = new Tamano_Panel(Number(result.data[0].ancho), Number(result.data[0].alto));
          var x = 800;
          var y = 800;
          if (this.tamano_panel_cm.alto >= this.tamano_panel_cm.ancho) {
            x = (this.tamano_panel_cm.ancho * 800) / this.tamano_panel_cm.alto;
            this.escala_panel = this.tamano_panel_cm.ancho / 1000;
          } else if (this.tamano_panel_cm.alto < this.tamano_panel_cm.ancho) {
            y = (this.tamano_panel_cm.alto * 800) / this.tamano_panel_cm.ancho;
            this.escala_panel = this.tamano_panel_cm.alto / 1000;
          }
          this.tamano_panel = new Tamano_Panel(x, y);
          this.restauranteService.getMesasPlano(e.id, this.selectedPerfil).subscribe((result) => {
            this.colocarMesasEnPlano(result.data);
            this.cargarDatosIniciales();
          });
        }
      });
    });
  }

  valueChangeComida(e) {
    this.selectedComida = e;
    //Cambiar colores reservas y cambiar horas para reservar
    this.cargarDatosIniciales();
  }

  btnBack() {
    this.router.navigate([
      'restaurantes-editar',
      { idorg: this.idOrg, idrecinto: this.idRestaurante },
    ]);
  }

  /**
   * Funcion que actualiza la lista de reservas actual
   */
  updateListaReservas() {
    this.restauranteService.getReservasActuales(this.peticionFecha).subscribe((response) => {
      let reservas = response.data;
      this.listaReservasActuales = this.getNuevasReservas(reservas);
    });
  }

  /**
   * Crea una lista de reservas con aquellas reservas pendientes de gestion al inicio de la lista, y las gestionadas despues ordenadas de manera descendente en base a la fecha de reserva.
   * @param listaReservaEntrante Nueva lista de reservas obtenida de la API.
   * @returns nueva lista de reservas.
   */
  getNuevasReservas(listaReservaEntrante: ReservaRestaurante[]) {
    let diferencia: number = listaReservaEntrante.length - this.listaReservasActuales.length;
    if (diferencia > 0) {
      this.listaNuevasReservas = listaReservaEntrante.slice(listaReservaEntrante.length - diferencia);
      return [...this.listaNuevasReservas, ...this.listaReservasActuales.sort(this.reservasComparator)];
    }
    return this.listaReservasActuales;
  }

  /**
   * Comparador de reservas en base a la fecha de cada 1
   * @param reserva1 Reserva que se compara
   * @param reserva2 Reserva contra la q se va a comparara
   * @returns Devuelve un entero en base al criterio requerido. 0: las fechas son iguales, 1: @param reserva1 es mayo a @param reserva2, -1: @param reserva1 es menor a @param reserva2
   */
  reservasComparator(reserva1: ReservaRestaurante, reserva2: ReservaRestaurante) {
    if (reserva1.fechaCompleta > reserva2.fechaCompleta) {
      return 1;
    }
    if (reserva1.fechaCompleta < reserva2.fechaCompleta) {
      return -1;
    }
    return 0;
  }

  /**
   * Gestiona la reserva seleccionada
   * @param reservaGestionada , Reserva de la fila actual en la que el boton de gestionar se ha pulsado
   */
  gestionarReserva(reservaGestionada) {
    reservaGestionada.isGestionado = true;
    if (reservaGestionada.numeroMesas === this.selectedNumeroMesa) {
      this.listaReservasActualesMesa.push(reservaGestionada);
      this.listaReservasActualesMesa = this.listaReservasActualesMesa.slice(0);
    }
    if (this.listaNuevasReservas.length > 0) {
      this.listaNuevasReservas = this.listaNuevasReservas.filter((reserva) => reserva.idReserva != reservaGestionada.idReserva);
    } else {
      this.listaNuevasReservas = this.listaReservasActuales.filter((reserva) => reserva.idReserva != reservaGestionada.idReserva && !reserva.isGestionado);
    }
    this.listaReservasActuales = this.listaReservasActuales.filter((reserva) => reserva.isGestionado);
    this.listaReservasActuales = this.listaReservasActuales.sort(this.reservasComparator);
    this.listaReservasActuales = [...this.listaNuevasReservas, ...this.listaReservasActuales];
    this.restauranteService.actualizarGestion(reservaGestionada).subscribe((result) => {});
  }

  /**
   * Asigna la clase correcta a cada fila de el grid en base a si la reserva esta gestionada o no
   * @param context Fila que actualmente se esta creando
   * @returns Devuelve el tipo de clase dependiendo de si la reserva esta gestionada o no
   */
  public rowCallback = (context: RowClassArgs) => {
    switch (context.dataItem.isGestionado) {
      case true:
        return "reserva-gestionada";
      default:
        return "reservas-sin-gestionar";
    }
  };

  /**
   * Divide la lista de reservas inicial en aquellas que necesita ser gestionadas y en aquellas que estan gestionadas
   */
  dividirListaReservasInicial() {
    this.listaNuevasReservas = this.listaReservasActuales.filter((reserva) => !reserva.isGestionado);
    this.listaReservasActuales = this.listaReservasActuales.filter((reserva) => reserva.isGestionado);
    this.listaReservasActuales.sort(this.reservasComparator);
    this.listaReservasActuales = [...this.listaNuevasReservas, ...this.listaReservasActuales];
  }

  onPerfilSelected($event) {
    console.log($event);
    this.selectedPerfil =  this.listaPerfiles[$event.index].id;
    this.restauranteService.getMesasPlano(this.selectedComedor.id, this.selectedPerfil).subscribe((result) => {
      this.colocarMesasEnPlano(result.data);
      this.cargarDatosIniciales();
    });
  }
}
