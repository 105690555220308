export class CamposIdioma {
    idOrg: string;
    idIdioma: number;
    descripcion:string;
    colaborador:string;
    faq:string;
    privacidad?:string;
    constructor(idOrg: string, idIdioma: number,descripcion:string,colaborador:string,faq:string,privacidad?:string) {
        this.idOrg = idOrg;
        this.idIdioma = idIdioma;
        this.descripcion = descripcion;
        this.colaborador = colaborador;
        this.faq=faq;
        this.privacidad = privacidad;
      }
}