import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DepartamentoUsuario } from '@app/_models/departamento-usuario';
import { AlertService, ContactosService, MenuService, UsuariosService } from '@app/_services';
import { AgentesService } from '@app/_services/agentes.service';
import { ContactosenteService } from '@app/_services/contactosente.service';
import { DepartamentosEnteService } from '@app/_services/departamentosente.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-contactos-ente-crear',
  templateUrl: './contactos-ente-crear.component.html',
  styleUrls: []
})
export class ContactosEnteCrearComponent implements OnInit {

  form: FormGroup;
  codigo: number;
  isAddMode: boolean;
  loading = false;
  submitted = false;
  idOrg:string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private contactosService: ContactosenteService,
    private alertService: AlertService,
    private menuService: MenuService
  ) {  }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('agentes.departamentos');
    
    console.log('LLega id');
    this.idOrg =this.route.snapshot.params['idorg'];

    this.route.params.subscribe((params) => {
      let idOrg = params.get('idorg');
  });

    this.isAddMode = !this.codigo;

    this.form = this.formBuilder.group({
        id:-1,
        idOrg: this.idOrg,
        nombre: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required,Validators.email]),
        phone: new FormControl('', [Validators.required]),
             
    });


  this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      // TODO This as a workaround.
      this.menuService.titulo = this.translateService.instant('contactos.contactos');
  });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }

    this.loading = true;
    this.crearDepartametno();
    }
    //this.idOrg

  private crearDepartametno() {
    console.log('Creando Departamento');
    this.contactosService.insert(this.form.value,this.idOrg)
        .subscribe((result) => { 
            if(!result.error) {
              this.alertService.success(this.translateService.instant('botones.creadocorrectamente'), { keepAfterRouteChange: true });
              this.router.navigate(['contactos-ente/', {idorg:this.idOrg}]);
            } else {
              this.alertService.error(this.translateService.instant('contactos.errorcrear'));
              this.loading = false;
            }
        });
  }


}
