export class Perfil {
    codigo: number;
    nombre1: string;
    nombre2: string;
    incidenciasAdmin: boolean;
    foroAdmin: boolean;
    avisosAdmin: boolean;
    mensajeriaAdmin: boolean;
    tablonAdmin: boolean;
    agendaAdmin: boolean;
    noticiasAdmin: boolean;
    admrecintos: boolean;
    admreservas: boolean;
    encuestasAdmin: boolean;
    reservas: boolean;
    confirmar: boolean;
    agenda: boolean;
    mensajeria: boolean;
    tablon: boolean;
    foro: boolean;
    encuestas: boolean;
    noticias: boolean;
    avisos: boolean;
    nreservasmes: number;
    limitereservas: number;
    sitios: boolean;
    incidencias: boolean;
    grupos: boolean;
}