export class Equipo {
    id: string;
    idOrg:string;
    urlFoto:string;
    nombre:string;
    descripcion:string;
    localidad:string;
    activo:boolean;
    created:Date;
    updated:Date;
    idIdioma:number;
    listaTraducciones:EquipoTrad[];
    updateFoto:boolean;
}

export class EquipoTrad{
    idIdioma:number;
    nombre:string;
    descripcion:string;
    nombreIdioma:string;
    filled:boolean;
}

export class Jugador{
    id:number;
    idUser:number;//Cuenta Usuario Vinculado
    idEquipo:string;
    idOrg:string;
    nombre:string='';
    apellidos:string='';
    nacionalidad:string='';
    fechaNacimiento:Date;
    altura:string='';
    peso:string='';
    posicion:string='';
    lateralidad:string='';
    fechaFinContrato:Date;
    observaciones:string;
    hasFacebook:boolean;
    urlFacebook:string='';
    hasTwitter:boolean;
    urlTwitter:string='';
    hasInstagram:boolean;
    urlInstagram:string='';
    hasTikTok:boolean;
    urlTikTok:string='';
    hasYoutube:boolean;
    urlYoutube:string='';
    hasCustomUrl:boolean;
    customUrl:string='';
    urlFoto:string;
    urlFondo:string;
    updateFoto:boolean;
    updateFondo:boolean;
    listaTraducciones:JugadorTrad[]=[];
    idIdioma:number;
}

export class JugadorTrad{
    idIdioma:number;
    posicion:string;
    lateralidad:string;
    nacionalidad:string;
    observaciones:string;
    nombreIdioma:string;
    filled:boolean;
} 