<form [formGroup]="form">
  <div class="card w-50">
    <div class="card-body">
      <h3 *ngIf="isAddMode">{{ 'enlaces.crear' | translate}}</h3>
      <h3 *ngIf="!isAddMode">{{ 'enlaces.editar' | translate}}</h3>
    </div>
    <div class="card-body">
      <div class="form-group col">
        <kendo-label text="{{ 'enlaces.nombre' | translate}}">
          <kendo-textbox formControlName="nombre2" placeholder="{{ 'enlaces.dato-eu' | translate }}"
            [ngClass]="{ 'is-invalid': submitted && f.nombre2.errors }">
          </kendo-textbox>
          <div *ngIf="submitted && f.nombre2.errors" class="invalid-feedback">
            <div *ngIf="f.nombre2.errors.required">{{ 'enlaces.nombre-eu-requerido' | translate}}</div>
          </div>
        </kendo-label>
      </div>
      <div class="form-group col">
        <kendo-textbox formControlName="nombre1" placeholder="{{ 'enlaces.dato-es' | translate }}"
          [ngClass]="{ 'is-invalid': submitted && f.nombre1.errors }">
        </kendo-textbox>
        <div *ngIf="submitted && f.nombre1.errors" class="invalid-feedback">
          <div *ngIf="f.nombre1.errors.required">{{ 'enlaces.nombrerequerido' | translate}}</div>
        </div>
      </div>
      <div class="form-group col">
        <kendo-label text="{{ 'enlaces.link' | translate}}">
          <kendo-textbox formControlName="link2" placeholder="{{ 'enlaces.dato-eu' | translate }}"
            [ngClass]="{ 'is-invalid': submitted && f.link2.errors }">
          </kendo-textbox>
          <div *ngIf="submitted && f.link2.errors" class="invalid-feedback">
            <div *ngIf="f.link2.errors.required">{{ 'enlaces.link-eu-requerido' | translate}}</div>
            <div *ngIf="f.link2.errors.pattern">{{ 'enlaces.formatourl' | translate}}</div>
          </div>
        </kendo-label>
      </div>
      <div class="form-group col">
        <kendo-textbox formControlName="link1" placeholder="{{ 'enlaces.dato-es' | translate }}"
          [ngClass]="{ 'is-invalid': submitted && f.link1.errors }">
        </kendo-textbox>
        <div *ngIf="submitted && f.link1.errors" class="invalid-feedback">
          <div *ngIf="f.link1.errors.required">{{ 'enlaces.linkrequerido' | translate}}</div>
          <div *ngIf="f.link1.errors.pattern">{{ 'enlaces.formatourl' | translate}}</div>
        </div>
      </div>
      <div class="form-group col">
        <div class="form-group col row">
          <kendo-label text="{{ 'enlaces.imagen' | translate}}">
          </kendo-label>
        </div>

        <div class="form-group col row">
          <kendo-fileselect formControlName="imagen" class="form-control" [restrictions]="restrictions"
            [multiple]="false" (select)="selectEventHandler($event)" (remove)="removeEventHandler($event)">
            <kendo-upload-messages cancel="{{ 'botones.cancelar' | translate }}"
              dropFilesHere="{{ 'enlaces.dropFoto' | translate }}"
              invalidFileExtension="{{ 'enlaces.extFotoIn' | translate }}"
              invalidMaxFileSize="{{ 'enlaces.invalidMax' | translate }}" remove="{{ 'enlaces.remFoto' | translate }}"
              select="{{ 'enlaces.slFoto' | translate }}">
            </kendo-upload-messages>
          </kendo-fileselect>
        </div>
        <br>
        <ng-template #notImEnl>
          <span><i>{{ 'enlaces.noimagen' | translate }}</i></span>
        </ng-template>
        <br>
        <div class="form-group col row">
          <div *ngIf="imagePreview; else notImEnl">
            <img [src]="imagePreview" style="max-height: 113px" />
          </div>
        </div>
        <div *ngIf="!isAddMode && !imagenBorrada && imagePreview && imagenAnt != []" class="form-group col row">
          <button kendoButton (click)="onClickEliminarImagen(content)" class="btn-sm btn-danger mr-1"
            [disabled]="isNotIns">{{ 'botones.eliminar' | translate}}</button>
        </div>
      </div>
      <div class="form-group col" style="padding-bottom: 1%;">
        <div class="form-group col row">
          <kendo-label text="{{ 'enlaces.activo' | translate}}">
          </kendo-label>
        </div>
        <div class="form-group col row">
          <kendo-switch formControlName="activo"></kendo-switch>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group">
    <button kendoButton [disabled]="loading" (click)="onSubmit()" class="btn mr-1  btn-primary">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ 'botones.guardar' | translate}}
    </button>
    <a routerLink="/enlaces" class="btn mr-1  btn-danger">{{ 'botones.cancelar' | translate}}</a>
  </div>
</form>


<!--POPUP ELIMINAR FOTO -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'enlaces.preguntaeliminarimagen' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'botones.no' |
      translate }}</button>
    <button type="button" class="btn btn-danger" (click)="eliminarImagen();">{{ 'botones.si' | translate }}</button>
  </div>
</ng-template>