import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MenuService, UsuariosTiposService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-editar-crear-usuario-tipo',
  templateUrl: './editar-crear-usuario-tipo.component.html'
})
export class EditarCrearUsuarioTipoComponent implements OnInit {

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  loading = false;
  submitted = false;

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private translateService: TranslateService,
      private usuariosTiposService: UsuariosTiposService,
      private alertService: AlertService,
      private menuService: MenuService
  ) {}
  
  ngOnInit() { 
      this.menuService.titulo = this.translateService.instant('usuarios.tipousuario');
      
      this.isAddMode = !this.id;

      this.form = this.formBuilder.group({
          id: this.route.snapshot.params['id'],
          nombre: new FormControl('', [Validators.required]),
          gestionUsuarios: new FormControl(0, [Validators.required])             
      });

      if (!this.isAddMode) {
          this.usuariosTiposService.getById(this.id)
              .pipe(first())
              .subscribe(x => this.form.patchValue(x));
      }
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
          // TODO This as a workaround.
          this.menuService.titulo = this.translateService.instant('usuarios.tipousuario');
        });
  }

    
  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
      this.submitted = true;

      // reset alerts on submit
      this.alertService.clear();

      // stop here if form is invalid
      if (this.form.invalid) {
          return;
      }

      this.loading = true;
      if (this.isAddMode) { 
          this.crearTipoUsuario();
      } else {
          this.updateTipoUsuario();
      }
      
  }

  private crearTipoUsuario() {
      this.usuariosTiposService.create(this.form.value)
          .pipe(first())
          .subscribe({
              next: () => {

                  this.alertService.success(this.translateService.instant('botones.creadocorrectamente'), { keepAfterRouteChange: true });
                  this.router.navigate(['../'], { relativeTo: this.route });

              },
              error: error => {
                  this.alertService.error(error);
                  this.loading = false;
              }
          });
  }

  private updateTipoUsuario() {
      this.usuariosTiposService.update(this.form.value)
          .pipe(first())
          .subscribe({
              next: () => {
                  this.alertService.success(this.translateService.instant('botones.editadocorrectamente'), { keepAfterRouteChange: true });
                  this.router.navigate(['../../'], { relativeTo: this.route });
              },
              error: error => {
                  this.alertService.error(error);
                  this.loading = false;
              }
          });
  }

}
