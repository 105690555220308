import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,FormsModule 
} from '@angular/forms';


import { NgModule } from "@angular/core";
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { ConfiguracionService } from '@app/_services';
import { first } from 'rxjs/operators';
import { AlertService, DepartamentosService } from '@app/_services';
import { AgentesService } from '@app/_services/agentes.service';
import { ColaboradoresService } from '@app/_services/colaboradores.service';
import { Permisos } from "@app/_models/permisos";
import { MenuService } from '../_services';
import { IdiomasService } from '@app/_services/idiomas.service';
import { numberSymbols } from '@progress/kendo-angular-intl';
import { GradientSettings } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'app-configuracion-inicial',
  templateUrl: './configuracion-inicial.component.html',
})

export class ConfiguracionInicialComponent implements OnInit {

  public dataOrganizaciones: [];
  public dataAgentes: [];
  public dataColaboradores: [];
  loading = false;
  form: FormGroup;
  public primaryColor;
  public primaryTextColor;
  public secondaryColor;
  public secondaryTextColor;

public camposValoresPublicarOrg:boolean[]=[];
public camposValoresComentarOrg:boolean[]=[];
public listaOrg:string[] = [];

public listaPermOrg: Permisos[] = [];

public camposValoresPublicarAgentes:boolean[]=[];
public camposValoresComentarAgentes:boolean[]=[];
public listaAgents:string[] = [];

public listaPermAgents: Permisos[] = [];

 public camposValoresPublicarColabs:boolean[]=[];
 public camposValoresComentarColabs:boolean[]=[];
 public listaColabs:string[] = [];

public listaPermColabs: Permisos[] = [];

private translate: TranslateService;

//Color picker
public settings: GradientSettings = {
  opacity: false,
};
//Idioma Priority
public listaIdiomas: string[] = [];
listaIndexIdiomas:number[]=[];

filePath: string;

  constructor(
    private organizacionesService: OrganizacionesService,
    private agenteService: AgentesService,
    private idiomaService:IdiomasService,
    private menuService: MenuService,
    translate: TranslateService,
    private colaboradoresService: ColaboradoresService,
    private configuracionService: ConfiguracionService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private translateService: TranslateService,
    private router: Router,
    private sanitizer: DomSanitizer
    ) {
      this.translate = translate;
      this.menuService.titulo = this.translate.instant('configuracion-inicial.titulo');

      this.organizacionesService.GetAll().subscribe((result) => {
        this.dataOrganizaciones = result.data;

        //Load Perm UsuariosEnte Publicar y Comentar
        for (var i in result.data) {
          this.camposValoresPublicarOrg[result.data[i].id] =
          result.data[i].canPublish;
          this.camposValoresComentarOrg[result.data[i].id] =
            result.data[i].canComment;
            this.listaOrg[i] =result.data[i].id;
        }
      })
      this.agenteService.GetAll().subscribe((result) => {
        this.dataAgentes = result.data;

                //Load Perm UsuariosEnte Publicar y Comentar
                for (var i in result.data) {
                  this.camposValoresPublicarAgentes[result.data[i].id] =
                  result.data[i].canPublish;
                  this.camposValoresComentarAgentes[result.data[i].id] =
                    result.data[i].canComment;
                    this.listaAgents[i] =result.data[i].id;
                }
      })
      this.colaboradoresService.GetAll().subscribe((result) => {
        this.dataColaboradores = result.data;
                        //Load Perm UsuariosEnte Publicar y Comentar
                        for (var i in result.data) {
                          this.camposValoresPublicarColabs[result.data[i].id] =
                          result.data[i].canPublish;
                          this.camposValoresComentarColabs[result.data[i].id] =
                            result.data[i].canComment;
                            this.listaColabs[i] =result.data[i].id;
                        }
      })
     }

  ngOnInit(): void {
    // console.log('PRUEBA API');
    // this.configuracionService.PRUEBAPI().subscribe((result)=>{
    //   console.log('RESULTADO PRUEBA API');
    //   console.log(result);
    // });
    //END PRUEBA API
    this.idiomaService.GetAll().pipe().subscribe((result)=>{
      console.log('Idiomas=');
      console.log(result);
      result.data.forEach(element => {
        this.listaIdiomas.push(element.nombre);
        this.listaIndexIdiomas.push(this.listaIdiomas.length);
      });
      //this.listaIdiomas=result.data;

    });
    this.configuracionService
    .GetConfig()
    .pipe()
    .subscribe((result) => {
      console.log('RESULTADO: ');
      console.log(result);
      this.form = this.formBuilder.group({
        primaryColor: result.data[0].primaryColor,
        primaryTextColor: result.data[0].primaryTextColor,
        secondaryColor: result.data[0].secondaryColor,
        secondaryTextColor: result.data[0].secondaryTextColor,

        activopublicar:new FormControl(result.data[0].activopublicar),
        activocomentar:new FormControl(result.data[0].activocomentar),
        activoseguir:new FormControl(result.data[0].activoseguir),
        activomensajes:new FormControl(result.data[0].activomensajes),
        
        activoNotificaciones:new FormControl(result.data[0].activoNotificaciones),
        activoContactos:new FormControl(result.data[0].activoContactos),
        activoFAQ:new FormControl(result.data[0].activoFAQ),
        activoGesDoc:new FormControl(result.data[0].activoGesDoc),
        activoFacebook:new FormControl(result.data[0].activoFacebook),
        activoTwitter:new FormControl(result.data[0].activoTwitter),
        activoFlicker:new FormControl(result.data[0].activoFlicker),
        activoInstagram:new FormControl(result.data[0].activoInstagram),
        activoYoutube:new FormControl(result.data[0].activoYoutube),
        activoAgenda:new FormControl(result.data[0].activoAgenda),
        activoMensajesOrg:new FormControl(result.data[0].activoMensajesOrg),
        activoForo:new FormControl(result.data[0].activoForo),
        activoEncuestas:new FormControl(result.data[0].activoEncuestas),
        activoReservas:new FormControl(result.data[0].activoReservas),
        activoAgentes:new FormControl(result.data[0].activoAgentes),
        activoColaboradores:new FormControl(result.data[0].activoColaboradores),

        activoNotificacionesA:new FormControl(result.data[0].activoNotificacionesA),
        activoContactosA:new FormControl(result.data[0].activoContactosA),
        activoFAQA:new FormControl(result.data[0].activoFAQA),
        activoGesDocA:new FormControl(result.data[0].activoGesDocA),
        activoFacebookA:new FormControl(result.data[0].activoFacebookA),
        activoTwitterA:new FormControl(result.data[0].activoTwitterA),
        activoFlickerA:new FormControl(result.data[0].activoFlickerA),
        activoInstagramA:new FormControl(result.data[0].activoInstagramA),
        activoYoutubeA:new FormControl(result.data[0].activoYoutubeA),
        activoAgendaA:new FormControl(result.data[0].activoAgendaA),
        activoMensajesA:new FormControl(result.data[0].activoMensajesA),
        activoForoA:new FormControl(result.data[0].activoForoA),
        activoEncuestasA:new FormControl(result.data[0].activoEncuestasA),
        activoReservasA:new FormControl(result.data[0].activoReservasA),
        activoAgentesA:new FormControl(result.data[0].activoAgentesA),
        activoColaboradoresA:new FormControl(result.data[0].activoColaboradoresA),

        activoNotificacionesC:new FormControl(result.data[0].activoNotificacionesC),
        activoContactosC:new FormControl(result.data[0].activoContactosC),
        activoFAQC:new FormControl(result.data[0].activoFAQC),
        activoGesDocC:new FormControl(result.data[0].activoGesDocC),
        activoFacebookC:new FormControl(result.data[0].activoFacebookC),
        activoTwitterC:new FormControl(result.data[0].activoTwitterC),
        activoFlickerC:new FormControl(result.data[0].activoFlickerC),
        activoInstagramC:new FormControl(result.data[0].activoInstagramC),
        activoYoutubeC:new FormControl(result.data[0].activoYoutubeC),
        activoAgendaC:new FormControl(result.data[0].activoAgendaC),
        activoMensajesC:new FormControl(result.data[0].activoMensajesC),
        activoForoC:new FormControl(result.data[0].activoForoC),
        activoEncuestasC:new FormControl(result.data[0].activoEncuestasC),
        activoReservasC:new FormControl(result.data[0].activoReservasC),
        activoAgentesC:new FormControl(result.data[0].activoAgentesC),
        activoColaboradoresC:new FormControl(result.data[0].activoColaboradoresC),

        activoPestana: new FormControl(result.data[0].pestanaMuroActiva),
        //activoFooter: new FormControl(result.data[0].footerActivo),
        //titulodentro: new FormControl(result.data[0].titulodentro),
        bloqueNoticiasHorizontal: new FormControl(result.data[0].bloqueNoticiasHorizontal),
        activoCategoria: new FormControl(result.data[0].activoCategoria),
        smsVerification: new FormControl(result.data[0].smsVerification),

        urlFotoMain: new FormControl(),
        activoFooterRRSS: new FormControl(result.data[0].activoFooterRRSS),
        htmlFooterRRSS: new FormControl(result.data[0].htmlFooterRRSS),
        activoFooterFinal: new FormControl(result.data[0].activoFooterFinal),
        htmlFooterFinal: new FormControl(result.data[0].htmlFooterFinal),
        numNoticiasHome: new FormControl(result.data[0].numNoticiasHome),

        notificacionPublicaciones: new FormControl(result.data[0].notificacionPublicaciones),
        notificacionEventos: new FormControl(result.data[0].notificacionEventos),
        notificacionEncuestas: new FormControl(result.data[0].notificacionEncuestas),
        notificacionReservas: new FormControl(result.data[0].notificacionReservas),
        notificacionMensajeria: new FormControl(result.data[0].notificacionMensajeria),
        
        activoRecompensas: new FormControl(result.data[0].activoRecompensas),
        nombreToken: new FormControl(result.data[0].nombreToken),
        recompensarLogin: new FormControl(result.data[0].recompensarLogin),
        recompensarComentar: new FormControl(result.data[0].recompensarComentar),
        recompensarVotar: new FormControl(result.data[0].recompensarVotar),
        
      })
        this.filePath = result.data[0].urlFotoMain;
    });
  }

  get fv() {
    return this.form.value;
  }
  public onTabSelect(e) {
    console.log(e);
  }

  onSubmit() {
     console.log('CAMPOS PERMISOS USUARIO ENTE: ');
     console.log(this.camposValoresPublicarOrg);
     console.log(this.camposValoresComentarOrg);
     console.log(this.camposValoresPublicarAgentes);
     console.log(this.camposValoresComentarAgentes);
     console.log(this.camposValoresPublicarColabs);
     console.log(this.camposValoresComentarColabs); 
    this.loading=true;
    this.updateConfiguration()
  }

  public updateConfiguration(){
    if(this.filePath){
     this.form.value.urlFotoMain =  this.filePath.split(',')[1]; 
    }

    this.configuracionService
    .updateConfig(this.form.value)
    .pipe(first())
    .subscribe((result) => {
      if (!result.error) {
        this.updatePermisos();
      } else {
        this.alertService.error(
          this.translateService.instant('enlaces.erroreditar')
        );
        this.loading = false;
      }
    });
  }

  public updatePermisos(){

     //Create Perms Structure
     for (let i = 0; i <this.listaOrg.length; i++) {
      this.listaPermOrg.push(
        new Permisos(
          this.listaOrg[i],
            this.camposValoresPublicarOrg[this.listaOrg[i]],
            this.camposValoresComentarOrg[this.listaOrg[i]]
        )
      );
        }
        for (let i = 0; i <this.listaAgents.length; i++) {
          this.listaPermAgents.push(
            new Permisos(
              this.listaAgents[i],
                this.camposValoresPublicarAgentes[this.listaAgents[i]],
                this.camposValoresComentarAgentes[this.listaAgents[i]]
            )
          );
            }
            for (let i = 0; i <this.listaColabs.length; i++) {
              this.listaPermColabs.push(
                new Permisos(
                  this.listaColabs[i],
                    this.camposValoresPublicarColabs[this.listaColabs[i]],
                    this.camposValoresComentarColabs[this.listaColabs[i]]
                )
              );
                }

//this.camposValoresPublicarOrg.forEach((k,v)=>console.log('CLAVE= '+k));
  var i=1;
  this.listaIdiomas.forEach((element)=>{
    console.log('Actualizando prioridad...');
      this.idiomaService.updatePriority(element,i).pipe(first())
      .subscribe((result) => {
          console.log('Actualizada la prioridad del idioma: '+element);
      });
    i=i+1;
  })
  this.configuracionService
  .updatePermsOrgs(this.listaPermOrg)
  .pipe(first())
  .subscribe((result) => {
    if (!result.error) {

      this.configuracionService
      .updatePermsAgentes(this.listaPermAgents)
      .pipe(first())
      .subscribe((result) => {
        if (!result.error) {

          this.configuracionService
          .updatePermsColabs(this.listaPermColabs)
          .pipe(first())
          .subscribe((result) => {
            if (!result.error) {
    
    
              
            this.alertService.success(
              this.translateService.instant('botones.editadocorrectamente'),
              { keepAfterRouteChange: true }
            );
            this.loading = false;
            }
            else {
              this.alertService.error(
                this.translateService.instant('enlaces.erroreditar')
              );
              this.loading = false;
            }
          });


        }
        else {
          this.alertService.error(
            this.translateService.instant('enlaces.erroreditar')
          );
          this.loading = false;
        }
      });

    } else {
      this.alertService.error(
        this.translateService.instant('enlaces.erroreditar')
      );
      this.loading = false;
    }
  });
  }

/*   public handleClickOrgs(cb,op) {
    if(cb==null || cb==undefined) return;
    console.log('Cambiando valor de '+ cb.id+ ' con opcion'+op);
    if(op==0)
    this.camposValoresPublicarOrg[cb.id]=!this.camposValoresPublicarOrg[cb.id];
    else if(op==1)
    this.camposValoresComentarOrg[cb.id]=!this.camposValoresComentarOrg[cb.id];
  }
  public handleClickAgents(cb,op) {
    if(cb==null || cb==undefined) return;
    console.log('Cambiando valor de '+ cb.id+ ' con opcion'+op);
    if(op==0)
    this.camposValoresPublicarAgentes[cb.id]=!this.camposValoresPublicarAgentes[cb.id];
    else if(op==1)
    this.camposValoresComentarAgentes[cb.id]=!this.camposValoresComentarAgentes[cb.id];
  } */

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

}
