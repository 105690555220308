<section class="cards">
  <article *ngFor="let item of listaApps" class="card card--1">
    <div class="card__info-hover">
        <div class="card__clock-info">
        <span class="card__time">Activa</span>
        </div>
      
    </div>
    <div class="card__img" style='background-image: "{{item.imageUrl}}";'></div>
    <a href="#" class="card_link">
       <div class="card__img--hover"></div>
     </a>
    <div class="card__info">
      <h3 class="card__title">{{item.nombre}}</h3>
      <span class="card__category"> Estado API: </span>
      <i *ngIf="item.isActivaApi; else apiInactiva" class="fas fa-power-off fa-2x text-success"></i>
      <ng-template #apiInactiva>
        <i  class="fas fa-power-off fa-2x text-danger"></i>
      </ng-template>
      <span class="card__category"> Estado BD:</span>
      <i *ngIf="item.isActivaDB; else dbInactiva" class="fas fa-power-off fa-2x text-success"></i>
      <ng-template #dbInactiva>
        <i  class="fas fa-power-off fa-2x text-danger"></i>
      </ng-template>
      <td></td>
      <button class="btn btn-outline-success">Administrar</button>
      <button class="btn btn-outline-info">Contenido</button>
    </div>
  </article>

  <article class="card card--1">
      <i class="fas fa-plus-circle fa-3x text-primary"></i>
  </article>
    </section>

    <kendo-multiselect
    [autoClose]="true"
    [filterable]="true"
    (filterChange)="handleFilter($event)"
    [data]="listaAppsFilter"
    textField="nombre"
    valueField="id"
    [(ngModel)]="listaSeleccionados"
    placeholder="Selecciona las Apps a las que enviar el contenido"
  ></kendo-multiselect>
  <div class="example-config">
    Selected Apps: {{ listaSeleccionados | json }}
  </div>


    <style>
   @import url('https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700');
@import url('https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');

*{
  box-sizing: border-box;
}

body, html {
   font-family: 'Roboto Slab', serif;
    margin: 0;
  width: 100%;
height: 100%;
    padding: 0;
}

body {
  background-color: #D2DBDD;
  display: flex;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
  align-items: center;
}

.cards {
    width: 100%;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    max-width: 820px;
}

.card--1 .card__img, .card--1 .card__img--hover {
    background-image: url('https://aioro.zitu.net/img/cfg/bg2.jpg');
}

.card__like {
    width: 18px;
}

.card__clock {
    width: 15px;
  vertical-align: middle;
    fill: #AD7D52;
}
.card__time {
    font-size: 12px;
    color: #AD7D52;
    vertical-align: middle;
    margin-left: 5px;
}

.card__clock-info {
    float: right;
}

.card__img {
  visibility: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 235px;
  border-top-left-radius: 12px;
border-top-right-radius: 12px;
  
}

.card__info-hover {
    position: absolute;
    padding: 16px;
  width: 100%;
  opacity: 0;
  top: 0;
}

.card__img--hover {
  transition: 0.2s all ease-out;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  position: absolute;
    height: 235px;
  border-top-left-radius: 12px;
border-top-right-radius: 12px;
top: 0;
  
}
.card {
  margin-right: 25px;
  transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
  background-color: #fff;
    width: 33.3%;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0,0.1);
}
.card:hover {
  box-shadow: 0px 30px 18px -8px rgba(0, 0, 0,0.1);
    transform: scale(1.10, 1.10);
}

.card__info {
z-index: 2;
  background-color: #fff;
  border-bottom-left-radius: 12px;
border-bottom-right-radius: 12px;
   padding: 16px 24px 24px 24px;
}

.card__category {
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-weight: 500;
  color: #868686;
}

.card__title {
    margin-top: 5px;
    margin-bottom: 10px;
    font-family: 'Roboto Slab', serif;
}

.card__by {
    font-size: 12px;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
}

.card__author {
    font-weight: 600;
    text-decoration: none;
    color: #AD7D52;
}

.card:hover .card__img--hover {
    height: 100%;
    opacity: 0.3;
}

.card:hover .card__info {
    background-color: transparent;
    position: relative;
}

.card:hover .card__info-hover {
    opacity: 1;
}

    </style>