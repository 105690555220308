import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Idioma } from '@app/_models/idioma';
import { AlertService, MenuService } from '@app/_services';
import { IdiomasService } from '@app/_services/idiomas.service';
import { TranslateService } from '@ngx-translate/core';
import { Modulo, ModuloTraduccion } from '@app/_models/modulo';
import { ModuloService } from '@app/_services/modulos.service';

@Component({
  selector: 'app-modulos-editar',
  templateUrl: './modulos-editar.component.html',
})
export class ModulosEditarComponent implements OnInit {

  id: number;
  nombre: string = '';
  urlModulo: string = '';
  html: string = '';
  imagenB64: string = '';
  lang: any;
  filePath: string;
  selectedIdioma: Idioma;
  selectedModulo: string;
  private translate: TranslateService;
  contains: boolean;
  auxIdioma: Idioma;
  auxModuloTrad: ModuloTraduccion;

  showUrl: boolean = false;
  showHtml: boolean = false;
  cargado: boolean = false;

  myModulo: Modulo = new Modulo();

 //Listas
 listaIdiomas: Idioma[] = [];
 listaIdiomasTotales: Idioma[] = [];
 listaTraducciones: Idioma[] = [];
 listaModulos: String[] = [];
 listaModulosOriginal: string[] = [];
 auxList: Idioma[] = [];

 //Traducciones
 listaTraduccionesEnviar: ModuloTraduccion[] = [];

  constructor(
    translate: TranslateService,
    private idiomaService: IdiomasService,
    private translateService: TranslateService,
    private menuService: MenuService,
    private modulosService: ModuloService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService
    ) { 
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('menu_modulos.cabecera');
    this.id = this.route.snapshot.params['idmodulo'];

    this.loadIdiomas();

    //Load modulo
    this.modulosService.getModuloById(this.id).subscribe((result) => {
      console.log('Cargado:');
      console.log(result.data[0]);
      this.myModulo=result.data[0];
      this.showHtml=this.myModulo.hasHtml;
      this.html=this.myModulo.html;
      this.showUrl=this.myModulo.hasUrl;
      this.urlModulo=this.myModulo.urlModulo;
      this.nombre=this.myModulo.nombre;
      this.selectedModulo=this.myModulo.direccion;
      this.filePath=this.myModulo.fotoUrl;
      this.listaIdiomas.forEach(i => {
        if(i.codigo ==  this.myModulo.idIdioma) this.selectedIdioma = i;
      });
      this.listaTraduccionesEnviar=this.myModulo.listaTraducciones;
      });
      console.log(this.listaTraduccionesEnviar);
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();

    //Load modules
    this.listaModulos.push(this.translate.instant('menu_modulos.urlexterna'));
    this.listaModulos.push(this.translate.instant('menu_modulos.html'));
    this.listaModulos.push(this.translate.instant('menu_modulos.home'));
    this.listaModulos.push(this.translate.instant('menu_modulos.faq'));
    this.listaModulos.push(this.translate.instant('menu_modulos.tienda'));

    //Load modules
    this.listaModulosOriginal.push('menu_modulos.urlexterna');
    this.listaModulosOriginal.push('menu_modulos.html');
    this.listaModulosOriginal.push('menu_modulos.home');
    this.listaModulosOriginal.push('menu_modulos.faq');
    this.listaModulosOriginal.push('menu_modulos.tienda');

    //Load trads
    this.modulosService.getModuloTraduccion(this.id).subscribe((result) => {
      this.auxList = result.data;
      this.auxList.forEach(tra => {
        if (tra.filled) {
          this.listaTraducciones.push(tra);
        }
      });
      console.log(this.listaTraducciones);
      this.listaTraducciones.forEach(tra => {
        this.auxModuloTrad = new ModuloTraduccion();
        this.auxModuloTrad.filled = tra.filled;
        this.auxModuloTrad.html = tra.html;
        this.auxModuloTrad.id = tra.id;
        this.auxModuloTrad.idIdioma = tra.idIdioma;
        this.auxModuloTrad.idModulo = tra.id;
        this.auxModuloTrad.nombre = tra.nombre;
        this.auxModuloTrad.nombreIdioma = tra.nombreIdioma;
        this.listaTraduccionesEnviar.push(this.auxModuloTrad);
      });
      console.log(this.listaTraduccionesEnviar);
      this.cargado = true;
    });

    this.listaIdiomas.forEach(i => {
      if (i.codigo == this.myModulo.idIdioma) this.selectedIdioma = i;
    });
  }

  loadIdiomas() {
    console.log('Cargando todos los idiomas');

    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomas = result.data;
      if (this.listaIdiomas, length > 0) {
        this.selectedIdioma = this.listaIdiomas[0];
      }
    });
  }
  
  btnCrearModulo() {
    console.log('Creando Modulo');
    for (let i = 0; i < this.listaTraducciones.length; i++) {
      console.log(this.auxList);
      if (this.listaTraducciones[i].idIdioma == this.selectedIdioma.codigo) {
        delete this.listaTraducciones[i];
        //delete this.auxList[i];
      }
    }
    var auxM = new Modulo();
    auxM.fotoUrl = this.filePath.split(',')[1];
    auxM.idModulo = this.id;
    this.modulosService
      .updateFoto(auxM)
      .subscribe((result) => {
        console.log("Foto actualizada");
      });
    this.btnAddTraduccion();
    console.log(this.listaTraduccionesEnviar);
    //Validación de los datos
    if (this.listaTraducciones.length <= 0) {
      this.alertService.info(
        this.translateService.instant('publicaciones.errortrads')
      );
      return;
    }
    if (this.nombre == null || this.filePath == null) {
      this.alertService.info(
        this.translateService.instant('publicaciones.validaciondatos')
      );
      return;
    }

    //AutoCompletado de Idiomas
    //seleccionado
    let seleccionado: ModuloTraduccion = new ModuloTraduccion();
    let encontrado: boolean = false;
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (trad.filled && !encontrado) {
        seleccionado = trad;
        encontrado = true;
        return;
      }
    });
    //Fill UnFilled
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (!trad.filled) {
        trad.nombre = seleccionado.nombre;
        trad.idModulo = seleccionado.idModulo;
        trad.html = seleccionado.html;
        //this.id = seleccionado.id;
      }
    });
    console.log(this.listaTraduccionesEnviar);

    this.modulosService
      .addTraduccionesModulo(this.listaTraduccionesEnviar, this.id)
      .subscribe((resultTrads) => {
        if (!resultTrads.error) {
          this.alertService.success(
            this.translateService.instant('publicaciones.success')
          );
          this.router.navigate(['acceso-directo']);
        } else {
          console.log('Error enviando traducciones publicacion');
          this.alertService.error(
            this.translateService.instant('publicaciones.error')
          );
          return;
        }
      });
  }

  btnAddTraduccion() {
    console.log('Comprobando datos');
    if (
      this.selectedIdioma == null ||
      this.nombre.length <= 0
    ) {
      if (this.listaTraducciones.length <= 0) {
        this.alertService.info(
          this.translateService.instant('publicaciones.validacion')
        );
        return;
      }
    }
    console.log('Datos validados --->'); 
    this.contains = false;
    this.listaTraducciones.forEach(tra => {
      if(tra.nombre == this.selectedIdioma.nombre) this.contains = true;
    });
    if (!this.contains) {
      
      this.selectedIdioma.titulo = this.nombre;
      this.selectedIdioma.idIdioma = this.selectedIdioma.codigo;
      this.selectedIdioma.filled = true;
      this.selectedIdioma.html = this.html;
      this.selectedIdioma.idModulo = this.id;
    
      this.listaTraducciones.push(this.selectedIdioma);
      for (let i = 0; i < this.auxList.length; i++) {
        if(this.auxList[i] != undefined && this.auxList[i].idIdioma == this.selectedIdioma.idIdioma) delete this.auxList[i];
      }
      this.auxList.push(this.selectedIdioma);

      this.listaTraduccionesEnviar = [];

        this.auxList.forEach(tra => {
        this.auxModuloTrad = new ModuloTraduccion();
        this.auxModuloTrad.filled = tra.filled;
        this.auxModuloTrad.html = tra.html;
        this.auxModuloTrad.id = tra.id;
        this.auxModuloTrad.idIdioma = tra.idIdioma;
        this.auxModuloTrad.idModulo = tra.idModulo;
        this.auxModuloTrad.nombre = tra.titulo;
        this.auxModuloTrad.nombreIdioma = tra.nombre;
        this.listaTraduccionesEnviar.push(this.auxModuloTrad);
      });

      this.listaTraduccionesEnviar.map((x) => {
        if (x.idIdioma == this.selectedIdioma.codigo) {
          x.filled = true;
          x.nombre = this.nombre;
          x.idModulo = this.id;
          x.html = this.html;
        }
      });
    } else {
      this.alertService.warn(
        this.translateService.instant('menu_modulos.existe')
      );
      console.log('Idioma ya añadido');
    }

    console.log(this.listaTraduccionesEnviar);
  }

  btnDeleteTraduccion(idioma: Idioma) {
    console.log(idioma);
    this.listaTraducciones = this.listaTraducciones.filter(
      (x) => x.codigo != idioma.codigo
    );

    this.listaTraduccionesEnviar.map((x) =>
      x.idIdioma == idioma.codigo ? (x.filled = false) : console.log()
    );

    console.log(this.listaTraduccionesEnviar);
  }

  //Imagen
  imagePreview(e) {
    var auxM = new Modulo();
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
      auxM.fotoUrl = this.filePath.split(',')[1];
      auxM.idModulo = this.id;
      this.modulosService
        .updateFoto(auxM)
        .subscribe((result) => {
          console.log("Foto actualizada");
        });
    };
    reader.readAsDataURL(file);
  }

  btnCancelar(){
    this.router.navigate(['acceso-directo']);
  }

  valueChange(value: any){
    this.selectedModulo = value;
    if(value == "URL Externa"){
      this.showUrl = true;
      this.showHtml = false;
    } else if(value == "Contenido HTML"){
      this.showHtml = true;
      this.showUrl = false;
    }
  }

  idiomaChange(value: any){
    this.contains = false;
    this.listaTraducciones.forEach(tra => {
      if (tra.nombre == value.nombre) {
        this.nombre = tra.titulo;
        this.html = tra.html;
        this.contains = true;
      }
    });
    if(!this.contains) {
      this.nombre = "";
      this.html = "";
      this.selectedModulo = "";
    }
  }

}