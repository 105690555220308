<div class="card" *ngIf="router.url === '/acceso-directo'">
  
  <div id="grid">
    <kendo-grid 
    [(data)]="gridData" 
    [pageable]="true" 
    [pageSize]="state.take"
    [rowClass]="rowCallback"
    kendoGridSelectBy="idModulo" 
    (cellClick)="cellClick($event)" 
    [(selectedKeys)]="seleccionados"
    (selectedKeysChange)="selectedKeysChange($event)">
      
      <ng-template kendoGridToolbarTemplate position="top">
        <button kendoButton (click)="onClickNuevo()" class="btn mr-1 btn-success btn-sm mr-1">
          {{ "botones.nuevo" | translate }}
        </button>
        <button kendoButton (click)="onClickEditar()" class="btn mr-1 btn-primary btn-sm mr-1">
          {{ "botones.editar" | translate }}
        </button>
        <button kendoButton (click)="onClickEliminar(content)" class="btn mr-1 btn-danger btn-sm mr-1"
          [disabled]="isDeleting">
          {{ "botones.eliminar" | translate }}
        </button>
      </ng-template>

      <kendo-grid-column width="3%">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="k-icon k-i-reorder" style="color: rgb(114, 114, 114);"></span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-checkbox-column width="3%" showSelectAll="true">
      </kendo-grid-checkbox-column>
      
      <kendo-grid-column field="nombre" title="{{ 'acceso_directo.nombre' | translate }}" width="74%">
      </kendo-grid-column>
      
      <kendo-grid-column field="direccion" title="{{ 'acceso_directo.direccion' | translate }}" width="20%">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.direccion | translate }}
        </ng-template>
      </kendo-grid-column>
      
    </kendo-grid>
  </div>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ "acceso_directo.eliminar" | translate }}</p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="modal.dismiss('cancel click')"
      >
        {{ "botones.no" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-danger"
        (click)="eliminarRegistro(contentloading)"
      >
        {{ "botones.si" | translate }}
      </button>
    </div>
  </ng-template>
  
  <ng-template #contentloading let-modal>
    <div class="modal-body">
      <p>{{ "organizaciones.eliminando" | translate }}</p>
      <div class="spinner-border" role="status">
        <span class="sr-only"
          >{{ "organizaciones.eliminando" | translate }}...</span
        >
      </div>
    </div>
  </ng-template>
  
  <router-outlet></router-outlet>