import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, Renderer2, } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services/menu.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { AlertService } from '@app/_services';
import { Modulo } from '@app/_models/modulo';
import { ModuloService } from '@app/_services/modulos.service';
import { fromEvent, Subscription } from 'rxjs';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { take, tap } from 'rxjs/operators';
import { process, State } from '@progress/kendo-data-query';

const tableRow = (node) => node.tagName.toLowerCase() === "tr";

const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }

  return node;
};

@Component({
  selector: 'app-accesos-directos',
  templateUrl: './accesos-directos.component.html',
})
export class AccesosDirectosComponent implements AfterViewInit, OnDestroy, OnInit {

  listaModulos: Modulo[] = [];
  public seleccionados: number[] = [];

  lang;
  private translate: TranslateService;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  public state: State = {
    skip: 0,
    take: 10,
  };
  public gridData: any;

  private currentSubscription: Subscription;
  
  constructor(
    private moduloService: ModuloService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    public router: Router,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private renderer: Renderer2, 
    private zone: NgZone
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('acceso_directo.cabecera');
    this.moduloService.getAllModulos().subscribe((result) => {
      this.listaModulos = result.data;
      console.log('Modulos cargados: ' + this.listaModulos.length);
      console.log(this.listaModulos[0]);
      this.dataStateChange(this.state);
      this.gridData = process(this.listaModulos, this.state);
      for (let i = 0; i < this.listaModulos.length; i++) {
        this.moduloService.updateIndex(this.listaModulos[i].idModulo, i + 1)
          .subscribe((result) => {
            if (!result.error) {
              console.log('Indices actualizados');
            } else {
              console.log('Error al actualizar los indices');
              return;
            }
            this.dataStateChange(this.state);
            this.gridData = process(this.listaModulos, this.state);
          });
      }
    });
  }

  public ngAfterViewInit(): void {
    this.currentSubscription = this.handleDragAndDrop();
  }

  private handleDragAndDrop(): Subscription {
    const sub = new Subscription(() => {});
    let draggedItemIndex;
    
    const tableRows = Array.from(document.querySelectorAll(".k-grid tr"));
    tableRows.forEach((item) => {
      this.renderer.setAttribute(item, "draggable", "true");
      const dragStart = fromEvent<DragEvent>(item, "dragstart");
      const dragOver = fromEvent(item, "dragover");
      const dragEnd = fromEvent(item, "dragend");
      
      sub.add(
        dragStart
          .pipe(
            tap(({ dataTransfer }) => {
              try {
                const dragImgEl = document.createElement("span");
                dragImgEl.setAttribute(
                  "style",
                  "position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;"
                );
                document.body.appendChild(dragImgEl);
                dataTransfer.setDragImage(dragImgEl, 0, 0);
              } catch (err) {
                // IE doesn't support setDragImage
              }
              try {
                // Firefox won't drag without setting data
                dataTransfer.setData("application/json", "");
              } catch (err) {
                // IE doesn't support MIME types in setData
              }
            })
          )
          .subscribe(({ target }) => {
            const row: HTMLTableRowElement = <HTMLTableRowElement>target;
            draggedItemIndex = row.rowIndex;
            const dataItem = this.gridData.data[draggedItemIndex];
            dataItem.dragging = true;
          })
      );
      
      sub.add(
        dragOver.subscribe((e: any) => {
          e.preventDefault();
          const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
          const dropIndex = closest(e.target, tableRow).rowIndex;
          const dropItem = this.gridData.data[dropIndex];

          draggedItemIndex = dropIndex;
          this.zone.run(() =>
            this.gridData.data.splice(dropIndex, 0, dataItem)
          );
        })
      );

      sub.add(
        dragEnd.subscribe((e: any) => {
          e.preventDefault();
          const dataItem = this.gridData.data[draggedItemIndex];
          dataItem.dragging = false;
          //Actualizar indice en DB
          for (let i = 0; i < this.gridData.data.length; i++) {
            console.log(this.gridData.data[i]);
            this.moduloService.updateIndex(this.gridData.data[i].idModulo, i+1)
            .subscribe((result) => {
              if (!result.error) {
                console.log('Indices actualizados');
              } else {
                console.log('Error al actualizar los indices');
                return;
              }
            });
          }
        })
      );
    });

    return sub;
  }

  public ngOnDestroy(): void {
    this.currentSubscription.unsubscribe();
  }

  public dataStateChange(state: State): void {
    this.state = state;
    this.gridData = process(this.listaModulos, this.state);
    this.currentSubscription.unsubscribe();
    this.zone.onStable
      .pipe(take(1))
      .subscribe(() => (this.currentSubscription = this.handleDragAndDrop()));
  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging,
    };
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
  }

  onClickNuevo() {
    this.router.navigate(['menu-modulos']);
  }

  onClickEliminar(content){
    console.log('Eliminando...');
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  eliminarRegistro(contentloading){
    console.log('Eliminar registro');
    this.moduloService.delete({ codigos: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.moduloService.getAllModulos().subscribe((result) => {
            this.listaModulos = result.data;
            this.dataStateChange(this.state);
            this.gridData = process(this.listaModulos, this.state);
            for (let i = 0; i < this.listaModulos.length; i++) {
              this.moduloService.updateIndex(this.listaModulos[i].idModulo, i + 1)
                .subscribe((result) => {
                  if (!result.error) {
                    console.log('Indices actualizados');
                  } else {
                    console.log('Error al actualizar los indices');
                    return;
                  }
                  this.dataStateChange(this.state);
                  this.gridData = process(this.listaModulos, this.state);
                });
            }
          }
          );
        }
        this.modalReferenceloading.close();
      }
    );
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }

  onClickEditar(){
    this.router.navigate(['modulos-editar',{ idmodulo:this.seleccionados[0] }]);
  }

  cellClick(){
    console.log('Modulo Seleccionado: ');
    console.log(this.seleccionados[0]);
    this.router.navigate(['modulos-editar',{ idmodulo:this.seleccionados[0] }]);
  }

  selectedKeysChange(rows: number[]) {
    this.seleccionados = rows;
    console.log("Seleccionados IDs: " + this.seleccionados)
  }

}
