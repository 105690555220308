import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Recinto, Reserva, Sala } from '@app/_models/recinto';
import { environment } from '@environments/environment';
import { SchedulerEvent } from '@progress/kendo-angular-scheduler';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/membresia`;

@Injectable({
  providedIn: 'root',
})
export class MensajeriaService {
  constructor(private http: HttpClient) {}

  public getPeticionesFromEnte(id: string): Observable<any> {
    return this.http.get(baseUrl + '/peticiones/' + id);
  }

  public getPeticion(id: number): Observable<any> {
    return this.http.get(baseUrl + '/peticiones/get/' + id);
  }

  public getDocumentosFromPeticion(idPeticion: number): Observable<any> {
    return this.http.get(baseUrl + '/documentos/' + idPeticion);
  }

  public darAltaUsuarioMembresia(iduser: number,idorg:string): Observable<any> {
    return this.http.get(baseUrl + '/peticiones/alta/' + iduser + '/'+ idorg);
  }

  public getEntesAdministrados(): Observable<any> {
    return this.http.get(
      environment.apiUrl + '/organizacion/getentesuser/' + '1'
    );
  }

  public downloadPDF(id: number): Observable<any> {
    return this.http.get(baseUrl + '/download/' + id);
  }
  
  delete(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminar', formData, {
      withCredentials: true,
    });
  }

  //Helpers
  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return (
      año +
      '-' +
      this.addZero(mes) +
      '-' +
      this.addZero(dia) +
      'T' +
      this.addZero(hora) +
      ':' +
      this.addZero(minutos) +
      ':' +
      this.addZero(segundos) +
      'Z'
    );
  }

  addZero(num) {
    if (num < 10) return '0' + num;
    else return num;
  }
}
