import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from '@app/_models';
import { MenuService, UsuariosService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

declare var require: any
var $ = require('jquery');

@Component({ templateUrl: 'home.component.html' })
  export class HomeComponent {
    dataUsuarios: Usuario[];
    interval: number;

  constructor(
    private usuariosService: UsuariosService,
    private menuService: MenuService,
    public router: Router,
    private translateService: TranslateService) {

  }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('menu.inicio');
  }

}
