import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Municipio } from '@app/_models/farmacias';
import { Recinto, Reserva, Sala } from '@app/_models/recinto';
import { environment } from '@environments/environment';
import { SchedulerEvent } from '@progress/kendo-angular-scheduler';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/farmacias`;

@Injectable({
  providedIn: 'root'
})
export class FarmaciasService {

  constructor(private http: HttpClient) {}
  public getMunicipios(): Observable<any> {
    return this.http.get(baseUrl + '/getmunicipios' );
  }

  public actualizarMunicipios(municipios:Municipio[]): Observable<any> {

    return this.http.post(baseUrl + '/updatemunicipios/', JSON.stringify(municipios), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

}
