import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Recinto, Reserva, Sala } from '@app/_models/recinto';
import { environment } from '@environments/environment';
import { SchedulerEvent } from '@progress/kendo-angular-scheduler';
import { Observable } from 'rxjs';
import { Modulo } from '@app/_models/menu-main';
const baseUrl = `${environment.apiUrl}/menumain`;

/**
 * IMPORTANTE
 * El tipo del modulo se representa con un valor:
 * 0 - Modulo Directo
 * 1- Modulo HTML
 * 2- Modulo Categoria (aka carpeta)
 * 3- Modulo URL
 */
@Injectable({
    providedIn: 'root',
})
export class MainMenuSerivice {
    constructor(private http: HttpClient) { }
    public getModulos(): Observable<any> {
        return this.http.get(baseUrl + "/getmodulos");
    }
    public getModuloByIdLang(id: number, idLang: number): Observable<any> {
        return this.http.get(baseUrl + "/getmodulo/" + id + "/" + idLang);
    }
    public getModuloByIdPadre(idPadre: number, idLang:number ): Observable<any> {
        return this.http.get(baseUrl + "/getmodulosidparent/" + idPadre + "/" + idLang)
    }
    public createModulo(modulo: Modulo): Observable<any> {
        console.log(modulo);
        return this.http.post(baseUrl + "/createmodulo", JSON.stringify(modulo),
            {
                headers: new HttpHeaders({
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    charset: 'utf-8',
                }),
                withCredentials: true,
            });
    }
    public updateModulo(modulo: Modulo): Observable<any> {
        return this.http.post(baseUrl + "/updateModulo", JSON.stringify(modulo),
            {
                headers: new HttpHeaders({
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    charset: 'utf-8',
                }),
                withCredentials: true,
            });
    }
    public updateOrden(modulo: Modulo): Observable<any> {
        return this.http.post(baseUrl + "/updateorden", JSON.stringify(modulo),
            {
                headers: new HttpHeaders({
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    charset: 'utf-8',
                }),
                withCredentials: true,
            });
    }

    public deleteModulos(ids:any): Observable<any> {
        var formData: any = new FormData();
        formData.append('ids', ids);
        return this.http.post<JSON>(baseUrl + "/eliminarmodulos", formData, { withCredentials: true });
    }

    public getOrden(idPadre: number): Observable<any> {
        return this.http.get(baseUrl + '/getultimonumeroorden/' + idPadre, {withCredentials: true});
    }

    public getCategoriasByIdLang(idLang: number): Observable<any> {
        return this.http.get(baseUrl + '/getcategorias/' + idLang);
    }
}