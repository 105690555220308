import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, ContactosService, MenuService } from '@app/_services';
import { AgentesService } from '@app/_services/agentes.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-agentes-tipos-detalles',
  templateUrl: './agentes-tipos-detalles.component.html',
})
export class AgentesTiposDetallesComponent implements OnInit {

  form: FormGroup;
  codigo: number;
  isAddMode: boolean;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private agenteService: AgentesService,
    private alertService: AlertService,
    private menuService: MenuService
  ) { }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('contactos.contactos');
    
    this.codigo = this.route.snapshot.params['id'];

    this.isAddMode = !this.codigo;

    this.form = this.formBuilder.group({
        codigo: this.route.snapshot.params['id'],
        nombre: new FormControl('', [Validators.required]),
        descripcion: new FormControl('', [Validators.required]),
        activo: new FormControl(true),
             
    });

    if (!this.isAddMode) {
      this.agenteService.GetTipoById(this.codigo)
          .pipe()
          .subscribe((result) => {
            this.form = this.formBuilder.group({
              codigo: this.route.snapshot.params['id'],
              nombre: new FormControl(result.data[0].nombre, [Validators.required]),
              descripcion: new FormControl(result.data[0].descripcion, [Validators.required]),
              activo :new FormControl(result.data[0].activo),
            });
          });
  }
  this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      // TODO This as a workaround.
      this.menuService.titulo = this.translateService.instant('contactos.contactos');
  });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }

    this.loading = true;
    if (this.isAddMode) { 
        this.crearTipoAgente();
    } else {
        this.updateTipoAgente();
    }
  }

  private crearTipoAgente() {
    this.agenteService.insertTipoAgente(this.form.value)
        .subscribe((result) => { 
            if(!result.error) {
              this.alertService.success(this.translateService.instant('botones.creadocorrectamente'), { keepAfterRouteChange: true });
              this.router.navigate(['../'], { relativeTo: this.route });
            } else {
              this.alertService.error(this.translateService.instant('contactos.errorcrear'));
              this.loading = false;
            }
        });
  }

  private updateTipoAgente() {
    this.agenteService.updateTipoAgente(this.form.value)
        .pipe(first())
        .subscribe((result) => {
            if(!result.error) {
              this.alertService.success(this.translateService.instant('botones.editadocorrectamente'), { keepAfterRouteChange: true });
              this.router.navigate(['../../'], { relativeTo: this.route });
            } else {
              this.alertService.error(this.translateService.instant('contactos.erroreditar'));
              this.loading = false;
            }
        });
  }

}
