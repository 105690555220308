<div class="row">
  <div class="col-xl-6 col-md-6">
    <div class="card">
      <!--GESTION AGENTE-->
      <div class="card-header">
        <h3>{{ "mensajeria.peticion" | translate }}</h3>
      </div>
      <div class="card-body">
        <div
          *ngIf="myMensaje.tipo == 1; else elseBlock"
          style="background-color: lavender"
        >
          El usuario {{ myMensaje.dni }} quiere darse de alta y ha adjuntado la
          documentación para ello.
        </div>
        <ng-template #elseBlock>
          <div style="background-color: lavender">
            El usuario {{ myMensaje.dni }} ya es miembro y solicita la
            activación de miembro en la app
          </div>
        </ng-template>
        <div class="form-group">
          <label>NombreUsuario</label>
          <div class="caja">
            {{ myMensaje.nombreUsuario }}
          </div>
        </div>
        <div class="form-group">
          <label>Nombre y Apeliidos</label>
          <div class="caja">
            {{ myMensaje.nombre }} {{ myMensaje.apellido1 }}
            {{ myMensaje.apellido2 }}
          </div>
        </div>
        <div class="form-group">
          <label>Email</label>
          <div class="caja">
            {{ myMensaje.email }}
          </div>
        </div>

        <div class="form-group">
          <label>Fecha Nacimiento</label>
          <div class="caja">
            {{ myMensaje.fechaNacimiento | date: "yyyy-MM-dd" }}
          </div>
        </div>
        <label>Documentos:</label>
        <div *ngFor="let doc of myDocumentos">
          <div>
            <img
              src="https://aioro.zitu.net/img/pdf.png"
              style="height: 50px; width: 50px"
            />
            <button (click)="btnDownload(doc)">
              {{ doc.nombre }}
            </button>
          </div>
        </div>

        <div class="form-group">
          <!--Boton Publicar-->
          <button
            type="button"
            class="btn btn-success btn-sm mr-1"
            (click)="btnAltaMembresia()"
          >
            {{ "mensajeria.aceptar" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm mr-1"
            (click)="btnCancelar()"
          >
            {{ "botones.cancelar" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
