import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertService, MenuService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { RestauranteService } from '@app/_services/restaurante.service';
import { ComedorTrad, Mesa, MesaComedor, Perfil, Tamano_Panel } from '@app/_models/restaurante';
import { Idioma } from '@app/_models/idioma';
import { IdiomasService } from '@app/_services/idiomas.service';

import * as $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { TabCloseEvent, TabStripComponent } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-comedores-detalle',
  templateUrl: './comedores-detalle.component.html',
})
export class ComedoresDetalleComponent implements OnInit {
  user = this.usuariosService.userValue;
  public submitted = false;
  private translate: TranslateService;
  public id: any;
  navigationSubscription;
  isAddMode: boolean;

  isBusy: boolean = false;
  listaIdiomas: Idioma[] = [];
  listaIdiomasTotales: Idioma[] = [];
  listaTraducciones: Idioma[] = [];
  selectedIdioma: Idioma;
  listaPerfilesComedor: any[] = [1, 2, 3];
  selectedIndex: number;
  filePath: string;
  public closedTabs = [];
  // Perfiles
  mapaPerfiles: Map<number, Perfil> = new Map<number, Perfil>();
  selectedPerfil: number;
  numeroPerfiles: number = 0;
  //Traducciones
  listaTraduccionesEnviar: ComedorTrad[] = [];
  //Params
  idOrg: string;
  idRestaurante: number;
  idComedor: number;
  nombre: string = '';

  //Data
  comensales: number;
  observaciones: string;
  fondo: any;
  archivo: any;

  tamano_panel: Tamano_Panel = new Tamano_Panel(800, 800); //El tamano en px
  tamano_panel_cm: Tamano_Panel = new Tamano_Panel(1000, 1000); //El tamano, a los clientes se les muestra en cm
  escala_panel: any = 1; //valor por el que se deben multiplicar las mesas a la hora de colocarlas en el panel

  @ViewChild('perfilTabs') perfilTabs;
  public restrictionsImage: FileRestrictions = {
    allowedExtensions: ['.jpg', '.jpeg', '.png', '.gif'],
    maxFileSize: 1048576
  };

  constructor(
    private restauranteService: RestauranteService,
    private idiomaService: IdiomasService,
    translate: TranslateService,
    private menuService: MenuService,
    private route: ActivatedRoute,
    public router: Router,
    private translateService: TranslateService,
    private usuariosService: UsuariosService,
    private alertService: AlertService) {

    this.translate = translateService;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        if (this.router.url.startsWith('/comedores/editar') == true) {
          this.menuService.titulo = this.translate.instant('comedor');
          this.id = Number(this.route.snapshot.params['idcomedor']);
          console.log(this.id);
          if (this.id == -1) {
            this.isAddMode = true;
          } else {
            this.isAddMode = false;
          }
        }
      }
    });
    this.cargarDatosIniciales();
  }

  cargarDatosIniciales() {
    this.idOrg = this.route.snapshot.params['idorg'];
    this.idRestaurante = this.route.snapshot.params['idrestaurante'];
    this.idComedor = this.route.snapshot.params['idcomedor'];
    this.isAddMode = this.idComedor == -1;

    this.nombre = "";
    this.comensales = 0;
    this.observaciones = "";

    //Load Langs
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomasTotales = result.data;
      this.listaIdiomasTotales.forEach((x) => {
        const trad = new ComedorTrad();
        trad.filled = false;
        trad.idIdioma = x.codigo;
        trad.nombreIdioma = x.nombre;
        this.listaTraduccionesEnviar.push(trad);
      });
      this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
        this.listaIdiomas = result.data;
        if (this.listaIdiomas.length > 0)
          this.selectedIdioma = this.listaIdiomas[0];
        this.cargarDatos();
      });
    });
  }

  ngOnInit(): void {
  }

  cargarDatos() {
    if (!this.isAddMode) { //editando
      this.restauranteService.getComedoresByIdLang(this.idComedor, this.selectedIdioma.codigo).subscribe((result: any) => {
        if (result.data.length > 0) {
          this.nombre = result.data[0].nombre;
          this.observaciones = result.data[0].observaciones;
          this.fondo = result.data[0].urlFondo;
          this.archivo = result.data[0].urlFondo; //Es para que no se borre el fondo al actualizar
          this.tamano_panel_cm = new Tamano_Panel(Number(result.data[0].ancho), Number(result.data[0].alto));
          var x = 800;
          var y = 800;
          if (this.tamano_panel_cm.alto >= this.tamano_panel_cm.ancho) {
            x = (this.tamano_panel_cm.ancho * 800) / this.tamano_panel_cm.alto;
            this.escala_panel = this.tamano_panel_cm.ancho / 1000;
          } else if (this.tamano_panel_cm.alto < this.tamano_panel_cm.ancho) {
            y = (this.tamano_panel_cm.alto * 800) / this.tamano_panel_cm.ancho;
            this.escala_panel = this.tamano_panel_cm.alto / 1000;
          }
          this.tamano_panel = new Tamano_Panel(x, y);
          this.loadData();
        }
      });
    } else {
      //si es nuevo inicializamos los datos
      this.nombre = "";
      this.observaciones = "";
      this.fondo = undefined;
      //Creamos el tamaño inicial del panel, los clientes lo veran en cm y asi se guardara en la base de datos, pero nosotros lo usaremos en px
      this.tamano_panel_cm = new Tamano_Panel(1000, 1000); //El tamano, a los clientes se les muestra en cm
      var x = (this.tamano_panel_cm.ancho * 800) / this.tamano_panel_cm.alto;
      var y = 800;
      //Como inicialmente ambas son 1000, iguales, se inicializa así  
      this.escala_panel = this.tamano_panel_cm.ancho / 1000;
      this.tamano_panel = new Tamano_Panel(x, y); //El tamano en px
      this.loadData();
    }
  }

  loadData() {
    this.restauranteService.getPerfiles(this.idComedor).subscribe((result) => {
      // Por defecto el perfil seleccionado sera el primero
      let perfiles = result.data;
      console.log(result.data);
      if (result.data.length > 0) {
        this.selectedPerfil = perfiles.filter(perfil => perfil.isSeleccionado === true)[0].id;
        this.selectedIndex = perfiles.findIndex(perfil => perfil.id === this.selectedPerfil);
        perfiles.forEach(perfil => {
          let id: number = perfil.id;
          this.mapaPerfiles.set(perfil.id, {
            nombre: perfil.nombre,
            listaMesas: [],
            listaMesasOrden: [],
            listaMesasPanel: [],
            comensales: 0,
            mesas: 0
          });
          //Cargar las mesas del restaurante
          this.restauranteService.getMesas(this.idRestaurante, this.idComedor).subscribe((result: any) => {
            let perfil: Perfil = this.mapaPerfiles.get(id);
            // En pos de eliminar cualquier mesa duplicada de la lista
            for (let index = 0; index < result.data.length; index++) {
              const mesa = result.data[index];
              if (perfil.listaMesas.length === 0) {
                perfil.listaMesas.push(mesa);
              }
              if (perfil.listaMesas.filter(m => m.nombre === mesa.nombre).length === 0) {
                perfil.listaMesas.push(mesa);
              }
            }
            //Cargar la configuracion de mesas del comedor
            this.restauranteService.getMesasPlano(this.idComedor, id).subscribe((result) => {
              this.colocarMesasEnPlano(result.data, id);
            });
            this.numeroPerfiles++;
          });
          this.restauranteService.getComensalesMesasPerfil(id).subscribe((result) => {
            this.mapaPerfiles.get(id).comensales = result.data[0].comensales;
            this.mapaPerfiles.get(id).mesas = result.data[0].mesas;
          });
        });
      } else {
        this.selectedPerfil = -1
        this.selectedIndex = 0;
        this.mapaPerfiles.set(-1, {
          nombre: 'Perfil 1',
          listaMesas: [],
          listaMesasOrden: [],
          listaMesasPanel: [],
          comensales: 0,
          mesas: 0
        });
        //Cargar las mesas del restaurante
        this.restauranteService.getMesas(this.idRestaurante, this.idComedor).subscribe((result: any) => {
          let perfil: Perfil = this.mapaPerfiles.get(-1);
          // En pos de eliminar cualquier mesa duplicada de la lista
          result.data.forEach(mesa => {
            if (perfil.listaMesas.length === 0) {
              perfil.listaMesas.push(mesa);
            }
            if (perfil.listaMesas.filter(m => m.nombre === mesa.nombre).length === 0) {
              perfil.listaMesas.push(mesa);
            }
          });
          this.numeroPerfiles++;
        });
      }
    });
  }

  btnAddTraduccion() {
    if (
      this.selectedIdioma == null ||
      this.nombre.length <= 0
    ) {
      if (this.listaTraducciones.length <= 0) {
        this.alertService.info(
          this.translateService.instant('publicaciones.validacion')
        );
        return;
      }
    }
    if (!this.listaTraducciones.includes(this.selectedIdioma)) {
      this.listaTraducciones.push(this.selectedIdioma);

      this.listaTraduccionesEnviar.map((x) => {
        if (x.idIdioma == this.selectedIdioma.codigo) {
          x.filled = true;
          x.nombre = this.nombre;
        }
      });
    }
  }

  btnDeleteTraduccion(idioma: Idioma) {
    this.listaTraducciones = this.listaTraducciones.filter(
      (x) => x.codigo != idioma.codigo
    );

    this.listaTraduccionesEnviar.map((x) =>
      x.idIdioma == idioma.codigo ? (x.filled = false) : console.log()
    );
  }

  colocarMesasEnPlano(listaMesasPlano, id: number) {
    var th = this;
    let perfil: Perfil = this.mapaPerfiles.get(id);
    for (var i = 0; i < listaMesasPlano.length; i++) {

      var dataItem = listaMesasPlano[i];
      var mesa = {
        id: dataItem.idMesa.toString(),
        numMesa: dataItem.numMesa,
        nombre: dataItem.nombre,
        isDragging: false,
        positionX: dataItem.positionX,
        positionY: dataItem.positionY,
        width: dataItem.width * this.escala_panel,
        height: dataItem.height * this.escala_panel,
        capacidad: dataItem.capacidad,
        restaComensalesAlto: dataItem.restaComensalesAlto,
        restaComensalesLargo: dataItem.restaComensalesLargo,
        urlFoto: dataItem.urlFoto,
        relacionadoArriba: { estaRelacionado: dataItem.relacionadoArriba, idRelacionado: dataItem.relacionadoArriba_idRelacionado },
        relacionadoAbajo: { estaRelacionado: dataItem.relacionadoAbajo, idRelacionado: dataItem.relacionadoAbajo_idRelacionado },
        relacionadoIzquierda: { estaRelacionado: dataItem.relacionadoIzquierda, idRelacionado: dataItem.relacionadoIzquierda_idRelacionado },
        relacionadoDerecha: { estaRelacionado: dataItem.relacionadoDerecha, idRelacionado: dataItem.relacionadoDerecha_idRelacionado },
        conectable: (dataItem.conectable == null || dataItem.conectable == undefined || !dataItem.conectable) ? false : true
      };
      console.log(mesa);
      perfil.listaMesasPanel.push(mesa);
      if (this.selectedPerfil === id) {
        this.createDraggrableItem(mesa);
      }
    }
    // Creating the ordered list based on the tables that are on the panel
    perfil.listaMesasOrden = perfil.listaMesas.filter(mesa => {
      for (let index = 0; index < perfil.listaMesasPanel.length; index++) {
        const mesaPanel = perfil.listaMesasPanel[index];
        if(+mesaPanel.id === mesa.id) {
          return true;
        }
      }
      return false;
    });
  }

  addMesaPanel(dataItem) {
    var nuevaMesa = {
      id: dataItem.id.toString(),
      numMesa: dataItem.numMesa,
      nombre: dataItem.nombre,
      isDragging: false,
      positionX: 0,
      positionY: 0,
      width: 200 * this.escala_panel,
      height: 100 * this.escala_panel,
      capacidad: dataItem.comensales,
      restaComensalesAlto: dataItem.restaComensalesAlto,
      restaComensalesLargo: dataItem.restaComensalesLargo,
      urlFoto: dataItem.urlFoto,
      relacionadoArriba: { estaRelacionado: false, idRelacionado: "-1" },
      relacionadoAbajo: { estaRelacionado: false, idRelacionado: "-1" },
      relacionadoIzquierda: { estaRelacionado: false, idRelacionado: "-1" },
      relacionadoDerecha: { estaRelacionado: false, idRelacionado: "-1" },
      conectable: (dataItem.conectable == null || dataItem.conectable == undefined || !dataItem.conectable) ? false : true
    };
    this.mapaPerfiles.get(this.selectedPerfil).listaMesasPanel.push(nuevaMesa);
    this.mapaPerfiles.get(this.selectedPerfil).listaMesasOrden.push(dataItem);
    this.mapaPerfiles.get(this.selectedPerfil).comensales += dataItem.comensales;

    this.createDraggrableItem(nuevaMesa);
  }

  async createDraggrableItem(mesa) {
    //CREAR DRAG AND DROP EN EL DIV
    //Damos tiempo a que genere los divs
    await this.sleep(1);
    var th = this;
    //Creamos los elementos draggables
    $("#" + mesa.id).draggable({
      containment: ".example-boundary",
      grid: [2, 2],
      start: function (event, ui) {
        th.puntoInicio = th.calcularPuntoDiv(this);
        th.puntoAnterior = th.calcularPuntoDiv(this);
      },
      drag: function (event, ui) {
        th.movimientoDrag(this, false)
        th.puntoAnterior = th.calcularPuntoDiv(this);
      },
      stop: function (event, ui) {
        th.movimientoDrag(this, true)
        //Si algun div esta rojo (esta solapado con otro)(tanto el que movemos como los relacionados),
        //llevamos el que estamos moviendo y sus relacionados a su punto de origen
        var divEnMovimiento = this;
        $(".example-box2").each(function () {
          if ($(this).children(".capaColor").css("background") == "rgb(255, 0, 0) none repeat scroll 0% 0% / auto padding-box border-box") {
            th.revertirMovimiento(divEnMovimiento);
          }
        });
        th.calcularPuntoMesa(this)
      }
    });
    // Colacando el elemento en la posicion correcta
    this.setOffsetMesa(mesa);
  }

  setOffsetMesa(mesa) {
    //Colocamos el elemento en una posicion x,y disponible
    //Conseguimos el offset del container
    var offsetContainer = $(".example-boundary").offset();
    var xContainer = offsetContainer.left;
    var yContainer = offsetContainer.top;
    $("#" + mesa.id).offset({ top: yContainer + mesa.positionY, left: xContainer + mesa.positionX });
  }

  async deleteMesaPanel(dataItem) {
    let perfil: Perfil = this.mapaPerfiles.get(this.selectedPerfil);
    var mesaParaBorrar = perfil.listaMesasPanel.find(x => x.id == dataItem.id.toString());
    var mesaParaBorrarOrden = perfil.listaMesasOrden.find(x => x.id == dataItem.id.toString());

    //Primero, si hay alguna mesa relacionada con esta, quitamos la relacion
    for (var i = 0; i < perfil.listaMesasPanel.length; i++) {
      if (perfil.listaMesasPanel[i].relacionadoArriba.idRelacionado == dataItem.id.toString()) perfil.listaMesasPanel[i].relacionadoArriba = { estaRelacionado: false, idRelacionado: "-1" };
      if (perfil.listaMesasPanel[i].relacionadoAbajo.idRelacionado == dataItem.id.toString()) perfil.listaMesasPanel[i].relacionadoAbajo = { estaRelacionado: false, idRelacionado: "-1" };
      if (perfil.listaMesasPanel[i].relacionadoIzquierda.idRelacionado == dataItem.id.toString()) perfil.listaMesasPanel[i].relacionadoIzquierda = { estaRelacionado: false, idRelacionado: "-1" };
      if (perfil.listaMesasPanel[i].relacionadoDerecha.idRelacionado == dataItem.id.toString()) perfil.listaMesasPanel[i].relacionadoDerecha = { estaRelacionado: false, idRelacionado: "-1" };
    }
    //Luego, quitamos la mesa del panel
    var index = perfil.listaMesasPanel.indexOf(mesaParaBorrar);
    perfil.listaMesasPanel.splice(index, 1);

    //Y de la lista ordenada
    var index2 = perfil.listaMesasOrden.indexOf(mesaParaBorrarOrden);
    perfil.listaMesasOrden.splice(index2, 1);

    await this.sleep(1);

    //Ponemos cada mesa que queda en su posicion
    //También aprovechamos para contar los comensales de nuevo, ya que al quitar esta se modifican y no sabemos cuanto, 
    //porque si tenía alguna conexion la conectada recupera tambien sus comensales de la conexion
    perfil.comensales = 0;
    for (var i = 0; i < perfil.listaMesasPanel.length; i++) {
      perfil.comensales += this.calcularCapacidad(perfil.listaMesasPanel[i]);
      var offsetContainer = $(".example-boundary").offset();
      var xContainer = offsetContainer.left;
      var yContainer = offsetContainer.top;
      $("#" + perfil.listaMesasPanel[i].id).offset({ top: yContainer + perfil.listaMesasPanel[i].positionY, left: xContainer + perfil.listaMesasPanel[i].positionX });
    }
  }

  mesaEstaEnPanel(dataItem) {
    return this.mapaPerfiles.get(this.selectedPerfil).listaMesasPanel.find(x => x.id == dataItem.id.toString()) != undefined;
  }

  //LOGICA DE DRAG DE MESAS
  public mousePosition = { x: 0, y: 0 };
  public listaMesasPanel = [];
  public puntoInicio = { x: 0, y: 0 }; //Indica el punto en el que se ha iniciado el drag de una mesa
  public puntoAnterior = { x: 0, y: 0 }; //Indica el punto del que se ha movido mientras se hace el drag de una mesa

  DragStartedMesa($event, mesa) {
    mesa.isDragging = true;
    this.mousePosition.x = $event.screenX;
    this.mousePosition.y = $event.screenY;
  }

  DragEndedMesa(mesa) {
    mesa.isDragging = false;
  }

  someOneDragging() {
    let perfil: Perfil = this.mapaPerfiles.get(this.selectedPerfil);
    for (var i = 0; i < perfil.listaMesasPanel.length; i++)
      if (perfil.listaMesasPanel[i].isDragging)
        return true;
    return false;
  }

  movimientoDrag(mesaDiv, dragStop = false) {
    this.calcularColores(mesaDiv, dragStop)

    //Si el div esta relacionado con otro div, movemos el otro div tambien
    var puntoActual = this.calcularPuntoDiv(mesaDiv);
    var idsRelacionados = this.getIdsRelacionados([], mesaDiv.id, true);
    for (var i = 0; i < idsRelacionados.length; i++) {
      var pixelsMovidoEjeX = puntoActual.x - this.puntoAnterior.x;
      var pixelsMovidoEjeY = puntoActual.y - this.puntoAnterior.y;

      var offsetDivRelacionado = $("#" + idsRelacionados[i]).offset();
      var xDivRelacionado = offsetDivRelacionado.left;
      var yDivRelacionado = offsetDivRelacionado.top;

      $("#" + idsRelacionados[i]).offset({ top: yDivRelacionado + pixelsMovidoEjeY, left: xDivRelacionado + pixelsMovidoEjeX });
    }
  }

  calcularPuntoMesa(mesaDiv) {
    let perfil: Perfil = this.mapaPerfiles.get(this.selectedPerfil);
    var mesa = perfil.listaMesasPanel.find(x => x.id == mesaDiv.id);

    mesa.isDragging = false;
    //Le guardamos la nueva posicion a cada mesa
    var offsetContainer = $(".example-boundary").offset();
    var xContainer = offsetContainer.left;
    var yContainer = offsetContainer.top;

    for (var i = 0; i < perfil.listaMesasPanel.length; i++) {
      var offsetMesa = $("#" + perfil.listaMesasPanel[i].id).offset();
      perfil.listaMesasPanel[i].positionX = offsetMesa.left - xContainer;
      perfil.listaMesasPanel[i].positionY = offsetMesa.top - yContainer;
    }
  }

  getIdsRelacionados(mesasAnalizadas, idMesa, primeraVez = false) {
    //Borramos los duplicados
    return [...new Set(this.getIdsRelacionados2([], idMesa, true))];
  }

  getIdsRelacionados2(mesasAnalizadas, idMesa, primeraVez = false) {

    //Recibe una mesa y devuelve los ids de las mesas que estan relacionadas con esta
    //Tiene en cuenta las mesas que no estan relacionadas directamente con la mesa, si no que estan
    //relacionadas con una mesa de la cual si esta relacionada

    var mesa = this.mapaPerfiles.get(this.selectedPerfil).listaMesasPanel.find(x => x.id == idMesa);

    //Controlamos las mesas analizadas para no caer en un bucle
    if (mesasAnalizadas.includes(idMesa))
      return [];
    else {
      mesasAnalizadas.push(idMesa);
      var respuesta = [];
      if (!primeraVez) respuesta.push(idMesa);
      if (mesa.relacionadoArriba.estaRelacionado && !mesasAnalizadas.includes(mesa.relacionadoArriba.idRelacionado)) {
        respuesta.push(mesa.relacionadoArriba.idRelacionado);
        respuesta = respuesta.concat(this.getIdsRelacionados2(mesasAnalizadas, mesa.relacionadoArriba.idRelacionado));
      }
      if (mesa.relacionadoAbajo.estaRelacionado && !mesasAnalizadas.includes(mesa.relacionadoAbajo.idRelacionado)) {
        respuesta.push(mesa.relacionadoAbajo.idRelacionado);
        respuesta = respuesta.concat(this.getIdsRelacionados2(mesasAnalizadas, mesa.relacionadoAbajo.idRelacionado));
      }
      if (mesa.relacionadoIzquierda.estaRelacionado && !mesasAnalizadas.includes(mesa.relacionadoIzquierda.idRelacionado)) {
        respuesta.push(mesa.relacionadoIzquierda.idRelacionado);
        respuesta = respuesta.concat(this.getIdsRelacionados2(mesasAnalizadas, mesa.relacionadoIzquierda.idRelacionado));
      }
      if (mesa.relacionadoDerecha.estaRelacionado && !mesasAnalizadas.includes(mesa.relacionadoDerecha.idRelacionado)) {
        respuesta.push(mesa.relacionadoDerecha.idRelacionado);
        respuesta = respuesta.concat(this.getIdsRelacionados2(mesasAnalizadas, mesa.relacionadoDerecha.idRelacionado));
      }
      return respuesta;
    }

  }

  calcularColores(mesaDiv, dragStop = false) {
    var th = this;

    var idMesaMov = mesaDiv.id;
    var idsRelacionados = this.getIdsRelacionados([], idMesaMov, true);

    //Calculamos los bordes de los 4 clips del div que estamos moviendo y del propio div
    var bordesMov = th.calcularBordesClipMesa(mesaDiv);
    //console.log(bordesMov);
    $('.example-box2:not(#' + mesaDiv.id + ')').each(function () {

      //Calculamos los bordes de los 4 clips de los divs estaticos y del propio div
      var bordesDiv = th.calcularBordesClipMesa(this);
      //Calculamos los bordes del div de los divs estaticos para ver si alguno interfiere con el del div en movimiento
      var a = bordesMov[0];
      var b = bordesDiv[0];
      if (a.left >= b.right || a.top >= b.bottom || a.right <= b.left || a.bottom <= b.top) {
      } else {
        //Si los divs estan relacionados, no sera una collision para nosotros
        //Esto pasa porque cuando estan relacionados y uno replica los movimientos del otro, no lo hace exacto y a veces hace que se solapen por 1 pixel
        var mesa = th.mapaPerfiles.get(th.selectedPerfil).listaMesasPanel.find(x => x.id == mesaDiv.id);
        if (!(mesa.relacionadoArriba.estaRelacionado && mesa.relacionadoArriba.idRelacionado == this.id) &&
          !(mesa.relacionadoAbajo.estaRelacionado && mesa.relacionadoAbajo.idRelacionado == this.id) &&
          !(mesa.relacionadoIzquierda.estaRelacionado && mesa.relacionadoIzquierda.idRelacionado == this.id) &&
          !(mesa.relacionadoDerecha.estaRelacionado && mesa.relacionadoDerecha.idRelacionado == this.id)) {
          a.collision = true;
          b.collision = true;
        }
      }

      //Calculamos los bordes de los 4 clips de los divs estaticos para ver si alguno interfiere con los del div en movimiento
      for (var i = 1; i < bordesMov.length; i++) {
        for (var j = 1; j < bordesDiv.length; j++) {
          var a = bordesMov[i];
          var b = bordesDiv[j];
          if (a.left >= b.right || a.top >= b.bottom || a.right <= b.left || a.bottom <= b.top) {
          } else {
            a.collision = true;
            b.collision = true;
            //Si algun clip esta junto con otro y se ha soltado el elemento (dragStop = true), los unimos
            if (dragStop) {
              var idMesa_a = bordesMov[0].div[0].id;
              var idMesa_b = bordesDiv[0].div[0].id;
              var mesa_a = th.mapaPerfiles.get(th.selectedPerfil).listaMesasPanel.find(x => x.id == idMesa_a);
              var mesa_b = th.mapaPerfiles.get(th.selectedPerfil).listaMesasPanel.find(x => x.id == idMesa_b);
              //Los unimos solo si no es rojo (es decir, no esta solapado con otra mesa)
              //Y si ambas mesas son conectables
              //Tambien miramos que ningun div relacionado esta en rojo
              var hacemosClip = true;
              if (bordesMov[0].div.children(".capaColor").css("background") == "rgb(255, 0, 0) none repeat scroll 0% 0% / auto padding-box border-box")
                hacemosClip = false;
              if (hacemosClip)
                for (var k = 0; k < idsRelacionados.length; k++) {
                  if ($("#" + idsRelacionados[k]).children(".capaColor").css("background") == "rgb(255, 0, 0) none repeat scroll 0% 0% / auto padding-box border-box")
                    hacemosClip = false;
                }
              if (!mesa_a.conectable || !mesa_b.conectable) {
                hacemosClip = false;
                th.revertirMovimiento(mesaDiv);
              }
              if (hacemosClip) {
                //Restamos las dos mesas de los comensales
                th.comensales -= th.calcularCapacidad(mesa_a);
                th.comensales -= th.calcularCapacidad(mesa_b);
                if (a.lado == "arriba" && b.lado == "arriba" && !mesa_a.relacionadoArriba.estaRelacionado && !mesa_b.relacionadoArriba.estaRelacionado) {
                  mesa_a.relacionadoArriba = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoArriba = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "arriba" && b.lado == "abajo" && !mesa_a.relacionadoArriba.estaRelacionado && !mesa_b.relacionadoAbajo.estaRelacionado) {
                  mesa_a.relacionadoArriba = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoAbajo = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "arriba" && b.lado == "izquierda" && !mesa_a.relacionadoArriba.estaRelacionado && !mesa_b.relacionadoIzquierda.estaRelacionado) {
                  mesa_a.relacionadoArriba = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoIzquierda = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "arriba" && b.lado == "derecha" && !mesa_a.relacionadoArriba.estaRelacionado && !mesa_b.relacionadoDerecha.estaRelacionado) {
                  mesa_a.relacionadoArriba = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoDerecha = { estaRelacionado: true, idRelacionado: idMesa_a };
                }

                if (a.lado == "abajo" && b.lado == "arriba" && !mesa_a.relacionadoAbajo.estaRelacionado && !mesa_b.relacionadoArriba.estaRelacionado) {
                  mesa_a.relacionadoAbajo = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoArriba = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "abajo" && b.lado == "abajo" && !mesa_a.relacionadoAbajo.estaRelacionado && !mesa_b.relacionadoAbajo.estaRelacionado) {
                  mesa_a.relacionadoAbajo = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoAbajo = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "abajo" && b.lado == "izquierda" && !mesa_a.relacionadoAbajo.estaRelacionado && !mesa_b.relacionadoIzquierda.estaRelacionado) {
                  mesa_a.relacionadoAbajo = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoIzquierda = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "abajo" && b.lado == "derecha" && !mesa_a.relacionadoAbajo.estaRelacionado && !mesa_b.relacionadoDerecha.estaRelacionado) {
                  mesa_a.relacionadoAbajo = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoDerecha = { estaRelacionado: true, idRelacionado: idMesa_a };
                }

                if (a.lado == "izquierda" && b.lado == "arriba" && !mesa_a.relacionadoIzquierda.estaRelacionado && !mesa_b.relacionadoArriba.estaRelacionado) {
                  mesa_a.relacionadoIzquierda = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoArriba = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "izquierda" && b.lado == "abajo" && !mesa_a.relacionadoIzquierda.estaRelacionado && !mesa_b.relacionadoAbajo.estaRelacionado) {
                  mesa_a.relacionadoIzquierda = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoAbajo = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "izquierda" && b.lado == "izquierda" && !mesa_a.relacionadoIzquierda.estaRelacionado && !mesa_b.relacionadoIzquierda.estaRelacionado) {
                  mesa_a.relacionadoIzquierda = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoIzquierda = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "izquierda" && b.lado == "derecha" && !mesa_a.relacionadoIzquierda.estaRelacionado && !mesa_b.relacionadoDerecha.estaRelacionado) {
                  mesa_a.relacionadoIzquierda = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoDerecha = { estaRelacionado: true, idRelacionado: idMesa_a };
                }

                if (a.lado == "derecha" && b.lado == "arriba" && !mesa_a.relacionadoDerecha.estaRelacionado && !mesa_b.relacionadoArriba.estaRelacionado) {
                  mesa_a.relacionadoDerecha = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoArriba = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "derecha" && b.lado == "abajo" && !mesa_a.relacionadoDerecha.estaRelacionado && !mesa_b.relacionadoAbajo.estaRelacionado) {
                  mesa_a.relacionadoDerecha = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoAbajo = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "derecha" && b.lado == "izquierda" && !mesa_a.relacionadoDerecha.estaRelacionado && !mesa_b.relacionadoIzquierda.estaRelacionado) {
                  mesa_a.relacionadoDerecha = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoIzquierda = { estaRelacionado: true, idRelacionado: idMesa_a };
                }
                if (a.lado == "derecha" && b.lado == "derecha" && !mesa_a.relacionadoDerecha.estaRelacionado && !mesa_b.relacionadoDerecha.estaRelacionado) {
                  mesa_a.relacionadoDerecha = { estaRelacionado: true, idRelacionado: idMesa_b };
                  mesa_b.relacionadoDerecha = { estaRelacionado: true, idRelacionado: idMesa_a };
                }

                //Y les sumamos la capacidad de ambas dos
                th.comensales += th.calcularCapacidad(mesa_a);
                th.comensales += th.calcularCapacidad(mesa_b);
              }
            }
          }
        }
      }

      //Si algun div estatico esta colisionando con el div en movimiento, lo resaltamos
      var b = bordesDiv[0];
      if (b.collision)
        b.div.children(".capaColor").css("background", "red");
      else
        b.div.children(".capaColor").css("background", "white");

      //Si algun clip de algun div estatico colisiona con algun clip del div en movimiento, lo resaltamos (solo si el div general no colisiona)
      //Si el div esta asociado a otro (es amarillo), entonces no lo resaltamos
      var divGeneralColisiona = b.collision;
      for (var j = 1; j < bordesDiv.length; j++) {
        var b = bordesDiv[j];
        if (b.div.css("background") != "rgb(255, 255, 0) none repeat scroll 0% 0% / auto padding-box border-box")
          if (b.collision && !divGeneralColisiona)
            b.div.css("background", "blue");
          else
            b.div.css("background", "#aaa");
      }

    });

    //Si algun div estatico esta colisionando con el div en movimiento, lo resaltamos
    var a = bordesMov[0];
    if (a.collision)
      a.div.children(".capaColor").css("background", "red");
    else
      a.div.children(".capaColor").css("background", "white");

    //Si algun clip del div en movimiento colisiona con algun clip de algun div estatico, lo resaltamos (solo si el div general no colisiona)
    //Si el div esta asociado a otro (es amarillo), entonces no lo resaltamos
    var divGeneralColisiona = a.collision;
    for (var i = 1; i < bordesMov.length; i++) {
      var a = bordesMov[i];
      if (a.div.css("background") != "rgb(255, 255, 0) none repeat scroll 0% 0% / auto padding-box border-box")
        if (a.collision && !divGeneralColisiona)
          a.div.css("background", "blue");
        else
          a.div.css("background", "#aaa");
    }

    //Si algun div esta fuera del container (alguno que se mueva por que esta relacionado) lo ponemos en rojo
    $(".example-box2").each(function () {
      var puntosDiv = th.calcularPuntoDiv(this);
      var heightDiv = $(this).height();
      var widthDiv = $(this).width();

      if (puntosDiv.x < 0 || puntosDiv.x + widthDiv > th.tamano_panel.ancho || puntosDiv.y < 0 || puntosDiv.y + heightDiv > th.tamano_panel.alto)
        $(this).children(".capaColor").css("background", "red");
    });

    //Miramos si el div relacionado esta solapado con algun otro div, y lo ponemos en rojo
    for (var i = 0; i < idsRelacionados.length; i++) {
      var idRealcionado = idsRelacionados[i];
      var bordesDivRelacionado = th.calcularBordesClipMesa($("#" + idRealcionado));
      $('.example-box2:not(#' + idRealcionado + ')').each(function () {
        var idMesa = $(this)[0].id;
        if (idMesa != idMesaMov) {
          var bordesDiv = th.calcularBordesClipMesa(this);
          var a = bordesDivRelacionado[0];
          var b = bordesDiv[0];
          if (a.left >= b.right || a.top >= b.bottom || a.right <= b.left || a.bottom <= b.top) { }
          else {
            $("#" + idRealcionado).children(".capaColor").css("background", "red");
            $(this).children(".capaColor").css("background", "red");
          }
        }
      });
    }

  }

  calcularPuntoDiv(mesaDiv) {
    //Conseguimos el offset del container
    var offsetContainer = $(".example-boundary").offset();
    var xContainer = offsetContainer.left;
    var yContainer = offsetContainer.top;

    //Sacamos los bordes del div
    var offset_Div = $(mesaDiv).offset();
    var xPos_Div = offset_Div.left - xContainer;
    var yPos_Div = offset_Div.top - yContainer;

    return { x: xPos_Div, y: yPos_Div };
  }

  calcularBordesClipMesa(mesaDiv) {
    //Conseguimos el offset del container
    var offsetContainer = $(".example-boundary").offset();
    var xContainer = offsetContainer.left;
    var yContainer = offsetContainer.top;

    //Sacamos los bordes del div
    var offset_Div = $(mesaDiv).offset();
    var xPos_Div = offset_Div.left - xContainer;
    var yPos_Div = offset_Div.top - yContainer;
    var height_Div = $(mesaDiv).height();
    var width_Div = $(mesaDiv).width();
    var borde_Div = {
      left: xPos_Div,
      right: xPos_Div + width_Div,
      top: yPos_Div,
      bottom: yPos_Div + height_Div,
      div: $(mesaDiv),
      collision: false,
      lado: false
    }

    //Sacamos los cuatro bordes de cada clip del div 
    //Arriba
    var offset_Arriba = $(mesaDiv).children(".clipArriba").offset();
    var xPos_Arriba = offset_Arriba.left - xContainer;
    var yPos_Arriba = offset_Arriba.top - yContainer;
    var height_Arriba = $(mesaDiv).children(".clipArriba").height();
    var width_Arriba = $(mesaDiv).children(".clipArriba").width();
    var borde_Arriba = {
      left: xPos_Arriba,
      right: xPos_Arriba + width_Arriba,
      top: yPos_Arriba,
      bottom: yPos_Arriba + height_Arriba,
      div: $(mesaDiv).children(".clipArriba"),
      collision: false,
      lado: "arriba"
    }
    //Abajo
    var offset_Abajo = $(mesaDiv).children(".clipAbajo").offset();
    var xPos_Abajo = offset_Abajo.left - xContainer;
    var yPos_Abajo = offset_Abajo.top - yContainer;
    var height_Abajo = $(mesaDiv).children(".clipAbajo").height();
    var width_Abajo = $(mesaDiv).children(".clipAbajo").width();
    var borde_Abajo = {
      left: xPos_Abajo,
      right: xPos_Abajo + width_Abajo,
      top: yPos_Abajo,
      bottom: yPos_Abajo + height_Abajo,
      div: $(mesaDiv).children(".clipAbajo"),
      collision: false,
      lado: "abajo"
    }
    //Izquierda
    var offset_Izquierda = $(mesaDiv).children(".clipIzquierda").offset();
    var xPos_Izquierda = offset_Izquierda.left - xContainer;
    var yPos_Izquierda = offset_Izquierda.top - yContainer;
    var height_Izquierda = $(mesaDiv).children(".clipIzquierda").height();
    var width_Izquierda = $(mesaDiv).children(".clipIzquierda").width();
    var borde_Izquierda = {
      left: xPos_Izquierda,
      right: xPos_Izquierda + width_Izquierda,
      top: yPos_Izquierda,
      bottom: yPos_Izquierda + height_Izquierda,
      div: $(mesaDiv).children(".clipIzquierda"),
      collision: false,
      lado: "izquierda"
    }
    //Derecha
    var offset_Derecha = $(mesaDiv).children(".clipDerecha").offset();
    var xPos_Derecha = offset_Derecha.left - xContainer;
    var yPos_Derecha = offset_Derecha.top - yContainer;
    var height_Derecha = $(mesaDiv).children(".clipDerecha").height();
    var width_Derecha = $(mesaDiv).children(".clipDerecha").width();
    var borde_Derecha = {
      left: xPos_Derecha,
      right: xPos_Derecha + width_Derecha,
      top: yPos_Derecha,
      bottom: yPos_Derecha + height_Derecha,
      div: $(mesaDiv).children(".clipDerecha"),
      collision: false,
      lado: "derecha"
    }

    return [borde_Div, borde_Arriba, borde_Abajo, borde_Izquierda, borde_Derecha];

  }

  revertirMovimiento(divEnMovimiento) {
    //Revierte el movimiento que hemos hecho y tambien el de los divs relacionados
    //Primero calculamos cuanto se ha desplazado desde el inicio del drag
    var puntoActual = this.calcularPuntoDiv(divEnMovimiento);

    var pixelsMovidoEjeX = puntoActual.x - this.puntoInicio.x;
    var pixelsMovidoEjeY = puntoActual.y - this.puntoInicio.y;

    //Revertimos el movimiento del div que estamos moviendo
    var offsetDivMovimeinto = $(divEnMovimiento).offset();
    var xDivMovimeinto = offsetDivMovimeinto.left;
    var yDivMovimeinto = offsetDivMovimeinto.top;
    $(divEnMovimiento).offset({ top: yDivMovimeinto - pixelsMovidoEjeY, left: xDivMovimeinto - pixelsMovidoEjeX });
    $(divEnMovimiento).children(".capaColor").css("background", "white");

    //Revertimos el movimiento de los divs relacionados
    var idsRelacionados = this.getIdsRelacionados([], divEnMovimiento.id, true);
    for (var i = 0; i < idsRelacionados.length; i++) {
      var offsetDivRelacionado = $("#" + idsRelacionados[i]).offset();
      var xDivRelacionado = offsetDivRelacionado.left;
      var yDivRelacionado = offsetDivRelacionado.top;
      $("#" + idsRelacionados[i]).offset({ top: yDivRelacionado - pixelsMovidoEjeY, left: xDivRelacionado - pixelsMovidoEjeX });
      $("#" + idsRelacionados[i]).children(".capaColor").css("background", "white");
    }

    //Les quitamos el color rojo a todos los divs
    $(".example-box2").each(function () {
      $(this).children(".capaColor").css("background", "white");
    });

    //Le quitamos el color azul a todos los clips azules
    $(".clipArriba, .clipAbajo, .clipIzquierda, .clipDerecha").each(function () {
      if ($(this).css("background") == "rgb(0, 0, 255) none repeat scroll 0% 0% / auto padding-box border-box") $(this).css("background", "#aaa");
    });

  }

  clickQuitarClip($event, mesa, posicionClip) {
    let perfil: Perfil = this.mapaPerfiles.get(this.selectedPerfil);
    //Solo quitamos el clip si es un click y no un drag (lo comprobamos mirando la posicion inicial del click del raton)
    if (this.mousePosition.x == $event.screenX && this.mousePosition.y == $event.screenY) {
      var idRelacionado;

      //Quitamos de los comensales la capacidad de ambas mesas unidas
      var auxi_a1 = this.calcularCapacidad(mesa);

      //Quitamos el clip de la mesa seleccionada
      if (posicionClip == "arriba") {
        idRelacionado = mesa.relacionadoArriba.idRelacionado;
        mesa.relacionadoArriba = { estaRelacionado: false, idRelacionado: "-1" };
      }
      if (posicionClip == "abajo") {
        idRelacionado = mesa.relacionadoAbajo.idRelacionado;
        mesa.relacionadoAbajo = { estaRelacionado: false, idRelacionado: "-1" };
      }
      if (posicionClip == "izquierda") {
        idRelacionado = mesa.relacionadoIzquierda.idRelacionado;
        mesa.relacionadoIzquierda = { estaRelacionado: false, idRelacionado: "-1" };
      }
      if (posicionClip == "derecha") {
        idRelacionado = mesa.relacionadoDerecha.idRelacionado;
        mesa.relacionadoDerecha = { estaRelacionado: false, idRelacionado: "-1" };
      }

      //Quitamos el clip de la mesa relacionada
      var mesaRelacionada = this.mapaPerfiles.get(this.selectedPerfil).listaMesasPanel.find(x => x.id == idRelacionado);

      //Seguimos quitando la capacidad
      perfil.comensales -= auxi_a1;
      perfil.comensales -= this.calcularCapacidad(mesaRelacionada);

      if (mesaRelacionada.relacionadoArriba.estaRelacionado && mesaRelacionada.relacionadoArriba.idRelacionado == mesa.id) mesaRelacionada.relacionadoArriba = { estaRelacionado: false, idRelacionado: "-1" };
      if (mesaRelacionada.relacionadoAbajo.estaRelacionado && mesaRelacionada.relacionadoAbajo.idRelacionado == mesa.id) mesaRelacionada.relacionadoAbajo = { estaRelacionado: false, idRelacionado: "-1" };
      if (mesaRelacionada.relacionadoIzquierda.estaRelacionado && mesaRelacionada.relacionadoIzquierda.idRelacionado == mesa.id) mesaRelacionada.relacionadoIzquierda = { estaRelacionado: false, idRelacionado: "-1" };
      if (mesaRelacionada.relacionadoDerecha.estaRelacionado && mesaRelacionada.relacionadoDerecha.idRelacionado == mesa.id) mesaRelacionada.relacionadoDerecha = { estaRelacionado: false, idRelacionado: "-1" };

      //Ahora sin la union
      perfil.comensales += this.calcularCapacidad(mesa);
      perfil.comensales += this.calcularCapacidad(mesaRelacionada);
    }

  }

  calcularCapacidad(mesa) {
    var capacidad = mesa.capacidad;
    if (mesa.relacionadoArriba.estaRelacionado) {
      capacidad -= mesa.restaComensalesLargo;
    }
    if (mesa.relacionadoAbajo.estaRelacionado) {
      capacidad -= mesa.restaComensalesLargo;
    }
    if (mesa.relacionadoIzquierda.estaRelacionado) {
      capacidad -= mesa.restaComensalesAlto;
    }
    if (mesa.relacionadoDerecha.estaRelacionado) {
      capacidad -= mesa.restaComensalesAlto;
    }
    return capacidad;
  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  //FIN LOGICA DE DRAG DE MESAS


  onClickCancelar(e) {
    e.preventDefault(); //para prevenir que entre al submit
    this.atras();
  }

  atras() {
    this.router.navigate(["/comedores", { idorg: this.idOrg, idrestaurante: this.idRestaurante }]);
  }

  onSubmit() {
    Array.from(this.mapaPerfiles.keys()).forEach((idPerfil: number) => {
      let perfil: Perfil = this.mapaPerfiles.get(idPerfil); // obtemos el perfil que corresponde al id
      var dbBulk = this.createComedorMesasDbBulk(perfil, idPerfil); //Representara la tabla que luego volcaremos en la bd para representar la disposicion de mesas (restaurante_comedor_mesas)
      var dbBulkOrden = this.createComedorMesasOrdenDbBulk(perfil, idPerfil); //Representara la tabla que luego volcaremos en la bd para representar el orden de mesas (restaurante_comedor_mesas_orden)
      if (!this.isAddMode) { //es editar un comedor que ya existe
        if (this.nombre == "") {
          this.alertService.error(this.translateService.instant('restaurante.debeAsignarseUnNombre'));
          return;
        }
        //Primero actualizamos el comedor
        var comedorUpdate = {
          id: this.idComedor,
          idRestaurante: this.idRestaurante,
          nombre: this.nombre,
          urlFondo: this.archivo,
          created: new Date(),
          alto: this.tamano_panel_cm.alto,
          ancho: this.tamano_panel_cm.ancho,
          idIdioma: this.selectedIdioma.codigo,
          listaTraducciones: [], //no nos hace falta, no las vamos a actualizar
          updateFoto: this.archivo.includes("base64") ? true : false,
          listaMesaComedor: [],
          mesas: perfil.listaMesasPanel.length,
          comensales: perfil.comensales
        };
        this.restauranteService.updateComedor(comedorUpdate).subscribe((result: any) => {
          if (!result.error) {
            var r1, r2: boolean = false;
            this.restauranteService.deletePerfil(idPerfil).subscribe((result: any) => {
              const isSeleccionado: boolean = idPerfil === this.selectedPerfil ? true : false;
              this.restauranteService.createPerfil(perfil.nombre, this.idComedor, perfil.comensales, perfil.listaMesasPanel.length, isSeleccionado).subscribe((result: any) => {
                let newPerfilId: number = result.codigo;
                //PRIMERO BORRAMOS LAS MESAS QUE TENIAMOS GUARDADAS
                this.restauranteService.deleteMesasComedor(this.idComedor, idPerfil).subscribe((result: any) => {
                  //actualizamos el id del comedor a la lista de mesas del plano
                  for (var i = 0; i < dbBulk.length; i++)
                    dbBulk[i].idPerfil = newPerfilId;
                  //DESPUES DE BORRAR GUARDAR LA NUEVA CONFIGURACION
                  this.restauranteService.setMesasComedor(dbBulk).subscribe((result: any) => {
                    r1 = true;
                    if (r1 && r2) {
                      this.alertService.success(this.translateService.instant('botones.editadocorrectamente'), { keepAfterRouteChange: true });
                      this.atras();
                    }
                  });
                });
                //PRIMERO BORRAMOS EL ORDEN QUE TENIAMOS GUARDADO
                this.restauranteService.deleteOrdenMesasComedor(this.idComedor, idPerfil).subscribe((result: any) => {
                  //hacemos lo mismo con los ordenes
                  for (var i = 0; i < dbBulkOrden.length; i++)
                    dbBulkOrden[i].idPerfil = newPerfilId;
                  //DESPUES DE BORRAR GUARDAR EL NUEVO ORDEN
                  this.restauranteService.insertMesasOrden(dbBulkOrden).subscribe((result: any) => {
                    r2 = true;
                    if (r1 && r2) {
                      this.alertService.success(this.translateService.instant('botones.editadocorrectamente'), { keepAfterRouteChange: true });
                      this.atras();
                    }
                  });
                });
              });
            });
          } else {
            this.alertService.error(this.translateService.instant('restaurante.erroru'));
          }
        });
      } else {//es crear un comedor nuevo
        if (this.nombre == "") {
          this.alertService.error(this.translateService.instant('restaurante.debeAsignarseUnNombre'));
          return;
        }
        //AutoCompletado de Idiomas
        //seleccionado
        let seleccionado: ComedorTrad = new ComedorTrad();
        let encontrado: boolean = false;
        this.listaTraduccionesEnviar.forEach((trad) => {
          if (trad.filled && !encontrado) {
            seleccionado = trad;
            encontrado = true;
            return;
          }
        });
        //Fill UnFilled
        this.listaTraduccionesEnviar.forEach((trad) => {
          if (!trad.filled) {
            trad.nombre = seleccionado.nombre;
          }
        });
        if (this.listaTraduccionesEnviar.length === 0) {
          this.alertService.error(this.translateService.instant('restuarante.errortrads'));
        }
        var comedor = {
          id: -1,
          idRestaurante: this.idRestaurante,
          nombre: this.nombre,
          urlFondo: this.archivo,
          created: new Date(),
          alto: this.tamano_panel_cm.alto,
          ancho: this.tamano_panel_cm.ancho,
          idIdioma: this.selectedIdioma.codigo,
          listaTraducciones: this.listaTraduccionesEnviar,
          updateFoto: false,
          listaMesaComedor: [],
          mesas: this.listaMesasPanel.length,
          comensales: perfil.comensales
        };
        //PRIMERO CREAMOS EL COMEDOR
        this.restauranteService.createComedor(comedor).subscribe((result: any) => {
          if (!result.error) {
            //actualizamos el id del comedor a la lista de mesas del plano
            for (var i = 0; i < dbBulk.length; i++)
              dbBulk[i].idComedor = result.codigo;
            //hacemos lo mismo con los ordenes
            for (var i = 0; i < dbBulkOrden.length; i++)
              dbBulkOrden[i].idComedor = result.codigo;
            var r1, r2: boolean = false;
            const isSeleccionado: boolean = idPerfil === this.selectedPerfil ? true : false;
            this.restauranteService.createPerfil(perfil.nombre, result.codigo, perfil.comensales, perfil.listaMesasPanel.length, isSeleccionado).subscribe((result: any) => {
              let newPerfilId: number = result.codigo;
              //actualizamos el id del comedor a la lista de mesas del plano
              for (var i = 0; i < dbBulk.length; i++)
                dbBulk[i].idPerfil = newPerfilId;
              //hacemos lo mismo con los ordenes
              for (var i = 0; i < dbBulkOrden.length; i++)
                dbBulkOrden[i].idPerfil = newPerfilId;
              //DESPUES DE BORRAR GUARDAR LA NUEVA CONFIGURACION
              this.restauranteService.setMesasComedor(dbBulk).subscribe((result: any) => {
                r1 = true;
                if (r1 && r2) {
                  this.alertService.success(this.translateService.instant('botones.editadocorrectamente'), { keepAfterRouteChange: true });
                  this.atras();
                }
              });
              //DESPUES DE BORRAR GUARDAR EL NUEVO ORDEN
              this.restauranteService.insertMesasOrden(dbBulkOrden).subscribe((result: any) => {
                r2 = true;
                if (r1 && r2) {
                  this.alertService.success(this.translateService.instant('botones.editadocorrectamente'), { keepAfterRouteChange: true });
                  this.atras();
                }
              });
            });
            //DESPUES DE CREAR EL COMEDOR GUARDAR LA NUEVA CONFIGURACION
            this.restauranteService.setMesasComedor(dbBulk).subscribe((result: any) => {
              r1 = true;
              if (r1 && r2) {
                this.alertService.success(this.translateService.instant('botones.creadocorrectamente'), { keepAfterRouteChange: true });
                this.atras();
              }
            });
            //DESPUES DE CREAR EL COMEDOR GUARDAR EL NUEVO ORDEN
            this.restauranteService.insertMesasOrden(dbBulkOrden).subscribe((result: any) => {
              r2 = true;
              if (r1 && r2) {
                this.alertService.success(this.translateService.instant('botones.creadocorrectamente'), { keepAfterRouteChange: true });
                this.atras();
              }
            });
          } else {
            this.alertService.error(this.translateService.instant('restaurante.error'));
          }
        });
      }
    });
  }

  createComedorMesasDbBulk(perfil: Perfil, idPerfil: number) {
    var dbBulk = [];
    for (var i = 0; i < perfil.listaMesasPanel.length; i++) {
      var mesaMoment = perfil.listaMesasPanel[i];
      //sacamos la mesa a la que hace referencia en listaMesas
      var mesaRef = perfil.listaMesas.find(x => x.id == mesaMoment.id);
      //Actualizamos los comensales dependiendo de si esta relacionado con alguna mesa o no
      var capacidad = mesaRef.comensales;
      dbBulk.push(this.createComedorMesasTuple(mesaMoment, capacidad, idPerfil));
    }
    return dbBulk;
  }

  createComedorMesasTuple(mesaData, capacidad: number, idPerfil: number) {
    let mesaTuple = {
      idComedor: this.idComedor,//[idComedor] [int] NULL,
      idMesa: mesaData.id,//[idMesa] [varchar](20) NULL,
      nombre: mesaData.nombre,//[nombre] [varchar](200) NULL,
      positionX: mesaData.positionX,//[positionX] [int] NULL,
      positionY: mesaData.positionY,//[positionY] [int] NULL,
      width: mesaData.width / this.escala_panel,//[width] [int] NULL,
      height: mesaData.height / this.escala_panel,//[height] [int] NULL,
      capacidad: capacidad,//[capacidad] [int] NULL,
      restaComensalesAlto: mesaData.restaComensalesAlto,//[restaComensalesAlto] [int] NULL,
      restaComensalesLargo: mesaData.restaComensalesLargo,//[restaComensalesLargo] [int] NULL,
      urlFoto: mesaData.urlFoto,//[urlFoto] [varchar](max) NULL,
      relacionadoArriba: mesaData.relacionadoArriba.estaRelacionado,//[relacionadoArriba] [bit] NULL,
      relacionadoArriba_idRelacionado: mesaData.relacionadoArriba.idRelacionado,//[relacionadoArriba_idRelacionado] [varchar](20) NULL,
      relacionadoAbajo: mesaData.relacionadoAbajo.estaRelacionado,//[relacionadoAbajo] [bit] NULL,
      relacionadoAbajo_idRelacionado: mesaData.relacionadoAbajo.idRelacionado,//[relacionadoAbajo_idRelacionado] [varchar](20) NULL,
      relacionadoIzquierda: mesaData.relacionadoIzquierda.estaRelacionado,//[relacionadoIzquierda] [bit] NULL,
      relacionadoIzquierda_idRelacionado: mesaData.relacionadoIzquierda.idRelacionado,//[relacionadoIzquierda_idRelacionado] [varchar](20) NULL,
      relacionadoDerecha: mesaData.relacionadoDerecha.estaRelacionado,//[relacionadoDerecha] [bit] NULL,
      relacionadoDerecha_idRelacionado: mesaData.relacionadoDerecha.idRelacionado,//[relacionadoDerecha_idRelacionado] [varchar](20) NULL
      conectable: mesaData.conectable,
      idPerfil: idPerfil
    }
    return mesaTuple;
  }

  createComedorMesasOrdenDbBulk(perfil: Perfil, idPerfil: number) {
    var dbBulkOrden = []; //Representara la tabla que luego volcaremos en la bd para representar el orden de mesas (restaurante_comedor_mesas_orden)
    var indexSort = 1;
    for (var i = 0; i < perfil.listaMesasOrden.length; i++) {
      var mesaMoment: any = perfil.listaMesasOrden[i];
      //sacamos la mesa a la que hace referencia en listaMesasPanel
      var mesaRef: any = perfil.listaMesasPanel.find(x => x.id == mesaMoment.id);
      if (mesaRef != undefined) {
        dbBulkOrden.push(this.createComedorMesasOrdenTuple(idPerfil, mesaMoment.id, indexSort));
        indexSort++;
      }
    }
    console.log(dbBulkOrden);
    return dbBulkOrden;
  }

  createComedorMesasOrdenTuple(idPerfil: number, idMesa: number, indexSort: number) {
    let mesaOrdenTuple = {
      idComedor: this.idComedor,
      idPerfil: idPerfil,
      idMesa: idMesa,
      indexSort: indexSort
    }
    return mesaOrdenTuple;
  }



  getMesasSorted(event) {
    console.log(event);
    console.log(this.mapaPerfiles.get(this.selectedPerfil).listaMesasOrden);
  }

  cambioIdioma() {
    console.log('Cargando Recinto Idioma :' + this.selectedIdioma.codigo);
    if (!this.isAddMode) //Si es nuevo no existen
      this.restauranteService
        .getComedoresByIdLang(this.idComedor, this.selectedIdioma.codigo)
        .subscribe((result) => {
          this.nombre = result.data[0].nombre;
        });
    else {
      var auxi = this.listaTraduccionesEnviar.filter(x => x.idIdioma == this.selectedIdioma.codigo);
      if (auxi != undefined && auxi[0].nombre != undefined)
        this.nombre = auxi[0].nombre;
      else
        this.nombre = "";
    }
  }

  //Imagen fondo
  archivoSeleccionado(e) {
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivo !== "" && th.archivo !== null)
        archivoBase64 = await th.toBase64(th.archivo[0]);
      else archivoBase64 = "";
      th.archivo = archivoBase64;
      th.fondo = archivoBase64;
    }, 500);
  }
  archivoEliminado(e) {
    this.archivo = "";
    this.fondo = "";
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  //tamano panel
  cambiarTamanoPanel($event) {
    var x = 800;
    var y = 800;
    if (this.tamano_panel_cm.alto >= this.tamano_panel_cm.ancho) {
      x = (this.tamano_panel_cm.ancho * 800) / this.tamano_panel_cm.alto;
      this.escala_panel = this.tamano_panel_cm.ancho / 1000;
    } else if (this.tamano_panel_cm.alto < this.tamano_panel_cm.ancho) {
      y = (this.tamano_panel_cm.alto * 800) / this.tamano_panel_cm.ancho;
      this.escala_panel = this.tamano_panel_cm.alto / 1000;
    }
    this.tamano_panel.ancho = x;
    this.tamano_panel.alto = y;
  }

  // Handler and helpers

  /**
   * Gestiona el evento de close que lanza el tabstrip. La funciona se cercionara de eliminar el perfil del mapa 
   * local al igual de borrar cualquier dato que quede del perfil borrado en la base de datos. A su vez, este vera si
   * la pestaña eliminada es la misma en la que se encuentra el usuario y solo en ese caso el usuario sera mandado al primer
   * perfil de todos.
   * @param event evento que se lanza cuando se cierra una pestaña en el componente tabstrip de telerik
   */
  tabCloseHandler(event: TabCloseEvent) {
    let key: number = this.getKeyFromTabTitle(event.tab.title);
    if (key !== 0) {
      this.mapaPerfiles.delete(key);
      if (key > 0) {
        this.restauranteService.deletePerfil(key).subscribe(result => {
          console.log(result);
        });
      }
      this.numeroPerfiles--;
      // Solo y solo cuando el perfil sea el activo saltaremos al primer perfil de todos
      if (key === this.selectedPerfil) {
        let firstTabKey: number = Array.from(this.mapaPerfiles.keys())[0];
        this.selectedPerfil = firstTabKey;
        const mesasPanel = this.mapaPerfiles.get(this.selectedPerfil).listaMesasPanel;
        this.setDragagableMesasPanel(mesasPanel);
        this.perfilTabs.selectTab(0);
      }
    }
  }

  /**
   * Gestion el evento de click en una pestaña. Este se encargara de actualizar el perfil seleccionado al id de la
   * pestaña correspondiente, al igual que de inicializar la habilidad de dragg de las mesas que estan en el panel.
   * @param event Evento que se lanza al hacer click en una pestaña del tabstrip. Este evento aporta datos como el id y el titulo de la pestaña seleccionada
   */
  onTabSelect(event) {
    this.selectedPerfil = this.getKeyFromTabTitle(event.title);
    let mesasPanel = this.mapaPerfiles.get(this.selectedPerfil).listaMesasPanel;
    this.setDragagableMesasPanel(mesasPanel);
  }

  /**
   * Da la capacidad de que las mesas que contenga el panel sean arrastrables
   * @param mesasPanel Lista de mesas colacadas en el panel
   */
  setDragagableMesasPanel(mesasPanel: any[]) {
    mesasPanel.forEach(mesa => {
      this.createDraggrableItem(mesa);
    });
  }

  /**
   * Devueelve en forma de array los valores que contiene un mapa
   * @param mapa Mapa 
   * @returns Valores de cada key
   */
  getValues(mapa: Map<number, Perfil>) {
    return Array.from(mapa.values());
  }

  /**
   * Devuelve la key para el objeto que tenga el mismo titulo que @oaram title
   * @param title Titulo del objeto
   * @returns Key atribuida al objeto
   */
  getKeyFromTabTitle(title: string) {
    let tabKey: number = 0;
    Array.from(this.mapaPerfiles.keys()).forEach(key => {
      if (this.mapaPerfiles.get(key).nombre === title) {
        tabKey = key;
        return;
      }
    });
    return tabKey;
  }

  /**
   * Crea y añade un nuevo perfil al mapa de perfiles
   */
  addPerfil() {
    this.numeroPerfiles++
    let listaMesas = this.mapaPerfiles.get(this.selectedPerfil).listaMesas;
    let perfil: Perfil = {
      nombre: 'Perfil ' + this.numeroPerfiles,
      listaMesas: listaMesas,
      listaMesasOrden: [],
      listaMesasPanel: [],
      comensales: 0,
      mesas: 0
    }
    this.mapaPerfiles.set(-1 * this.numeroPerfiles, perfil);
  }
}
