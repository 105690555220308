import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/idiomas`;

@Injectable()
export class IdiomasService {
  constructor(private http: HttpClient) {}

  //Get all langs from Organizations id
  public GetById(id: Number): Observable<any> {
    return this.http.get(baseUrl + '/' + id);
  }

  //Get all available langs from db
  public GetAll(): Observable<any> {
    return this.http.get(baseUrl);
  }

  //Create selectedLangs for Organization
  create(data: any, id: number) {
    console.log('Idiomas seleccionados');
    console.log(JSON.stringify(data));

    return this.http.post<any>(
      baseUrl + '/create/' + id,
      JSON.stringify(data),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  //Update selectedLangs by Ooganization
  update(data: any, id: number) {
    console.log('Idiomas actualizados');
    console.log(JSON.stringify(data));

    return this.http.post<any>(baseUrl + '/edit/' + id, JSON.stringify(data), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }
  //Update camposIdiomas of Organization
  updateCampos(data: any, id: number) {
    console.log('Campos actualizados');
    console.log(JSON.stringify(data));

    return this.http.post<any>(
      baseUrl + '/campos/update/' + id,
      JSON.stringify(data),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }
  //Create camposIdiomas of Organization
  insertCamposIdiomasOrg(data: any, id: number) {
    console.log('Campos a insertar');
    console.log(JSON.stringify(data));

    return this.http.post<any>(
      baseUrl + '/campos/create/' + id,
      JSON.stringify(data),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }
  //Get all Campos from selected Idiomas Organizacion
  public getCamposByIdOrg(id: Number): Observable<any> {
    return this.http.get(baseUrl + '/campos/' + id);
  }

  public updatePriority(lang:string,priority:number){
    return this.http.get(baseUrl + '/priority/' + lang + '/'+priority);
  }

  public getIdiomasFromEnte(id:string): Observable<any> {
    return this.http.get<any>(baseUrl + '/'+id);
  }

}
