import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Usuario } from '@app/_models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';

const baseUrl = `${environment.apiUrl}/recorridos`;

@Injectable()
export class RecorridosService {

    constructor(private http: HttpClient) { }

    public GetById(id: Number): Observable<any> {
        return this.http.get(baseUrl + "/" + id);
    }

    public GetAll(): Observable<any> {
        return this.http.get(baseUrl);
    }

    insert(data) {
      return this.http.post<any>(baseUrl + "/crear",
       { codigo: -1, nombre1: data.nombre1, nombre2: data.nombre2,
         descripcion1: data.descripcion1, descripcion2: data.descripcion2, foto: data.foto,
         link1: data.link1, link2: data.link2, gpxFileName: data.gpxFileName }, { withCredentials: true });
    }

  
    update(data) {
      //console.log(JSON.stringify(data));
      //console.log(idTipoPerdida_DAT);
      return this.http.post<any>(baseUrl + "/editar",
       { codigo: data.codigo, nombre1: data.nombre1, nombre2: data.nombre2,
         descripcion1: data.descripcion1, descripcion2: data.descripcion2,
         link1: data.link1, link2: data.link2, gpxFileName: data.gpxFileName }, { withCredentials: true });
    }
  
    updateImagen(data) {
      return this.http.post<any>(baseUrl + "/editarFoto", { codigo: data.codigo, foto: data.foto }, { withCredentials: true });
    }
     
    delete(data?: any): Observable<any> {
   
       //console.log(JSON.stringify(data));
       var formData: any = new FormData();
       formData.append("codigos", data.codigos);
       //console.log(JSON.stringify(formData));
   
       return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });
    }

    deleteImagen(data) {
      return this.http.post<any>(baseUrl + "/eliminarFoto", { codigo: data.codigo });
    }

    download(imagen): Observable<any> {
      return this.http.get(baseUrl + "/sitios/download/" + imagen);
    }

    upload(modo, data): Observable<any> {
      return this.http.post<any>(baseUrl + "/sitios/upload/" + modo, { gpxFilename: data.gpxFileName, gpx: data.gpx, foto: data.foto, fotoContent: data.fotoContent }, { withCredentials: true });
    }
}
