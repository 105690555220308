<div class="row">
  <div class="col-xl-6 col-md-6">
    <div class="card">
      <!--GESTION AGENTE-->
      <div class="card-header">
        <h3>{{ "global.recorridoscrear" | translate }}</h3>
      </div>
      <div class="card-body">
        <dropdown-idiomas-ente
          [idOrg]="idOrg"
          (selectedIdiomaOut)="getIdiomaFromReus($event)"
        ></dropdown-idiomas-ente>

        <div class="form-group">
          <!--Titulo-->
          <kendo-textbox
            placeholder="{{ 'global.nombrerecorrido' | translate }}"
            [clearButton]="true"
            [(ngModel)]="nombre"
          >
          </kendo-textbox>
        </div>

        <!--Cuerpo-->
        <div class="form-group">
          <kendo-label text="{{ 'global.descriprecorrido' | translate }}">
            <textarea
              kendoTextArea
              style="resize: none"
              rows="5"
              [(ngModel)]="descripcion"
            >
            </textarea>
          </kendo-label>
        </div>

        <!--DistanciaKm-->
        <div class="form-group">
          <kendo-label text="{{ 'global.distancia' | translate }}">
            <kendo-numerictextbox [(ngModel)]="distanciaKm" [min]="1">
            </kendo-numerictextbox>
          </kendo-label>
        </div>

        <div class="form-group">
          <!--Imagen Publicacion-->
          <label class="image-upload-container btn btn-bwm">
            <span>Select Image</span>
            <input
              #imageInput
              type="file"
              accept="image/*"
              (change)="imagePreview($event)"
            />
          </label>

          <div *ngIf="filePath && filePath !== ''">
            <img [src]="filePath" style="height: 200px" />
          </div>
        </div>

        <div class="form-group col">
          <div class="form-group col row">
            <kendo-label text="{{ 'recorridos.arcgpx' | translate }}">
            </kendo-label>
          </div>
          <div class="form-group col row">
            <div *ngIf="gpxFileName">
              <span
                ><i>{{ gpxFileName }}</i></span
              >
            </div>
          </div>
          <div class="form-group col row" style="padding-bottom: 7%">
            <kendo-fileselect
              [(ngModel)]="gpxFileName"
              class="form-control"
              [restrictions]="restrictionsGpx"
              [multiple]="false"
              (select)="selectEventHandlerGpx($event)"
            >
              <kendo-upload-messages
                cancel="{{ 'botones.cancelar' | translate }}"
                dropFilesHere="{{ 'recorridos.dropGpx' | translate }}"
                select="{{ 'recorridos.slGpx' | translate }}"
              >
              </kendo-upload-messages>
            </kendo-fileselect>
          </div>
        </div>

        <!-- Traducciones -->
        <div class="form-group">
          <button
            type="button"
            class="btn btn-primary btn-sm mr-1"
            (click)="btnAddTraduccion()"
          >
            {{ "eventos.anadir" | translate }}
          </button>

          <div class="listado-traducciones">
            <div class="traduccion" *ngFor="let idioma of listaTraducciones">
              {{ idioma.nombre }}

              <button
                type="button"
                class="btn btn-info btn-sm mr-1"
                (click)="btnDeleteTraduccion(idioma)"
              >
                X
              </button>
            </div>
          </div>
          <!--Boton Publicar-->
          <button
            type="button"
            class="btn btn-success btn-sm mr-1"
            (click)="btnCrearRecorrido()"
          >
            {{ "global.crear" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm mr-1"
            (click)="btnCancelar()"
          >
            {{ "botones.cancelar" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
