<ul class="list-group list-group-flush" *ngIf="router.url === '/profile'">
    <p>
        <strong>{{ 'usuarios.nombre' | translate}}:</strong> {{user.nombre}} {{user.apellido1}} {{user.apellido2}}<br />
        <strong>{{ 'usuarios.email' | translate}}: </strong> {{user.email}}
    </p>
    <div class="form-row">
        <div class="form-group col">
            <button kendoButton (click)="onClickEditar()"
                class="btn mr-1  btn-primary">{{ 'botones.actualizar' | translate}}</button>
        </div>
    </div>
</ul>
<router-outlet></router-outlet>
