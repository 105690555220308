<kendo-grid-messages filter="{{'filter' | translate}}"
filterAfterOperator="{{'filterAfterOperator' | translate}}"
filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
filterAndLogic="{{'filterAndLogic' | translate}}"
filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
filterBooleanAll="{{'filterBooleanAll' | translate}}"
filterClearButton="{{'filterClearButton' | translate}}"
filterContainsOperator="{{'filterContainsOperator' | translate}}"
filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
filterEqOperator="{{'filterEqOperator' | translate}}"
filterFilterButton="{{'filterFilterButton' | translate}}"
filterGtOperator="{{'filterGtOperator' | translate}}"
filterGteOperator="{{'filterGteOperator' | translate}}"
filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
filterIsFalse="{{'filterIsFalse' | translate}}"
filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
filterLtOperator="{{'filterLtOperator' | translate}}"
filterLteOperator="{{'filterLteOperator' | translate}}"
filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
filterOrLogic="{{'filterOrLogic' | translate}}"
filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}"
noRecords="{{'noRecords' | translate}}" unlock="{{'unlock' | translate}}">
</kendo-grid-messages>