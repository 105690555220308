<form [formGroup]="form">
    <div class="row">
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body">
                    <h3 *ngIf="isAddMode">{{ 'recorridos.crear' | translate}}</h3>
                    <h3 *ngIf="!isAddMode">{{ 'recorridos.editar' | translate}}</h3>
                </div>
                <div class="card-body">
                    <div class="form-group col">
                        <kendo-label text="{{ 'recorridos.nombre' | translate}}">
                            <kendo-textbox formControlName="nombre2"
                                placeholder="{{ 'recorridos.dato-eu' | translate }}"
                                [ngClass]="{ 'is-invalid': submitted && f.nombre2.errors }">
                            </kendo-textbox>
                            <div *ngIf="submitted && f.nombre2.errors" class="invalid-feedback">
                                <div *ngIf="f.nombre2.errors.required">{{ 'recorridos.nombre-eu-requerido' | translate}}
                                </div>
                            </div>
                        </kendo-label>
                    </div>
                    <div class="form-group col">
                        <kendo-textbox formControlName="nombre1" placeholder="{{ 'recorridos.dato-es' | translate }}"
                            [ngClass]="{ 'is-invalid': submitted && f.nombre1.errors }">
                        </kendo-textbox>
                        <div *ngIf="submitted && f.nombre1.errors" class="invalid-feedback">
                            <div *ngIf="f.nombre1.errors.required">{{ 'recorridos.nombrerequerido' | translate}}</div>
                        </div>
                    </div>
                    <div class="form-group col">
                        <kendo-label text="{{ 'recorridos.descripcion' | translate}}">
                            <textarea kendoTextArea style="resize: none;" rows="5" formControlName="descripcion2"
                                placeholder="{{ 'recorridos.dato-eu' | translate }}"
                                [ngClass]="{ 'is-invalid': submitted && f.descripcion2.errors }">
                            </textarea>
                            <div *ngIf="submitted && f.descripcion2.errors" class="invalid-feedback">
                                <div *ngIf="f.descripcion2.errors.required">{{ 'recorridos.descripcion-eu-requerido' |
                                    translate}}</div>
                            </div>
                        </kendo-label>
                    </div>
                    <div class="form-group col">
                        <textarea kendoTextArea style="resize: none;" rows="5" formControlName="descripcion1"
                            placeholder="{{ 'recorridos.dato-es' | translate }}"
                            [ngClass]="{ 'is-invalid': submitted && f.descripcion1.errors }">
                    </textarea>
                        <div *ngIf="submitted && f.descripcion1.errors" class="invalid-feedback">
                            <div *ngIf="f.descripcion1.errors.required">{{ 'recorridos.descripcionrequerido' |
                                translate}}
                            </div>
                        </div>
                    </div>
                    <div class="form-group col">
                        <kendo-label text="{{ 'recorridos.link' | translate}}">
                            <kendo-textbox formControlName="link2" placeholder="{{ 'recorridos.dato-eu' | translate }}"
                                [ngClass]="{ 'is-invalid': submitted && f.link2.errors }">
                            </kendo-textbox>
                            <div *ngIf="submitted && f.link2.errors" class="invalid-feedback">
                                <div *ngIf="f.link2.errors.pattern">{{ 'recorridos.formatourl' | translate}}</div>
                            </div>
                        </kendo-label>
                    </div>
                    <div class="form-group col">
                        <kendo-textbox formControlName="link1" placeholder="{{ 'recorridos.dato-es' | translate }}"
                            [ngClass]="{ 'is-invalid': submitted && f.link1.errors }">
                        </kendo-textbox>
                        <div *ngIf="submitted && f.link1.errors" class="invalid-feedback">
                            <div *ngIf="f.link1.errors.pattern">{{ 'recorridos.formatourl' | translate}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <!-- CARTA ADJUNTOS -->
            <div class="card">
                <div class="card-body">
                    <h3>{{ 'recorridos.adjuntos' | translate}}</h3>

                    <div class="form-group col">
                        <div class="form-group col row" style="padding-top: 5%;">
                            <kendo-label text="{{ 'recorridos.imagen' | translate}}">
                            </kendo-label>
                        </div>
                        <div class="form-group col row" style="padding-bottom: 7%;">
                            <kendo-fileselect formControlName="foto" class="form-control"
                                [restrictions]="restrictionsFoto" [multiple]="false"
                                (select)="selectEventHandlerFoto($event)" (remove)="removeEventHandlerFoto($event)">
                                <kendo-upload-messages cancel="{{ 'botones.cancelar' | translate }}"
                                    dropFilesHere="{{ 'recorridos.dropFoto' | translate }}"
                                    invalidFileExtension="{{ 'recorridos.extFotoIn' | translate }}"
                                    invalidMaxFileSize="{{ 'recorridos.invalidMax' | translate }}"
                                    remove="{{ 'recorridos.remFoto' | translate }}"
                                    select="{{ 'recorridos.slFoto' | translate }}">
                                </kendo-upload-messages>
                            </kendo-fileselect>
                        </div>
                        <ng-template #notImEnl>
                            <span><i>{{ 'enlaces.noimagen' | translate }}</i></span>
                        </ng-template>
                        <div class="form-group col row">
                            <div *ngIf="imagePreview; else notImEnl">
                                <img [src]="imagePreview" style="max-height: 113px" />
                            </div>
                        </div>
                        <div *ngIf="!isAddMode && !imagenBorrada && imagePreview" class="form-group col row">
                            <button kendoButton (click)="onClickEliminarImagen(content)" class="btn-sm btn-danger mr-1"
                                [disabled]="isNotIns">{{ 'botones.eliminar' | translate}}</button>
                        </div>
                    </div>
                    <div class="form-group col">
                        <div class="form-group col row">
                            <kendo-label text="{{ 'recorridos.arcgpx' | translate}}">
                            </kendo-label>
                        </div>
                        <div class="form-group col row">
                            <div *ngIf="gpxFileName">
                                <span><i>{{ gpxFileName }}</i></span>
                            </div>
                        </div>
                        <div *ngIf="!isAddMode && !gpxBorrado && gpxFileName != ''" class="form-group col row">
                            <button kendoButton (click)="onClickEliminarGpx(content)" class="btn-sm btn-danger mr-1"
                                [disabled]="isNotInsGpx">{{ 'botones.eliminar' | translate}}</button>
                        </div>
                        <div class="form-group col row" style="padding-bottom: 7%;">
                            <kendo-fileselect formControlName="gpxFileName" class="form-control"
                                [restrictions]="restrictionsGpx" [multiple]="false"
                                (select)="selectEventHandlerGpx($event)" (remove)="removeEventHandlerGpx($event)">
                                <kendo-upload-messages cancel="{{ 'botones.cancelar' | translate }}"
                                    dropFilesHere="{{ 'recorridos.dropGpx' | translate }}"
                                    invalidFileExtension="{{ 'recorridos.extGpxIn' | translate }}"
                                    invalidMaxFileSize="{{ 'recorridos.invalidMax-gp' | translate }}"
                                    remove="{{ 'recorridos.remGpx' | translate }}"
                                    select="{{ 'recorridos.slGpx' | translate }}">
                                </kendo-upload-messages>
                            </kendo-fileselect>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group" style="padding-top: 2%;">
        <button kendoButton [disabled]="loading" (click)="onSubmit()" class="btn mr-1  btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            {{ 'botones.guardar' | translate}}
        </button>
        <a routerLink="/recorridos" class="btn mr-1  btn-danger">{{ 'botones.cancelar' | translate}}</a>
    </div>
</form>


<!--POPUP ELIMINAR FOTO -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div *ngIf="!modalImGpx; else gpxOp">
            <p>{{ 'recorridos.preguntaeliminarimagen' | translate }}</p>
        </div>
        <ng-template #gpxOp>
            <p>{{ 'recorridos.preguntaeliminargpx' | translate }}</p>
        </ng-template>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'botones.no'
            | translate }}</button>
        <div *ngIf="!modalImGpx; else gpxOp2">
            <button type="button" class="btn btn-danger" (click)="eliminarImagen();">{{ 'botones.si' | translate
                }}</button>
        </div>
        <ng-template #gpxOp2>
            <button type="button" class="btn btn-danger" (click)="eliminarGpx();">{{ 'botones.si' | translate
                }}</button>
        </ng-template>
    </div>
</ng-template>