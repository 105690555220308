<div class="row">
  <div class="col-xl-4 col-md-6">
    <div class="card">
      <!--GESTION AGENTE-->
      <div class="card-header">
        <h3>{{ "publicaciones.titulo" | translate }}</h3>
      </div>
      <div class="card-body">
        <!--Lista Entes-->
        <div class="form-group">
          <kendo-dropdownlist #dropdown
                              [data]="listaEntesAdministrados"
                              textField="nombre"
                              valueField="id"
                              [(ngModel)]="selectedOrg"
                              (valueChange)="loadIdiomas($event, dropdown)">
          </kendo-dropdownlist>
        </div>

        <div class="form-group">
          <!--Lista idiomas-->

          <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
          </kendo-label>

          <div class="caja">
            <kendo-dropdownlist #dropdownI
                                [data]="listaIdiomas"
                                textField="nombre"
                                valueField="codigo"
                                [(ngModel)]="selectedIdioma"
                                (valueChange)="loadCategorias($event, dropdownI)"></kendo-dropdownlist>

          </div>
        </div>

        <div class="form-group">
          <label>Título</label>
          <div class="caja">
            <!--Titulo-->
            <kendo-textbox placeholder="{{ 'publicaciones.titulo_publi' | translate }}"
                           [clearButton]="true"
                           [(ngModel)]="titulo">
              <ng-template kendoTextBoxPrefixTemplate>
              </ng-template>
            </kendo-textbox>
          </div>
        </div>

        <!--Cuerpo-->
        <div class="form-group">
          <!-- <kendo-label text="{{ 'publicaciones.cuerpo_publi' | translate }}">
            <textarea kendoTextArea style="resize: none" rows="5" [(ngModel)]="cuerpo"> </textarea>
          </kendo-label> -->
          <kendo-editor [(ngModel)]="cuerpo"></kendo-editor>
        </div>

        <div class="form-group">
          <label>Categoría</label>
          <!--Lista Categorias-->
          <div class="caja">
            <kendo-dropdownlist [data]="listaCategorias"
                                textField="nombre"
                                valueField="id"
                                [(ngModel)]="selectedCategoria"></kendo-dropdownlist>
          </div>

        </div>

        <!--Imagen Publicacion-->
        <label class="image-upload-container btn btn-bwm">
          <span>Select Image</span>
          <input #imageInput
                 type="file"
                 accept="image/*"
                 (change)="imagePreview($event)">
        </label>

        <div *ngIf="filePath && filePath !== ''">
          <img [src]="filePath" style="height: 200px;">
        </div>

        <!-- Video -->
        <div class="form-group">
          <kendo-label text="{{ 'publicaciones.isvideo' | translate }}">
          </kendo-label>
          <br>
          <kendo-switch [(ngModel)]="hasVideo"
                        [ngModelOptions]="{ standalone: true }">
          </kendo-switch>

          <div *ngIf="hasVideo">
            <kendo-label text="{{ 'publicaciones.isyoutube' | translate }}">
            </kendo-label>
            <br>
            <kendo-switch [(ngModel)]="isYoutubeVideo"
            [ngModelOptions]="{ standalone: true }">
            </kendo-switch>
            <kendo-textbox *ngIf="!isYoutubeVideo;else youtube" placeholder="{{ 'publicaciones.urlvideo' | translate }}"
                           [clearButton]="true"
                           [(ngModel)]="urlVideo">
            </kendo-textbox>
            <ng-template #youtube>
              <kendo-textbox *ngIf="isYoutubeVideo;else youtube" placeholder="{{ 'publicaciones.yturlvideo' | translate }}"
              [clearButton]="true"
              [(ngModel)]="urlVideo">
</kendo-textbox>
            </ng-template>
          </div>

        </div>


        <!-- Publicacion Programada-->
        <div class="form-group">

          <kendo-label class="k-checkbox-label"
                       text="{{ 'publicaciones.programada' | translate }}"></kendo-label>
          <div class="caja">
            <input type="checkbox"
                   [(ngModel)]="isProgramada"
                   kendoCheckBox />

            <kendo-datetimepicker *ngIf="isProgramada" [format]="format" [(value)]="fecha">

            </kendo-datetimepicker>
          </div>

        </div>
        <!--CheckBox Miembros-->
        <div class="form-group">
          <kendo-label class="k-checkbox-label"
                       text="{{ 'publicaciones.miembros' | translate }}"></kendo-label>
          <div class="caja">
                   <kendo-switch
                   [(ngModel)]="isMembersOnly"
                   onLabel="{{ 'switch.on' | translate }}"
                   offLabel="{{ 'switch.off' | translate }}"
                 ></kendo-switch>
          </div>
        </div>



        <!-- Traducciones -->
        <div class="form-group">
          <button type="button"
                  class="btn btn-primary btn-sm mr-1"
                  (click)="btnAddTraduccion()">
            {{ "publicaciones.anadir" | translate }}
          </button>
          <div class="listado-traducciones">
            <div class="traduccion" *ngFor="let idioma of listaTraducciones">
              {{idioma.nombre}}

              <button type="button"
                      class="btn btn-info btn-sm mr-1"
                      (click)="btnDeleteTraduccion(idioma)">
                X
              </button>

            </div>
          </div>

          <!--Boton Publicar-->
          <button type="button"
                  class="btn btn-success btn-sm mr-1"
                  (click)="btnCrearPublicacion()">
            {{ "publicaciones.crear" | translate }}
          </button>
          <button type="button"
          class="btn btn-danger btn-sm mr-1"
          (click)="btnCancelar()">
          {{ "botones.cancelar" | translate }}
        </button>
        </div>

      </div>
    </div>
  </div>
</div>
