<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="card w-50">
      <div class="card-body">
        <h3 *ngIf="isAddMode">{{ 'contactos.crear' | translate}}</h3>
        <h3 *ngIf="!isAddMode">{{ 'contactos.editar' | translate}}</h3>
      </div>
      <div class="card-body">
        <div class="form-group col">
          <kendo-label text="{{ 'contactos.nombre' | translate}}">
            <kendo-textbox formControlName="nombre2" placeholder="{{ 'contactos.nombre-eu' | translate }}" [ngClass]="{ 'is-invalid': submitted && f.nombre2.errors }">
            </kendo-textbox>
            <div *ngIf="submitted && f.nombre2.errors" class="invalid-feedback">
                <div *ngIf="f.nombre2.errors.required">{{ 'contactos.nombre-eu-requerido' | translate}}</div>
            </div>
          </kendo-label>
        </div>
        <div class="form-group col">
            <kendo-textbox formControlName="nombre1" placeholder="{{ 'contactos.nombre-es' | translate }}" [ngClass]="{ 'is-invalid': submitted && f.nombre1.errors }">
            </kendo-textbox>
            <div *ngIf="submitted && f.nombre1.errors" class="invalid-feedback">
              <div *ngIf="f.nombre1.errors.required">{{ 'contactos.nombrerequerido' | translate}}</div>
            </div>
        </div>
        <div class="form-group col-6">
          <kendo-label text="{{ 'contactos.email' | translate}}">
            <kendo-textbox formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
            </kendo-textbox>
          </kendo-label>
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors">{{ 'contactos.emailrequerido' | translate}}</div>
        </div>
        </div>
        <div class="form-group col-6">
            <kendo-label text="{{ 'contactos.telefono' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.telefono.errors }">
                <kendo-textbox formControlName="telefono" >
                </kendo-textbox>
            </kendo-label>
            <div *ngIf="submitted && f.telefono.errors" class="invalid-feedback">
                <div *ngIf="f.telefono.errors.required">{{ 'contactos.telefonorequerido' | translate}}</div>
                <div *ngIf="f.telefono.errors.pattern">{{ 'contactos.formatotlf' | translate}}</div>                
            </div>
        </div>
        </div>
      </div>
      <div class="form-group">
        <button kendoButton [disabled]="loading" class="btn mr-1  btn-primary">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ 'botones.guardar' | translate}}
        </button>
        <a routerLink="/contactos" class="btn mr-1  btn-danger">{{ 'botones.cancelar' | translate}}</a>
        </div>
  </form>