import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Categoria } from '@app/_models/categoria';
import { Ente } from '@app/_models/ente';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { AlertService, MenuService } from '@app/_services';
import { IdiomasService } from '@app/_services/idiomas.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { FormPublicacionModel } from '@app/_models/formPublicacionModel';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
})
export class VideosComponent implements OnInit {

  titulo: string = '';
  urlVideo: string = '';
  imagenB64: string = '';
  lang: any;
  filePath: string;
  selectedIdioma: Idioma;
  private translate: TranslateService;

  myForm: FormPublicacionModel = new FormPublicacionModel();

 //Listas
 listaEntesAdministrados: Ente[] = [];
 listaIdiomas: Idioma[] = [];
 listaIdiomasTotales: Idioma[] = [];
 listaTraducciones: Idioma[] = [];
 listaCategorias: Categoria[] = [];

 //Traducciones
 listaTraduccionesEnviar: NoticiaTraduccion[] = [];
  isMembersOnly: boolean;
  isProgramada: boolean;
  fecha: string | number | Date;    

  constructor(
    translate: TranslateService,
    private idiomaService: IdiomasService,
    private translateService: TranslateService,
    private menuService: MenuService,
    private publicacionesService: PublicacionesService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService
    ) { 
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('publicaciones.cabecera');
    this.publicacionesService.getEntesAdministrados().subscribe((result) => {
      this.listaEntesAdministrados = result.data;

      this.loadIdiomas();
    });
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();

    //Load Langs
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomasTotales = result.data;
      console.log('Cargados idiomas = ' + this.listaIdiomasTotales.length);
      this.listaIdiomasTotales.forEach((x) => {
        const trad = new NoticiaTraduccion();
        trad.filled = false;
        trad.idIdioma = x.codigo;
        trad.nombreIdioma = x.nombre;
        this.listaTraduccionesEnviar.push(trad);
      });
    });
  }

  loadIdiomas() {
    //dropDownControl.writeValue("old value");
    console.log('Cargando todos los idiomas');

    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomas = result.data;
      if (this.listaIdiomas, length > 0) {
        this.selectedIdioma = this.listaIdiomas[0];
      }
    });
  }
  
  btnCrearVideo(){
    console.log('Publicando Video');
    //Validación de los datos
    if (this.listaTraducciones.length <= 0) {
      this.alertService.info(
        this.translateService.instant('publicaciones.errortrads')
      );
      return;
    }
    if (this.titulo == null || this.filePath == null) {
      this.alertService.info(
        this.translateService.instant('publicaciones.validaciondatos')
      );
      return;
    }

    //AutoCompletado de Idiomas
    //seleccionado
    let seleccionado: NoticiaTraduccion = new NoticiaTraduccion();
    let encontrado: boolean = false;
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (trad.filled && !encontrado) {
        seleccionado = trad;
        encontrado = true;
        return;
      }
    });
    //Fill UnFilled
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (!trad.filled) {
        trad.titulo = seleccionado.titulo;
      }
    });

    console.log(this.listaTraduccionesEnviar);
    
    this.myForm.myNoticia = new Noticia();
    this.myForm.myNoticia.urlVideo= this.urlVideo;
    this.myForm.myNoticia.fotoUrl = this.filePath.split(',')[1]; //Borramos cabecera data: base64
    this.myForm.listaTraducciones = [];
    this.myForm.listaTraducciones = this.listaTraduccionesEnviar;

    this.publicacionesService
      .createPublicacionVideo(this.myForm)
      .subscribe((result) => {
        if (!result.error) {
          this.alertService.success(
            this.translateService.instant('publicaciones.success')
          );
        } else {
          this.alertService.error(
            this.translateService.instant('publicaciones.error')
          );
          return;
        }
      });
  }

  btnAddTraduccion() {
    console.log('Comprobando datos');
    if (
      this.selectedIdioma == null ||
      this.titulo.length <= 0 ||
      this.urlVideo.length <= 0
    ) {
      if (this.listaTraducciones.length <= 0) {
        this.alertService.info(
          this.translateService.instant('publicaciones.validacion')
        );
        return;
      }
    }
    console.log('Datos validados --->');
    if (!this.listaTraducciones.includes(this.selectedIdioma)) {
      this.listaTraducciones.push(this.selectedIdioma);

      this.listaTraduccionesEnviar.map((x) => {
        if (x.idIdioma == this.selectedIdioma.codigo) {
          x.filled = true;
          x.titulo = this.titulo;
          x.cuerpo = this.urlVideo;
        }
      });
    } else {
      console.log('Idioma ya añadido');
    }

    console.log(this.listaTraduccionesEnviar);
  }

  btnDeleteTraduccion(idioma: Idioma) {
    console.log(idioma);
    this.listaTraducciones = this.listaTraducciones.filter(
      (x) => x.codigo != idioma.codigo
    );

    this.listaTraduccionesEnviar.map((x) =>
      x.idIdioma == idioma.codigo ? (x.filled = false) : console.log()
    );

    console.log(this.listaTraduccionesEnviar);
  }

  //Imagen
  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  btnCancelar(){
    this.router.navigate(['publicaciones-lista']);
  }

}
