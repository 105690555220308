<form [formGroup]="form">
    <div class="card w-50">
      <div class="card-body">
        <h3 *ngIf="isAddMode">{{ 'transportes.crear' | translate}}</h3>
        <h3 *ngIf="!isAddMode">{{ 'transportes.editar' | translate}}</h3>
      </div>
      <div class="card-body">
        <div class="form-group col">
          <kendo-label text="{{ 'transportes.nombre' | translate}}">
            <kendo-textbox formControlName="nombre" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
            </kendo-textbox>
            <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                <div *ngIf="f.nombre.errors.required">{{ 'transportes.nombrerequerido' | translate}}</div>
            </div>
          </kendo-label>
        </div>
        <div class="form-group col">
            <kendo-label text="{{ 'transportes.link' | translate}}">
            <kendo-textbox formControlName="link" [ngClass]="{ 'is-invalid': submitted && f.link.errors }">
            </kendo-textbox>
            <div *ngIf="submitted && f.link.errors" class="invalid-feedback">
              <div *ngIf="f.link.errors.required">{{ 'transportes.linkrequerido' | translate}}</div>
              <div *ngIf="f.link.errors.pattern">{{ 'transportes.formatourl' | translate}}</div>
            </div>
            </kendo-label>
        </div>
        <div class="form-group col">
            <div class="form-group col row">
                <kendo-label text="{{ 'transportes.imagen' | translate}}">
                </kendo-label> 
            </div>
            <div class="form-group col row">
              <div *ngIf="imagePreview; else notImTrans">
                <img [src]="imagePreview" style="max-height: 113px"/>
              </div>
              <ng-template #notImTrans>
                <span><i>{{ 'transportes.noimagen' | translate }}</i></span>
              </ng-template>
            </div>
            <div *ngIf="!isAddMode && !fotoBorrada && imagePreview && fotoAnt != []" class="form-group col row">
              <button kendoButton (click)="onClickEliminarFoto(content)" class="btn-sm btn-danger mr-1" [disabled]="isNotIns">{{ 'botones.eliminar' | translate}}</button>
            </div>
            <div class="form-group col row" style="padding-bottom: 7%;">
                <kendo-fileselect formControlName="foto" class="form-control" [restrictions]="restrictions" [multiple]="false" (select)="selectEventHandler($event)" (remove)="removeEventHandler($event)">
                  <kendo-upload-messages cancel="{{ 'botones.cancelar' | translate }}"
                  dropFilesHere="{{ 'transportes.dropFoto' | translate }}"
                  invalidFileExtension="{{ 'transportes.extFotoIn' | translate }}"
                  invalidMaxFileSize="{{ 'transportes.invalidMax' | translate }}"
                  remove="{{ 'transportes.remFoto' | translate }}"
                  select="{{ 'transportes.slFoto' | translate }}">
                  </kendo-upload-messages>
                </kendo-fileselect>
            </div>
        </div>
        </div>
    </div>
    <div class="form-group">
      <button kendoButton [disabled]="loading" (click)="onSubmit()" class="btn mr-1  btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{ 'botones.guardar' | translate}}
      </button>
      <a routerLink="/transportes" class="btn mr-1  btn-danger">{{ 'botones.cancelar' | translate}}</a>
    </div>
  </form>


<!--POPUP ELIMINAR FOTO -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'transportes.preguntaeliminarfoto' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'botones.no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="eliminarFoto();">{{ 'botones.si' | translate }}</button>
  </div>
</ng-template>