import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, DepartamentosService, MenuService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { removeSummaryDuplicates } from '@angular/compiler';

@Component({
  selector: 'app-departamentos-detalle',
  templateUrl: './departamentos-detalle.component.html'
})
export class DepartamentosDetalleComponent implements OnInit {

  form: FormGroup;
  codigo: number;
  isAddMode: boolean;
  activoUsuarios = false;
  public events: string[] = [];
  loading = false;
  submitted = false;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  // Apartado Emails departamento
  public dataDepartamentoEmails: [];
  public EmailsSeleccionados: number[] = [];

  // Apartado ListBox
  public dataUsuariosNoDpt: any[] = [];
  public dataUsuariosDpt: any[] = [];
  public seleccionadosND: number[] = [];
  public seleccionadosD: number[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private departamentosService: DepartamentosService,
    private alertService: AlertService,
    private menuService: MenuService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('departamentos.departamentos');

    this.codigo = this.route.snapshot.params['id'];

    this.isAddMode = !this.codigo;

    this.form = this.formBuilder.group({
      codigo: this.route.snapshot.params['id'],
      nombredep1: new FormControl('', [Validators.required]),
      nombredep2: new FormControl('', [Validators.required]),
      activo: new FormControl(false),
      departamento_cods: new FormControl(''),
      nodepartamento_cods: new FormControl('')
    });

    if (!this.isAddMode) {
      this.departamentosService.GetUsersDpto(this.codigo)
        .subscribe((result) => {
          this.dataUsuariosDpt = result.data;
          this.dataUsuariosDpt.sort((a: any, b: any) => (a.nombre > b.nombre ? 1 : -1));
          //console.log(this.dataUsuariosDpt);
        });


      this.departamentosService.GetUsersNoDpto(this.codigo)
        .subscribe((result) => {
          this.dataUsuariosNoDpt = result.data;
          this.dataUsuariosNoDpt.sort((a: any, b: any) => (a.nombre > b.nombre ? 1 : -1));
          //console.log(this.dataUsuariosNoDpt);
        });

      this.departamentosService.GetById(this.codigo)
        .pipe()
        .subscribe((result) => {
          this.form = this.formBuilder.group({
            codigo: this.route.snapshot.params['id'],
            nombredep1: new FormControl(result.data[0].nombredep1, [Validators.required]),
            nombredep2: new FormControl(result.data[0].nombredep2, [Validators.required]),
            activo: new FormControl(result.data[0].activo),
            departamento_cods: new FormControl(''),
            nodepartamento_cods: new FormControl('')
          });

        });

      this.departamentosService.GetEmailsById(this.codigo)
        .subscribe((result) => {
          this.dataDepartamentoEmails = result.data;
        });
    } else {
      this.departamentosService.GetMaxDpto()
        .subscribe((result) => {
          this.codigo = result.maxdpto + 1;
          this.departamentosService.GetUsersNoDpto(this.codigo)
            .subscribe((result) => {
              this.dataUsuariosNoDpt = result.data;
              this.dataUsuariosNoDpt.sort((a: any, b: any) => (a.nombre > b.nombre ? 1 : -1));
            });
        });
    }

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      // TODO This as a workaround.
      this.menuService.titulo = this.translateService.instant('departamentos.departamentos');
    });
  }

  onChangeSwitch(value) {
    let i: number = 0;
    let removed: boolean = false;
    if (this.activoUsuarios) {
      this.departamentosService.GetUsersNoDptoT(this.codigo)
        .subscribe((result) => {
          this.dataUsuariosNoDpt = result.data;
          while (i < this.dataUsuariosNoDpt.length) {
            for (let j = 0; j < this.dataUsuariosDpt.length; j++) {
              if (this.dataUsuariosNoDpt[i] && this.dataUsuariosDpt[j]) {
                if (this.dataUsuariosNoDpt[i].id == this.dataUsuariosDpt[j].id) {
                  this.dataUsuariosNoDpt.splice(i, 1);
                  removed = true;
                }
              }
            }

            if (removed) {
              removed = false;
            } else {
              i++;
            }
          }

          this.dataUsuariosNoDpt.sort((a: any, b: any) => (a.nombre > b.nombre ? 1 : -1));
        });
    } else {
      this.departamentosService.GetUsersNoDpto(this.codigo)
        .subscribe((result) => {
          this.dataUsuariosNoDpt = result.data;
          while (i < this.dataUsuariosNoDpt.length) {
            for (let j = 0; j < this.dataUsuariosDpt.length; j++) {
              if (this.dataUsuariosNoDpt[i] && this.dataUsuariosDpt[j]) {
                if (this.dataUsuariosNoDpt[i].id == this.dataUsuariosDpt[j].id) {
                  this.dataUsuariosNoDpt.splice(i, 1);
                  removed = true;
                }
              }
            }

            if (removed) {
              removed = false;
            } else {
              i++;
            }
          }

          this.dataUsuariosNoDpt.sort((a: any, b: any) => (a.nombre > b.nombre ? 1 : -1));
        });
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.crearDepartamento();
    } else {
      this.updateDepartamento();
    }
  }

  private crearDepartamento() {
    this.form.value.departamento_cods = this.dataUsuariosDpt;
    this.form.value.nodepartamento_cods = this.dataUsuariosNoDpt;

    this.departamentosService.insert(this.form.value)
      .subscribe((result) => {
        if (!result.error) {
          this.departamentosService.GetMaxDpto().subscribe((result) => {
            this.codigo = result.maxdpto;
            this.alertService.success(this.translateService.instant('botones.creadocorrectamente'), { keepAfterRouteChange: true });
            this.router.navigate(['departamentos/editar', this.codigo]);
          });
        } else {
          this.alertService.error(this.translateService.instant('departamentos.errorcrear'));
          this.loading = false;
        }
      });
  }

  private updateDepartamento() {
    this.form.value.departamento_cods = this.dataUsuariosDpt;
    this.form.value.nodepartamento_cods = this.dataUsuariosNoDpt;


    this.departamentosService.update(this.form.value)
      .pipe(first())
      .subscribe((result) => {
        if (!result.error) {
          this.alertService.success(this.translateService.instant('botones.editadocorrectamente'), { keepAfterRouteChange: true });
          this.router.navigate(['../../'], { relativeTo: this.route });
        } else {
          this.alertService.error(this.translateService.instant('departamentos.erroreditar'));
          this.loading = false;
        }
      });
  }

  // ListBox
  onClickRight() {
    for (let i = 0; i < this.seleccionadosND.length; i++) {
      var indexUser = this.dataUsuariosNoDpt.findIndex((usr: any) => usr.id === this.seleccionadosND[i]);
      this.dataUsuariosDpt.push(this.dataUsuariosNoDpt[indexUser]);
      this.dataUsuariosNoDpt.splice(indexUser, 1);
    }
    this.dataUsuariosDpt.sort((a: any, b: any) => (a.nombre > b.nombre ? 1 : -1));
    this.seleccionadosND = [];
  }

  onClickDoubleRight() {
    this.dataUsuariosDpt = this.dataUsuariosDpt.concat(this.dataUsuariosNoDpt);
    this.dataUsuariosDpt.sort((a: any, b: any) => (a.nombre > b.nombre ? 1 : -1));
    this.dataUsuariosNoDpt = [];
    this.seleccionadosD = [];
    this.seleccionadosND = [];
  }

  onClickLeft() {
    for (let i = 0; i < this.seleccionadosD.length; i++) {
      var indexUser = this.dataUsuariosDpt.findIndex((usr: any) => usr.id === this.seleccionadosD[i]);
      this.dataUsuariosNoDpt.push(this.dataUsuariosDpt[indexUser]);
      this.dataUsuariosDpt.splice(indexUser, 1);
    }
    this.dataUsuariosNoDpt.sort((a: any, b: any) => (a.nombre > b.nombre ? 1 : -1));
    this.seleccionadosD = [];
  }

  onClickDoubleLeft() {
    this.dataUsuariosNoDpt = this.dataUsuariosNoDpt.concat(this.dataUsuariosDpt);
    this.dataUsuariosNoDpt.sort((a: any, b: any) => (a.nombre > b.nombre ? 1 : -1));
    this.dataUsuariosDpt = [];
    this.seleccionadosD = [];
    this.seleccionadosND = [];
  }

    // Parte emails
    onClickNuevo() {
      this.router.navigate(['departamentos/' + this.codigo + '/email/crear']);
      console.log(['departamentos/' + this.codigo, '/email/crear'].toString());
    }
  
    onClickEditar() {
      if (this.EmailsSeleccionados[0] > 0) {
        this.router.navigate(['departamentos/' + this.codigo + '/email/editar/', this.EmailsSeleccionados[0]]);
      }
    }
  
    cellClick(e) {
      if (e.columnIndex > 0) {
        this.router.navigate(['departamentos/' + this.codigo + '/email/editar/', this.EmailsSeleccionados[0]]);
      }
    }
  
    onClickEliminar(content) {
      if (this.EmailsSeleccionados.length > 0) {
        this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
      }
    }
  
    private eliminarRegistro(contentloading) {
      this.departamentosService.DeleteEmailsDpt({ codigos: this.EmailsSeleccionados }).subscribe(
        (data) => {
          if (data.error == false) {
            this.departamentosService.GetEmailsById(this.codigo).subscribe((result) => {
              this.dataDepartamentoEmails = result.data;
            }
            );
          }
          this.modalReferenceloading.close();
        }
      );
      this.modalReference.close();
      this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
      this.EmailsSeleccionados = [];
    }


}