// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.



export const environment = {
  production: true,
  //apiUrl: 'https://localhost:44330' //HUTSA
  //apiUrl: 'https://localhost:44331' //ERIS
  //apiUrl: 'https://localhost:44332' //RESERVAONLINE
  //apiUrl: 'https://localhost:7200' //APIBASE

  //apiUrl: 'https://localhost:44333' //AIORA DESARROLLO LOCALHOST
  //apiUrl: 'https://localhost:7108' //AIORA DESARROLLO LOCALHOST API2022
  //apiUrl: 'https://aioroapi.zitu.net' //AIORA DESARROLLO
  //apiUrl: 'https://aioroapi2.zitu.net' //PINTER
  apiUrl: 'https://sukalberriapi.zitu.net' //SUKALBERRI
  //apiUrl: 'https://bagabigaapi.zitu.net' //UNICAJA
  //apiUrl: 'https://aioroapi2.zitu.net' //AIORA DESARROLLO2
  //apiUrl: 'https://aioraeskubaloiaapi.zitu.net' //AIORA ESKUBALOIA
  //apiUrl: 'https://aioraudalakapi.zitu.net', //AIORA UDALAK
  //apiUrl: 'https://aioroapi3.zitu.net' //AIORA 3
  //apiUrl: 'https://ostalaritzaapi.zitu.net'
  //apiUrl:'https://aioroapi3.zitu.net'
  //apiUrl:'https://basqueteamapi.zitu.net'
  //apiUrl:'https://sagardotegiapi.zitu.net'

  //apiUrl: 'https://localhost:44334' //TTTGOIKO
  //apiUrl: 'http://localhost:7200' //HUTSA
  //apiUrl: 'http://localhost:7201' //ERIS
  //apiUrl: 'http://localhost:7202' //RESERVAONLINE
  //apiUrl: 'http://localhost:7203' //AIORA
  //apiUrl: 'http://localhost:7204' //TTTGOIKO
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
