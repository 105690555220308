
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="card w-50">
    <div class="card-body">
      <h3 *ngIf="isAddMode">{{ 'usuarios.crearusuario' | translate}}</h3>
      <h3 *ngIf="!isAddMode">{{ 'usuarios.editarusuario' | translate}}</h3>
      <div class="plegarpanel"></div>
    </div>
    <div class="card-body">

      <div class="form-group">
        <kendo-label text="{{ 'usuarios.nombre' | translate}}">
          <kendo-textbox formControlName="nombre" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
          </kendo-textbox>
          <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
            <div *ngIf="f.nombre.errors.required">{{ 'usuarios.nombrerequerido' | translate}}</div>
          </div>
        </kendo-label>
      </div>
      <div class="form-group">
        <kendo-label text="{{ 'permisos.gestionusuarios' | translate}}">
          <kendo-numerictextbox class="form-control" formControlName="gestionUsuarios" [min]="0" [max]="2" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
        </kendo-label>
        </div>


      <div class="form-group">
        <button kendoButton [disabled]="loading" class="btn mr-1  btn-primary">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ 'botones.guardar' | translate}}
        </button>
        <a routerLink="/usuarios-tipos" class="btn mr-1  btn-danger">{{ 'botones.cancelar' | translate}}</a>
        </div>
      </div>
    </div>
</form>
