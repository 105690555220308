import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Mesa } from '@app/_models/restaurante';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'sort-listamesas',
  templateUrl: './sort-listamesas.component.html',
})
export class SortListamesasComponent implements OnInit {

//Uso
//<sort-listamesas [listaMesas]="listaMesasRestaurante" (listaMesasSorted)="getMesasSorted($event)"></sort-listamesas>

  @Input() listaMesas: Mesa[] = [];
  @Output() listaMesasSorted = new EventEmitter<Mesa[]>();
  
  constructor() { }

  ngOnInit(): void {
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.listaMesas, event.previousIndex, event.currentIndex);
    this.listaMesasSorted.emit(this.listaMesas);
  }

}
