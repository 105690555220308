<div class="row">
  <div class="col-xl-8 col-md-6">
    <div class="card">
      <!--GESTION AGENTE-->
      <div class="card-header">
        <h3>{{ "ecommerce.lbl_edicionarticulo" | translate }}</h3>
      </div>
      <div class="card-body">
        <div class="form-group">
          <!--Lista idiomas-->

          <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
          </kendo-label>

          <div class="caja">
            <kendo-dropdownlist
              #dropdownI
              [data]="listaIdiomas"
              textField="nombre"
              valueField="codigo"
              [(ngModel)]="selectedIdioma"
              (valueChange)="loadArticulo($event, dropdownI)"
            ></kendo-dropdownlist>
          </div>
        </div>

        <div class="form-group">
          <label>{{ "ecommerce.nombrecategoria" | translate }}</label>
          <div class="caja">
            <!--Titulo-->
            <kendo-textbox
              placeholder="{{ 'ecommerce.ph_nombre' | translate }}"
              [clearButton]="true"
              [(ngModel)]="nombreRecinto"
            >
              <ng-template kendoTextBoxPrefixTemplate> </ng-template>
            </kendo-textbox>
          </div>
        </div>

        <!--Cuerpo-->
        <div class="form-group">
          <kendo-label
            text="{{ 'ecommerce.lbl_descripcioncategoria' | translate }}"
          >
            <textarea
              kendoTextArea
              style="resize: none"
              rows="5"
              [(ngModel)]="descripcionRecinto"
              placeholder="{{
                'ecommerce.ph_descripcioncategoria' | translate
              }}"
            >
            </textarea>
          </kendo-label>
        </div>

        <!--Precio-->
        <div class="form-group">
          <kendo-label text="{{ 'ecommerce.lbl_precio' | translate }}">
            <kendo-numerictextbox
              format="n3"
              [min]="0"
              [step]="0.1"
              [(ngModel)]="precio"
            ></kendo-numerictextbox>
          </kendo-label>
        </div>
        <!--Stock -->
        <div class="form-group">
          <kendo-label text="{{ 'ecommerce.lbl_stock' | translate }}">
            <kendo-numerictextbox
              format="n0"
              [min]="0"
              [step]="1"
              [(ngModel)]="stock"
            ></kendo-numerictextbox>
          </kendo-label>
        </div>
        <!-- Oferta Articulo-->

        <div class="form-group">
          <kendo-label
            class="k-checkbox-label"
            text="{{ 'ecommerce.isOferta' | translate }}"
          ></kendo-label>
          <div class="caja">
            <kendo-switch
              [(ngModel)]="isOferta"
              onLabel="{{ 'switch.activo' | translate }}"
              offLabel="{{ 'switch.inactivo' | translate }}"
            ></kendo-switch>
          </div>
          <div *ngIf="isOferta" class="form-group">
            <kendo-label
              text="{{ 'ecommerce.lbl_porcentajeoferta' | translate }}"
            >
              <kendo-numerictextbox
                format="n0"
                [min]="0"
                [step]="1"
                [(ngModel)]="porcentajeOferta"
              ></kendo-numerictextbox>
            </kendo-label>
          </div>
        </div>

        <!--Redireccionar a web -->
        <div class="form-group">
          <kendo-label
            class="k-checkbox-label"
            text="{{ 'ecommerce.isRedirect' | translate }}"
          ></kendo-label>
          <div class="caja">
            <kendo-switch
              [(ngModel)]="isRedirect"
              onLabel="{{ 'switch.activo' | translate }}"
              offLabel="{{ 'switch.inactivo' | translate }}"
            ></kendo-switch>
          </div>
          <div *ngIf="isRedirect" class="form-group">
            <kendo-label text="{{ 'ecommerce.lbl_urlredirect' | translate }}">
              <kendo-textbox
                placeholder="{{ 'ecommerce.ph_url' | translate }}"
                [clearButton]="true"
                [(ngModel)]="urlRedirect"
              ></kendo-textbox>
            </kendo-label>
          </div>
        </div>

        <!--Imagen Publicacion-->
        <p>
          <kendo-fileselect
            [restrictions]="fileRestrictions"
            (select)="select($event)"
            (remove)="remove($event)"
            (valueChange)="valueChange($event)"
          >
          </kendo-fileselect>
        </p>

        <div *ngIf="imagePreviews.length" class="img-preview example-config">
          <h5>{{ "ecommerce.imagenesselected" | translate }}</h5>
          <img
            *ngFor="let image of imagePreviews"
            [src]="image.src"
            alt="image preview"
            style="width: 70px; margin: 10px"
          />
        </div>
        <div *ngIf="listaImagenes.length" class="img-preview example-config">
          <div class="clearfix">
          <h5>{{ "ecommerce.imagenarticulo" | translate }}</h5>
          </div>
          <div class="clearfix">

          <div *ngFor="let image of listaImagenes" class="cadaimagen">
            <button class="btn btn-danger" (click)="btnBorrarImagen(image)">X</button>
            <img
              [src]="image.imagen"
              alt="image preview"
              style="width: 70px; margin: 10px"
            />
          </div>
          </div>

        </div>
        <!-- <label class="image-upload-container btn btn-bwm">
              <span>{{ 'ecommerce.seleccionaimagencategoria' | translate }}</span>
              <input
                #imageInput
                type="file"
                accept="image/*"
                (change)="imagePreview($event)"
              />
            </label>
    
            <div *ngIf="filePath && filePath !== ''">
              <img [src]="filePath" style="height: 200px" />
            </div> -->

        <!--Articulo Activo-->
        <div class="form-group">
          <kendo-label
            class="k-checkbox-label"
            text="{{ 'ecommerce.activoarticulo' | translate }}"
          ></kendo-label>
          <div class="caja">
            <kendo-switch
              [(ngModel)]="articuloActivo"
              onLabel="{{ 'switch.activo' | translate }}"
              offLabel="{{ 'switch.inactivo' | translate }}"
            ></kendo-switch>
          </div>
        </div>
        <!--Boton Publicar-->
        <div class="form-group">
          <button
            type="button"
            class="btn btn-success btn-sm mr-1"
            (click)="btnActualizarArticulo()"
          >
            {{ "ecommerce.btn_actualizar_articulo" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm mr-1"
            (click)="btnCancelar()"
          >
            {{ "botones.cancelar" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
