import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, RecorridosService, MenuService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FileInfo, FileRestrictions, SelectEvent, RemoveEvent } from '@progress/kendo-angular-upload';
import { first } from 'rxjs/operators';

// Editado
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-recorridos-detalle',
  templateUrl: './recorridos-detalle.component.html'
})
export class RecorridosDetalleComponent implements OnInit {

  form: FormGroup;
  codigo: number;
  isAddMode: boolean;
  public events: string[] = [];
  public imagePreview;
  loading = false;
  submitted = false;
  modalReference: NgbModalRef;
  imagenAnt: string;
  gpxAnt: string;
  isNotIns = false;
  isNotInsGpx = false;
  modalImGpx = false;
  imagenBorrada = false;
  gpxBorrado = false;

  formImErrFoto = false;
  formImErrGpx = false;
  gpxFileName;
  imgFileName;
  gpxContent;
  imgContent;

  regexLink = '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$';
  public restrictionsFoto: FileRestrictions = {
    allowedExtensions: ['.png', '.jpeg', '.jpg'],
    maxFileSize: 1048576
  };
  public restrictionsGpx: FileRestrictions = {
    allowedExtensions: ['.xml', '.gpx'],
    maxFileSize: 1048576
  };



  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private recorridosService: RecorridosService,
    private alertService: AlertService,
    private menuService: MenuService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('recorridos.recorridos');

    this.codigo = this.route.snapshot.params['id'];

    this.isAddMode = !this.codigo;

    this.form = this.formBuilder.group({
      codigo: this.route.snapshot.params['id'],
      nombre1: new FormControl('', [Validators.required]),
      nombre2: new FormControl('', [Validators.required]),
      descripcion1: new FormControl('', [Validators.required]),
      descripcion2: new FormControl('', [Validators.required]),
      foto: new FormControl(''),
      fotoContent: new FormControl(''),
      link1: new FormControl('', [Validators.pattern(this.regexLink)]),
      link2: new FormControl('', [Validators.pattern(this.regexLink)]),
      gpxFileName: new FormControl(''),
      gpx: new FormControl('')
    });

    if (!this.isAddMode) {
      this.recorridosService.GetById(this.codigo)
        .pipe()
        .subscribe((result) => {
          this.form = this.formBuilder.group({
            codigo: this.route.snapshot.params['id'],
            nombre1: new FormControl(result.data[0].nombre1, [Validators.required]),
            nombre2: new FormControl(result.data[0].nombre2, [Validators.required]),
            descripcion1: new FormControl(result.data[0].descripcion1, [Validators.required]),
            descripcion2: new FormControl(result.data[0].descripcion2, [Validators.required]),
            foto: new FormControl(result.data[0].foto),
            fotoContent: new FormControl(''),
            link1: new FormControl('', [Validators.pattern(this.regexLink)]),
            link2: new FormControl('', [Validators.pattern(this.regexLink)]),
            gpxFileName: new FormControl(result.data[0].gpxFileName),
            gpx: new FormControl('')
          });

          this.gpxFileName = result.data[0].gpxFileName;
          this.gpxAnt = result.data[0].gpxFileName;
          this.imgFileName = result.data[0].foto;
          this.imagenAnt = result.data[0].foto;

          if(this.imgFileName)
            this.recorridosService.download(this.imgFileName).subscribe((result2) => {
              this.imgContent = result2.imagen;
              this.createImageFromBase64(this.imgContent);
            });
        });
    }
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      // TODO This as a workaround.
      this.menuService.titulo = this.translateService.instant('recorridos.recorridos');
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid || this.formImErrFoto || this.formImErrGpx) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.crearRecorrido();
    } else {
      this.updateRecorrido();
    }
  }

  private crearRecorrido() {
    var error = null;

    if(this.gpxFileName && this.gpxContent && this.imgFileName && this.imgContent) {
      this.form.value.gpxFileName = this.timeStamp()+this.gpxFileName;
      this.form.value.gpx = this.gpxContent;
  
      this.form.value.foto = this.timeStamp()+this.imgFileName;
      this.form.value.fotoContent = this.imgContent;

      // Subimos ambos archivos (ambos, modo 2)
      this.recorridosService.upload(2, this.form.value)
        .subscribe((result) => {
          if(result.error)
            error = result.error;
        });
    } else {
      if(this.gpxFileName && this.gpxContent) {
        this.form.value.gpxFileName = this.timeStamp()+this.gpxFileName;
        this.form.value.gpx = this.gpxContent;

        // Subimos solo un archivo (gpx, modo 0)
        this.recorridosService.upload(0, this.form.value)
        .subscribe((result) => {
          if(result.error)
            error = result.error;
        });
      } 

      if(this.imgFileName && this.imgContent) {
        this.form.value.foto = this.timeStamp()+this.imgFileName;
        this.form.value.fotoContent = this.imgContent;

        // Subimos solo un archivo (foto, modo 1)
        this.recorridosService.upload(1, this.form.value)
        .subscribe((result) => {
          if(result.error)
            error = result.error;
        });        
      }
    }

    this.recorridosService.insert(this.form.value)
      .subscribe((result) => {
        if (!result.error && !error) {
          this.alertService.success(this.translateService.instant('botones.creadocorrectamente'), { keepAfterRouteChange: true });
          this.router.navigate(['../'], { relativeTo: this.route });
        } else {
          this.alertService.error(this.translateService.instant('recorridos.errorcrear'));
          this.loading = false;
        }
      });

  }

  private updateRecorrido() {
    var error = null;
    this.form.value.foto = this.imgFileName;
    this.form.value.gpxFileName = this.gpxFileName;

    if (this.imagenBorrada) {
      // Caso de foto borrada
      this.recorridosService.deleteImagen(this.form.value).subscribe((result) => {
        if (result.error)
          error = result.error;
      });
    } 

    if ((this.imgFileName != this.imagenAnt && this.imgContent != [] && !this.imagenBorrada) && (this.gpxFileName != this.gpxAnt)) {
      this.form.value.foto = this.timeStamp()+this.imgFileName;
      this.form.value.fotoContent = this.imgContent;
      this.form.value.gpxFileName = this.timeStamp()+this.gpxFileName;
      this.form.value.gpx = this.gpxContent;

      this.recorridosService.updateImagen(this.form.value).subscribe((result) => {
        console.log(result.traza);
        if (result.error)
          error = result.descripcion;
      });

      this.recorridosService.upload(2, this.form.value)
      .subscribe((result) => {
        if(result.error)
          error = result.error;
      });
    } else {
      if(this.imgFileName != this.imagenAnt && this.imgContent != [] && !this.imagenBorrada) {
        this.form.value.foto = this.timeStamp()+this.imgFileName;

        this.recorridosService.updateImagen(this.form.value).subscribe((result) => {
          console.log(result.traza);
          if (result.error)
            error = result.descripcion;
        });

        this.form.value.fotoContent = this.imgContent;

        this.recorridosService.upload(1, this.form.value)
        .subscribe((result) => {
          if(result.error)
            error = result.error;
        });
      }

      if(this.gpxFileName != this.gpxAnt && this.gpxFileName != "") {
          this.form.value.gpxFileName = this.timeStamp()+this.gpxFileName;
          this.form.value.gpx = this.gpxContent;
  
          // Subimos solo un archivo (gpx, modo 0)
          this.recorridosService.upload(0, this.form.value)
          .subscribe((result) => {
            if(result.error)
              error = result.error;
              console.log(result.traza);
          });
      }

    }

    // Caso actualización de otra información
    this.recorridosService.update(this.form.value)
      .pipe(first())
      .subscribe((result) => {
        if (!result.error && !error) {
          this.alertService.success(this.translateService.instant('botones.editadocorrectamente'), { keepAfterRouteChange: true });
          this.router.navigate(['../../'], { relativeTo: this.route });
        } else {
          this.alertService.error(this.translateService.instant('recorridos.erroreditar'));
          this.loading = false;
        }
      });

  }

  timeStamp() {
    var today = new Date();
    var date = ""+today.getFullYear()+(today.getMonth()+1)+today.getDate();
    var time = ""+today.getHours() + today.getMinutes() + today.getSeconds();
    return date+time;
  }

  // Parte foto
  createImageFromBase64(image) {
    let objectURL = 'data:image/jpeg;base64,' + image;
    this.imagePreview = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  onClickEliminarImagen(content) {
    this.modalImGpx = false;
    this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  onClickEliminarGpx(content) {
    this.modalImGpx = true;
    this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  eliminarImagen() {
    this.imagenBorrada = true;
    this.imgFileName = "";
    this.imgContent = [];
    this.imagePreview = null;
    this.modalReference.close();
  }

  eliminarGpx() {
    this.gpxBorrado = true;
    this.gpxFileName = "";
    this.gpxContent = [];
    this.modalReference.close();
  }

  public selectEventHandlerFoto(e: SelectEvent): void {
    const that = this;

    e.files.forEach((file) => {

      if (!file.validationErrors) {
        this.formImErrFoto = false;
        const reader = new FileReader();

        reader.onload = function (ev) {
          const image = {
            src: ev.target['result'],
            uid: file.uid
          };

          that.imagePreview = image.src.toString();

          that.imgFileName = file.name;
          that.imgContent = image.src.toString().split(",", 2)[1];

          that.isNotIns = true;
        };
        reader.readAsDataURL(file.rawFile);
      } else {
        this.formImErrFoto = true;
      }
    });
  }

  public removeEventHandlerFoto(e: RemoveEvent): void {
    this.formImErrFoto = false;
    this.imgContent = [];
    this.imgFileName = "";
    this.imagePreview = null;
  }

  public selectEventHandlerGpx(e: SelectEvent): void {
    const that = this;

    e.files.forEach((file) => {

      if (!file.validationErrors) {
        this.formImErrGpx = false;
        const reader = new FileReader();

        reader.onload = function (ev) {
          const gpx = {
            src: ev.target['result'],
            uid: file.uid
          };


          that.gpxFileName = file.name;
          that.gpxContent = gpx.src.toString().split(",", 2)[1];

          that.isNotInsGpx = true;

        };
        reader.readAsDataURL(file.rawFile);
      } else {
        this.formImErrGpx = true;
      }
    });
  }

  public removeEventHandlerGpx(e: RemoveEvent): void {
    this.formImErrGpx = false;
    this.gpxContent = [];
    this.gpxFileName = "";
  }

}
