<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="card w-50">
      <div class="card-header">
        <h3 *ngIf="isAddMode">{{ 'agentes.creartipo' | translate}}</h3>
        <h3 *ngIf="!isAddMode">{{ 'agentes.editartipo' | translate}}</h3>
      </div>
      <div class="card-body">
        <div class="form-group col-6">
          <kendo-label text="{{ 'agentes.nombretipos' | translate}}">
            <kendo-textbox formControlName="nombre" placeholder="Club" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
            </kendo-textbox>
            <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                <div *ngIf="f.nombre.errors.required">{{ 'agentes.nombretipo-requerido' | translate}}</div>
            </div>
          </kendo-label>
        </div>
        <div class="form-group col">
          <kendo-label text="{{ 'agentes.descripcion' | translate}}">
            <kendo-textbox formControlName="descripcion" [ngClass]="{ 'is-invalid': submitted && f.descripcion.errors }">
            </kendo-textbox>
          </kendo-label>
        </div>
        <div class="form-group col-6">
            <kendo-label text="{{ 'agentes.activotipo' | translate}}">
                <input type="checkbox" kendoCheckBox formControlName="activo" />
            </kendo-label>
          </div>

        </div>
      </div>
      <div class="form-group">
        <button kendoButton [disabled]="loading" class="btn mr-1  btn-primary">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ 'botones.guardar' | translate}}
        </button>
        <a routerLink="/agentes-tipos" class="btn mr-1  btn-danger">{{ 'botones.cancelar' | translate}}</a>
        </div>
  </form>
