<kendo-grid
  #grid
  [kendoGridBinding]="listaMunicipios"
  kendoGridSelectBy="id"
  [pageSize]="15"
  [pageable]="true"
  [navigable]="true"
  [sortable]="true"
  [filterable]="true"
  [resizable]="true"
  [selectable]="{
    cell: false,
    checkboxOnly: false,
    drag: true,
    enabled: true,
    mode: 'multiple'
  }"
>
  <!--checkbox-->
  <kendo-grid-checkbox-column width="1%">
    <ng-template kendoGridHeaderTemplate>
      <input
        class="k-checkbox"
        id="selectAllCheckboxId"
        kendoGridSelectAllCheckbox
        [state]="selectAllState"
      />
      <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
    </ng-template>
  </kendo-grid-checkbox-column>

  <kendo-grid-column
    field="nombre"
    title="{{ 'farmacias.nombre' | translate }}"
    width="10%"
  ></kendo-grid-column>

  <kendo-grid-column
    width="20%"
    [style]="{ 'text-align': 'left' }"
    field="checked"
    title="{{ 'farmacias.asignar' | translate }}"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" [(ngModel)]="dataItem.checked" />
    </ng-template>
  </kendo-grid-column>
  
</kendo-grid>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="btnCancelar()"
  >
    {{ "botones.cancelar" | translate }}
  </button>
  <button type="button" class="btn btn-success" (click)="btnGuardar()">
    {{ "botones.guardar" | translate }}
  </button>
</div>
