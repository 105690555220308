import { Component, OnInit } from '@angular/core';
import { Mensaje } from '@app/_models/mensaje';
import { Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services/menu.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Idioma } from '@app/_models/idioma';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { AlertService, DepartamentosService } from '@app/_services';
import { MensajeriaService } from '@app/_services/mensajeria.service';
import { Ente } from '@app/_models/ente';
@Component({
  selector: 'app-peticiones',
  templateUrl: './peticiones.component.html',
})
export class PeticionesComponent implements OnInit {
  listaEntesAdministrados: Ente[] = [];
  selectedEnte: Ente;
  listaMensajes: Mensaje[] = [];

  public seleccionados: number[] = [];
  lang;
  private translate: TranslateService;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  constructor(
    private mensajesService: MensajeriaService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    public router: Router,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('mensajeria.cabecera');
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.mensajesService.getEntesAdministrados().subscribe((resultEntes) => {
      this.listaEntesAdministrados = resultEntes.data;
      if (this.listaEntesAdministrados.length > 0) {
        this.selectedEnte = this.listaEntesAdministrados[0];
        //Load Messages
        this.mensajesService
          .getPeticionesFromEnte(this.selectedEnte.id)
          .subscribe((result) => {
            console.log(result.data);
            this.listaMensajes = result.data;
          });
      }
    });
  }

  loadPeticiones(value, dropDownControl: DropDownListComponent) {
    this.mensajesService
      .getPeticionesFromEnte(this.selectedEnte.id)
      .subscribe((result) => {
        console.log(result.data);
        this.listaMensajes = result.data;
      });
  }

  onClickEliminar(content) {
    console.log('Eliminando...');
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, {
        backdrop: 'static',
        size: 'lg',
        keyboard: false,
        centered: true,
      });
    }
  }

  onClickEditar() {
    this.router.navigate([
      'peticion-mensaje',
      { idorg: this.selectedEnte.id, idpeticion: this.seleccionados[0] },
    ]);
  }

  cellClick() {
    this.router.navigate([
      'peticion-mensaje',
      { idorg: this.selectedEnte.id, idpeticion: this.seleccionados[0] },
    ]);
  }

  eliminarRegistro(contentloading) {
    console.log('Eliminar registro');
    this.mensajesService
      .delete({ codigos: this.seleccionados })
      .subscribe((data) => {
        if (data.error == false) {
          this.mensajesService
            .getPeticionesFromEnte(this.selectedEnte.id)
            .subscribe((result) => {
              this.listaMensajes = result.data;
            });
        }
        this.modalReferenceloading.close();
      });
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, {
      backdrop: 'static',
      size: 'sm',
      keyboard: false,
      centered: true,
    });
    this.seleccionados = [];
  }
}
