<form [formGroup]="form">
    <div class="card w-50">
        <div class="card-body">
            <h3 *ngIf="isAddMode">{{ 'departamentos.crear' | translate}}</h3>
            <h3 *ngIf="!isAddMode">{{ 'departamentos.editar' | translate}}</h3>
        </div>
        <div class="card-body">
            <div class="form-group col">
                <kendo-label text="{{ 'departamentos.nombre' | translate}}">
                    <kendo-textbox formControlName="nombredep2" placeholder="{{ 'departamentos.dato-eu' | translate }}"
                        [ngClass]="{ 'is-invalid': submitted && f.nombredep2.errors }">
                    </kendo-textbox>
                    <div *ngIf="submitted && f.nombredep2.errors" class="invalid-feedback">
                        <div *ngIf="f.nombredep2.errors.required">{{ 'departamentos.nombre-eu-requerido' | translate}}
                        </div>
                    </div>
                </kendo-label>
            </div>
            <div class="form-group col">
                <kendo-textbox formControlName="nombredep1" placeholder="{{ 'departamentos.dato-es' | translate }}"
                    [ngClass]="{ 'is-invalid': submitted && f.nombredep1.errors }">
                </kendo-textbox>
                <div *ngIf="submitted && f.nombredep1.errors" class="invalid-feedback">
                    <div *ngIf="f.nombredep1.errors.required">{{ 'departamentos.nombrerequerido' | translate}}</div>
                </div>
            </div>
            <div class="form-group col" style="padding-bottom: 1%;">
                <div class="form-group col row">
                    <kendo-label text="{{ 'departamentos.activo' | translate}}">
                    </kendo-label>
                </div>
                <div class="form-group col row">
                    <kendo-switch formControlName="activo"></kendo-switch>
                </div>
            </div>
            <div class="form-group col" style="padding-bottom: 1%;">
                <div class="form-group col row">
                    <kendo-label text="{{ 'departamentos.usuarios' | translate}}">
                    </kendo-label>
                </div>
                <div class="form-group col row">
                    <kendo-switch [(ngModel)]="activoUsuarios" (valueChange)="onChangeSwitch($event)"
                        [ngModelOptions]="{standalone: true}"></kendo-switch>
                    <span style="padding-left: 1%;">{{ 'departamentos.soltrab' | translate}}</span>
                </div>
            </div>
            <!-- ListBox -->
            <div class="form-group col row">
                <div class="form-group col-sm-5">
                    <kendo-grid [data]="dataUsuariosNoDpt" [height]="188" kendoGridSelectBy="id" [navigable]="true" [selectedKeys]="seleccionadosND" [hideHeader]="true">
                        <kendo-grid-column field="nombre" title="nombre" width="250">
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
                <div class="form-group col-sm-1 mx-auto">
                    <div class="d-flex form-group row justify-content-center">
                        <button kendoButton (click)="onClickRight()" class="btn-sm btn-light">▶</button>
                    </div>
                    <div class="d-flex form-group row justify-content-center">
                        <button kendoButton (click)="onClickDoubleRight()" class="btn-sm btn-light">▶▶</button>
                    </div>
                    <div class="d-flex form-group row justify-content-center">
                        <button kendoButton (click)="onClickLeft()" class="btn-sm btn-light">◀</button>
                    </div>
                    <div class="d-flex form-group row justify-content-center">
                        <button kendoButton (click)="onClickDoubleLeft()" class="btn-sm btn-light">◀◀</button>
                    </div>
                </div>
                <div class="form-group col-sm-5">
                    <kendo-grid [data]="dataUsuariosDpt" [height]="188" kendoGridSelectBy="id" [selectedKeys]="seleccionadosD" [navigable]="true" [hideHeader]="true">
                        <kendo-grid-column field="nombre" title="nombre" width="250">
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group">
        <button kendoButton [disabled]="loading" (click)="onSubmit()" class="btn mr-1  btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            {{ 'botones.guardar' | translate}}
        </button>
        <a routerLink="/departamentos" class="btn mr-1  btn-danger">{{ 'botones.cancelar' | translate}}</a>
    </div>
</form>

<div *ngIf="!isAddMode" class="form-group">
    <ul class="list-group list-group-flush">
        <kendo-grid [kendoGridBinding]="dataDepartamentoEmails" [sortable]="true" kendoGridSelectBy="codigo"
            [navigable]="true" filterable="menu" (cellClick)="cellClick($event)" [selectedKeys]="EmailsSeleccionados">
            <ng-template kendoGridToolbarTemplate position="top">
                <button kendoButton (click)="onClickEditar()" class="btn mr-1  btn-success btn-sm mr-1">{{
                    'botones.editar' | translate}}</button>
                <button kendoButton (click)="onClickNuevo()" class="btn mr-1  btn-primary btn-sm mr-1">{{
                    'botones.nuevo' | translate}}</button>
                <button kendoButton (click)="onClickEliminar(content)" class="btn mr-1  btn-danger btn-sm mr-1"
                    [disabled]="isDeleting">{{ 'botones.eliminar' | translate}}</button>
            </ng-template>
            <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
            <kendo-grid-column width="20%" [style]="{'text-align': 'left'}" field="nombre"
                title="{{ 'departamentos.nombre' | translate}}">
            </kendo-grid-column>
            <kendo-grid-column width="20%" [style]="{'text-align': 'left'}" field="email"
                title="{{ 'departamentos.email' | translate}}">
            </kendo-grid-column>
            <kendo-grid-column width="20%" [style]="{'text-align': 'left'}" field="activo"
                title="{{ 'departamentos.activo' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <input type="checkbox" [checked]="dataItem.activo" disabled />
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </ul>
</div>

<!-- POP UP ELIMINAR EMAIL -->
<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ 'departamentos.email-eliminar' | translate }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'botones.no' | translate }}</button>
      <button type="button" class="btn btn-danger" (click)="eliminarRegistro(contentloading)">{{ 'botones.si' | translate }}</button>
    </div>
  </ng-template>
  
  <ng-template #contentloading let-modal>
    <div class="modal-body">
      <p>{{ 'departamentos.email-eliminando' | translate }}</p>
      <div class="spinner-border" role="status">
        <span class="sr-only">{{ 'departamentos.email-eliminando' | translate }}...</span>
      </div>
    </div>
  </ng-template>