import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Injectable()
export class DatosService {

public listaColumnas=[];
public listaFiltros:CompositeFilterDescriptor;

  constructor(private http: HttpClient) { 
    this.loadData();
  }

loadData(){
  console.log('Cargando datos...');
  this.listaColumnas = [
    { field: 'nombre', filter: true, tipo: 'string', prioridad: 0, width:'400px',sticky:true },
    { field: 'numComensales', filter: false, tipo: 'numeric', prioridad: 1,width:'200px' },
    { field: 'fecha', filter: true, tipo: 'date', prioridad: 2,width:'200px' },
    { field: 'activa', filter: true, tipo: 'boolean', prioridad: 3,width:'50px' },
  ];

  this.listaFiltros = {
    logic: "or",
    filters: [
      { field: "numComensales", operator: "gt", value: 5 },
      {
        logic: "or",
        filters: [
          { field: "fecha", operator: "gte", value: new Date(Date.now()) },
          { field: "fecha", operator: "lt", value: new Date(Date.now()) },
        ],
      },
    ],
  };
}

  getOrdenColumnas(){
    console.log('[S] Devolviendo: ');
    console.log(this.listaColumnas);
    return this.listaColumnas;
  }

  updateOrdenColumnas(lista){
    this.listaColumnas = lista;
    console.log('[S] Lista actualizada a ');
    console.log(this.listaColumnas);
  }

  getListaFiltros(){
    //var tmpFiltros = JSON.stringify('{logic: "or",filters: [{ field: "numComensales", operator: "gt", value: 5 },{logic: "or",filters: [{ field: "fecha", operator: "gte", value: new Date(Date.now()) },{ field: "fecha", operator: "lt", value: new Date(Date.now()) },],},],}');
    //let obj: CompositeFilterDescriptor = JSON.parse(tmpFiltros);
    return this.listaFiltros;
  }

  updateListaFiltros(listaFiltros){
    this.listaFiltros =listaFiltros;
  }


}
