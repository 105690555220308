import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ConfiguracionEcommerce } from '@app/_models/ecommerce';
import { Ente } from '@app/_models/ente';
import { AlertService, MenuService } from '@app/_services';
import { EcommerceService } from '@app/_services/ecommerce.service';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-ecommerce-configuracion',
  templateUrl: './ecommerce-configuracion.component.html',
})
export class EcommerceConfiguracionComponent implements OnInit {
  listaEntesAdministrados: Ente[] = [];
  selectedEnte: Ente;
  config: ConfiguracionEcommerce = new ConfiguracionEcommerce();
  modulos: Modulo[] = [];

  constructor(
    public ecommerceService: EcommerceService,
    public organizacionesService: OrganizacionesService,
    public alertService: AlertService,
    public translateService: TranslateService,
    public menuService: MenuService
  ) {
    this.menuService.titulo = this.translateService.instant(
      'ecommerce.configecommerce'
    );
  }

  ngOnInit(): void {
    this.ecommerceService.getEntesAdministrados().subscribe((result) => {
      this.listaEntesAdministrados = result.data;
      if (this.listaEntesAdministrados.length > 0) {
        this.selectedEnte = this.listaEntesAdministrados[0];
        this.ecommerceService
          .getConfiguracionEcommerceEnte(this.selectedEnte.id)
          .subscribe((config) => {
            console.log(config);
            if (config.data.length > 0) {
              this.config = config?.data[0];
              this.configurarSortable();
            }
            console.log(this.config);
          });
      }
    });
  }

  loadConfiguracion(value, dropDownControl: DropDownListComponent) {
    this.config = new ConfiguracionEcommerce();
    this.ecommerceService
      .getConfiguracionEcommerceEnte(this.selectedEnte.id)
      .subscribe((result) => {
        console.log(result);
        if (result.data.length > 0) {
          this.config = result?.data[0];
          this.configurarSortable();
        }
        console.log(this.config);
      });
  }

  updateCOnfiguracion() {
    //Actualizamos los index de los modulos según la lista sort
    //modulo=0 categorias modulo=1 top modulo=2 favs
    this.config.indexModuloCategorias = this.modulos.indexOf(
      this.modulos.find((elem) => elem.modulo == 1)
    );
    this.config.indexModuloTop = this.modulos.indexOf(
      this.modulos.find((elem) => elem.modulo == 2)
    );
    this.config.indexModuloFavs = this.modulos.indexOf(
      this.modulos.find((elem) => elem.modulo == 3)
    );

    console.log('Actualizando Configuracion...');
    console.log(this.config);
    this.ecommerceService
      .updateConfiguracionEcommerce(this.config)
      .subscribe((result) => {
        if (!result.error) {
          this.alertService.success(
            this.translateService.instant('ecommerce.supdate')
          );
        } else {
          this.alertService.error(
            this.translateService.instant('ecommerce.eupdate')
          );
        }
      });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.modulos, event.previousIndex, event.currentIndex);
  }

  configurarSortable() {
    console.log('Configurando sortable');
    this.modulos = [
      {
        id: this.config.indexModuloCategorias,
        nombre: 'Categorias',
        modulo: 1,
      },
      { id: this.config.indexModuloTop, nombre: 'Top Artículos', modulo: 2 },
      { id: this.config.indexModuloFavs, nombre: 'Favoritos', modulo: 3 },
    ];
    this.modulos = this.modulos.sort((prev, elem) => {
      return prev.id - elem.id;
    });
  }
}

export class Modulo {
  id: number;
  nombre: string;
  modulo: number;
}
