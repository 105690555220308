import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Idioma } from '@app/_models/idioma';
import { Mesa, MesaTrad } from '@app/_models/restaurante';
import { AlertService, MenuService, UsuariosService } from '@app/_services';
import { IdiomasService } from '@app/_services/idiomas.service';
import { RestauranteService } from '@app/_services/restaurante.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-mesas-editar',
  templateUrl: './mesas-editar.component.html',
})
export class MesasEditarComponent implements OnInit {
  idRestaurante: number;
  idOrg: string;
  idMesa: number;
  myMesa: Mesa = new Mesa();
  listaTraducciones: Idioma[] = [];
  filePath: string;
  nombreMesa: string = '';
  conectable: boolean = true;
  numComensales: number = 1;
  restaComensalesLargo: number = 0;
  restaComensalesAlto: number = 0;
  isExterior: boolean;
  isAccesible: boolean;
  numMesa: string = ''; //string por opción de 103A

  //Traducciones
  selectedIdioma: Idioma;
  listaIdiomas: Idioma[] = [];
  listaIdiomasTotales: Idioma[] = [];

  private translate: TranslateService;
  lang;

  constructor(
    private restauranteService: RestauranteService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translateService;
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.idOrg = this.route.snapshot.params['idorg'];
    this.idMesa = this.route.snapshot.params['idmesa'];
    this.idRestaurante = this.route.snapshot.params['idrestaurante'];
    //Load Langs
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomasTotales = result.data;
      console.log('Cargados idiomas = ' + this.listaIdiomasTotales.length);
      this.listaIdiomasTotales.forEach((x) => {
        const trad = new MesaTrad();
        trad.filled = false;
        trad.idIdioma = x.codigo;
        trad.nombreIdioma = x.nombre;
      });
    });
    this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
      if (this.listaIdiomas.length > 0) {
        this.selectedIdioma = this.listaIdiomas[0];
        //Load Mesa
        this.restauranteService
          .getMesaByIdLang(this.idMesa, this.selectedIdioma.codigo)
          .subscribe((result) => {
            console.log(result);
            this.myMesa = result.data[0];
            this.nombreMesa = result.data[0].nombre;
            this.numMesa = result.data[0].numMesa;
            this.numComensales=result.data[0].comensales;
            this.restaComensalesLargo = result.data[0].restaComensalesLargo;
            this.restaComensalesAlto = result.data[0].restaComensalesAlto;
            this.conectable = result.data[0].conectable;
            this.isExterior = result.data[0].isExterior;
            this.isAccesible = result.data[0].isAccesible;
            this.filePath = result.data[0].urlFoto;
          });
      }
    });
  }

  loadMesa(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Cargando Sala Idioma :' + value.codigo);
    this.restauranteService
      .getMesaByIdLang(this.idMesa, this.selectedIdioma.codigo)
      .subscribe((result) => {
        console.log(result);
        this.nombreMesa = result.data[0].nombre;
      });
  }
  btnUpdateMesa() {
    console.log('Updating Mesa ... ');
    if(this.nombreMesa=='' || this.numMesa==''){
      this.alertService.info(
        this.translateService.instant('publicaciones.validaciondatos')
      );
      return;
    }

    if (this.filePath != this.myMesa.urlFoto) {
      this.myMesa.urlFoto = this.filePath.split(',')[1]; //Borramos cabecera data: base
      this.myMesa.updateFoto = true;
    }
    this.myMesa.id = this.idMesa;
    this.myMesa.idRestaurante = this.idRestaurante;
    this.myMesa.idIdioma = this.selectedIdioma.codigo;
    this.myMesa.nombre = this.nombreMesa;
    this.myMesa.numMesa = this.numMesa;
    this.myMesa.comensales = this.numComensales;
    this.myMesa.restaComensalesLargo = this.restaComensalesLargo;
    this.myMesa.restaComensalesAlto = this.restaComensalesAlto;
    this.myMesa.conectable = this.conectable;
    this.myMesa.isExterior = this.isExterior;
    this.myMesa.isAccesible = this.isAccesible;

    this.restauranteService.updateMesa(this.myMesa).subscribe((result)=>{
      if(!result.error){
        this.alertService.success(
          this.translateService.instant('restaurante.successregistro')
        );
      }
      else{
        this.alertService.error(
          this.translateService.instant('restaurante.errrorregistro')
        );
      }
    });
  }

  //Imagen

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  btnCancelar() {
    this.router.navigate([
      'mesas',
      { idorg: this.idOrg, idrestaurante: this.idRestaurante },
    ]);
  }
}
