import { Component, OnInit } from '@angular/core';
import { Ente } from '@app/_models/ente';
import { App } from '@app/_models/app';

@Component({
  selector: 'app-patrocinadores-multinivel',
  templateUrl: './patrocinadores-multinivel.component.html',
  styleUrls: [],
})
export class PatrocinadoresMultinivelComponent implements OnInit {
  listaApps: App[] = [];
  listaAppsFilter: App[] = [];
  listaSeleccionados: App[] = [];

  constructor() {}

  ngOnInit(): void {
    let tmpEnte: App = new App();
    tmpEnte.id = '0x0000fff';
    tmpEnte.nombre = 'Real Sociedad';
    tmpEnte.descripcion =
      'Real Sociedad Club de Fútbol Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum';
    tmpEnte.imageUrl = 'https://www.w3schools.com/w3images/avatar2.png';
    tmpEnte.isActivaApi = true;
    tmpEnte.isActivaDB = true;
    this.listaApps.push(tmpEnte);
    let tmpEnte2: App = new App();
    tmpEnte2.id = '0x0000aaa';
    tmpEnte2.nombre = 'Real Aiora Eskubaloia';
    tmpEnte2.descripcion =
      'Real Aiora Eskubaloia Club Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum';
    tmpEnte.imageUrl = 'https://aioraeskubaloia.zitu.net/images/cfg/logo5.jpg';
    this.listaApps.push(tmpEnte2);

    this.listaAppsFilter = this.listaApps;

    //Recorrer lista de apps consultando la prueba de conexión a la api e ir asignando isActivaApi isActivaDB a cada App
    //Hacer que esa consulta sea obtener el número de usuarios en BD y mostrar el número de usuarios que tiene cada App debajo de los estados
  }

  handleFilter(query: string) {
    if (query.length > 1) {
      this.listaAppsFilter = this.listaApps.filter(
        (s) => s.nombre.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    } else if (query.length == 0) {
      this.listaAppsFilter = this.listaApps;
    }
  }
}
