<div class="row">
  <div class="col-xl-4 col-md-8">
    <div class="card">
      <!--EDICION MESA-->
      <div class="card-header">
        <h3>{{ "restaurante.lbl_editar_mesa" | translate }}</h3>
      </div>
      <div class="card-body">


        <div class="row">

          <div class="col">
            <div class="form-group">
              <!--Lista idiomas-->

              <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
              </kendo-label>

              <div class="caja">
                <kendo-dropdownlist #dropdownI
                                    [data]="listaIdiomas"
                                    textField="nombre"
                                    valueField="codigo"
                                    [(ngModel)]="selectedIdioma"
                                    (valueChange)="loadMesa($event, dropdownI)"></kendo-dropdownlist>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>{{ "restaurante.lbl_numero_mesa" | translate }}</label>
              <div class="caja">
                <!--Num Mesa-->
                <kendo-textbox placeholder="{{ 'restaurante.ph_numeromesa' | translate }}"
                               [clearButton]="true"
                               [(ngModel)]="numMesa">
                  <ng-template kendoTextBoxPrefixTemplate> </ng-template>
                </kendo-textbox>
              </div>
            </div>
          </div>

        </div>




        <div class="form-group">
          <label>{{ "restaurante.lbl_nombre_mesa" | translate }}</label>
          <div class="caja">
            <!--Nombre Mesa-->
            <kendo-textbox
              placeholder="{{ 'recintos.ph_nombre' | translate }}"
              [clearButton]="true"
              [(ngModel)]="nombreMesa"
            >
              <ng-template kendoTextBoxPrefixTemplate> </ng-template>
            </kendo-textbox>
          </div>
        </div>



        <!--Comensales-->
        <div class="form-group">
          <kendo-label text="{{ 'restaurante.numcomensales' | translate }}">
            <div class="caja">
              <kendo-numerictextbox format="n0"
                                    [min]="1"
                                    [step]="1"
                                    [(ngModel)]="numComensales"></kendo-numerictextbox>
            </div>
          </kendo-label>
        </div>

        <!--restaComensalesLargo-->
        <div class="form-group">
          <kendo-label text="{{ 'restaurante.restaComensalesLargo' | translate }}">
            <div class="caja">
              <kendo-numerictextbox format="n0"
                                    [min]="1"
                                    [step]="1"
                                    [(ngModel)]="restaComensalesLargo"></kendo-numerictextbox>
            </div>
              </kendo-label>
          
        </div>

        <!--RestaComensalesAlto-->
        <div class="form-group">
          <kendo-label
            text="{{ 'restaurante.restaComensalesAlto' | translate }}"
          >
            <div class="caja">
              <kendo-numerictextbox format="n0"
                                    [min]="1"
                                    [step]="1"
                                    [(ngModel)]="restaComensalesAlto"></kendo-numerictextbox>
            </div>
          </kendo-label>
        </div>

        <div class="row">

          <div class="col">
            <!-- conectable-->
            <div class="form-group">
              <kendo-label class="k-checkbox-label"
                           text="{{ 'restaurante.conectable' | translate }}"></kendo-label>
              <div class="caja">
                <kendo-switch [onLabel]="' '" [offLabel]="' '"
                [(ngModel)]="conectable"
                              onLabel="{{ 'switch.activo' | translate }}"
                              offLabel="{{ 'switch.inactivo' | translate }}"></kendo-switch>
              </div>
            </div>
          </div>
          <div class="col">
            <!-- isExterior-->
            <div class="form-group">
              <kendo-label class="k-checkbox-label"
                           text="{{ 'restaurante.isexterior' | translate }}"></kendo-label>
              <div class="caja">
                <kendo-switch [onLabel]="' '" [offLabel]="' '"
                [(ngModel)]="isExterior"
                              onLabel="{{ 'switch.activo' | translate }}"
                              offLabel="{{ 'switch.inactivo' | translate }}"></kendo-switch>
              </div>
            </div>
          </div>
          <div class="col">
            <!-- isAccesible-->
            <div class="form-group">
              <kendo-label class="k-checkbox-label"
                           text="{{ 'restaurante.isaccesible' | translate }}"></kendo-label>
              <div class="caja">
                <kendo-switch [onLabel]="' '" [offLabel]="' '" 
                [(ngModel)]="isAccesible"
                              onLabel="{{ 'switch.activo' | translate }}"
                              offLabel="{{ 'switch.inactivo' | translate }}"></kendo-switch>
              </div>
            </div>
          </div>

        </div>


        <!--Imagen Publicacion-->
        <label class="image-upload-container btn btn-bwm">
          <span>Select Image</span>
          <input
            #imageInput
            type="file"
            accept="image/*"
            (change)="imagePreview($event)"
          />
        </label>

        <div *ngIf="filePath && filePath !== ''">
          <img [src]="filePath" style="height: 200px" />
        </div>

        <div class="form-group">
          <!--Boton Publicar-->
          <button
            type="button"
            class="btn btn-success btn-sm mr-1"
            (click)="btnUpdateMesa()"
          >
            {{ "restaurante.btn_actualizar" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm mr-1"
            (click)="btnCancelar()"
          >
            {{ "botones.cancelar" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
