<div class="card" *ngIf="router.url === '/restaurantes'">
  <div class="selec-organizacion">
    <!--Lista Entes-->
    <kendo-label text="{{ 'publicaciones.selectente' | translate }}">
    </kendo-label>

    <kendo-dropdownlist
      #dropdown
      [data]="listaEntesAdministrados"
      textField="nombre"
      valueField="id"
      [(ngModel)]="selectedOrg"
      (valueChange)="loadRecintos($event, dropdown)"
    >
    </kendo-dropdownlist>
  </div>

  <kendo-grid
    [kendoGridBinding]="listaRecintos"
    [sortable]="true"
    kendoGridSelectBy="id"
    [navigable]="true"
    filterable="menu"
    (cellClick)="cellClick($event)"
    [(selectedKeys)]="seleccionados"
  >
    <ng-template kendoGridToolbarTemplate position="top">
      <button
        kendoButton
        (click)="onClickEditar()"
        class="btn mr-1 btn-success btn-sm mr-1"
      >
        {{ "botones.editar" | translate }}
      </button>
      <button
        kendoButton
        (click)="onClickNuevo()"
        class="btn mr-1 btn-primary btn-sm mr-1"
      >
        {{ "botones.nuevo" | translate }}
      </button>
      <button
        kendoButton
        (click)="onClickEliminar(content)"
        class="btn mr-1 btn-danger btn-sm mr-1"
        [disabled]="isDeleting"
      >
        {{ "botones.eliminar" | translate }}
      </button>
    </ng-template>
    <kendo-grid-checkbox-column
      width="1%"
      showSelectAll="true"
    ></kendo-grid-checkbox-column>
    <kendo-grid-column
      width="20%"
      [style]="{ 'text-align': 'left' }"
      field="nombre"
      title="{{ 'restaurante.grid_lbl_nombre' | translate }}"
    >
    </kendo-grid-column>
    <kendo-grid-column
      width="20%"
      [style]="{ 'text-align': 'left' }"
      field="activo"
      title="{{ 'recintos.lbl_activo' | translate }}"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <input type="checkbox" [checked]="dataItem.activo" disabled />
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ "organizaciones.eliminar" | translate }}</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="modal.dismiss('cancel click')"
    >
      {{ "botones.no" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-danger"
      (click)="eliminarRegistro(contentloading)"
    >
      {{ "botones.si" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #contentloading let-modal>
  <div class="modal-body">
    <p>{{ "organizaciones.eliminando" | translate }}</p>
    <div class="spinner-border" role="status">
      <span class="sr-only"
        >{{ "organizaciones.eliminando" | translate }}...</span
      >
    </div>
  </div>
</ng-template>

<router-outlet></router-outlet>
