
<div class="pantalla">
    <div class="menuzaharra">
      <h2>{{ 'restaurante.reservas' | translate}}</h2>
    </div>
    <div class="clearfix">
      <div class="left-reserva">
        <div class="card">
          <div class="card-header">
            <h3>
              <label CssClass="">{{ 'comedor' | translate }}</label>
            </h3>
            <div class="plegarpanel"></div>
          </div>
          <div class="card-body">
            <div class="plano" style="height:600px;width:600px;" >
              <table class="fondo" *ngIf="diaActivo"  style="position:absolute;width:600px; height:600px;">
                <tr *ngFor="let imagen of imagenes">
                  <td *ngFor="let imagen of imagenes">
                    <img [src]="imagen" *ngIf="!expandido">
                    <img [src]="imagen" *ngIf="expandido" class="fondoCompleto">
                  </td>           
                </tr>
                <div *ngFor="let obj of objetos"> <!-- Recorro los objetos -->
                  <div [ngStyle]="{'position': 'absolute', 'top.px': obj.y, 'left.px': obj.x}" > <!-- Los coloco -->
                    <!-- NÚMEROS MESAS -->
                    <p *ngIf="obj.numero > 0 && obj.reservado === 0" class="reservas-mesas-noReservadas">{{obj.numero}}</p> <!-- Le doy el numero a cada mesa y el color-->
                    <p *ngIf="obj.numero > 0 && obj.reservado >= obj.comensales" class="reservas-mesas-reservadas">{{obj.numero}}</p> <!-- Le doy el numero a cada mesa y el color -->
                    <p *ngIf="obj.numero > 0 && obj.reservado > 0 && obj.comensales > obj.reservado" class="reservas-mesas-reservadasParcial">{{obj.numero}}</p> <!-- Le doy el numero a cada mesa y el color -->
                    <p *ngIf="obj.numero > 0" class="reservas-mesas">{{obj.numero}}</p> <!-- Le doy el numero a cada mesa y el color -->
                    <!-- MESAS -->
                    <input *ngIf="obj.numero > 0 && obj.reservado === 0" (click)="nuevaReserva(obj.numero, fecha)" type="image" [src]="obj.imagen" 
                      [ngStyle]="{'height.px': obj.alto, 'width.px': obj.ancho, 'z-index': obj.zIndex, 'border':'3px solid #22c48c' }" 
                      onmouseover="this.style.backgroundColor='#e5e5e5'" 
                      onmouseout="this.style.backgroundColor='transparent'"> <!-- Les doy tamaño y Hago que pueda clickar para realizar una reserva -->
                    <input *ngIf="obj.numero > 0 && obj.reservado >= obj.comensales" type="image" [src]="obj.imagen" 
                      [ngStyle]="{'height.px': obj.alto, 'width.px': obj.ancho, 'z-index': obj.zIndex, 'border':'3px solid tomato', 'cursor':'default' }" > <!-- Les doy tamaño y Hago que pueda clickar para realizar una reserva -->
                    <input *ngIf="obj.numero > 0 && obj.reservado > 0 && obj.comensales > obj.reservado" (click)="nuevaReserva(obj.numero,fecha)" type="image" [src]="obj.imagen" 
                      [ngStyle]="{'height.px': obj.alto, 'width.px': obj.ancho, 'z-index': obj.zIndex, 'border':'3px solid orange' }" 
                      onmouseover="this.style.backgroundColor='#e5e5e5'" 
                      onmouseout="this.style.backgroundColor='transparent'"> <!-- Les doy tamaño y Hago que pueda clickar para realizar una reserva -->
                    <img *ngIf="obj.numero === 0" type="image" [src]="obj.imagen" [ngStyle]="{'height.px': obj.alto, 'width.px': obj.ancho, 'z-index': obj.zIndex }"> <!-- Les doy el tamaño y el Z-Index 0 no se podrán clicar-->
                  </div>
                </div>
              </table>
            </div>
            <div class="datosPlano1">
              <div id="mesas" [style]="{'text-align': 'center'}"><span>{{ 'mesas' | translate }}</span><br>{{ mesas }}</div>
              <div id="comensales" [style]="{'text-align': 'center'}"><span>{{ 'comensales' | translate }}</span><br>{{ comensales }}</div>
              <div id="plazaslibres" [style]="{'text-align': 'center'}"><span>{{ 'plazaslibres' | translate }}</span><br>{{ comensales - contReservado }}</div>
            </div>
            <div class="datosPlano2">
              <div id="cuadradoVerde" ></div> {{ 'libre' | translate }}
              <div id="cuadradoRojo"></div>{{ 'reservado' | translate }}
              <div id="cuadradoNaranja"></div>{{ 'parcialmentereservado' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="right-reserva">
        <div class="card">
            <div class="card-header">
              <h3>
                <label CssClass="">{{ 'datos' | translate }}</label>
              </h3>
              <div class="plegarpanel"></div>
            </div>
            <div class="card-body">
              <div class="clearfix">
                <div class="inputReservas">
                  <p>{{ 'fecha' | translate }}</p>  
                  <!-- Se duplica para que la fecha esté bien puesta en los idiomas  -->                  
                  <kendo-datepicker *ngIf="idioma == 'es'"
                      #picker   
                      [firstDay]="2"
                      [readOnlyInput]="true"                
                      [disabledDates]="disabledDates"           
                      [(value)]="fecha"
                      [(ngModel)]="fecha"
                      (valueChange)="cambiarDia()"
                      format="dd/MM/yyyy"
                  >
                  <kendo-calendar-messages today="{{ 'filterDateToday' | translate}}"> </kendo-calendar-messages>
                  </kendo-datepicker>
                  <kendo-datepicker *ngIf="idioma == 'eu'"
                      #picker   
                      [readOnlyInput]="true"                
                      [disabledDates]="disabledDates"           
                      [(value)]="fecha"
                      [(ngModel)]="fecha"
                      (valueChange)="cambiarDia()"
                      format="yyyy/MM/dd"
                  >
                  </kendo-datepicker>
                </div>
                <div class="inputReservas">
                  <p>{{ 'comida' | translate }}</p>
                  <kendo-dropdownlist [data]="listItemsComida" [value]="comidaNum" valuePrimitive="true"
                  [textField]="'value'" [valueField]="'id'" (valueChange)="cambiarCombo(1, false, $event)" style="width: 300px;">
                  </kendo-dropdownlist>
                </div>
                <div class="inputReservas">
                  <p>{{ 'comedor' | translate }}</p>
                  <kendo-dropdownlist [data]="listItemsComedor" [value]="comedorNum" valuePrimitive="true"
                  [textField]="'value'" [valueField]="'id'" (valueChange)="cambiarCombo(0, false, $event)" style="width: 300px;">
                  </kendo-dropdownlist>
                </div>
              </div>
            </div>
        </div>
  
        <div class="card">
            <div class="card-header">
              <h3>
                <label>{{ 'mesas' | translate }}</label>
              </h3>
              <div class="plegarpanel"></div>
            </div>
            <div class="card-body" style="height:275px;">
                <ul class="listareserva list-group list-group-flush reservas-mesa-taula" *ngIf="diaActivo">
                    <kendo-grid [kendoGridBinding]="reserva"
                                [sortable]="true"
                                [navigable]="true"
                                filterable="menu"
                                kendoGridSelectBy="numero"
                                scrollable="virtual"
                                [rowHeight]="25"
                                [height]="250"
                                [pageSize]="25"
                                [selectable]="{cell:false, checkboxOnly: false, drag: false, enabled: true, mode: 'multiple'}"
                                [resizable]="true"
                                (cellClick)="nuevaReserva2(fecha, $event)" 
                                [selectedKeys]="seleccionados"
                                [rowClass] = "colorLinea"
                                >
                        <kendo-grid-column width="10%" field="numero" title="{{ 'num' | translate}}" [style]="{'text-align': 'right'}">          
                          <ng-template kendoGridCellTemplate let-dataItem >
                            <ngcontainer >{{ dataItem.numero }}</ngcontainer>
                          </ng-template>                     
                        </kendo-grid-column>
                        <!-- Necesitamos que sean sortable, y si no se duplican así no lo son -->
                        <kendo-grid-column width="45%"  *ngIf="idioma == 'es'" field="nombre1" title="{{ 'nombre' | translate}}">
                          <ng-template kendoGridCellTemplate let-dataItem>
                            <ngcontainer [ngClass]="dataItem.color">{{ dataItem.nombre1 }}</ngcontainer>
                          </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column width="45%"  *ngIf="idioma == 'eu'" field="nombre2" title="{{ 'nombre' | translate}}">
                          <ng-template kendoGridCellTemplate let-dataItem>
                            <ngcontainer [ngClass]="dataItem.color">{{ dataItem.nombre2 }}</ngcontainer>
                          </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column width="20%" field="comensales" title="{{ 'comensales' | translate}}" [style]="{'text-align': 'right'}">
                          <ng-template kendoGridCellTemplate let-dataItem>
                            <ngcontainer [ngClass]="dataItem.color">{{ dataItem.comensales }}</ngcontainer>
                          </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column width="20%" field="reservado" title="{{ 'reservados' | translate}}" [style]="{'text-align': 'right'}">
                          <ng-template kendoGridCellTemplate let-dataItem>
                            <ngcontainer [ngClass]="dataItem.color">{{ dataItem.reservado }}</ngcontainer>
                          </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                          filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                          filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                          filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                          filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                          filterContainsOperator="{{'filterContainsOperator' | translate}}"
                          filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                          filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                          filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                          filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                          filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                          filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                          filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                          filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                          filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                          filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                          filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                          filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                          filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                          filterOrLogic="{{'filterOrLogic' | translate}}"
                          filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                          groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}"
                          noRecords="{{'noRecords' | translate}}" unlock="{{'unlock' | translate}}">
                      </kendo-grid-messages>
                    </kendo-grid>
                </ul>
            </div>
        </div>
  
        <div class="card">
            <div class="card-header">
              <h3>
                <label CssClass="">{{ 'reservas' | translate }}</label>
              </h3>
              <div class="plegarpanel"></div>
            </div>
            <div class="card-body" style="height:300px;">
                <p *ngIf="diaActivo">{{ 'vercancelados' | translate }} 
                  <kendo-switch *ngIf="diaActivo" (valueChange)="valueChangeVerCancelados()" [(ngModel)]="verCanceladas"
                    [onLabel]="' '"
                    [offLabel]="' '">
                  </kendo-switch>
                </p>
                <ul class="listareserva list-group list-group-flush" *ngIf="diaActivo">
                    <kendo-grid [kendoGridBinding]="reservaAuxiliar"
                                [sortable]="true"
                                [navigable]="true"
                                filterable="menu"
                                scrollable="virtual" [height]="200" (cellClick)="nuevaReserva3($event)"
                                >
                      <kendo-grid-column width="10%" field="numeromesa" title="{{ 'mesa' | translate}}" [style]="{'text-align': 'right'}">
                      </kendo-grid-column>
                      <kendo-grid-column width="50%" field="nombre" title="{{ 'socio' | translate}}">
                      </kendo-grid-column>
                      <kendo-grid-column width="15%" field="comensalesmesa" title="{{ 'reservado' | translate}}" [style]="{'text-align': 'right'}">
                      </kendo-grid-column>
                      <kendo-grid-column width="20%" field="dia" title="{{ 'fecha' | translate}}" [style]="{'text-align': 'right'}">
                        <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.dia | kendoDate }}</ng-template>
                      </kendo-grid-column>
                      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                          filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                          filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                          filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                          filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                          filterContainsOperator="{{'filterContainsOperator' | translate}}"
                          filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                          filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                          filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                          filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                          filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                          filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                          filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                          filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                          filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                          filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                          filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                          filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                          filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                          filterOrLogic="{{'filterOrLogic' | translate}}"
                          filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                          groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}"
                          noRecords="{{'noRecords' | translate}}" unlock="{{'unlock' | translate}}">
                      </kendo-grid-messages>
                    </kendo-grid>
                </ul>
                <div style="text-align: center;" *ngIf="diaActivo">
                    <button kendoButton class="btn mr-1  btn-danger btn-sm mt-2 mr-1" (click)="EliminarReserva()">{{ 'cancelarmireserva' | translate}}</button>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <kendo-dialog title="" *ngIf="opened" (close)="close('cancel')" [minWidth]="250" [width]="450">
    <p style="margin: 30px; text-align: center;">{{mensajeError | translate}}</p>
    <kendo-dialog-actions>
        <button kendoButton (click)="close()">Cerrar</button>
    </kendo-dialog-actions>
  </kendo-dialog>
  <router-outlet></router-outlet>