import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from '@app/_services/menu.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Idioma } from '@app/_models/idioma';
import {
  AlertService,
} from '@app/_services';
import { Recinto, RecintoTrad, Sala, SalaTrad } from '@app/_models/recinto';
import  {Equipo, EquipoTrad} from '@app/_models/equipo'
import { EquiposService } from '@app/_services/equipos.service';

@Component({
  selector: 'app-equipos-crear',
  templateUrl: './equipos-crear.component.html',
})
export class EquiposCrearComponent implements OnInit {

  idOrg:string;
  //Listas
  listaIdiomas: Idioma[] = [];
  listaIdiomasTotales: Idioma[] = [];
  listaTraducciones: Idioma[] = [];
  //Traducciones
  listaTraduccionesEnviar: EquipoTrad[] = [];
  //Data
  nombreEquipo:string='';
  descripcionEquipo:string;
  localidad:string='';
  equipoActivo:boolean=true;
  imagenB64: string = '';
  selectedIdioma: Idioma;
  myEquipo: Equipo = new Equipo();
  filePath: string;
  lang;
  private translate: TranslateService;
  

  constructor(
    private equiposService: EquiposService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('recintos.cabecera');
  }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('equipos.create_equipo');
    
    this.idOrg =this.route.snapshot.params['idorg'];
    this.lang = this.translateService.getDefaultLang();

    //Load Langs
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomasTotales = result.data;
      console.log('Cargados idiomas = ' + this.listaIdiomasTotales.length);
      this.listaIdiomasTotales.forEach((x) => {
        const trad = new SalaTrad();
        trad.filled = false;
        trad.idIdioma = x.codigo;
        trad.nombreIdioma = x.nombre;
        this.listaTraduccionesEnviar.push(trad);
      });
    });

    this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
      if (this.listaIdiomas.length > 0)
        this.selectedIdioma = this.listaIdiomas[0];
    });

  }

   crearEquipo() {
    console.log('Creando Equipo');
    //Validación de los datos
    if (this.listaTraducciones.length <= 0) {
      this.alertService.info(
        this.translateService.instant('publicaciones.errortrads')
      );
      return;
    }
    if (this.filePath == null) {
      this.alertService.info(
        this.translateService.instant('publicaciones.validaciondatos')
      );
      return;
    }

    //AutoCompletado de Idiomas
    //seleccionado
    let seleccionado: EquipoTrad = new EquipoTrad();
    let encontrado: boolean = false;
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (trad.filled && !encontrado) {
        seleccionado = trad;
        encontrado = true;
        return;
      }
    });
    //Fill UnFilled
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (!trad.filled) {
        trad.nombre = seleccionado.nombre;
        trad.descripcion = seleccionado.descripcion;
      }
    });

    console.log(this.listaTraduccionesEnviar);
    //Subir Foto  y EnviarNoticia
    this.myEquipo.idOrg = this.idOrg;
    this.myEquipo.activo = this.equipoActivo;
    this.myEquipo.nombre = this.nombreEquipo;
    this.myEquipo.descripcion = this.descripcionEquipo;
    this.myEquipo.localidad = this.localidad;
    this.myEquipo.activo = this.equipoActivo;
    this.myEquipo.listaTraducciones = this.listaTraduccionesEnviar;
    this.myEquipo.urlFoto = this.filePath.split(',')[1]; //Borramos cabecera data: base64
    this.equiposService.crearEquipo(this.myEquipo).subscribe((result) => {
      if (!result.error) {
        this.alertService.success(
          this.translateService.instant('registros.success')
        );
        this.router.navigate(['equipos']);
      } else {
        this.alertService.error(
          this.translateService.instant('registros.error')
        );
        return;
      }
    });
  }
   btnCancelar(){
    this.router.navigate(['equipos']);
  }


  btnAddTraduccion() {
    console.log('Comprobando datos');
    if (
      this.selectedIdioma == null ||
      this.nombreEquipo.length <= 0 ||
      this.descripcionEquipo.length <= 0
    ) {
      if (this.listaTraducciones.length <= 0) {
        this.alertService.info(
          this.translateService.instant('publicaciones.validacion')
        );
        return;
      }
    }
    console.log('Datos validados --->');
    if (!this.listaTraducciones.includes(this.selectedIdioma)) {
      this.listaTraducciones.push(this.selectedIdioma);

      this.listaTraduccionesEnviar.map((x) => {
        if (x.idIdioma == this.selectedIdioma.codigo) {
          x.filled = true;
          x.nombre = this.nombreEquipo;
          x.descripcion = this.descripcionEquipo;
        }
      });
    } else {
      console.log('Idioma ya añadido');
    }

    console.log(this.listaTraduccionesEnviar);
  }

  btnDeleteTraduccion(idioma: Idioma) {
    console.log(idioma);
    this.listaTraducciones = this.listaTraducciones.filter(
      (x) => x.codigo != idioma.codigo
    );

    this.listaTraduccionesEnviar.map((x) =>
      x.idIdioma == idioma.codigo ? (x.filled = false) : console.log()
    );

    console.log(this.listaTraduccionesEnviar);
  }

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }


}
