export class PermisosAdmin {
    id: number;
    idUser: number;
    isGlobalAdmin:boolean;
    isPublicacionesAdmin:boolean;
    isEventosAdmin:boolean;
    isEncuestasAdmin:boolean;
    isRecintosAdmin:boolean;
    isRestauranteAdmin:boolean;
    isEcommerceAdmin:boolean;
    isMembresiaAdmin:boolean;
    isForoAdmin:boolean;
  }