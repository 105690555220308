import { Idioma } from "./idioma";

export class Organizacion {
    id:string='';
    nombre:string='';
    
    //urlAvatar:string='';
    imagenAvatar:string='';
    //urlCabecera:string='';
    imagenCabecera:string='';
    //activa:boolean;
    organizacionActiva:boolean;
    email:string='';
    smtp:string='';
    puerto:number;
    //usuario:string='';
    emailUsuario:string='';
    emailPassword:string='';
    
    cambiarPasswordActivo:boolean;
    //cambiarContrasena:boolean;
    //contrasena:string='';
    infoNotificacionesActivas:boolean;
    infoFarmaciasActivas:boolean;
    infoFaqActivo:boolean;
    infoGestionDocumentalActiva:boolean;
    infoContactosActivo:boolean;

    //agenda
    activoAgenda:boolean;
    agendaEdadMinima:number;

    //mensajeria
    mensajeriaActiva:boolean;
    mensajeriaIncidenciasDepUnico:boolean;
    idDepartamento:number;

    //RESERVAS
    reservasActivas:boolean;
    reservasConfirmacion:boolean;
    reservasUsuarioEnCalendario:boolean;
    reservasDiasMinimos:number;
    reservasApertura:string='';
    reservasCorreos:string='';

    //coordenadas
    coordenadasLatitud:string='';
    coordenadasLongitud:string='';
    coordenadasZoom:number;

    //foro
    foroActivo:boolean;
    foroEdadMinima:number;
    foroPermUsuariosCrearPost:boolean;

    //encuestas
    encuestasActivas:boolean;
    encuestasEdadMinima:number;

    //RRSS
    rrssFacebookActivo:boolean;
    rrssFacebookUrl:string='';
    rrssTwitterActivo:boolean;
    rrssTwitterUrl:string='';
    rrssFlickerActivo:boolean;
    rrssFlickerUrl:string='';
    rrssInstagramActivo:boolean;
    rrssInstagramUrl:string='';
    rrssYoutubeActivo:boolean;
    rrssYoutubeUrl:string='';
    rrssMultimedia:string='';

    
    //agentes
    agentesActivos:boolean;
    agentesTiposActivos:boolean;
    isPrivado:boolean;

    //intercomunicacion usuarios
    permUsuariosPublicar:boolean;
    permUsuariosComentar:boolean;

    //Idiomas
    idIdiomas:number;
    idCamposIdiomas:number;

    tipoAgente:number;
    idPadre:string;

/*
    //activoForo:boolean;
    //activoPermUsuariosPostear:boolean;
    //foroEdadMin:number;
    // activoAgentes:boolean;
    // activoTipos:boolean;
    // intercomunicacionUsuariosPublicar:boolean;
    // intercomunicacionUsuariosComentar:boolean;
    agentesActivos:boolean;
    agentesTiposActivos:boolean;
    permUsuariosPublicar:boolean;
    permUsuariosComentar:boolean;
    
    
    
    // activoEncuestas:boolean;
    // encuestasEdadMin:number;
    


    // activoFb:boolean;
    // urlFb:string='';
    // activoTwitter:boolean;
    // urlTwitter:string='';
    // activoFlicker:boolean;
    // urlFlicker:boolean;
    // activoInstagram:boolean;
    // urlInstagram:string='';
    // activoYoutube:boolean;
    // urlYoutube:string='';
    // multimedia:string='';

    
    
    tipo:string='';
    idIdiomas:number;
    idCamposIdiomas:number;
    tipoAgente:number;
    isMine:boolean;
    actualizar:boolean;
        
    //idiomasSeleccionados:Idioma[];
    //listaIdiomasCampos:string[];
    */


  }


  export class OrganizacionTraduccion {
    id: string;
    //nombre: string;
    //cuerpo: string;
    descripcion: string;
    colaboradores: string;
    politicaDePrivacidad: string;
    //faq: string;
    
    idIdioma:number;
    nombreIdioma:string;
    idOrganizacion:number;
    filled:boolean;
}
export class Recurrencia {
    id: number;
    nombre: string;
}

export class OrganizacionModel {
    organizacion: Organizacion;
    listaTraducciones: OrganizacionTraduccion[];
}

