<div class="row">
    <div class="col-xl-4 col-md-6">
      <div class="card">
        <!--GESTION AGENTE-->
        <div class="card-header">
          <h3>{{ "categorias.titulo" | translate }}</h3>
        </div>
        <div class="card-body">
  
          <div class="form-group">
            <!--Lista idiomas-->
  
            <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
            </kendo-label>
  
            <div class="caja">
              <kendo-dropdownlist #dropdownI
                                  [data]="listaIdiomas"
                                  textField="nombre"
                                  valueField="codigo"
                                  [(ngModel)]="selectedIdioma"></kendo-dropdownlist>
  
            </div>
          </div>
  
          <div class="form-group">
            <label>{{ "categorias.lbl_nombre" | translate }}</label>
            <div class="caja">
              <!--Nombre Categoria-->
              <kendo-textbox placeholder="{{ 'categorias.ph_categoria' | translate }}"
                             [clearButton]="true"
                             [(ngModel)]="nombreCategoria">
                <ng-template kendoTextBoxPrefixTemplate>
                </ng-template>
              </kendo-textbox>
            </div>

            <label>{{ "categorias.color" | translate }}</label>
            <div class="caja">
              <kendo-colorpicker
                view="gradient"
                format="hex"
                [gradientSettings]="settings"
                name="colorCategoria"
                [(ngModel)]="colorCategoria"
                required
              >
              </kendo-colorpicker>
            </div>
          </div>
  
          <!-- Traducciones -->
          <div class="form-group">
            <button type="button"
                    class="btn btn-primary btn-sm mr-1"
                    (click)="btnAddTraduccion()">
              {{ "publicaciones.anadir" | translate }}
            </button>
            <div class="listado-traducciones">
              <div class="traduccion" *ngFor="let idioma of listaTraducciones">
                {{idioma.nombre}}
  
                <button type="button"
                        class="btn btn-info btn-sm mr-1"
                        (click)="btnDeleteTraduccion(idioma)">
                  X
                </button>
  
              </div>
            </div>
  
            <!--Boton Publicar-->
            <button type="button"
                    class="btn btn-success btn-sm mr-1"
                    (click)="btnCrearCategoria()">
              {{ "categorias.crear" | translate }}
            </button>

            <button type="button"
            class="btn btn-danger btn-sm mr-1"
            (click)="btnCancelar()">
            {{ "botones.cancelar" | translate }}
          </button>
          </div>
  
        </div>
      </div>
    </div>
  </div>
  