import { Component, OnInit } from '@angular/core';
import { Documento, Mensaje } from '@app/_models/mensaje';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services/menu.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Idioma } from '@app/_models/idioma';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { AlertService, DepartamentosService } from '@app/_services';
import { MensajeriaService } from '@app/_services/mensajeria.service';
import { Ente } from '@app/_models/ente';

@Component({
  selector: 'app-peticion-mensaje',
  templateUrl: './peticion-mensaje.component.html',
})
export class PeticionMensajeComponent implements OnInit {
  myMensaje: Mensaje;
  myDocumentos: Documento[];
  idPeticion: number;
  idOrg: string;

  lang;
  private translate: TranslateService;

  constructor(
    private mensajesService: MensajeriaService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('mensajeria.cabecera');
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.idOrg = this.route.snapshot.params['idorg'];
    this.idPeticion = this.route.snapshot.params['idpeticion'];

    this.mensajesService
      .getPeticion(this.idPeticion)
      .subscribe((resultPeticion) => {
        console.log(resultPeticion);
        this.myMensaje = resultPeticion.data[0];
        //Load docs
        this.mensajesService
          .getDocumentosFromPeticion(this.myMensaje.id)
          .subscribe((result) => {
            console.log('DOCUMENTOS');
            console.log(result);
            this.myDocumentos = result.data;
          });
      });
  }

  btnAltaMembresia() {
    console.log('Dando de alta usuario como miembro...');
    this.mensajesService
      .darAltaUsuarioMembresia(this.myMensaje.idUser, this.myMensaje.idOrg)
      .subscribe((result) => {
        console.log(result);
        if (!result.error) {
          this.alertService.success(
            this.translateService.instant('mensajeria.successalta')
          );
        } else {
          if(result.alreadymember){
            this.alertService.error(
              this.translateService.instant('mensajeria.alreadymember')
            );
          
          }
          else{
            this.alertService.error(
              this.translateService.instant('mensajeria.erroralta')
            );
          }

          }
      });
  }

  btnCancelar() {
    this.router.navigate(['members-peticiones']);
  }

  btnDownload(doc:Documento){
    console.log(doc);
    this.mensajesService.downloadPDF(doc.id).subscribe((result)=>{
      console.log('LLEGA DOC');
      console.log(result);
      //Convertir b64 a pdf
      
      //SOL1
      const source = `data:application/pdf;base64,${result.data[0].urlDocumento}`;
      const link = document.createElement("a");
      link.href = source;
      link.download = 'prueba.pdf';
      link.click();
      
      //SOL2
      //const source = 'data:application/pdf;base64,'+result.data[0].urlDocumento;
      // let uriContent = URL.createObjectURL(new Blob([result.data[0].urlDocumento], {type : 'application/pdf'}));
      // let link = document.createElement('a');
      // link.setAttribute('href', uriContent);
      // link.setAttribute('download', doc.nombre);
      // let event = new MouseEvent('click');
      // link.dispatchEvent(event);
    });
  }
}
