import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Contacto } from '@app/_models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';

const baseUrl = `${environment.apiUrl}/censo`;
const APICenso:string="https://desa1.sare.gipuzkoa.net/WAS/AYTO/URJUdalRecursosWSExtWEB/estaEmpadronado";

@Injectable({
  providedIn: 'root'
})
export class CensoService {

  constructor(private http: HttpClient) { }


  // *NEEDED* = Datos necesarios para realizar la petición, los debe proporcionar la adminsitración del municipio
  //Posibles respuestas: 
  // N -> No pertenece al censo
  // S -> DNI pertenece al cesno
  // E -> Fecha de nacimiento erronea para ese DNI
  public comprobar_dni_empadronado(dniUser:string,fechaNacimiento:number): Observable<any> {
    console.log('[S] Comprobando dni: '+dniUser +' fecha: '+ fechaNacimiento);
    return this.http.post(APICenso,
      {
        idioma:'B',
        dni:dniUser,
        dniPeticionario:'15942859H',// *NEEDED*
        fechanac:fechaNacimiento,
        nombrePeticionario:'Javier Tolosa',// *NEEDED*
        codprocedimiento:'UDWEBS02'//
      },
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
          USERNAME:'USUWBS75',// *NEEDED*
          PASSWORD:'xD209GgG',// *NEEDED*
          IP:'83.213.115.108'
        }),
        withCredentials: true,
      }
    );
    }



}
