import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TipoUsuario, Usuario } from '@app/_models';
import { AlertService, MenuService, UsuariosTiposService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-usuarios-tipos',
  templateUrl: './usuarios-tipos.component.html'
})
export class UsuariosTiposComponent implements OnInit {
  form: FormGroup;
  dataUsuariosTipos: TipoUsuario[];
  mySelection: number[] = [];
  navigationSubscription;
  isDeleting = false;
  constructor(private usuariosTiposService: UsuariosTiposService, 
    private alertService: AlertService, 
    private menuService: MenuService, 
    public router: Router, 
    private route: ActivatedRoute, 
    private formBuilder: FormBuilder,
    private translateService: TranslateService) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/usuarios-tipos') {
          this.menuService.titulo = this.translateService.instant('usuarios.tiposusuario');
          this.cargarDatos();
        }
      }else{
        if (this.router.url == '/usuarios-tipos') {
          this.menuService.titulo = this.translateService.instant('usuarios.tiposusuario');
        }
      }
    });
  }

  cargarDatos() {
    this.usuariosTiposService.getAll()
      .pipe(first())
      .subscribe(usuariostipos =>
        this.dataUsuariosTipos = usuariostipos
      );
  }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('usuarios.tiposusuario');
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      // TODO This as a workaround.
      this.menuService.titulo = this.translateService.instant('usuarios.tiposusuario');
    });
  }
  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['usuarios-tipos/editar/', this.mySelection[0]]);
    }
  }
  onClickEditar() {
    if (this.mySelection[0] > 0) {
      this.router.navigate(['usuarios-tipos/editar/', this.mySelection[0]]);
    }
  }
  onClickNuevo() {
    this.router.navigate(['usuarios-tipos/crear']);
  }
  onClickEliminar() {
    this.mySelection.forEach(element => {
      if (element > 0) {
        const user = this.dataUsuariosTipos.find(x => x.id == element);
        this.isDeleting = true;
        this.form = this.formBuilder.group({
          id: element
      });
        this.usuariosTiposService.delete(this.form.value)
          .pipe(first())
          .subscribe({
            next: () => {
              this.dataUsuariosTipos = this.dataUsuariosTipos.filter(x => x.id !== element)
              this.isDeleting = false;
              this.alertService.success(this.translateService.instant('botones.eliminadocorrectamente'), { keepAfterRouteChange: true });
              this.router.navigate(['../'], { relativeTo: this.route });

            },
            error: error => {
              this.isDeleting = false;
              this.alertService.error(this.translateService.instant('botones.errorenuso'));
            }
          });
      }
    });
  }

}
