<form [formGroup]="form">
    <div class="card-columns">
        <!-- Datos -->
        <div class="card">
            <div class="card-body">
                <h3>{{ 'configuracion.datos' | translate}}</h3>
            </div>
            <div class="card-body">
                <div class="form-group">
                    <kendo-label text="{{ 'configuracion.nombre' | translate}}">
                        <kendo-textbox formControlName="nombre" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
                        </kendo-textbox>
                    </kendo-label>
                </div>
                <div class="form-group col">
                    <div class="row">
                        <kendo-label text="{{ 'configuracion.logo' | translate}}">
                        </kendo-label>
                    </div>

                    <div class="row">
                        <kendo-fileselect formControlName="logo" class="form-control" [restrictions]="restrictions"
                            [multiple]="false" (select)="selectEventHandler($event)"
                            (remove)="removeEventHandler($event)">
                            <kendo-upload-messages cancel="{{ 'botones.cancelar' | translate }}"
                                dropFilesHere="{{ 'configuracion.dropFoto' | translate }}"
                                invalidFileExtension="{{ 'configuracion.extFotoIn' | translate }}"
                                invalidMaxFileSize="{{ 'configuracion.invalidMax' | translate }}"
                                remove="{{ 'configuracion.remFoto' | translate }}"
                                select="{{ 'configuracion.slFoto' | translate }}">
                            </kendo-upload-messages>
                        </kendo-fileselect>
                    </div>
                    <br>
                    <ng-template #notImConf>
                        <span><i>{{ 'configuracion.noimagen' | translate }}</i></span>
                    </ng-template>
                    <br>
                    <div class="row">
                        <div *ngIf="imagePreview; else notImConf">
                            <img [src]="imagePreview" style="max-height: 113px" />
                        </div>
                    </div>
                    <div *ngIf="!imagenBorrada && imagePreview && imagenAnt != []"
                        class="row" style="padding-top: 4%;">
                        <button kendoButton (click)="onClickEliminarImagen(content)" class="btn-sm btn-danger mr-1"
                            [disabled]="isNotIns">{{ 'botones.eliminar' | translate}}</button>
                    </div>
                </div>
                <div class="form-group">
                    <kendo-label text="{{ 'configuracion.texto' | translate}}">
                        <textarea kendoTextArea style="resize: none;" rows="5" formControlName="texto2"
                            placeholder="{{ 'configuracion.dato-eu' | translate }}"
                            [ngClass]="{ 'is-invalid': submitted && f.texto2.errors }">
                        </textarea>
                    </kendo-label>
                </div>
                <div class="form-group">
                    <textarea kendoTextArea style="resize: none;" rows="5" formControlName="texto1"
                        placeholder="{{ 'configuracion.dato-es' | translate }}"
                        [ngClass]="{ 'is-invalid': submitted && f.texto1.errors }">
                    </textarea>
                </div>
            </div>
        </div>
        <!-- Correo -->
        <div class="card">
            <div class="card-body">
                <h3>{{ 'configuracion.correo' | translate}}</h3>
            </div>
            <div class="card-body">
                <div class="form-group col">
                    <div class="row">
                        <kendo-label text="{{ 'configuracion.email' | translate}}">
                        </kendo-label>
                    </div>
                    <div class="row">
                        <kendo-textbox formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                        </kendo-textbox>
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors">{{ 'configuracion.emailincorrecto' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <kendo-label text="{{ 'configuracion.smtp' | translate}}">
                        </kendo-label>
                    </div>
                    <div class="row">
                        <kendo-textbox formControlName="smtp" [ngClass]="{ 'is-invalid': submitted && f.smtp.errors }">
                        </kendo-textbox>
                    </div>
                    <div class="row">
                        <kendo-label text="{{ 'configuracion.puerto' | translate}}">
                        </kendo-label>
                    </div>
                    <div class="row">
                        <kendo-numerictextbox class="form-control" formControlName="puerto" decimals="0" format="n">
                        </kendo-numerictextbox>
                    </div>
                    <div class="row">
                        <kendo-label text="{{ 'configuracion.usuario' | translate}}">
                        </kendo-label>
                    </div>
                    <div class="row">
                        <kendo-textbox formControlName="usuario"
                        [ngClass]="{ 'is-invalid': submitted && f.usuario.errors }">
                        </kendo-textbox>
                    </div>
                    <div class="row">
                        <kendo-label text="{{ 'configuracion.contrasena' | translate}}">
                        </kendo-label>
                    </div>
                    <div class="row">
                        <input kendoTextBox class="form-control" type="password" formControlName="contrasena"
                        [ngClass]="{ 'is-invalid': submitted && f.contrasena.errors }">
                        <div *ngIf="submitted && f.contrasena.errors" class="invalid-feedback">
                            <div *ngIf="f.contrasena.errors.required">{{ 'usuarios.contraseñarequerida' | translate}}</div>
                            <div *ngIf="f.contrasena.errors.minlength">{{ 'usuarios.contraseña6caracteres' | translate}}</div>
                          </div>
                    </div>
                    <div class="row">
                        <kendo-label text="{{ 'configuracion.cambiocontrasena' | translate}}">
                        </kendo-label>
                    </div>
                    <div class="row">
                        <kendo-switch [(ngModel)]="cambioCon" [ngModelOptions]="{standalone: true}"></kendo-switch>
                    </div>
                </div>
            </div>
        </div>
        <!-- Coordenadas -->
        <div class="card">
            <div class="card-body">
                <h3>{{ 'configuracion.coordenadas' | translate}}</h3>
            </div>
            <div class="card-body">
                <div class="form-group">
                    <div class="row">
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.latitud' | translate}}">
                            </kendo-label>
                        </div>
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.longitud' | translate}}">
                            </kendo-label>
                        </div>
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.zoom' | translate}}">
                            </kendo-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <kendo-textbox formControlName="latitud" [ngClass]="{ 'is-invalid': submitted && f.latitud.errors }">
                            </kendo-textbox>
                            <div *ngIf="submitted && f.latitud.errors" class="invalid-feedback">
                                <div *ngIf="f.latitud.errors">{{ 'configuracion.ltdincorrecta' | translate}}</div>
                            </div>
                        </div>
                        <div class="col">
                            <kendo-textbox formControlName="longitud" [ngClass]="{ 'is-invalid': submitted && f.longitud.errors }">
                            </kendo-textbox>
                            <div *ngIf="submitted && f.longitud.errors" class="invalid-feedback">
                                <div *ngIf="f.longitud.errors">{{ 'configuracion.lngincorrecta' | translate}}</div>
                            </div>
                        </div>
                        <div class="col">
                            <kendo-numerictextbox class="form-control" style="width: 60%;" formControlName="zoomcor" decimals="0" format="n">
                            </kendo-numerictextbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Comunicación -->
        <div class="card">
            <div class="card-body">
                <h3>{{ 'configuracion.comunicacion' | translate}}</h3>
            </div>
            <div class="card-body">
                <div class="form-group">
                    <div class="row">
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.noticias' | translate}}">
                            </kendo-label>
                        </div>
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.avisos' | translate}}">
                            </kendo-label>
                        </div>
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.tablon' | translate}}">
                            </kendo-label>
                        </div>
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.mensajeria' | translate}}">
                            </kendo-label>
                        </div>
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.agenda' | translate}}">
                            </kendo-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <kendo-switch formControlName="noticias"></kendo-switch>
                        </div>
                        <div class="col">
                            <kendo-switch formControlName="avisos"></kendo-switch>
                        </div>
                        <div class="col">
                            <kendo-switch formControlName="tablon"></kendo-switch>
                        </div>
                        <div class="col">
                            <kendo-switch formControlName="mensajeria"></kendo-switch>
                        </div>
                        <div class="col">
                            <kendo-switch formControlName="agenda"></kendo-switch>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Información -->
        <div class="card">
            <div class="card-body">
                <h3>{{ 'configuracion.informacion' | translate}}</h3>
            </div>
            <div class="card-body">
                <div class="form-group">
                    <div class="row">
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.farmacias' | translate}}">
                            </kendo-label>
                        </div>
                        <div class="col">
                            <kendo-label text="{{ 'transportes.transportes' | translate}}">
                            </kendo-label>
                        </div>
                        <div class="col">
                            <kendo-label text="{{ 'contactos.contactos' | translate}}">
                            </kendo-label>
                        </div> 
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.sitios' | translate}}">
                            </kendo-label>
                        </div>
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.documentos' | translate}}">
                            </kendo-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <kendo-switch formControlName="farmacias"></kendo-switch>
                        </div>
                        <div class="col">
                            <kendo-switch formControlName="transportes">
                            </kendo-switch>
                        </div>
                        <div class="col">
                            <kendo-switch formControlName="contactos"></kendo-switch>
                        </div>
                        <div class="col">
                            <kendo-switch formControlName="sitios"></kendo-switch>
                        </div>
                        <div class="col">
                            <kendo-switch formControlName="documentos"></kendo-switch>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Incidencias -->
        <div class="card">
            <div class="card-body">
                <h3>{{ 'configuracion.incidencias' | translate}}</h3>
            </div>
            <div class="card-body">
                <div class="form-group">
                    <div class="row">
                        <div class="col-sm-2">
                            <kendo-label text="{{ 'configuracion.incidencias' | translate}}">
                            </kendo-label>
                        </div>
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.enviarincidencias' | translate}}">
                            </kendo-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2">
                            <kendo-switch formControlName="incidencias">
                            </kendo-switch>
                        </div>
                        <div class="col">
                            <kendo-switch formControlName="departUnicoIncid">
                            </kendo-switch>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Reservas -->
        <div class="card">
            <div class="card-body">
                <h3>{{ 'configuracion.reservas' | translate}}</h3>
            </div>
            <div class="card-body">
                <div class="form-group">
                    <div class="row">
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.reservas' | translate}}">
                            </kendo-label>
                        </div>
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.confirmarreserva' | translate}}">
                            </kendo-label>
                        </div>
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.mostrarusuarioreserva' | translate}}">
                            </kendo-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <kendo-switch formControlName="reservas"></kendo-switch>
                        </div>
                        <div class="col">
                            <kendo-switch formControlName="confirmarreserva">
                            </kendo-switch>
                        </div>
                        <div class="col">
                            <kendo-switch formControlName="mostrarusuarioreserva">
                            </kendo-switch>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.resminlab' | translate}}">
                            </kendo-label>
                        </div>
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.resaper' | translate}}">
                            </kendo-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <kendo-numerictextbox class="form-control" formControlName="mindiaslaborables" decimals="0" format="n">
                            </kendo-numerictextbox>
                        </div>
                        <div class="col">
                            <kendo-dropdownlist class="form-control" [data]="apItems" [value]="apItems[fv.apertura]" (valueChange)="changeDropdown($event)">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.correos' | translate}}">
                                <textarea kendoTextArea style="resize: none;" rows="5" formControlName="correosReservas"
                                    [ngClass]="{ 'is-invalid': submitted && f.correosReservas.errors }">
                                </textarea>
                            </kendo-label>
                            <div *ngIf="submitted && f.correosReservas.errors" class="invalid-feedback">
                                <div *ngIf="f.correosReservas.errors">{{ 'configuracion.emailsincorrectos' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Agentes -->
        <div class="card">
            <div class="card-body">
                <h3>{{ 'configuracion.agentes' | translate}}</h3>
            </div>
            <div class="card-body">
                <div class="form-group">
                    <div class="row">
                        <div class="col-sm-2">
                            <kendo-label text="{{ 'configuracion.agentes' | translate}}">
                            </kendo-label>
                        </div>
                        <div class="col-sm-3">
                            <kendo-label text="{{ 'configuracion.uniagentes' | translate}}">
                            </kendo-label>
                        </div>
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.grupos' | translate}}">
                            </kendo-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2">
                            <kendo-switch formControlName="agentes"></kendo-switch>
                        </div>
                        <div class="col-sm-3">
                            <kendo-switch formControlName="agentesUnificados">
                            </kendo-switch>
                        </div>
                        <div class="col">
                            <kendo-switch formControlName="grupos"></kendo-switch>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Texto login -->
        <div class="card">
            <div class="card-body">
                <h3>{{ 'configuracion.textologin' | translate}}</h3>
            </div>
            <div class="card-body">
                <div class="form-group">
                    <div class="row">
                        <div class="col-sm-3">
                            <kendo-label text="{{ 'configuracion.menuturista' | translate}}">
                            </kendo-label>
                        </div>
                        <div class="col-sm-3">
                            <kendo-label text="{{ 'configuracion.noticiaspub' | translate}}">
                            </kendo-label>
                        </div>
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.texto' | translate}}">
                            </kendo-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <kendo-switch formControlName="menuTurista">
                            </kendo-switch>
                        </div>
                        <div class="col-sm-3">
                            <kendo-switch formControlName="agendaPublica">
                            </kendo-switch>
                        </div>
                        <div class="col">
                            <div class="form-group row">
                                <kendo-textbox formControlName="textoTuristaES"
                                    placeholder="{{ 'configuracion.turista' | translate }}"
                                    [ngClass]="{ 'is-invalid': submitted && f.textoTuristaES.errors }">
                                </kendo-textbox>
                            </div>
                            <div class="form-group row">
                                <kendo-textbox formControlName="textoTuristaEU"
                                    placeholder="{{ 'configuracion.invitado' | translate }}"
                                    [ngClass]="{ 'is-invalid': submitted && f.textoTuristaEU.errors }">
                                </kendo-textbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Foro -->
        <div class="card">
            <div class="card-body">
                <h3>{{ 'configuracion.foro' | translate}}</h3>
            </div>
            <div class="card-body">
                <div class="form-group">
                    <div class="row">
                        <div class="col-sm-2">
                            <kendo-label text="{{ 'configuracion.foro' | translate}}">
                            </kendo-label>
                        </div>
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.edadmin' | translate}}">
                            </kendo-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2">
                            <kendo-switch formControlName="foro"></kendo-switch>
                        </div>
                        <div class="col">
                            <kendo-numerictextbox class="form-control w-50" formControlName="edadforo" decimals="0" format="n">
                            </kendo-numerictextbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Encuestas -->
        <div class="card">
            <div class="card-body">
                <h3>{{ 'configuracion.encuestas' | translate}}</h3>
            </div>
            <div class="card-body">
                <div class="form-group">
                    <div class="row">
                        <div class="col-sm-2">
                            <kendo-label text="{{ 'configuracion.encuestas' | translate}}">
                            </kendo-label>
                        </div>
                        <div class="col">
                            <kendo-label text="{{ 'configuracion.edadmin' | translate}}">
                            </kendo-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2">
                            <kendo-switch formControlName="encuestas"></kendo-switch>
                        </div>
                        <div class="col">
                            <kendo-numerictextbox class="form-control w-50" formControlName="edadencuestasmin" decimals="0" format="n">
                            </kendo-numerictextbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Redes sociales -->
        <div class="card">
            <div class="card-body">
                <h3>{{ 'configuracion.redes' | translate}}</h3>
            </div>
            <div class="card-body">
                <div class="form-group">
                    <div class="row">
                        <div class="col-sm-2">
                            <kendo-label text="{{ 'configuracion.fb' | translate}}">
                            </kendo-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2">
                            <kendo-switch formControlName="facebook"></kendo-switch>
                        </div>
                        <div class="col">
                            <kendo-textbox formControlName="fblink"
                                [ngClass]="{ 'is-invalid': submitted && f.fblink.errors }">
                            </kendo-textbox>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2">
                            <kendo-label text="{{ 'configuracion.tw' | translate}}">
                            </kendo-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2">
                            <kendo-switch formControlName="twitter"></kendo-switch>
                        </div>
                        <div class="col">
                            <kendo-textbox formControlName="twlink"
                                [ngClass]="{ 'is-invalid': submitted && f.twlink.errors }">
                            </kendo-textbox>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2">
                            <kendo-label text="{{ 'configuracion.fl' | translate}}">
                            </kendo-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2">
                            <kendo-switch formControlName="flicker"></kendo-switch>
                        </div>
                        <div class="col">
                            <kendo-textbox formControlName="fllink"
                                [ngClass]="{ 'is-invalid': submitted && f.fllink.errors }">
                            </kendo-textbox>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2">
                            <kendo-label text="{{ 'configuracion.ins' | translate}}">
                            </kendo-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2">
                            <kendo-switch formControlName="instagram"></kendo-switch>
                        </div>
                        <div class="col">
                            <kendo-textbox formControlName="inslink"
                                [ngClass]="{ 'is-invalid': submitted && f.inslink.errors }">
                            </kendo-textbox>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2">
                            <kendo-label text="{{ 'configuracion.yt' | translate}}">
                            </kendo-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2">
                            <kendo-switch formControlName="youtube"></kendo-switch>
                        </div>
                        <div class="col">
                            <kendo-textbox formControlName="ytlink"
                                [ngClass]="{ 'is-invalid': submitted && f.ytlink.errors }">
                            </kendo-textbox>
                        </div>
                    </div>
                    <div *ngIf="submitted && (f.ytlink.errors || f.fblink.errors || f.twlink.errors || f.inslink.errors || f.fllink.errors)" class="row col invalid-feedback">
                        <div>{{ 'configuracion.enlacesincorrectos' | translate}}
                        </div>
                    </div>
                    <div class="row col">
                        <kendo-label text="{{ 'configuracion.media' | translate}}">
                        </kendo-label>
                    </div>
                    <div class="row col">
                        <textarea kendoTextArea style="resize: none;" rows="5" formControlName="media"
                            [ngClass]="{ 'is-invalid': submitted && f.media.errors }">
                        </textarea>
                        <div *ngIf="submitted && f.media.errors" class="invalid-feedback">
                            <div *ngIf="f.media.errors">{{ 'configuracion.emailsincorrectos' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="row col">
                        <p>* {{ 'configuracion.separar' | translate}}</p>
                        <p>* {{ 'configuracion.mediaapp' | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Censo -->
        <div class="card">
            <div class="card-body">
                <h3>{{ 'configuracion.censo' | translate}}</h3>
            </div>
            <div class="card-body">
                <div class="form-group col">
                    <div class="row">
                        <kendo-label text="{{ 'configuracion.censo' | translate}}">
                        </kendo-label>
                    </div>
                    <div class="row">
                        <kendo-switch formControlName="censo"></kendo-switch>
                    </div>
                    <div class="row">
                        <kendo-label text="{{ 'configuracion.correos' | translate}}">
                        </kendo-label>
                    </div>
                    <div class="row">
                        <textarea kendoTextArea style="resize: none;" rows="5" formControlName="correosCenso"
                            placeholder="{{ 'configuracion.dato-eu' | translate }}"
                            [ngClass]="{ 'is-invalid': submitted && f.correosCenso.errors }">
                        </textarea>
                        <div *ngIf="submitted && f.correosCenso.errors" class="invalid-feedback">
                            <div *ngIf="f.correosCenso.errors">{{ 'configuracion.emailsincorrectos' | translate}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <h3>{{ 'configuracion.privacidad' | translate}}</h3>
        </div>
        <div class="card-body">
            <div class="form-group">
                <kendo-label text="{{ 'configuracion.texto' | translate}}">
                    <textarea kendoTextArea style="resize: none;" rows="5" formControlName="textEULA2"
                        placeholder="{{ 'configuracion.dato-eu' | translate }}"
                        [ngClass]="{ 'is-invalid': submitted && f.textEULA2.errors }">
                    </textarea>
                </kendo-label>
            </div>
            <div class="form-group">
                <textarea kendoTextArea style="resize: none;" rows="5" formControlName="textEULA1"
                    placeholder="{{ 'configuracion.dato-es' | translate }}"
                    [ngClass]="{ 'is-invalid': submitted && f.textEULA1.errors }">
                </textarea>
            </div>
        </div>
    </div>
    <div class="form-group">
        <button kendoButton [disabled]="loading" (click)="onSubmit()" class="btn mr-1  btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            {{ 'botones.guardar' | translate}}
        </button>
        <a routerLink="/home" class="btn mr-1  btn-danger">{{ 'botones.cancelar' | translate}}</a>
    </div>
</form>

<!--POPUP ELIMINAR FOTO -->
<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ 'configuracion.preguntaeliminarlogo' | translate }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'botones.no' |
        translate }}</button>
      <button type="button" class="btn btn-danger" (click)="eliminarImagen();">{{ 'botones.si' | translate }}</button>
    </div>
  </ng-template>