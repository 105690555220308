<div class="row align-items-start">
    <div class="col-xl-4 col-md-6">
      <div class="card">
        <!--GESTION AGENTE-->
        <div class="card-header">
          <h3>{{ "equipos.datos_equipo" | translate }}</h3>
        </div>
        <div class="card-body">
          <div class="form-group">
            <!--Lista idiomas-->
  
            <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
            </kendo-label>
  
            <div class="caja">
              <kendo-dropdownlist
                #dropdownI
                [data]="listaIdiomas"
                textField="nombre"
                valueField="codigo"
                [(ngModel)]="selectedIdioma"
                (valueChange)="loadEquipo($event, dropdownI)"
              ></kendo-dropdownlist>
            </div>
          </div>
  
          <div class="form-group">
            <label>{{ "equipos.lbl_nombre_equipo" | translate }}</label>
            <div class="caja">
              <!--Titulo-->
              <kendo-textbox
                placeholder="{{ 'recintos.ph_nombre' | translate }}"
                [clearButton]="true"
                [(ngModel)]="myEquipo.nombre"
              >
                <ng-template kendoTextBoxPrefixTemplate> </ng-template>
              </kendo-textbox>
            </div>
          </div>
  
          <!--Cuerpo-->
          <div class="form-group">
            <kendo-label text="{{ 'equipos.lbl_descr_equioi' | translate }}">
              <textarea
                kendoTextArea
                style="resize: none"
                rows="5"
                [(ngModel)]="myEquipo.descripcion"
                placeholder="{{ 'recintos.ph_descripcion' | translate }}"
              >
              </textarea>
            </kendo-label>
          </div>

          <!--Localidad Equipo-->
          <div class="form-group">
            <label>{{ "equipos.lbl_localidad_equipo" | translate }}</label>
            <div class="caja">
              <!--Titulo-->
              <kendo-textbox
                placeholder="{{ 'equipos.ph_localidad' | translate }}"
                [clearButton]="true"
                [(ngModel)]="myEquipo.localidad"
              >
                <ng-template kendoTextBoxPrefixTemplate> </ng-template>
              </kendo-textbox>
            </div>
          </div>
          <!--Imagen Publicacion-->
          <label class="image-upload-container btn btn-bwm">
            <span>Select Image</span>
            <input
              #imageInput
              type="file"
              accept="image/*"
              (change)="imagePreview($event)"
            />
          </label>
  
          <div *ngIf="filePath && filePath !== ''">
            <img [src]="filePath" style="height: 200px" />
          </div>
  

          <!-- Traducciones -->
          <div class="form-group">
                      <!--CheckBox Activo-->
          <div class="form-group">
            <kendo-label
              class="k-checkbox-label"
              text="{{ 'equipos.equipoactivo' | translate }}"
            ></kendo-label>
            <div class="caja">
              <kendo-switch
                [(ngModel)]="equipoActivo"
                onLabel="{{ 'switch.on' | translate }}"
                offLabel="{{ 'switch.off' | translate }}"
              ></kendo-switch>
            </div>
          </div>
            <!--Boton Publicar-->
            <button
              type="button"
              class="btn btn-success btn-sm mr-1"
              (click)="btnActualizarEquipo()"
            >
              {{ "recintos.btn_actualizar" | translate }}
            </button>
            <button
              type="button"
              class="btn btn-danger btn-sm mr-1"
              (click)="btnCancelar()"
            >
              {{ "botones.cancelar" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Jugadores Equipo Grid-->
    <div class="col-xl-8 col-md-6">
        <div class="card">
            <div class="card-header">
                <h3>{{ "equipos.gestionjugadores" | translate }}</h3>
              </div>
            <div class="form-group">
                <kendo-grid
                [kendoGridBinding]="listaJugadores"
                [sortable]="true"
                [pageable]="true"
                [pageSize]="14"
                kendoGridSelectBy="id"
                [navigable]="true"
                filterable="menu"
                (cellClick)="cellClick($event)"
                [(selectedKeys)]="seleccionados"
              >
                <ng-template kendoGridToolbarTemplate position="top">
                  <button
                    kendoButton
                    (click)="onClickEditar()"
                    class="btn mr-1 btn-success btn-sm mr-1"
                  >
                    {{ "botones.editar" | translate }}
                  </button>
                  <button
                    kendoButton
                    (click)="onClickNuevo()"
                    class="btn mr-1 btn-primary btn-sm mr-1"
                  >
                    {{ "botones.nuevo" | translate }}
                  </button>
                  <button
                    kendoButton
                    (click)="onClickEliminar(content)"
                    class="btn mr-1 btn-danger btn-sm mr-1"
                    [disabled]="isDeleting"
                  >
                    {{ "botones.eliminar" | translate }}
                  </button>
                </ng-template>
                <kendo-grid-checkbox-column
                  width="5%"
                  showSelectAll="true"
                ></kendo-grid-checkbox-column>
                <kendo-grid-column
                  width="20%"
                  [style]="{ 'text-align': 'left' }"
                  field="nombre"
                  title="{{ 'equipos.jugadornombre' | translate }}"
                >
                </kendo-grid-column>
                <kendo-grid-column
                  width="20%"
                  [style]="{ 'text-align': 'left' }"
                  field="apellidos"
                  title="{{ 'equipos.jugadorapellidos' | translate }}"
                >
                </kendo-grid-column>
              </kendo-grid>
        
                </div>
                </div>
                </div>
        

  </div>
  
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ "organizaciones.eliminar" | translate }}</p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="modal.dismiss('cancel click')"
      >
        {{ "botones.no" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-danger"
        (click)="eliminarRegistro(contentloading)"
      >
        {{ "botones.si" | translate }}
      </button>
    </div>
  </ng-template>
  
  <ng-template #contentloading let-modal>
    <div class="modal-body">
      <p>{{ "organizaciones.eliminando" | translate }}</p>
      <div class="spinner-border" role="status">
        <span class="sr-only">{{ "organizaciones.eliminando" | translate }}...</span>
      </div>
    </div>
  </ng-template>