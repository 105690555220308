import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Idioma } from '@app/_models/idioma';
import { Mesa, MesaTrad } from '@app/_models/restaurante';
import { AlertService, MenuService, UsuariosService } from '@app/_services';
import { IdiomasService } from '@app/_services/idiomas.service';
import { RestauranteService } from '@app/_services/restaurante.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mesas-crear',
  templateUrl: './mesas-crear.component.html',
})
export class MesasCrearComponent implements OnInit {

  idRestaurante:number;
  idOrg:string;
  myMesa:Mesa= new Mesa();
  listaTraducciones: Idioma[] = [];
  filePath: string;
  nombreMesa:string='';
  conectable:boolean=true;
  numComensales:number=1;
  restaComensalesLargo:number=0;
  restaComensalesAlto:number=0;
  isExterior:boolean;
  isAccesible:boolean;
  numMesa:string='';//string por opción de 103A

  //Traducciones
  listaTraduccionesEnviar: MesaTrad[] = [];
  selectedIdioma: Idioma;
  listaIdiomas: Idioma[] = [];
  listaIdiomasTotales: Idioma[] = [];

  private translate: TranslateService;
  lang;

  constructor(
    private restauranteService: RestauranteService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translateService;
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.idOrg = this.route.snapshot.params['idorg'];
    this.idRestaurante = this.route.snapshot.params['idrestaurante'];
    //Load Langs
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomasTotales = result.data;
      console.log('Cargados idiomas = ' + this.listaIdiomasTotales.length);
      this.listaIdiomasTotales.forEach((x) => {
        const trad = new MesaTrad();
        trad.filled = false;
        trad.idIdioma = x.codigo;
        trad.nombreIdioma = x.nombre;
        this.listaTraduccionesEnviar.push(trad);
      });
    });

    this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
      if (this.listaIdiomas.length > 0)
        this.selectedIdioma = this.listaIdiomas[0];
    });


  }


  btnCrearMesa(){
    console.log('Creando Mesa');
    //Validación de los datos
    if (this.listaTraducciones.length <= 0) {
      this.alertService.info(
        this.translateService.instant('publicaciones.errortrads')
      );
      return;
    }

    if (this.filePath == null || this.numMesa=='') {
      this.alertService.info(
        this.translateService.instant('publicaciones.validaciondatos')
      );
      return;
    }

    //AutoCompletado de Idiomas
    //seleccionado
    let seleccionado: MesaTrad = new MesaTrad();
    let encontrado: boolean = false;
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (trad.filled && !encontrado) {
        seleccionado = trad;
        encontrado = true;
        return;
      }
    });
    //Fill UnFilled
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (!trad.filled) {
        trad.nombre = seleccionado.nombre;
      }
    });

    this.myMesa.idRestaurante = this.idRestaurante;
    this.myMesa.nombre = this.nombreMesa;
    this.myMesa.comensales = this.numComensales;
    this.myMesa.asignada = false;
    this.myMesa.conectable = this.conectable;
    this.myMesa.numMesa = this.numMesa;
    this.myMesa.isExterior = this.isExterior;
    this.myMesa.isAccesible = this.isAccesible;
    this.myMesa.restaComensalesLargo = this.restaComensalesLargo;
    this.myMesa.restaComensalesAlto = this.restaComensalesAlto;
    this.myMesa.listaTraducciones = this.listaTraduccionesEnviar;
    this.myMesa.urlFoto = this.filePath.split(',')[1]; //Borramos cabecera data: base64

    this.restauranteService.createMesa(this.myMesa).subscribe((result)=>{
      console.log(result);
      if(!result.error){
        this.alertService.success(
          this.translateService.instant('restaurante.successmesa')
        );
      }
      else{
        this.alertService.error(
          this.translateService.instant('restaurante.errormesa')
        );
      }
    });
  }



  btnAddTraduccion() {
    console.log('Comprobando datos');
    if (
      this.selectedIdioma == null ||
      this.nombreMesa.length <= 0 
    ) {
      if (this.listaTraducciones.length <= 0) {
        this.alertService.info(
          this.translateService.instant('publicaciones.validacion')
        );
        return;
      }
    }
    console.log('Datos validados --->');
    if (!this.listaTraducciones.includes(this.selectedIdioma)) {
      this.listaTraducciones.push(this.selectedIdioma);

      this.listaTraduccionesEnviar.map((x) => {
        if (x.idIdioma == this.selectedIdioma.codigo) {
          x.filled = true;
          x.nombre = this.nombreMesa;
        }
      });
    } else {
      console.log('Idioma ya añadido');
    }

    console.log(this.listaTraduccionesEnviar);
  }

  btnDeleteTraduccion(idioma: Idioma) {
    console.log(idioma);
    this.listaTraducciones = this.listaTraducciones.filter(
      (x) => x.codigo != idioma.codigo
    );

    this.listaTraduccionesEnviar.map((x) =>
      x.idIdioma == idioma.codigo ? (x.filled = false) : console.log()
    );

    console.log(this.listaTraduccionesEnviar);
  }

  //Imagen

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  btnCancelar() {
    this.router.navigate(['mesas',{idorg:this.idOrg,idrestaurante:this.idRestaurante}]);
  }

}
