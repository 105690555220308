import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Usuario } from '@app/_models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';

const baseUrl = `${environment.apiUrl}/perfiles`;

@Injectable()
export class PerfilesService {

  constructor(private http: HttpClient) { }

  public GetById(id: Number): Observable<any> {
    return this.http.get(baseUrl + "/" + id);
  }

  public GetAll(): Observable<any> {
    return this.http.get(baseUrl);
  }

  insert(data) {
    return this.http.post<any>(baseUrl + "/crear", { codigo: -1, nombre1: data.nombre1, nombre2: data.nombre2,
      incidenciasAdmin: data.incidenciasAdmin, encuestasAdmin: data.encuestasAdmin, agendaAdmin: data.agendaAdmin, noticiasAdmin: data.noticiasAdmin,
      foroAdmin: data.foroAdmin, avisosAdmin: data.avisosAdmin, mensajeriaAdmin: data.mensajeriaAdmin, tablonAdmin: data.tablonAdmin, admrecintos: data.admrecintos, 
      admreservas: data.admreservas, reservas: data.reservas, confirmar: data.confirmar, agenda: data.agenda, mensajeria: data.mensajeria, tablon: data.tablon, foro: data.foro,
      encuestas: data.encuestas, noticias: data.noticias, avisos: data.avisos, nreservasmes: data.nreservasmes, limitereservas: data.limitereservas, sitios: data.sitios,
      incidencias: data.incidencias, grupos: data.grupos }, { withCredentials: true });
  }

  update(data) {
    //console.log(JSON.stringify(data));
    //console.log(idTipoPerdida_DAT);
    return this.http.post<any>(baseUrl + "/editar", { codigo: data.codigo, nombre1: data.nombre1, nombre2: data.nombre2,
      incidenciasAdmin: data.incidenciasAdmin, encuestasAdmin: data.encuestasAdmin, agendaAdmin: data.agendaAdmin, noticiasAdmin: data.noticiasAdmin,
      foroAdmin: data.foroAdmin, avisosAdmin: data.avisosAdmin, mensajeriaAdmin: data.mensajeriaAdmin, tablonAdmin: data.tablonAdmin, admrecintos: data.admrecintos, 
      admreservas: data.admreservas, reservas: data.reservas, confirmar: data.confirmar, agenda: data.agenda, mensajeria: data.mensajeria, tablon: data.tablon, foro: data.foro,
      encuestas: data.encuestas, noticias: data.noticias, avisos: data.avisos, nreservasmes: data.nreservasmes, limitereservas: data.limitereservas, sitios: data.sitios,
      incidencias: data.incidencias, grupos: data.grupos }, { withCredentials: true });
  }

  delete(data?: any): Observable<any> {

    //console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append("codigos", data.codigos);
    //console.log(JSON.stringify(formData));

    return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });
  }

}
