import { stringify } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-app-editar',
  templateUrl: './app-editar.component.html',
})
export class AppEditarComponent implements OnInit {
  nombre: string;
  descripcion: string;
  imageUrl: string;
  avatarUrl: string;
  entryPoint: string;
  apiKey: string;

  filePath: string;

  constructor(public router: Router) {}

  ngOnInit(): void {}

  btnActualizarApp() {
    if (
      this.nombre.length <= 0 ||
      this.descripcion.length <= 0 ||
      this.entryPoint.length <= 5 ||
      this.apiKey.length <= 0
    ) {
      return;
    }
    //Actualizando App
  }

  btnCancelar() {
    this.router.navigate(['home']);
  }



  //Imagen

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }
}
