import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';

const baseUrl = `${environment.apiUrl}/contactosente`;

//hh
@Injectable()
export class ContactosenteService {

  constructor(private http: HttpClient) { }

  public GetById(id: String): Observable<any> {
    return this.http.get(baseUrl + "/" + id);
  }

  public GetContacto(id): Observable<any> {
    console.log('Consultando: '+ id);
    return this.http.get(baseUrl + "/get/" + id);
  }

  public GetAll(): Observable<any> {
    return this.http.get(baseUrl);
  }

  insert(data,idorg) {
    return this.http.post<any>(baseUrl + "/crear/"+idorg, {id: -1, contacto: data.nombre, email: data.email, phone: data.phone}, { withCredentials: true });
  }

  update(data,idcontacto) {
    console.log('Updating: '+idcontacto);
    console.log(JSON.stringify(data));
    //console.log(idTipoPerdida_DAT);
    return this.http.post<any>(baseUrl + "/editar", { id: idcontacto, contacto: data.nombre, email: data.email, phone: data.phone}, { withCredentials: true });
  }

  delete(data?: any): Observable<any> {

    //console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append("codigos", data.codigos);
    //console.log(JSON.stringify(formData));

    return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });
  }

}
