<div class="row">
  <div class="col-xl-4 col-md-6">
    <div class="card">
      <!--GESTION AGENTE-->
      <div class="card-header">
        <h3>{{ "recintos.editarreserva" | translate }}</h3>
      </div>
      <div class="card-body">
        <div class="form-group">
          <!--Boton Gestion Reservas-->
          <button
            *ngIf="!myReserva.isConfirmada; else notConfirmada"
            type="button"
            class="btn btn-info btn-sm mr-1"
            (click)="btnConfirmarReserva()"
          >
            {{ "recintos.confirmarreserva" | translate }}
          </button>
          <ng-template #notConfirmada>
            <button
              type="button"
              class="btn btn-danger btn-sm mr-1"
              (click)="btnCancelarReserva()"
            >
              {{ "recintos.cancelarconfirmada" | translate }}
            </button>
          </ng-template>

          <button
            type="button"
            class="btn btn-danger btn-sm mr-1"
            (click)="btnBorrarReserva()"
          >
            {{ "recintos.borrarreserva" | translate }}
          </button>
        </div>

        <div class="form-group">
          <label>Título</label>
          <div class="caja">
            <!--Titulo-->
            <kendo-textbox
              placeholder="{{ 'reservas.ph_titulo_reserva' | translate }}"
              [clearButton]="true"
              [(ngModel)]="titulo"
            >
              <ng-template kendoTextBoxPrefixTemplate> </ng-template>
            </kendo-textbox>
          </div>
        </div>
        <!--Cuerpo-->
        <div class="form-group">
          <kendo-label text="{{ 'publicaciones.cuerpo_publi' | translate }}">
            <textarea
              kendoTextArea
              style="resize: none"
              rows="5"
              [(ngModel)]="descripcion"
            >
            </textarea>
          </kendo-label>
        </div>
        <!-- Fechas Reserva-->
        <div class="form-group">
          <kendo-label
            class="k-checkbox-label"
            text="{{ 'recintos.fechainicio' | translate }}"
          ></kendo-label>
          <div class="caja">
            <kendo-datetimepicker [(value)]="fechaInicio">
            </kendo-datetimepicker>
            <kendo-datetimepicker [(value)]="fechaFin"> </kendo-datetimepicker>
          </div>
        </div>

        <!-- Traducciones -->
        <div class="form-group">
          <!--Boton Publicar-->
          <button
            type="button"
            class="btn btn-success btn-sm mr-1"
            (click)="btnActualizarReserva()"
          >
            {{ "recintos.actualizarreserva" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm mr-1"
            (click)="btnCancelar()"
          >
            {{ "botones.cancelar" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
