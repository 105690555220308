<ul class="list-group list-group-flush">
  <h4>ListaMesas</h4>
  {{
    listaMesas | json
  }}
  <div *ngFor="let col of listaColumnas">
    <h4>Columna</h4>
    {{ col | json }}
  </div>
  <div>
    <h4>Filtros Activos</h4>
    {{value |json}}
  </div>

  <div class="card-body">

    <kendo-filter #filter 
    [filters]="filters"
    [value]="value"
    (valueChange)="onFilterChange($event)"
    >
      <!--https://www.telerik.com/kendo-angular-ui/components/filter/api/CustomMessagesComponent/-->
      <kendo-filter-messages 
      addFilter="Añadir Filtro" 
      addGroup="Añadir Grupo"
      filterAfterOperator="después de"
      filterIsFalse="Es Falso"
      filterIsTrue="Es Verdadero"
      editorDateTodayText="Hoy"
      >
      </kendo-filter-messages>
    </kendo-filter>
    <button kendoButton (click)="applyFilter(filter.value)">
      Aplicar Filtro
    </button>
    <button kendoButton (click)="borrarFiltro()">
      Borrar Filtro
    </button>
  </div>

  <kendo-grid
    #firstGrid
    [data]="listaFilter"
    [height]="410"
    [filterable]="false"
    [reorderable]="true"
    (columnReorder)="reordenar($event)"
    [resizable]="true"
    (columnResize)="resizeColumn($event)"
    (dataStateChange)="onStateChange($event)"
    (cellClick)="cellClickHandler($event)"
    (cellClose)="cellCloseHandler($event)"
  >
    <kendo-grid-column
      *ngFor="let col of listaColumnas"
      [field]="col.field"
      [width]="col.width"
      [editor]="col.tipo"
      [filterable]="col.filter"
      [sticky]="col.sticky"
    >
      <ng-template
        *ngIf="col.tipo == 'string'"
        kendoGridFilterCellTemplate
        let-filter
        let-column="column"
      >
        <kendo-grid-string-filter-cell
          [showOperators]="false"
          [column]="column"
          [filter]="filter"
        >
        </kendo-grid-string-filter-cell>
      </ng-template>

      <ng-template
        *ngIf="col.tipo == 'numeric'"
        kendoGridFilterCellTemplate
        let-filter
        let-column="column"
      >
        <kendo-grid-numeric-filter-cell
          [showOperators]="false"
          [column]="column"
          [filter]="filter"
        >
        </kendo-grid-numeric-filter-cell>
      </ng-template>

      <ng-template
        *ngIf="col.tipo == 'boolean'"
        kendoGridCellTemplate
        let-dataItem
      >
        <input type="checkbox" [checked]="dataItem.activa" disabled />
      </ng-template>

      <ng-template
        *ngIf="col.tipo == 'date'"
        kendoGridCellTemplate
        let-dataItem
      >
        {{ dataItem.fecha | date: "dd/MM/yyyy" }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-messages i18n-noRecords="Sin Datoa" noRecords="Sin Datos">
    </kendo-grid-messages>
  </kendo-grid>
  <button type="button" class="btn btn-info" (click)="addColumn()">
    Add Column
  </button>
</ul>
