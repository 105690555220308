import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Idioma } from '@app/_models/idioma';
import { AlertService, MenuService } from '@app/_services';
import { IdiomasService } from '@app/_services/idiomas.service';
import { TranslateService } from '@ngx-translate/core';
import { Modulo, ModuloTraduccion } from '@app/_models/modulo';
import { ModuloService } from '@app/_services/modulos.service';

@Component({
  selector: 'app-menu-modulos',
  templateUrl: './menu-modulos.component.html',
})
export class MenuModulosComponent implements OnInit {

  id: number;
  nombre: string = '';
  urlModulo: string = '';
  html: string = '';
  imagenB64: string = '';
  lang: any;
  filePath: string;
  selectedIdioma: Idioma;
  selectedModulo: string;
  private translate: TranslateService;

  showUrl: boolean = false;
  showHtml: boolean = false;

  myModulo: Modulo = new Modulo();

 //Listas
 listaIdiomas: Idioma[] = [];
 listaIdiomasTotales: Idioma[] = [];
 listaTraducciones: Idioma[] = [];
 listaModulos: string[] = [];
 listaModulosOriginal: string[] = [];

 //Traducciones
 listaTraduccionesEnviar: ModuloTraduccion[] = [];

  constructor(
    translate: TranslateService,
    private idiomaService: IdiomasService,
    private translateService: TranslateService,
    private menuService: MenuService,
    private modulosService: ModuloService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService
    ) { 
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('menu_modulos.cabecera');

    this.loadIdiomas();
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();

    //Load Langs
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomasTotales = result.data;
      console.log('Cargados idiomas = ' + this.listaIdiomasTotales.length);
      this.listaIdiomasTotales.forEach((x) => {
        const trad = new ModuloTraduccion();
        trad.filled = false;
        trad.idIdioma = x.codigo;
        trad.nombreIdioma = x.nombre;
        this.listaTraduccionesEnviar.push(trad);
      });
    });

    //Load translated modules
    this.listaModulos.push(this.translate.instant('menu_modulos.urlexterna'));
    this.listaModulos.push(this.translate.instant('menu_modulos.html'));
    this.listaModulos.push(this.translate.instant('menu_modulos.home'));
    this.listaModulos.push(this.translate.instant('menu_modulos.faq'));
    this.listaModulos.push(this.translate.instant('menu_modulos.tienda'));

    //Load modules
    this.listaModulosOriginal.push('menu_modulos.urlexterna');
    this.listaModulosOriginal.push('menu_modulos.html');
    this.listaModulosOriginal.push('menu_modulos.home');
    this.listaModulosOriginal.push('menu_modulos.faq');
    this.listaModulosOriginal.push('menu_modulos.tienda');
  }

  loadIdiomas() {
    //dropDownControl.writeValue("old value");
    console.log('Cargando todos los idiomas');

    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomas = result.data;
      if (this.listaIdiomas, length > 0) {
        this.selectedIdioma = this.listaIdiomas[0];
      }
    });
  }
  
  btnCrearModulo(){
    console.log('Creando Modulo');
    //Validación de los datos
    if (this.listaTraducciones.length <= 0) {
      this.alertService.info(
        this.translateService.instant('publicaciones.errortrads')
      );
      return;
    }
    if (this.nombre == null || this.filePath == null) {
      this.alertService.info(
        this.translateService.instant('publicaciones.validaciondatos')
      );
      return;
    }

    //AutoCompletado de Idiomas
    //seleccionado
    let seleccionado: ModuloTraduccion = new ModuloTraduccion();
    let encontrado: boolean = false;
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (trad.filled && !encontrado) {
        seleccionado = trad;
        encontrado = true;
        return;
      }
    });
    //Fill UnFilled
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (!trad.filled) {
        trad.nombre = seleccionado.nombre;
        trad.idModulo = seleccionado.id;
        trad.html = seleccionado.html;
        this.id = seleccionado.id;
      }
    });

    console.log(this.listaTraduccionesEnviar);
    
    this.myModulo = new Modulo();
    this.myModulo.nombre = this.nombre;
    if(this.showUrl) this.myModulo.urlModulo = this.urlModulo;
    if(this.showUrl) this.myModulo.hasUrl = true;
    if(this.showHtml) this.myModulo.html = this.html;
    if(this.showHtml) this.myModulo.hasHtml = true;
    this.myModulo.fotoUrl = this.filePath.split(',')[1]; //Borramos cabecera data: base64
    this.myModulo.listaTraducciones = [];
    this.myModulo.direccion = this.translateService.instant(this.listaModulosOriginal[this.listaModulos.indexOf(this.selectedModulo)]);
    this.myModulo.idIdioma = this.selectedIdioma.codigo;
    this.myModulo.listaTraducciones = this.listaTraduccionesEnviar;

    this.modulosService
      .createModulo(this.myModulo)
      .subscribe((result) => {
        if (!result.error) {
          this.modulosService
            .addTraduccionesModulo(this.listaTraduccionesEnviar, result.codigo)
            .subscribe((resultTrads) => {
              if (!resultTrads.error) {
                this.alertService.success(
                  this.translateService.instant('publicaciones.success')
                );
                this.router.navigate(['acceso-directo']);
              } else {
                console.log('Error enviando traducciones publicacion');
                this.alertService.error(
                  this.translateService.instant('publicaciones.error')
                );
                return;
              }
            });
        } else {
          this.alertService.error(
            this.translateService.instant('publicaciones.error')
          );
          return;
        }
      });
  }

  btnAddTraduccion() {
    console.log('Comprobando datos');
    if (
      this.selectedIdioma == null ||
      this.nombre.length <= 0
    ) {
      if (this.listaTraducciones.length <= 0) {
        this.alertService.info(
          this.translateService.instant('publicaciones.validacion')
        );
        return;
      }
    }
    console.log('Datos validados --->');
    if (!this.listaTraducciones.includes(this.selectedIdioma)) {
      this.listaTraducciones.push(this.selectedIdioma);

      this.listaTraduccionesEnviar.map((x) => {
        if (x.idIdioma == this.selectedIdioma.codigo) {
          x.filled = true;
          x.nombre = this.nombre;
          x.idModulo = this.id;
          x.html = this.html;
        }
      });
    } else {
      console.log('Idioma ya añadido');
    }

    console.log(this.listaTraduccionesEnviar);
  }

  btnDeleteTraduccion(idioma: Idioma) {
    console.log(idioma);
    this.listaTraducciones = this.listaTraducciones.filter(
      (x) => x.codigo != idioma.codigo
    );

    this.listaTraduccionesEnviar.map((x) =>
      x.idIdioma == idioma.codigo ? (x.filled = false) : console.log()
    );

    console.log(this.listaTraduccionesEnviar);
  }

  //Imagen
  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0]; 
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  btnCancelar(){
    this.router.navigate(['acceso-directo']);
  }

  valueChange(value: any){
    this.selectedModulo = value;
    if(value == "URL Externa"){
      this.showUrl = true;
      this.showHtml = false;
    } else if(value == "Contenido HTML"){
      this.showHtml = true;
      this.showUrl = false;
    }
  }

}

